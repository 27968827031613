import {
  Enabled,
  GroupToTypeToEnabled,
} from '../components/pages/Settings/NotificationSettings/types';
import {
  NotificationGroup,
  NotificationSubscription,
  NotificationSubscriptionAPI,
  NotificationSubscriptionType,
} from '../store/Notification/store';

export const getNotificationEnableMap = (
  notificationGroups: NotificationGroup[],
  notificationSubscriptionTypes: NotificationSubscriptionType[],
  notificationSubscriptions: NotificationSubscription[],
  override?: {
    notificationName: string;
    notificationType: Record<'in_app' | 'email', { enabled: boolean }>;
  }
) => {
  const enableMap = notificationGroups?.reduce((acc, group) => {
    notificationSubscriptionTypes?.forEach(type => {
      const relevantSubscription = notificationSubscriptions?.find(
        subscription =>
          subscription.notificationGroupId === group.id &&
          subscription.notificationGroupSubscriptionTypeId === type.id
      );
      if (
        override?.notificationName === group.name &&
        override?.notificationType[type.name as 'in_app' | 'email']
      ) {
        acc[group.id] = {
          ...acc[group.id],
          [type.id]: {
            enabled:
              override.notificationType[type.name as 'in_app' | 'email']
                .enabled,
            original: relevantSubscription?.enabled || false,
          },
        };
      } else {
        acc[group.id] = {
          ...acc[group.id],
          [type.id]: {
            enabled: relevantSubscription?.enabled || false,
            original: relevantSubscription?.enabled || false,
          },
        };
      }
    });
    return acc;
  }, {} as GroupToTypeToEnabled);
  return enableMap;
};

export const transformNotificationToPayloadPUTBody = (
  groupsToTypes: GroupToTypeToEnabled
): NotificationSubscriptionAPI[] => {
  return Object.keys(groupsToTypes).reduce((acc, group) => {
    const groupTypes = Object.entries(groupsToTypes[group]);
    groupTypes.forEach(([type, enabled]: [string, Enabled]) => {
      acc.push({
        notification_group_id: group,
        notification_group_subscription_type_id: type,
        enabled: enabled.enabled,
      });
    });
    return acc;
  }, [] as NotificationSubscriptionAPI[]);
};

export const agentFirstLoginTranslations = {
  agentFirstLogin: {
    'AGENT.DASHBOARD.FIRST_LOGIN_MODAL.TITLE': 'Profile Setup',
    'AGENT.DASHBOARD.FIRST_LOGIN_MODAL.GREETING_MESSAGE':
      "Hey! Welcome to Society. Before you get started, let's complete the set up of your profile.",
    'AGENT.DASHBOARD.FIRST_LOGIN_MODAL.FIRST_NAME': 'First Name',
    'AGENT.DASHBOARD.FIRST_LOGIN_MODAL.LAST_NAME': 'Last Name',
    'AGENT.DASHBOARD.FIRST_LOGIN_MODAL.CONTINUE': 'Continue',
    'AGENT.DASHBOARD.FIRST_LOGIN_MODAL.UPDATE_ERROR':
      'Something went wrong while updating your details. Please try again.',
    'AGENT.DASHBOARD.FIRST_LOGIN_MODAL.LOGOUT': 'Log out and continue later',
    'AGENT.DASHBOARD.FIRST_LOGIN_MODAL.GET_AGENT_ERROR':
      'Something went wrong while getting your details. Please try again.',
  },
};

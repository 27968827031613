import { BankingLocale, BankAccount, APIPost, BankDetails } from './store';
import { FormValues, FormFields } from './models';
import { getValueByKey } from '../../utils/func.utils';

const formatAPIValues = (key: string, value: string) => {
  if (key === 'sort_code') return value.replace(/-/g, '');
  return value;
};

export const createPostPayload = (
  formValues: FormValues,
  locale: BankingLocale
): APIPost => {
  const keys = Object.keys(formValues);
  const fields = keys.reduce((acc: any, curr: string) => {
    const formField = getValueByKey(formValues, curr);
    return { ...acc, [curr]: formatAPIValues(curr, formField.value) };
  }, {});

  const { currentCountry, currentCurrency: currency } = locale;
  const { nickname, ...rest } = fields;
  return {
    nickname: nickname,
    values: rest,
    country: currentCountry.toUpperCase(),
    currency: currency.toUpperCase(),
  };
};

export const transformBankAccountAPIPayload = (
  bankDetails: BankDetails
): BankAccount => {
  const { country, currency, values, nickname, bank_details_id } = bankDetails;

  return {
    id: bank_details_id,
    details: { ...values, nickname },
    locale: {
      country,
      currency,
    },
  };
};

export const transformBankDetailsPayload = (response: any): BankAccount[] => {
  const details: BankDetails[] = response.bank_details;
  return details.reduce((acc, cur) => {
    if (cur.currency !== 'ZZ') acc.push(transformBankAccountAPIPayload(cur));
    return acc;
  }, [] as BankAccount[]);
};

export const transformPaypalDetailsPayload = (
  response: any
): BankAccount | null => {
  const details: BankDetails[] = response.bank_details;
  const paypal = details.find(account => account.currency === 'ZZ');
  return paypal ? transformBankAccountAPIPayload(paypal) : null;
};

export const transformFormFields = (formFields: FormFields): FormFields => {
  return [
    ...formFields,
    {
      field_name: 'nickname',
      display_name: 'Account Nickname',
      field_type: 'text',
      validation_regex: '',
      priority: 99,
      options: [],
      rules: [],
    },
  ];
};

export const generateDefaultFormValues = (
  formFields: FormFields
): FormValues => {
  return formFields.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.field_name]: {
        value: '',
        isValid: false,
        isRequired: true,
      },
    };
  }, {});
};

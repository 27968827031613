import { useEffect, RefObject } from 'react';
import { useDebouncedState } from '../../../hooks/useDebouncedState';

export const useExpando = (targetRef: RefObject<HTMLDivElement>) => {
  const [, dimensions, setDimensions] = useDebouncedState(
    { width: 0, height: 0 },
    100
  );

  const getDimensions = (ref: RefObject<HTMLDivElement>) => ({
    width: ref.current?.offsetWidth || 0,
    height: ref.current?.offsetHeight || 0,
  });

  useEffect(() => {
    if (targetRef.current) {
      setDimensions(getDimensions(targetRef));
    }

    const handleResize = () => {
      setDimensions(getDimensions(targetRef));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setDimensions, targetRef]);

  return {
    dimensions,
  };
};

import styled from 'styled-components';
import { Header1, Header2 } from '../../../common/Heading/Heading.styles';
import { theme } from '../../../styling/theme';

const LogoContainer = styled.div`
  margin: 0 auto;
  width: 60%;
  transform: scale(0.75);
`;

const ProfileSetupContainer = styled.div`
  background: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const ContentWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${props => (props.isMobile ? '90vw' : '50vw')};
`;

const Title = styled(Header1)`
  padding-top: 4rem;
  color: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
`;

const Greeting = styled(Header2)`
  color: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
`;

const BackLink = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? { 'padding-left': '10px', position: 'relative' }
      : {
          right: '0px',
          padding: '20px',
          position: 'absolute',
          margin: '20px',
        }};
`;

const HeadWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  position: relative;
`;

const Form = styled.form`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
`;

export default {
  LogoContainer,
  ProfileSetupContainer,
  ContentWrapper,
  Title,
  Greeting,
  Form,
  BackLink,
  HeadWrapper,
};

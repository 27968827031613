import React, { FC } from 'react';
import { useStore } from '../../../store/state';
import { FirstLogin } from '../../pages/agent/FirstLogin/FirstLogin';
import { LoadingPage } from '../../pages/LoadingPage';
import * as s from '../common.styles';

export const AgentFirstLoginWrapper: FC = ({ children }) => {
  const [{ userState }] = useStore();
  const agent = userState.agent;
  const isLoading = userState.isLoading.agent;

  if (isLoading) return <LoadingPage />;

  if (agent.firstName === null || agent.firstLogin) {
    return <FirstLogin />;
  }
  return <s.MainContentWrapper>{children}</s.MainContentWrapper>;
};

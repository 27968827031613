import React, { FC } from 'react';
import { SocialDialog } from './SocialDialog/SocialDialog';
import { useSocialDialog } from './SocialDialog/useSocialDialog';
import * as s from '../common.styles';
import { useTranslationsController } from '../../../utils/globalTranslationsController';
import { useEmailOptModal } from '../Modal/useEmailOptModal';
import { NotificationModal } from '../Modal/NotificationModal';
import { Toast } from '../../common';

const translationObject = {
  reconnect: {
    'SOCIALDIALOG.TITLE.RECONNECT': 'Reconnect to your Social Account',
    'REGISTER.BUTTON.LOGOUT': ' Log out',
  },
};

export const SocialContainer: FC = ({ children }) => {
  const props = useSocialDialog();
  const { reconnect: t } = useTranslationsController(translationObject);

  const { handleResponse, isModalOpen, showToast, setShowToast, toastMessage } =
    useEmailOptModal();

  return (
    <s.MainContentWrapper>
      {children}
      <SocialDialog
        {...props}
        titleText={t['SOCIALDIALOG.TITLE.RECONNECT']}
        titleLogout={t['REGISTER.BUTTON.LOGOUT']}
        mode="dashboard"
      />
      <NotificationModal
        handleResponse={handleResponse}
        isModalOpen={isModalOpen}
        modalType={'emailOpt'}
      />
      {showToast && (
        <Toast
          message={toastMessage}
          duration={3000}
          type={'message'}
          onClose={() => setShowToast(false)}
        />
      )}
    </s.MainContentWrapper>
  );
};

import React, { FC } from 'react';
import { InterestOption, InterestsPage, InterestsViewProps } from './types';
import marketplaceStyles from './styles/Marketplace.styles';
import profileStyles from './styles/Profile.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breakpoint } from '../../styling/breakpoints';
import { useResponsive } from '../../../hooks';

export const InterestsView: FC<InterestsViewProps> = ({
  interests,
  handleInterestsChange,
  maxTopicsReached,
  getNameHandler = x => x.name,
  disableForm,
  page,
  mode = 'normal',
}) => {
  const { layout } = useResponsive();
  const isDesktop = layout >= Breakpoint.DESKTOP;

  const s =
    page === InterestsPage.Marketplace ? marketplaceStyles : profileStyles;

  return (
    <s.InterestsList data-testid="t_interests_list">
      {interests?.map((interest: InterestOption) => (
        <s.InterestItem key={interest.name} isDesktop={isDesktop}>
          <s.CheckBoxButton
            data-active={interest.active}
            data-disabled={
              disableForm || (maxTopicsReached && !interest.active)
            }
            fitContent={true}
            disabled={disableForm || (maxTopicsReached && !interest.active)}
            aria-disabled={
              disableForm || (maxTopicsReached && !interest.active)
            }
            data-testid={`t_interest_checkbox_${interest.value}`}
            mode={mode}
          >
            {page === InterestsPage.Marketplace && (
              <s.IconCheckHolder data-active={interest.active}>
                <FontAwesomeIcon icon={'check-circle'} />
              </s.IconCheckHolder>
            )}
            <s.CheckBoxLabel>
              <s.CheckBoxInput
                type="checkbox"
                onChange={() => handleInterestsChange(interest)}
                value={interest.value}
                disabled={disableForm || (maxTopicsReached && !interest.active)}
                aria-disabled={
                  disableForm || (maxTopicsReached && !interest.active)
                }
                data-testid={`t_interest_item_${interest.value}`}
              />
              <s.CheckBoxSpan>
                <s.InterestContent>
                  <s.IconHolder>
                    <FontAwesomeIcon icon={interest.iconName} />
                  </s.IconHolder>
                  <s.InterestLabel data-testid="t_interest_text">
                    {getNameHandler(interest)}
                  </s.InterestLabel>
                </s.InterestContent>
              </s.CheckBoxSpan>
            </s.CheckBoxLabel>
          </s.CheckBoxButton>
        </s.InterestItem>
      ))}
    </s.InterestsList>
  );
};

import { ContentActions, ContentState } from './store';
import { Action } from '../state';
import { languagesPostsTransformer } from './transformers';

export const ContentReducer = (
  state: ContentState,
  action: Action
): ContentState => {
  switch (action.type) {
    case ContentActions.setKeyArray:
      return {
        ...state,
        keyArray: Array.from(new Set([...state.keyArray, action.payload])),
      };
    case ContentActions.requestTranslationsAttempt:
      return {
        ...state,
        translations: null,
        isLoading: true,
      };
    case ContentActions.requestTranslationsSuccess:
      return {
        ...state,
        translations: action.payload,
        isLoading: false,
      };
    case ContentActions.requestTranslationsFailure:
      return {
        ...state,
        translations: null,
        isLoading: false,
        error: 'there was an error receiving translations',
      };
    case ContentActions.requestLanguagesAttempt:
      return {
        ...state,
        availableLanguages: [],
        isLoadingLanguagesList: true,
      };
    case ContentActions.requestLanguagesSuccess:
      return {
        ...state,
        availableLanguages: languagesPostsTransformer(action.payload),
        isLoadingLanguagesList: false,
      };
    case ContentActions.requestLanguagesFailure:
      return {
        ...state,
        availableLanguages: [],
        isLoadingLanguagesList: false,
      };

    case ContentActions.setLocalLangCode:
      return {
        ...state,
        localLangCode: action.payload,
      };

    default:
      return state;
  }
};

import {
  CommissionItemAPImodel,
  CommissionAPImodel,
  CommissionsAPImodel,
  CommissionSummaryAPImodel,
} from '../../contexts/common/interfaces';
import {
  CommissionsByPageNumber,
  Commission,
  CommissionSummary,
  ApplicationForm,
  FormValue,
  SocialChannel,
  CampaignSummaryApi,
  CampaignSummary,
  ProgrammeCommissionsApi,
  ProgrammeCommissions,
  CommissionTarget,
  ProgrammeCommission,
  DiscountCode,
  DiscountCodeApi,
} from './store';
import {
  TrackingLink,
  TrackingLinkAPIModel,
  TrackingLinksAPIModel,
} from '../../contexts/common/interfaces';

import { Association, defaultAssociation, AssociationAPIModel } from './store';

type AssociationMode = 'programme' | 'campaign';

export const getAssociationCommissionPayload = (
  response: ProgrammeCommissionsApi,
  mode: AssociationMode
): ProgrammeCommissions | null => {
  if (!response?.fallback) return null;

  const target =
    mode === 'programme'
      ? CommissionTarget.PROGRAMME
      : CommissionTarget.CAMPAIGN;

  const targettedFallback = {
    ...response.fallback,
    target: target,
  };

  const targettedCategories: ProgrammeCommission[] = response.categories.map(
    commission => {
      return {
        ...commission,
        target: target,
      };
    }
  );

  return {
    programmeFallback: mode === 'programme' ? targettedFallback : null,
    campaignFallback: mode === 'campaign' ? targettedFallback : null,
    categories: targettedCategories,
  };
};

export const getAssociationPayload = (
  response: AssociationAPIModel,
  mode: AssociationMode
): Association => {
  const {
    influencer_id,
    programme_id,
    influencer_discount_code,
    invited_date,
    accepted_date,
    created_by_marketer_id,
    commissions,
    agreement_version_accepted,
    view_commissions,
    campaign_id,
    invitation_id,
  } = { ...response };
  return {
    ...defaultAssociation,
    influencerId: influencer_id,
    programmeId: programme_id,
    influencerDiscountCode: influencer_discount_code,
    createdByMarketerId: created_by_marketer_id,
    acceptedDate: accepted_date,
    invitedDate: invited_date,
    commissions: commissions
      ? getAssociationCommissionPayload(commissions, mode)
      : null,
    agreementVersionAccepted: agreement_version_accepted,
    viewCommissions: view_commissions,
    campaignId: campaign_id,
    invitationId: invitation_id,
  };
};

export const getAssociationsPayload = (
  response: AssociationAPIModel[]
): Association[] => {
  return response.map((association: AssociationAPIModel) =>
    getAssociationPayload(association, 'programme')
  );
};

export interface ProgrammeAgreementAPIModel {
  programme_id: string;
  version: string;
  created_at: string;
  created_by_admin: string;
  agreement: string;
}

export const transformGetProgrammeAgreementPayload = (
  response: ProgrammeAgreementAPIModel
) => {
  return {
    programmeId: response.programme_id,
    version: response.version,
    createdAt: response.created_at,
    createdByAdmin: response.created_by_admin,
    agreement: response.agreement,
  };
};

export const transformToTrackingLink = (
  response: TrackingLinkAPIModel
): TrackingLink => {
  return {
    id: response.id,
    title: response.title,
    description: response.description,
    link: response.link,
    trackingLink: response.tracking_link,
    campaignId: response.campaign_id,
    campaignName: response.campaign_name,
    createdAt: response.created_at,
  };
};

export const getTrackingLinksPayload = (
  response: TrackingLinksAPIModel
): TrackingLink[] => {
  return response.tracking_links.map((trackingLink: TrackingLinkAPIModel) =>
    transformToTrackingLink(trackingLink)
  );
};

export const getTrackingLinkPayload = (
  response: TrackingLinkAPIModel,
  currentTrackingLinks: TrackingLink[] | null
): TrackingLink[] => {
  const links = currentTrackingLinks || [];
  const freshLink = transformToTrackingLink(response);
  const doesAlreadyExist = links.some(({ id }) => id === freshLink.id);
  return !doesAlreadyExist
    ? [...links, freshLink]
    : links.map(trackingLink => {
        if (trackingLink.id === freshLink.id) {
          return freshLink;
        }

        return trackingLink;
      });
};

export const postTrackingLinksPayload = (
  response: TrackingLinkAPIModel,
  currentTrackingLinks: TrackingLink[] | null
): TrackingLink[] => {
  const newLink = transformToTrackingLink(response);
  return currentTrackingLinks ? [...currentTrackingLinks, newLink] : [newLink];
};

export const putTrackingLinksPayload = (
  updatedLink: TrackingLinkAPIModel,
  currentTrackingLinks: TrackingLink[] | null
): TrackingLink[] => {
  const updatedLinks = currentTrackingLinks?.map(
    (trackingLink: TrackingLink) => {
      return trackingLink.id === updatedLink.id
        ? transformToTrackingLink(updatedLink)
        : trackingLink;
    }
  );
  return currentTrackingLinks && updatedLinks
    ? updatedLinks
    : [transformToTrackingLink(updatedLink)];
};

export const transformItemPayload = (item: CommissionItemAPImodel) => {
  return {
    sku: item.sku,
    productName: item.product_name,
    category: item.category,
    subcategory: item.subcategory,
    value: item.value,
    commission: item.commission,
    status: item.status,
    voucherCode: item.voucher_code,
    rejectReason: item.reject_reason,
  };
};

const transformCommissionPayload = (commission: CommissionAPImodel) => {
  return {
    commissionId: commission.commission_id,
    createdAt: commission.created_at,
    status: commission.status,
    customerType: commission.customer_type,
    totalValue: commission.items.reduce((acc, item: CommissionItemAPImodel) => {
      return acc + item.value;
    }, 0),
    totalCommission: commission.items.reduce(
      (acc, item: CommissionItemAPImodel) => {
        return acc + item.commission;
      },
      0
    ),
    items: commission.items.map((item: CommissionItemAPImodel) =>
      transformItemPayload(item)
    ),
    saleSource: commission.sale_source,
    trackingLinkId: commission.tracking_link_id,
  };
};

interface CommissionResponse extends CommissionsAPImodel {
  pageNumber: number;
}
export const transformGetProgrammeCommissionsPayload = (
  response: CommissionResponse,
  currentCommissions: CommissionsByPageNumber | undefined
) => {
  const newCommissions: Commission[] =
    response.commissions && response.commissions.length
      ? response.commissions.map((commission: CommissionAPImodel) =>
          transformCommissionPayload(commission)
        )
      : [];

  const commissionPageMap: CommissionsByPageNumber = currentCommissions
    ? currentCommissions.set(
        response.pageNumber,
        response.commissions && response.commissions.length
          ? response.commissions.map((commission: CommissionAPImodel) =>
              transformCommissionPayload(commission)
            )
          : []
      )
    : new Map().set(0, newCommissions);
  return {
    commissions: commissionPageMap,
    pagination: response.pagination
      ? {
          totalCount: response.pagination.total_count,
          count: response.pagination.count,
          offset: response.pagination.offset,
        }
      : { totalCount: 0, count: 0, offset: 0 },
  };
};

export const transformCommissionSummaryPayload = (
  response: CommissionSummaryAPImodel
): CommissionSummary => {
  return {
    totalCount: response.total_count,
    totalCommission: response.total_commission,
  };
};

export const transformToApplicationFormBody = (
  application: ApplicationForm
) => {
  const getSocials = (socials: SocialChannel[]) =>
    socials.filter(social => social.channel !== '');

  return Object.entries(application).reduce(
    (
      acc,
      [key, value]: [string, FormValue<string | any | SocialChannel[]>]
    ) => {
      if (key === 'social_channels') {
        const socials = value.value as SocialChannel[];
        const newValue = getSocials(socials);

        return { ...acc, [key]: newValue };
      }
      return { ...acc, [key]: value.value };
    },
    {}
  );
};

export const transformGetCampaignPayload = (
  response: CampaignSummaryApi[]
): CampaignSummary[] => {
  return response.map(campaign => {
    return {
      invitationId: campaign.invitation_id,
      campaignId: campaign.campaign_id,
      invitationStatus: campaign.invitation_status,
      name: campaign.name,
      brandName: campaign.brand_name,
      brief: campaign.brief,
      startDate: campaign.start_date,
      endDate: campaign.end_date,
      timeZone: campaign.time_zone,
      campaignBannerId: campaign.campaign_banner_id,
      visualDirection: campaign.visual_direction,
      connectors: campaign.connectors,
      links: campaign.links,
      dos: campaign.dos,
      donts: campaign.donts,
      additionalImages: campaign.additional_images,
      programmeId: campaign.programme_id,
      isActive: campaign.is_active,
      hasExpired: campaign.has_expired,
      daysToExpiry: campaign.days_to_expiry,
    };
  });
};

export const transformDiscountCodesPayload = (
  response: DiscountCodeApi[]
): DiscountCode[] => {
  const res = response.map(discount => transformToDiscountPayload(discount));
  return res;
};

export const transformToDiscountPayload = (
  response: DiscountCodeApi
): DiscountCode => {
  return {
    id: response.id,
    name: response.name,
    code: response.code,
    influencerId: response.influencer_id,
    validFrom: response.valid_from,
    validTo: response.valid_to,
    isCommissionable: response.is_commissionable,
    programmeId: response.programme_id,
    campaignId: response.campaign_id,
    createdAt: response.created_at,
    createdBy: response.created_by,
    deletedAt: response.deleted_at,
    updatedAt: response.updated_at,
    updatedBy: response.updated_by,
  };
};

import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { theme } from '../../../../styling/theme';

interface RouterLinkProps {
  selected?: boolean;
}

const RouterLinkStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  text-decoration: none;

  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;
const RouterLink = styled(Link)<RouterLinkProps>`
  ${RouterLinkStyles};
  color: ${props =>
    props.selected
      ? theme(props).COLOUR.PRIMARY
      : theme(props).COLOUR.BACKGROUND_DARKEST};
`;

const ExternalRouterLink = styled.a<RouterLinkProps>`
  ${RouterLinkStyles};
  color: ${props =>
    props.selected
      ? theme(props).COLOUR.PRIMARY
      : theme(props).COLOUR.BACKGROUND_DARKEST};
  white-space: initial;
  width: inherit;
`;

const LinkText = styled.span`
  margin-left: 0.4em;
  height: 0em;
  padding: 0 0 0 0.25rem;
  line-height: 0rem;
`;

const RouterLinkLogout = styled(Link)<RouterLinkProps>`
  ${RouterLinkStyles}
  color: ${props =>
    props.selected
      ? theme(props).COLOUR.PRIMARY
      : theme(props).COLOUR.BACKGROUND_DARKEST};
  opacity: ${props => (props.selected ? 1.0 : 0.6)};
`;

export { RouterLink, ExternalRouterLink, LinkText, RouterLinkLogout };

import React from 'react';
import { AppRoute, RoutePairs } from '../routes';
import { Route, Redirect as ReactRedirect } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

const SentryRoute = withSentryRouting(Route);

export const getRoutesListComponents = (
  routes: RoutePairs,
  RouteType: React.FC<{ route: AppRoute }>
) => {
  return Object.entries(routes).map(entry => {
    const [key, route] = entry;
    if (!route) return null;
    return route.redirect ? (
      <ReactRedirect
        from={route.redirect.from}
        to={route.redirect.to}
        exact={route.redirect.exact}
        key={key}
      />
    ) : (
      <SentryRoute
        key={key}
        path={route.path}
        exact={route.exact}
        component={() => <RouteType route={route} />}
      />
    );
  });
};

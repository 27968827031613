import styled from 'styled-components/macro';
import { theme } from '../../styling/theme';

export const FormLabel = styled.label<{
  isBold: boolean;
  darkMode?: boolean;
  isTitleLeft?: boolean;
  hasBottomPadding?: boolean;
}>`
  color: ${props =>
    props.darkMode ? 'white' : theme(props).COLOUR.BACKGROUND_DARKEST};
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
  font-weight: ${props => (props.isBold ? '400' : '300')};
  ${props => props.isTitleLeft && `padding-right: ${theme(props).SIZE.SMALL}`}
  ${props =>
    props.hasBottomPadding && `padding-bottom: ${theme(props).SIZE.SMALL}`}
`;

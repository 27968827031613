import { TrackingLink } from '../../contexts/common/interfaces';

export enum CommissionType {
  fixed = 'fixed',
  percentage = 'percentage',
}

export enum CommissionTarget {
  PROGRAMME = 'programme',
  CAMPAIGN = 'campaign',
}

export interface ProgrammeCommission {
  type: CommissionType;
  new: number;
  returning: number;
  target: CommissionTarget;
  category?: string;
}

export interface ProgrammeCommissions {
  programmeFallback: ProgrammeCommission | null;
  campaignFallback: ProgrammeCommission | null;
  categories: ProgrammeCommission[];
}

export interface ProgrammeCommissionsApi {
  fallback?: ProgrammeCommission;
  campaign_fallback?: ProgrammeCommission[];
  categories: ProgrammeCommission[];
}

export interface Programme {
  status?: string;
  urls: string[];
  id: string;
  brand: string;
  title: string;
  created_by_admin: string;
  commissions: {
    fallback: ProgrammeCommission;
    categories: ProgrammeCommission[];
  };
  default_currency: string;
  description: string;
  banner_image_id: string | null;
  logo_image_id: string | null;
  thumbnail_image_id: string | null;
  programme_type: string | null;
}

export interface Association {
  influencerId: string | null;
  programmeId: string | null;
  influencerDiscountCode?: string;
  createdByMarketerId: string | null;
  invitedDate: string | null;
  acceptedDate: string | null;
  agreementVersionAccepted: number;
  commissions: ProgrammeCommissions | null;
  viewCommissions: boolean;
  campaignId: string | null;
  invitationId: string | null;
}

export interface ProgrammeAgreement {
  programmeId: string;
  version: string;
  createdAt: string;
  createdByAdmin: string;
  agreement: string;
}
export const pageLimits = [15, 30, 60];

export enum DateType {
  START = 'start',
  END = 'end',
}

export interface DiscountCode {
  id: string;
  name: string;
  code: string;
  influencerId: string;
  validFrom: string;
  validTo?: string;
  isCommissionable: boolean;
  programmeId: string;
  campaignId?: string;
  createdAt: string;
  createdBy: string;
  deletedAt?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export type CommissionsByPageNumber = Map<number, Commission[]>;

export interface CommissionItem {
  sku: string;
  productName: string;
  category: string;
  subcategory: string;
  value: number;
  commission: number;
  status: string;
  voucherCode: string | null;
  rejectReason: string | null;
}
export interface Commission {
  commissionId: string;
  createdAt: string;
  status: string;
  customerType: string;
  totalValue: number;
  totalCommission: number;
  saleSource: string;
  items: CommissionItem[];
  trackingLinkId: string | null;
}
export interface Commissions {
  commissions: CommissionsByPageNumber;
  pagination: {
    totalCount: number;
    offset: number;
    count: number;
  };
}

export interface CommissionSummary {
  totalCount: number;
  totalCommission: number;
}
export interface CampaignLinks {
  title: string;
  description: string;
  destination_url: string;
}

export interface CampaignApi {
  campaign_id: string;
  name: string;
  brand_name: string;
  brief: string;
  start_date: string;
  end_date: string;
  time_zone: string;
  campaign_banner_id: string;
  visual_direction: string[];
  connectors: string[];
  links: CampaignLinks[];
  dos: string[];
  donts: string[];
  additional_images: string[];
  programme_id: string;
  budget?: number;
  is_active: boolean;
  has_expired: boolean;
  days_to_expiry?: number;
}

export interface Campaign {
  campaignId: string;
  name: string;
  brandName: string;
  brief: string;
  startDate: string;
  endDate: string;
  timeZone: string;
  campaignBannerId: string;
  visualDirection: string[];
  connectors: string[];
  links: CampaignLinks[];
  dos: string[];
  donts: string[];
  additionalImages: string[];
  programmeId: string;
  budget?: number;
  isActive: boolean;
  hasExpired: boolean;
  daysToExpiry?: number;
}

export interface CampaignSummaryApi extends CampaignApi {
  invitation_status: string;
  invitation_id: string | null;
}

export interface DiscountCodeApi {
  campaign_id: string;
  code: string;
  id: string;
  influencer_id: string;
  is_commissionable: boolean;
  name: string;
  programme_id: string;
  created_at: string;
  created_by: string;
  deleted_at: string;
  updated_at: string;
  updated_by: string;
  valid_from: string;
  valid_to: string;
}

export interface CampaignSummary extends Campaign {
  invitationStatus: string;
  invitationId: string | null;
}

export interface ApplicationInfo {
  programme_id: string;
  title: string;
  banner_image_id: string | null;
  logo_image_id: string;
  thumbnail_image_id: string;
}
export interface SocialChannel {
  channel: string;
  username: string;
  followers: string;
}
export const defaultSocialList = [
  { channel: '', username: '', followers: '' },
  { channel: '', username: '', followers: '' },
];
export interface FormValue<T> {
  value: T;
  valid: boolean;
  dirty: boolean;
}
export interface ApplicationForm {
  [index: string]: FormValue<any>;
  first_name: FormValue<string>;
  last_name: FormValue<string>;
  email_address: FormValue<string>;
  primary_audience_region: FormValue<string>;
  secondary_audience_region: FormValue<string | null>;
  categories_need_help: FormValue<string | null>;
  time_creating: FormValue<string | null>;
  social_channels: FormValue<SocialChannel[]>;
}
export interface ApplicationFormAPI {
  [index: string]: any;
  first_name: string;
  last_name: string;
  email_address: string;
  primary_audience_region: string;
  secondary_audience_region: string | null;
  categories_need_help: string | null;
  time_creating: string | null;
  social_channels: SocialChannel[];
}

export const defaultApplicationForm: ApplicationForm = {
  first_name: { value: '', valid: false, dirty: false },
  last_name: { value: '', valid: false, dirty: false },
  email_address: { value: '', valid: false, dirty: false },
  primary_audience_region: { value: '', valid: false, dirty: false },
  secondary_audience_region: { value: null, valid: false, dirty: false },
  categories_need_help: { value: null, valid: false, dirty: false },
  time_creating: { value: null, valid: false, dirty: false },
  social_channels: { value: defaultSocialList, valid: false, dirty: false },
};

export interface FetchingProgrammeState {
  agreement: boolean;
  programme: boolean;
  association: boolean;
  invitation: boolean;
  discountCodes: boolean;
  commissions: boolean;
  commissionSummary: boolean;
  commissionOverview: boolean;
  trackingLinks: boolean;
  postTrackingLink: boolean;
  updateTrackingLink: boolean;
  campaignTrackingLinks: boolean;
  postCampaignTrackingLink: boolean;
  updateCampaignTrackingLink: boolean;
  getApplication: boolean;
  postApplication: boolean;
  postApplicationReminder: boolean;
  campaigns: boolean;
  walletProgrammes: boolean;
  userProgrammeAssociations: boolean;
}

export interface ErrorsProgrammeState {
  getApplication: string | null;
  postApplication: string | null;
  postApplicationReminder: string | null;
  getWalletProgrammes: string | null;
}

export interface ProgrammeState {
  currentAssociation: Association | null;
  currentProgramme: Programme | null;
  currentProgrammeAgreement: ProgrammeAgreement | null;
  currentTrackingLinks: TrackingLink[] | null;
  currentCampaignTrackingLinks: TrackingLink[] | null;
  applicationInfo: ApplicationInfo | null;
  applicationForm: ApplicationForm;
  isFetching: FetchingProgrammeState;
  error: string | null;
  errors: ErrorsProgrammeState;
  postLinkError: string | null;
  postCampaignLinkError: string | null;
  updateLinkError: string | null;
  updateCampaignLinkError: string | null;
  programmes: Programme[] | null;
  walletProgrammes: Programme[] | null;
  discountCodes: DiscountCode[] | null;
  associations: Association[] | null;
  commissions: Commissions | null;
  commissionFilteredSummary: CommissionSummary | null;
  commissionOverview: CommissionSummary | null;
  postAssociationFeedbackOpen: boolean;

  campaigns: CampaignSummary[] | null;
  campaignInvitations: CampaignSummary[] | null;
  campaignError: string | null;
  userProgrammeAssociations: string[] | null;
}

export interface AssociationPostBody {
  invitation_id: string;
  agreement_version_accepted?: number;
  custom_agreement_accepted?: boolean;
}

export interface AssociationAPIModel {
  influencer_id: string;
  key_cloak_id: string;
  programme_id: string;
  influencer_discount_code?: string;
  invited_date: string;
  accepted_date: string;
  created_by_marketer_id: string;
  agreement_version_accepted: number;
  commissions: ProgrammeCommissionsApi | null;
  view_commissions: boolean;
  campaign_id: string | null;
  invitation_id: string | null;
}

export interface AssociationPostReturn {
  associations: AssociationAPIModel[];
  userId: string;
}

export const defaultAssociation: Association = {
  influencerId: null,
  programmeId: null,
  influencerDiscountCode: '',
  createdByMarketerId: null,
  acceptedDate: null,
  invitedDate: null,
  commissions: {
    programmeFallback: {
      type: CommissionType.fixed,
      new: 0,
      returning: 0,
      target: CommissionTarget.PROGRAMME,
    },
    campaignFallback: null,
    categories: [],
  },
  agreementVersionAccepted: 0,
  viewCommissions: false,
  campaignId: null,
};

export const defaultAssociationAPIModel: AssociationAPIModel = {
  influencer_id: '',
  key_cloak_id: '',
  programme_id: '',
  influencer_discount_code: '',
  invited_date: '',
  accepted_date: '',
  created_by_marketer_id: '',
  commissions: {
    fallback: {
      type: CommissionType.fixed,
      new: 0,
      returning: 0,
      target: CommissionTarget.PROGRAMME,
    },
    categories: [],
  },

  agreement_version_accepted: 0,
  view_commissions: false,
  campaign_id: null,
};

export const defaultProgrammeState: ProgrammeState = {
  currentAssociation: null,
  currentProgramme: null,
  currentProgrammeAgreement: null,
  currentTrackingLinks: null,
  currentCampaignTrackingLinks: null,
  applicationInfo: null,
  applicationForm: defaultApplicationForm,

  isFetching: {
    agreement: false,
    association: false,
    programme: false,
    invitation: false,
    discountCodes: false,
    commissions: false,
    commissionSummary: false,
    commissionOverview: false,
    trackingLinks: false,
    campaignTrackingLinks: false,
    postTrackingLink: false,
    postCampaignTrackingLink: false,
    updateTrackingLink: false,
    updateCampaignTrackingLink: false,
    getApplication: false,
    postApplication: false,
    postApplicationReminder: false,
    campaigns: false,
    walletProgrammes: false,
    userProgrammeAssociations: false,
  },
  errors: {
    getApplication: null,
    postApplicationReminder: null,
    postApplication: null,
    getWalletProgrammes: null,
  },

  error: null,
  postLinkError: null,
  postCampaignLinkError: null,
  updateLinkError: null,
  updateCampaignLinkError: null,
  programmes: null,
  associations: null,
  discountCodes: null,
  commissions: null,
  commissionFilteredSummary: null,
  commissionOverview: null,
  postAssociationFeedbackOpen: false,

  campaigns: null,
  campaignInvitations: null,
  campaignError: null,
  walletProgrammes: null,

  userProgrammeAssociations: null,
};

export const defaultCommission: ProgrammeCommission = {
  new: 0,
  returning: 0,
  type: CommissionType.fixed,
  target: CommissionTarget.PROGRAMME,
};

export * from './reducer';
export * from './actions';
export * from './requests';

import styled, { css } from 'styled-components';
import { theme } from '../../../../styling/theme';
import { SocialButton } from '../SocialButton';

export const YoutubeButton = styled(SocialButton)<{
  fullWidth?: boolean;
  monochrome?: boolean;
}>`
  background: ${props => theme(props).COLOUR.BRAND_YOUTUBE};

  ${props =>
    props.monochrome &&
    css`
      background: white;
      color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
    `}
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

import React, { FC } from 'react';
import styled from 'styled-components/macro';

export const Message = styled.div`
  color: red;
`;

interface ValidationMessageProp {
  message?: string;
  isValid: boolean;
  testId?: string;
}

export const ValidationMessage: FC<ValidationMessageProp> = ({
  message,
  isValid,
  testId,
}) => {
  return isValid ? null : <Message data-testid={testId}>{message}</Message>;
};

import dayjs from 'dayjs';
import {
  CampaignLink,
  CampaignLinkAPI,
  ReadCampaign,
  ReadCampaignAPI,
} from '.';

const transformCampaignFromApi = (
  readCampaign: ReadCampaignAPI
): ReadCampaign => {
  return {
    ...readCampaign,
    brandName: readCampaign.brand_name,
    startDate: dayjs(readCampaign.start_date),
    endDate: dayjs(readCampaign.end_date),
    timeZone: readCampaign.time_zone,
    campaignBannerId: readCampaign.campaign_banner_id,
    visualDirection: readCampaign.visual_direction,
    additionalImages: readCampaign.additional_images,
    links: transformLinksFromApi(readCampaign.links),
    programmeId: readCampaign.programme_id,
    campaignId: readCampaign.campaign_id,
    isActive: readCampaign.is_active,
    hasExpired: readCampaign.has_expired,
    daysToExpiry: readCampaign.days_to_expiry,
  };
};

const transformLinksFromApi = (links: CampaignLinkAPI[]): CampaignLink[] => {
  return links.map(link => {
    return {
      ...link,
      destinationUrl: link.destination_url,
    };
  });
};

export const CampaignsTransformers = {
  transformCampaignFromApi,
  transformLinksFromApi,
};

import React, { FC } from 'react';
import {
  Step,
  StepContent,
  StepIcon,
  StepperLink,
  StepperSubtitle,
  StepperTitle,
  StepperWrapper,
  StepTitle,
} from './Stepper.styles';
import { Step as StepType, StepperInternalProps } from './types';
import { Alignment, Box } from '../Box';
import { Spacer } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Expando } from '../Expando/index';

export const Stepper: FC<StepperInternalProps> = ({
  title,
  introduction,
  subtitle,
  steps,
  backLink,
  currentIndex,
  setIndexHandler,
  disableClick = false,
  onTitleClick,
}) => {
  return (
    <StepperWrapper>
      <Box flex justify={Alignment.SPACE_BETWEEN} align={Alignment.CENTER}>
        {backLink && (
          <StepperLink href={backLink.href} onClick={backLink.onClick}>
            {`< ${backLink.displayText}`}
          </StepperLink>
        )}
        {title && (
          <>
            <StepperTitle>{title}</StepperTitle>
            <span></span>
          </>
        )}
      </Box>
      {title && <Spacer isVertical />}
      {introduction && (
        <>
          <div>{introduction}</div>
          <Spacer isVertical />
        </>
      )}
      <StepperSubtitle>{subtitle}</StepperSubtitle>
      <Spacer isVertical />
      <ul>
        {steps.map((item: StepType, i) => {
          const { title, content } = item;
          if (currentIndex > i)
            return (
              <Step
                key={title}
                onClick={disableClick ? undefined : () => setIndexHandler(i)}
              >
                <StepIcon visited>
                  <FontAwesomeIcon icon="check" />
                </StepIcon>
                <StepContent>
                  <StepTitle onClick={onTitleClick} visited>
                    {title}
                  </StepTitle>
                </StepContent>
              </Step>
            );
          if (currentIndex === i)
            return (
              <Step
                key={title}
                onClick={disableClick ? undefined : () => setIndexHandler(i)}
              >
                <StepIcon>{i + 1}</StepIcon>
                <StepContent>
                  <StepTitle onClick={onTitleClick} active>
                    {title}
                  </StepTitle>
                  <Expando>{content}</Expando>
                </StepContent>
              </Step>
            );
          if (currentIndex < i)
            return (
              <Step
                key={title}
                onClick={disableClick ? undefined : () => setIndexHandler(i)}
              >
                <StepIcon>{i + 1}</StepIcon>{' '}
                <StepContent>
                  <StepTitle onClick={onTitleClick}>{title}</StepTitle>
                </StepContent>
              </Step>
            );
        })}
      </ul>
    </StepperWrapper>
  );
};

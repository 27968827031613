import styled, { css } from 'styled-components/macro';
import { SocietyLogo } from '../../../common/Logo';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';

const NavContainer = styled.nav`
  position: fixed;
  left: 0;
  width: 100%;
  height: ${props => theme(props).SIZE.MOBILE_TOP};
  background: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  display: grid;
  grid-gap: ${px2rem(10)};
  align-items: center;
  grid-template-columns: 1fr 2fr 1fr;
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1};
  box-shadow: 0 0 ${px2rem(7)} 0 rgba(0, 0, 0, 0.1);
  z-index: 6;
  & ${SocietyLogo} svg {
    height: ${px2rem(24)};
    margin: 0;
  }
`;

const BarsContainer = styled.div`
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1};
  margin-left: ${px2rem(20)};
  display: flex;
  justify-content: flex-start;
`;

const BellContainer = styled.div`
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1};
  margin-right: ${px2rem(20)};
  display: flex;
  justify-content: flex-end;
`;

const BalanceContainer = styled.div`
  border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
  height: 1.1em;
  width: 3.2em;
  display: flex;
  justify-content: center;
`;

const TextColor = styled.div`
  color: white;
`;

const Hamburger = styled.button<{ isChanging: boolean }>`
  all: unset;
  &:focus {
    box-shadow: 0 0 ${px2rem(4)} ${px2rem(2)} #ffffff52;
  }
  width: ${px2rem(37)};
  height: ${px2rem(35)};
  position: relative;
  transform: rotate(0deg) scale(0.75);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: white;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 5px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  span:nth-child(2) {
    top: 15px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  span:nth-child(3) {
    top: 25px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  ${props =>
    props.isChanging &&
    css`
      span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 2px;
        left: 5px;
      }
      span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 28px;
        left: 5px;
      }
    `}
`;

export {
  Hamburger,
  NavContainer,
  BarsContainer,
  BalanceContainer,
  TextColor,
  BellContainer,
};

import { DispatchFn } from '../state';
import client, { Microservice } from '../../utils/http.utils';
import { Translations } from './store';
import { createMessagesBody, transformMessages } from './transformers';
import { captureException } from '../../utils/sentry.utils';

export enum ContentActions {
  setKeyArray = 'SET_KEY_ARRAY',

  requestTranslationsAttempt = 'REQUEST_GET_TRANSLATIONS_ATTEMPT',
  requestTranslationsSuccess = 'REQUEST_GET_TRANSLATIONS_SUCCESS',
  requestTranslationsFailure = 'REQUEST_GET_TRANSLATIONS_FAILURE',

  requestLanguagesAttempt = 'REQUEST_GET_LANGUAGES_ATTEMPT',
  requestLanguagesSuccess = 'REQUEST_GET_LANGUAGES_SUCCESS',
  requestLanguagesFailure = 'REQUEST_GET_LANGUAGES_FAILURE',

  setLocalLangCode = 'SET_LOCAL_LANG_CODE',
}

export const SetTranslationKeyArray = (dispatch: DispatchFn, key: string) => {
  dispatch({
    type: ContentActions.setKeyArray,
    payload: key,
  });
};

export const RequestTranslationsAsync = async (
  dispatch: DispatchFn,
  changedKeys: string[],
  translations: Translations,
  locale = 'en-GB'
): Promise<void> => {
  dispatch({ type: ContentActions.requestTranslationsAttempt });
  try {
    const response = await client.requestNoAuth<{ messages: Translations }>(
      Microservice.LOCALISATION,
      '/localisation/message',
      'POST',
      createMessagesBody(changedKeys, locale)
    );
    dispatch({
      type: ContentActions.requestTranslationsSuccess,
      payload: transformMessages(response.messages, changedKeys, translations),
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ContentActions.requestTranslationsFailure,
    });
  }
};

export const RequestLanguagesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  dispatch({ type: ContentActions.requestLanguagesAttempt });
  try {
    const response = await client.requestNoAuth<Translations>(
      Microservice.LOCALISATION,
      `/localisation/language/active`,
      'GET'
    );
    dispatch({
      type: ContentActions.requestLanguagesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ContentActions.requestLanguagesFailure,
    });
  }
};

export const SetLocalLangCode = async (dispatch: DispatchFn, code: string) => {
  dispatch({ type: ContentActions.setLocalLangCode, payload: code });
};

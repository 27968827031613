import React from 'react';
import { PulseBubble, PulseContainer } from './Pulse.styles';

export enum Scale {
  HALF = 1,
  FULL = 2,
}

export const Pulse: React.FC<{
  visible: boolean;
  scale?: Scale;
  testId?: string;
}> = ({ visible, scale, testId }) => (
  <>
    {visible && (
      <PulseContainer data-testid={testId || 't_pulse'} scale={scale}>
        <PulseBubble variant={0} />
        <PulseBubble variant={1} />
        <PulseBubble variant={2} />
      </PulseContainer>
    )}
  </>
);

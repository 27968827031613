import { useState, useEffect } from 'react';
import { useStore } from '../../../store/state';
import { useTranslationsController } from '../../../utils/globalTranslationsController';
import {
  RequestGetNotificationGroupsAsync,
  RequestGetNotificationSubscriptions,
  RequestGetSubscriptionTypesAsync,
  RequestPutNotificationSubscriptions,
} from '../../../store/Notification/actions';
import {
  getNotificationEnableMap,
  transformNotificationToPayloadPUTBody,
} from '../../../utils/notification';
import { translations } from '../translations';

export const useEmailOptModal = () => {
  const [{ userState, notificationState }, dispatch] = useStore();
  const [initialised, setInitialised] = useState(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const kcId = userState.user?.key_cloak_id;

  const { emailOptModal: t } = useTranslationsController(translations);

  useEffect(() => {
    const hasSetSocialMarketingPopUp = localStorage.getItem(
      'THGSocietySocialMarketingEmailPopUp'
    );
    if (!hasSetSocialMarketingPopUp && !initialised && kcId) {
      RequestGetSubscriptionTypesAsync(dispatch);
      RequestGetNotificationSubscriptions(dispatch, kcId);
      RequestGetNotificationGroupsAsync(dispatch);
      setInitialised(true);
    }
  }, [dispatch, initialised, kcId]);

  useEffect(() => {
    const hasSetSocialMarketingPopUp = localStorage.getItem(
      'THGSocietySocialMarketingEmailPopUp'
    );
    if (
      !hasSetSocialMarketingPopUp &&
      notificationState.notificationSubscriptions &&
      notificationState.notificationGroups
    ) {
      const marketingEmailOptFieldName =
        notificationState.notificationGroups?.find(
          group => group.name === 'society_marketing'
        );
      const subscriptions = notificationState.notificationSubscriptions?.find(
        o => o.notificationGroupId === marketingEmailOptFieldName?.id
      );

      if (!marketingEmailOptFieldName || !subscriptions) {
        setIsModalOpen(true);
      } else {
        localStorage.setItem('THGSocietySocialMarketingEmailPopUp', 'wasShown');
      }
    }
  }, [
    notificationState.notificationGroups,
    notificationState.notificationSubscriptions,
  ]);

  const handleResponse = (hasAccepted: boolean) => {
    if (
      notificationState.notificationSubscriptions &&
      notificationState.notificationSubscriptionTypes &&
      notificationState.notificationGroups
    ) {
      const enabledMap = getNotificationEnableMap(
        notificationState.notificationGroups,
        notificationState.notificationSubscriptionTypes,
        notificationState.notificationSubscriptions,
        {
          notificationName: 'society_marketing',
          notificationType: {
            email: { enabled: hasAccepted },
            in_app: { enabled: false },
          },
        }
      );

      const requestBody = transformNotificationToPayloadPUTBody(enabledMap);
      kcId &&
        RequestPutNotificationSubscriptions(dispatch, kcId, requestBody).then(
          () => {
            setToastMessage(t['EMAIL.OPT.SUCCESS.TEXT']);
            setShowToast(true);
            setTimeout(() => {
              setIsModalOpen(false);
            }, 1000);
          }
        );
    }
    localStorage.setItem('THGSocietySocialMarketingEmailPopUp', 'wasShown');
    setIsModalOpen(false);
  };

  return {
    handleResponse,
    isModalOpen,
    showToast,
    setShowToast,
    toastMessage,
  };
};

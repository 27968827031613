import { useState } from 'react';
import {
  RequestGetYoutubeStatusAsync,
  RequestPostYoutubeTokenAsync,
  YoutubeTokenPayload,
} from '../../../../../store/Social/store';
import { useStore } from '../../../../../store/state';
import { SetRegistrationComplete } from '../../../../../store/User/actions';
import { captureException } from '../../../../../utils/sentry.utils';

export const useYoutubeLogin = (
  getStore = useStore,
  postTokenAction = RequestPostYoutubeTokenAsync,
  getSessionAction = RequestGetYoutubeStatusAsync,
  setRegistrationComplete = SetRegistrationComplete
) => {
  const [{ userState, socialState }, dispatch] = getStore();
  const isLoading =
    !!socialState.isFetching.youtube || !!socialState.isUpdating.youtube;

  const [youtubeErrorMessage, setYoutubeErrorMessage] = useState<string | null>(
    null
  );

  const successHandler = (res: any) => {
    const influencerId = userState.user?.influencer_id;
    if (influencerId) {
      const payload: YoutubeTokenPayload = {
        user_code: res.code,
      };
      postTokenAction(dispatch, payload, influencerId)
        .then(() => {
          getSessionAction(dispatch, influencerId);
          !userState.isRegistrationComplete &&
            setRegistrationComplete(dispatch, true);
        })
        .catch((e: Error) => {
          setYoutubeErrorMessage(e.message);
        });
    }
  };

  const failureHandler = (error: any) => {
    captureException(error);
  };

  return {
    successHandler,
    failureHandler,
    isLoading,
    youtubeErrorMessage,
  };
};

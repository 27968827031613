export const marketplaceTranslations = {
  marketplace: {
    'MARKETPLACE.H1': 'Connect',
    'MARKETPLACE.LABEL.PRODUCTS': 'Products',
    'MARKETPLACE.LABEL.RETAILERS': 'Retailers',
    'MARKETPLACE.LABEL.FAVOURITES': 'Favourites',
    'MARKETPLACE.LABEL.PERFORMANCE': 'Performance',
    'MARKETPLACE.BUTTON.SHOW_MORE': 'Show More',
    'MARKETPLACE.LABEL.PRODUCT_DETAILS': 'Product Details',
    'MARKETPLACE.MODAL.TERMS.TITLE': 'THG Society - Connect Terms',
    'MARKETPLACE.MODAL.TERMS.REDIRECT.LATER': `I'll do it later`,
    'MARKETPLACE.MODAL.TERMS.ACCEPT': 'Accept',
  },
  productCard: {
    'MARKETPLACE.PRODUCT_CARD.PRICE': 'Price: ',
    'MARKETPLACE.PRODUCT_CARD.EST_COMMISSION': 'Est. Commission: ',
    'MARKETPLACE.PRODUCT_CARD.CREATE_LINK': 'Create Link',
    'MARKETPLACE.PRODUCT_CARD.GET_LINK': 'Get Link',
    'MARKETPLACE.PRODUCT_CARD.AT': 'At',
    'MARKETPLACE.PRODUCT_CARD.BY': 'By',
    'MARKETPLACE.PRODUCT_CARD.CLOSE': 'Close',
    'MARKETPLACE.PRODUCT_CARD.ADD_TO_FAVOURITES': 'Added product to favourites',
    'MARKETPLACE.PRODUCT_CARD.REMOVE_FROM_FAVOURITES':
      'Removed product from favourites',
    'MARKETPLACE.PRODUCT_CARD.FAVOURITES.EMPTY':
      'You do not have any favourite products yet.',
    'MARKETPLACE.PRODUCT_CARD.GET_LINK.ERROR':
      'Unable to fetch tracking link. Please try again later.',
    'MARKETPLACE.PRODUCT_CARD.CREATE_LINK.ERROR':
      'Unable to create a tracking link. Please try again later.',
    'MARKETPLACE.PRODUCT_CARD.CREATE_LINK.LOADING':
      'Please allow up to a minute for the tracking link to appear. You can close this and access the link later.',
    'FEEDBACKFORM.TRACKING.LINK.PROMPT': 'How are you finding Connect? ',
    'FEEDBACKFORM.TRACKING.LINK.PROMPT.LINKTEXT': 'Share Feedback',
    'MARKETPLACE.PRODUCT_CARD.AD_NOTICE':
      'Please remember to always correctly label your content with #AD',
    'FEEDBACKFORM.TRACKING.LINK.PROMPT.QUESTION.MARK': '?',
  },
  productCount: {
    'MARKETPLACE.PRODUCT_COUNT.NO_RESULTS': 'No results',
    'MARKETPLACE.PRODUCT_COUNT.ALL_PRODUCTS': 'All products',
  },
  performance: {
    'MARKETPLACE.PERFORMANCEORDER.LIST': 'Orders list',
    'MARKETPLACE.PERFORMANCEORDER.HEADING.DATE': 'Date',
    'MARKETPLACE.PERFORMANCEORDER.HEADING.RETAILER': 'Retailer',
    'MARKETPLACE.PERFORMANCEORDER.HEADING.VALUE': 'Value',
    'MARKETPLACE.PERFORMANCEORDER.HEADING.COMMISSION': 'Commission',
    'MARKETPLACE.PERFORMANCEORDER.HEADING.STATUS': 'Status',
    'MARKETPLACE.PERFORMANCEORDER.PAGEBUTTONS.ARIATEXT.NEXT': 'Next page',
    'MARKETPLACE.PERFORMANCEORDER.PAGEBUTTONS.ARIATEXT.PREV': 'Previous page',
    'MARKETPLACE.PERFORMANCEORDER.SELECT.LIMIT': 'Per page',
    'MARKETPLACE.PERFORMANCEORDER.SELECTED':
      'There have been no orders tracked so far.',
  },
  search: {
    'MARKETPLACE.SEARCH.PLACEHOLDER': 'Search for a Product or a Retailer',
    'MARKETPLACE.SEARCH.SEARCH': 'Search',
    'MARKETPLACE.SEARCH.RESET': 'Reset',
    'MARKETPLACE.SEARCH.BACK': 'Back',
    'MARKETPLACE.SEARCH.NO_RESULTS': 'No results found for',
  },
  errors: {
    'MARKETPLACE.PRODUCTLIST.ERROR': 'There was an error fetching products.',
    'MARKETPLACE.INTERESTS.ERROR':
      'An error occurred while fetching interests.',
    'MARKETPLACE.RETAILERS.ERROR': 'There was an error fetching retailers.',
    'MARKETPLACE.TOTAL.TRACKING.LINKS.ERROR':
      'There was an error fetching total created tracking links..',
    'MARKETPLACE.PERFORMANCE.ERROR':
      'There has been an error fetching performance',
    'MARKETPLACE.FAVOURITE.PRODUCTLIST.ERROR':
      'There was an error fetching favourite products.',
    'TOAST.FEEDBACK.SUBMITERROR':
      'There has been an error submitting your feedback.',
    'TOAST.FEEDBACK.SUBMITCONFIRM':
      'Your feedback has been submitted. Thank you!',
    'MARKETPLACE.MODAL.TERMS.ERROR': 'Error occurred while accepting terms',
  },

  waitlistModal: {
    'MARKETPLACE.WAITLIST.MODAL.REMIND': 'Remind me later',
    'MARKETPLACE.WAITLIST.MODAL.JOIN.BUTTON': 'Join the waitlist',
    'MARKETPLACE.WAITLIST.GENERATE.TRACKING.LINKS.TEXT':
      "Generate tracking links for products without joining the brand's programme",
    'MARKETPLACE.WAITLIST.ACCESS.PRODUCT.CATALOGUE.TEXT':
      'Access product catalogue of individual brands from within Society',
    'MARKETPLACE.WAITLIST.COMMISSION.RATES.TEXT':
      "Benefit from THG's brand relations for competitive commission rates",
    'MARKETPLACE.WAITLIST.MODAL.INTERACT.TEXT':
      'We are introducing a new way that you can interact with Brands!',
    'MARKETPLACE.WAITLIST.MODAL.JOIN.TITLE': 'Join the waitlist today!',
    'MARKETPLACE.WAITLIST.MODAL.INSIGHTS.TEXT':
      'In-depth insights on the products and brands that your audience is buying',
    'MARKETPLACE.WAITLIST.JOIN.WAITLIST.TEXT':
      'You can join the waitlist today to be one of the first users of this new Society offering',
    'MARKETPLACE.WAITLIST.SUCCESS.TEXT': 'Great, you are now in the waitlist!',
  },
  interests: {
    'MARKETPLACE.INTERESTS.SUBMIT': "Let's go",
    'MARKETPLACE.INTERESTS.HEADER':
      'Choose between one and five industries that you would like to promote',
  },
  feedbackForm: {
    'FEEDBACKFORM.TRACKING.LINK.QUESTION1':
      'Connect made it easier for me to find a product that I want to promote',
    'FEEDBACKFORM.TRACKING.LINK.QUESTION2':
      'How likely are you to recommend Connect to a friend or colleague?',
    'FEEDBACKFORM.TRACKING.LINK.QUESTION3':
      'What improvements or changes would you suggest for Connect?',
    'FEEDBACKFORM.TRACKING.LINK.TITLE': 'Connect Feedback',
    'FEEDBACKFORM.TRACKING.LINK.DETAILS':
      'Thank you for providing your feedback! We`re constantly striving to improve our platform and your insights would be invaluable in helping us enhance your experience.',
    'FEEDBACKFORM.TRACKING.LINK.SUCCESSMESSAGE': 'You have successfully',
  },
  filters: {
    'MARKETPLACE.FILTERS.SELECT.LIMIT': 'Per page',
    'MARKETPLACE.FILTERS.SUBHEADER': 'Filters',
    'MARKETPLACE.FILTERS.TOGGLE': 'Toggle Filters',
    'MARKETPLACE.FILTERS.CLEAR_ALL': 'Clear all',
    'MARKETPLACE.FILTERS.CLEAR': 'Clear',
    'MARKETPLACE.FILTERS.APPLY': 'Apply',
    'MARKETPLACE.FILTERS.BUTTON.SELECT_ALL': 'Select all',
  },
};

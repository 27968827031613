import { DispatchFn } from '../state';
import client, { Microservice } from '../../utils/http.utils';
import { InvitationApi } from './store';
import { captureException } from '../../utils/sentry.utils';

export enum InvitationActions {
  requestGetInvitationsAttempt = 'REQUEST_GET_INVITATIONS_ATTEMPT',
  requestGetInvitationsSuccess = 'REQUEST_GET_INVITATIONS_SUCCESS',
  requestGetInvitationsFailure = 'REQUEST_GET_INVITATIONS_FAILURE',

  requestGetInvitationAttempt = 'REQUEST_GET_INVITATION_ATTEMPT',
  requestGetInvitationSuccess = 'REQUEST_GET_INVITATION_SUCCESS',
  requestGetInvitationFailure = 'REQUEST_GET_INVITATION_FAILURE',

  requestPutInvitationAttempt = 'REQUEST_PUT_INVITATION_ATTEMPT',
  requestPutInvitationSuccess = 'REQUEST_PUT_INVITATION_SUCCESS',
  requestPutInvitationFailure = 'REQUEST_PUT_INVITATION_FAILURE',

  requestPatchInvitationDeclineAttempt = 'REQUEST_PATCH_INVITATION_DECLINE_ATTEMPT',
  requestPatchInvitationDeclineSuccess = 'REQUEST_PATCH_INVITATION_DECLINE_SUCCESS',
  requestPatchInvitationDeclineFailure = 'REQUEST_PATCH_INVITATION_DECLINE_FAILURE',

  setInvitesNeedRefresh = 'SET_INVITES_NEED_REFRESH',

  clearCurrentInvitation = 'CLEAR_CURRENT_INVITATION',
}

export enum InvitationErrors {
  requestGetInvitationsError = 'An error occurred while fetching the invitations.',
  requestGetInvitationError = 'An error occurred while fetching the invitation.',
  requestPutInvitationError = 'An error occurred while updating the invitation.',
  requestPatchInvitiationDeclineError = 'An error occured while declining the invitation',
}

export const RequestGetInvitationsAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  dispatch({ type: InvitationActions.requestGetInvitationsAttempt });
  try {
    const invitations = await client.request<InvitationApi[]>(
      Microservice.INVITATION,
      `/invitation`,
      'GET'
    );
    dispatch({
      type: InvitationActions.requestGetInvitationsSuccess,
      payload: invitations,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: InvitationActions.requestGetInvitationsFailure,
      payload: InvitationErrors.requestGetInvitationsError,
    });
  }
};

export const RequestGetInvitationAsync = async (
  dispatch: DispatchFn,
  invitationId: string
): Promise<void> => {
  dispatch({ type: InvitationActions.requestGetInvitationAttempt });
  try {
    const invitation = await client.request<InvitationApi>(
      Microservice.INVITATION,
      `/invitation/${invitationId}`,
      'GET'
    );
    dispatch({
      type: InvitationActions.requestGetInvitationSuccess,
      payload: invitation,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: InvitationActions.requestGetInvitationFailure,
      payload: InvitationErrors.requestGetInvitationError,
    });
  }
};

export const RequestPutInvitationAsync = async (
  dispatch: DispatchFn,
  invitationId: string
): Promise<void> => {
  dispatch({ type: InvitationActions.requestPutInvitationAttempt });
  try {
    const response = await client.request<InvitationApi>(
      Microservice.INVITATION,
      `/invitation/${invitationId}/viewed`,
      'PUT'
    );
    dispatch({
      type: InvitationActions.requestPutInvitationSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: InvitationActions.requestPutInvitationFailure,
      payload: InvitationErrors.requestPutInvitationError,
    });
  }
};

export const RequestPatchDeclineInvitationAsync = async (
  dispatch: DispatchFn,
  invitationId: string
): Promise<void> => {
  dispatch({ type: InvitationActions.requestPatchInvitationDeclineAttempt });
  try {
    await client.request<InvitationApi>(
      Microservice.INVITATION,
      `/invitation/${invitationId}/decline`,
      'PATCH'
    );
    dispatch({
      type: InvitationActions.requestPatchInvitationDeclineSuccess,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: InvitationActions.requestPatchInvitationDeclineFailure,
      payload: InvitationErrors.requestPatchInvitiationDeclineError,
    });
    throw e;
  }
};

export const SetInvitesNeedRefresh = async (
  dispatch: DispatchFn,
  needsRefresh: boolean
): Promise<void> => {
  dispatch({
    type: InvitationActions.setInvitesNeedRefresh,
    payload: needsRefresh,
  });
};

export const ClearCurrentInvitation = async (
  dispatch: DispatchFn
): Promise<void> => {
  dispatch({
    type: InvitationActions.clearCurrentInvitation,
  });
};

import React, { FC } from 'react';
import { Modal } from '../../../common/Modal';
import { useSocialDialog } from './useSocialDialog';
import { Breakpoint } from '../../../styling/breakpoints';
import s from './SocialDialog.styles';
import { Pulse } from '../../../common/Loading';
import { LinkButton, SecondaryButton } from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatValidString } from '../../../../utils/format.utils';
import { CustomIcon } from '../../../common/CustomIcon/CustomIcon';
import { socialDialogTranslation } from './translations';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';

export interface SocialDialogProps extends ReturnType<typeof useSocialDialog> {
  titleText?: string;
  titleLogout?: string;
  mode: string;
}

export const SocialDialog: FC<SocialDialogProps> = ({
  layout,
  isLoading,
  isOpen,
  errorMessage,
  actions = [],
  titleText = '',
  daysRemaining,
  handleClose,
  needsFacebookRefresh,
  needsYoutubeRefresh,
  mode,
}) => {
  const { socialDialog: t } = useTranslationsController(
    socialDialogTranslation
  );
  const socialType =
    needsYoutubeRefresh && !needsFacebookRefresh ? 'Youtube' : 'Facebook';
  const reconnectMessage = () => {
    return (
      <div>
        <s.SocialDialogSubtitle>
          {t['LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.SUBTITLE']}
        </s.SocialDialogSubtitle>
        <s.TimeUntilReconnect data-testid={'t_time_until_reconnect'}>
          {daysRemaining > 0 && (
            <>
              <s.TimeCircle>
                {formatValidString(
                  t['LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.DAYS_LEFT'],
                  [daysRemaining]
                )}
              </s.TimeCircle>
              <p>
                {
                  t[
                    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.RECONNECTION_MESSAGE'
                  ]
                }
              </p>
            </>
          )}
        </s.TimeUntilReconnect>
        <div>
          <s.ContentWrapper data-testid={'t_content_wrapper'}>
            <p>{t['LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.EXPLANATION']}</p>
            <s.SocialDialogLine>
              <CustomIcon icon={'iosCircleLock'} />
              <p>{t['LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.PASSWORD']} </p>
            </s.SocialDialogLine>
            {needsFacebookRefresh && (
              <s.SocialDialogLine data-testid="t_facebook_disconnected">
                <FontAwesomeIcon icon="unlink" size={'2x'} />
                <p>{t['LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.DISCONNECTED']}</p>
              </s.SocialDialogLine>
            )}
            <s.SocialDialogLine data-testid="t_security_reconnection">
              <FontAwesomeIcon icon="shield-alt" size={'2x'} />
              <p>
                {formatValidString(
                  t['LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.SECURITY'],
                  [socialType]
                )}
              </p>
            </s.SocialDialogLine>
            {needsFacebookRefresh && needsYoutubeRefresh && (
              <p data-testid="t_both_require_reconnection">
                {t['LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.CONNECT']}
              </p>
            )}
          </s.ContentWrapper>
        </div>
      </div>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      fullScreen={layout <= Breakpoint.MOBILE}
      titleText={titleText}
    >
      <s.SocialDialogWrapper>
        {isLoading ? <Pulse visible /> : reconnectMessage()}
        {errorMessage && !!errorMessage.length && (
          <s.ErrorContainer>
            {errorMessage.map(error => (
              <p key={error}>{error}</p>
            ))}
          </s.ErrorContainer>
        )}
        <s.SocialDialogFooter>
          <s.SocialDialogActions>
            {daysRemaining > 0 || mode == 'dashboard' ? (
              <SecondaryButton
                onClick={handleClose}
                data-testid={'t_close_button'}
              >
                {t['LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.CLOSE']}
              </SecondaryButton>
            ) : (
              <LinkButton
                data-testid={`t_redirect_button`}
                buttonType={SecondaryButton}
                to={`/${mode}/`}
              >
                {`Return to ${mode}`}
              </LinkButton>
            )}
            {actions.map((Component, i) => (
              <Component key={i} />
            ))}
          </s.SocialDialogActions>
        </s.SocialDialogFooter>
      </s.SocialDialogWrapper>
    </Modal>
  );
};

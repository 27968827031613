import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

type ButtonVariants = 'maxi' | 'micro' | 'default';

const snapInAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  fitContent?: boolean;
  variant?: ButtonVariants;
  side?: 'left' | 'right';
  sideActive?: boolean;
  styleType?: 'outline';
  mode?: 'dark' | 'normal';
}

interface ToggleButtonProps extends ButtonProps {
  isActive: boolean;
}

export const StandardButtonStyles = css<ButtonProps>`
  all: unset;
  background: none;
  ${props => theme(props).TYPOGRAPHY.SMALL_alt};
  width: ${props => (props.fullWidth ? '100%' : 'fit-content')};
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  transition: all 0.2s;
  user-select: none;
  border-radius: ${px2rem(8)};
  cursor: pointer;
  min-width: ${px2rem(115)};
  border: none;
  border-color: transparent;
  min-height: ${px2rem(40)};
  padding: 0 ${px2rem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: ${props =>
    `inset 0 0 ${px2rem(0.5)} ${px2rem(0.5)} ${
      theme(props).COLOUR.BACKGROUND_MEDIUM
    }`};
  &:active {
    transform: scale(0.98);
  }
  &:focus {
    overflow: hidden;
    &:before {
      content: '';
      border-radius: 100%;
      background: black;
      width: max(80%, 100px);
      height: max(300%, 100px);
      opacity: 0.3;
      position: absolute;
      transform: scale(0);
      animation-name: ${snapInAnimation}, ${pulseAnimation};
      animation-duration: 300ms, 2000ms;
      animation-delay: 0ms, 300ms;
      animation-timing-function: ease-in, ease-out;
      animation-iteration-count: 1, infinite;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
    transform: scale(1) !important;
    &:before {
      content: none;
    }
  }

  & > svg {
    margin-right: 0.25rem;
  }
`;

export const PrimaryButtonStyles = css<ButtonProps>`
  border: none;
  ${props =>
    props.styleType === 'outline'
      ? `color: ${theme(props).COLOUR.PRIMARY}`
      : `color: white;`};
  ${props =>
    props.styleType === 'outline'
      ? `border: 2px solid ${theme(props).COLOUR.PRIMARY}`
      : `background-color: ${theme(props).COLOUR.PRIMARY}`};
  height: ${props =>
    props.variant
      ? props.variant === 'maxi'
        ? px2rem(20)
        : props.variant === 'micro'
          ? px2rem(20)
          : px2rem(40)
      : px2rem(40)};

  &:hover {
    background-color: ${props => theme(props).COLOUR.PRIMARY_DARK};
    color: white;
    border: none;
  }

  &:focus {
    ${props =>
      props.styleType === 'outline'
        ? `border: 2px solid ${theme(props).COLOUR.PRIMARY}`
        : `background-color: ${theme(props).COLOUR.PRIMARY_DARKER}`};
    &:before {
      background-color: white;
    }
  }

  &:active {
    ${props =>
      props.styleType === 'outline'
        ? `border: 2px solid ${theme(props).COLOUR.PRIMARY}`
        : `background-color: ${theme(props).COLOUR.PRIMARY_DARKEST}`};
  }

  &:disabled {
    ${props =>
      props.styleType === 'outline'
        ? `border: 2px solid ${theme(props).COLOUR.PRIMARY}`
        : css`
            color: ${theme(props).COLOUR.BACKGROUND_LIGHT};
            background-color: transparent;
            border: ${px2rem(1)} solid ${theme(props).COLOUR.BACKGROUND_LIGHT};
          `};
  }
`;

export const PrimaryButton = styled.button<ButtonProps>`
  ${StandardButtonStyles}
  ${PrimaryButtonStyles}
`;

const ToggleButtonStyles = css<ToggleButtonProps>`
  ${StandardButtonStyles}
  ${PrimaryButtonStyles}
  background: ${props => !props.isActive && 'none'};
  color: ${props => !props.isActive && theme(props).COLOUR.PRIMARY};
  &:hover {
    color: ${props => !props.isActive && 'white'};
  }
`;

export const ToggleButtonStart = styled.button<ToggleButtonProps>`
  ${ToggleButtonStyles}
  border-radius: ${px2rem(8)} 0 0 ${px2rem(8)};
`;

export const ToggleButtonEnd = styled.button<ToggleButtonProps>`
  ${ToggleButtonStyles}
  border-radius: 0 ${px2rem(8)} ${px2rem(8)} 0;
`;

export const ToggleButtonMid = styled.button<ToggleButtonProps>`
  ${ToggleButtonStyles}
  border-radius: 0;
`;

export const ToggleButtonSingle = styled.button<ToggleButtonProps>`
  ${ToggleButtonStyles}
  border-radius: ${px2rem(8)};
`;

export const PrimaryButtonAlternate = styled.button<ButtonProps>`
  ${StandardButtonStyles}
  border: ${px2rem(1)} solid ${props => theme(props).COLOUR.SECONDARY_DARK};
  background-color: ${props => theme(props).COLOUR.SECONDARY_DARK};
  color: white;
  text-shadow: 0 0 2px #00000070;
  height: ${props =>
    props.variant
      ? props.variant === 'maxi'
        ? px2rem(20)
        : props.variant === 'micro'
          ? px2rem(20)
          : px2rem(40)
      : px2rem(40)};
  ${props =>
    props.fitContent &&
    css`
      height: fit-content;
      width: fit-content;
    `};
  &:hover {
    background-color: ${props => theme(props).COLOUR.SECONDARY_DARKEST};
    text-shadow: none;
  }

  &:focus {
    background-color: ${props => theme(props).COLOUR.SECONDARY_DARKEST};
    text-shadow: none;
  }

  &:active {
    background-color: ${props => theme(props).COLOUR.SECONDARY_DARKEST};
    text-shadow: none;
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    text-shadow: none;
  }
`;

export const SecondaryButton = styled.button<ButtonProps>`
  ${StandardButtonStyles}
  text-decoration: none;
  border: ${px2rem(1)} solid ${props => theme(props).COLOUR.PRIMARY};
  background-color: transparent;
  color: ${props => theme(props).COLOUR.PRIMARY};
  height: ${props =>
    props.variant
      ? props.variant === 'maxi'
        ? px2rem(20)
        : props.variant === 'micro'
          ? px2rem(20)
          : px2rem(40)
      : px2rem(40)};

  &:hover {
    background-color: ${props => `${theme(props).COLOUR.PRIMARY_LIGHTER}60`};
  }

  &:focus {
    background-color: ${props => `${theme(props).COLOUR.PRIMARY_LIGHTER}50`};
  }

  &:active {
    background-color: ${props => `${theme(props).COLOUR.PRIMARY_LIGHTER}75`};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  }
`;

export const SecondaryButtonAlternate = styled.button<ButtonProps>`
  ${StandardButtonStyles}
  border: ${px2rem(1)} solid ${props => theme(props).COLOUR.SECONDARY_DARKER};
  color: ${props => theme(props).COLOUR.SECONDARY_DARKER};
  height: ${props =>
    props.variant
      ? props.variant === 'maxi'
        ? px2rem(20)
        : props.variant === 'micro'
          ? px2rem(20)
          : px2rem(40)
      : px2rem(40)};
  ${props =>
    props.fitContent &&
    css`
      height: fit-content;
      width: fit-content;
    `};
  &:hover {
    background-color: ${props => `${theme(props).COLOUR.SECONDARY_LIGHTER}25`};
    color: ${props => theme(props).COLOUR.SECONDARY_DARKEST};
  }

  &:focus {
    background-color: ${props => `${theme(props).COLOUR.SECONDARY_LIGHTER}50`};
    color: ${props => theme(props).COLOUR.SECONDARY_DARKEST};
  }

  &:active {
    background-color: ${props => `${theme(props).COLOUR.SECONDARY_LIGHTER}75`};
    color: ${props => theme(props).COLOUR.SECONDARY_DARKEST};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  }
`;

export const TertiaryButtonAlternate = styled.button<ButtonProps>`
  ${StandardButtonStyles}
  border: ${px2rem(1)} solid ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
  color: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
  height: ${props =>
    props.variant
      ? props.variant === 'maxi'
        ? px2rem(20)
        : props.variant === 'micro'
          ? px2rem(20)
          : px2rem(40)
      : px2rem(40)};

  &:hover {
    background-color: ${props => `${theme(props).COLOUR.BACKGROUND_LIGHT}25`};
    color: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
  }

  &:focus {
    background-color: ${props => `${theme(props).COLOUR.BACKGROUND_LIGHT}50`};
    color: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
  }

  &:active {
    background-color: ${props => `${theme(props).COLOUR.BACKGROUND_LIGHT}75`};
    color: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  }
`;

export const RejectButton = styled.button<ButtonProps>`
  ${StandardButtonStyles}
  border: none;
  background-color: ${props => theme(props).COLOUR.ALERT};
  color: white;
  height: ${props =>
    props.variant
      ? props.variant === 'maxi'
        ? px2rem(20)
        : props.variant === 'micro'
          ? px2rem(20)
          : px2rem(40)
      : px2rem(40)};

  box-shadow: none;
  &:hover {
    background-color: ${props => theme(props).COLOUR.ALERT_DARK};
  }

  &:focus {
    background-color: ${props => theme(props).COLOUR.ALERT_DARK};
  }

  &:active {
    background-color: ${props => theme(props).COLOUR.ALERT_DARKER};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  }
`;

export const TextButton = styled.button<ButtonProps>`
  ${StandardButtonStyles}
  border: none;
  background-color: transparent;
  color: ${props => theme(props).COLOUR.PRIMARY};
  min-width: fit-content;
  height: fit-content;
  padding: 0;
  box-shadow: none;
  &:hover {
    color: ${props => theme(props).COLOUR.PRIMARY_DARK};
  }

  &:focus {
    color: ${props => theme(props).COLOUR.PRIMARY_DARKER};
    text-decoration: underline;
    &:before {
      content: none;
    }
  }

  &:active {
    color: ${props => theme(props).COLOUR.PRIMARY_DARKEST};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: none;
  }
`;

export const TextButtonLogo = styled.button<ButtonProps>`
  ${StandardButtonStyles}
  border: none;
  background-color: transparent;
  color: white;
  min-width: fit-content;
  height: fit-content;
  padding: 0;
  box-shadow: none;
  font-size: 1.2rem;
  &:hover {
    text-decoration: underline;
  }

  &:focus {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
    text-decoration: underline;
    &:before {
      content: none;
    }
  }

  &:active {
    color: ${props => theme(props).COLOUR.PRIMARY};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: none;
  }
  svg {
    margin-right: ${px2rem(8)};
  }
`;

export const InlineTextButton = styled.a<ButtonProps>`
  display: inline-block;
  border: none;
  background-color: transparent;
  color: ${props => theme(props).COLOUR.PRIMARY};
  min-width: fit-content;
  height: fit-content;
  padding: 0;
  box-shadow: none;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;
  font-size: 1rem;
  &:hover {
    color: ${props => theme(props).COLOUR.PRIMARY_DARK};
  }

  &:focus {
    color: ${props => theme(props).COLOUR.PRIMARY_DARKER};
    text-decoration: underline;
    &:before {
      content: none;
    }
  }

  &:active {
    color: ${props => theme(props).COLOUR.PRIMARY_DARKEST};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: none;
  }
`;

export * from './LinkButton';

export * from './IconButton';

import {
  CommissionTarget,
  ProgrammeCommission,
  defaultCommission,
} from '../Programme/store';
import {
  Invitation,
  InvitationApi,
  InvitationCommissions,
  InvitationCommissionsApi,
} from './store';

export const getInviteCommissionPayload = (
  response: InvitationCommissionsApi
): InvitationCommissions => {
  let programmeFallback = response.fallback
    ? response.fallback
    : defaultCommission;

  if (response.campaign_fallback)
    programmeFallback = response.campaign_fallback.reduce((acc, fallback) => {
      if (fallback.target === CommissionTarget.PROGRAMME) return fallback;
      return acc;
    }, defaultCommission as ProgrammeCommission);

  const campaignFallback: ProgrammeCommission | null =
    response.campaign_fallback
      ? response.campaign_fallback.reduce(
          (acc, fallback) => {
            if (fallback.target === CommissionTarget.CAMPAIGN) return fallback;
            return acc;
          },
          null as ProgrammeCommission | null
        )
      : null;

  return {
    programmeFallback: programmeFallback,
    campaignFallback: campaignFallback,
    categories: response.categories,
  };
};

export const getInvitationsPayload = (
  response: InvitationApi[]
): Invitation[] => {
  return response.map(invitation => {
    return getInvitationPayload(invitation);
  });
};

export const getInvitationPayload = (invitation: InvitationApi): Invitation => {
  return {
    id: invitation.id,
    createdTimestamp: invitation.created_timestamp,
    keycloakId: invitation.keycloak_id,
    programmeId: invitation.programme_id,
    programmeName: invitation.programme_name,
    influencerName: invitation.influencer_name,
    createdByMarketerName: invitation.created_by_marketer_name,
    createdByMarketerId: invitation.created_by_marketer_id,
    status: invitation.status,
    commissions: invitation.commissions
      ? getInviteCommissionPayload(invitation.commissions)
      : null,
    agreement: invitation.agreement,
    viewCommissions: invitation.view_commissions,
    campaignId: invitation.campaign_id,
    socials: invitation.socials,
  };
};

import { AgentState } from './types';

export const defaultAgentState: AgentState = {
  associationRequests: [],
  associatedInfluencers: null,
  currentMappingRequest: null,
  influencerProgrammes: {},
  influencerInvites: {},

  influencerAssociatedProgrammes: null,
  influencerAssociatedCampaigns: null,
  influencerInvitations: null,

  influencerProgrammeDetails: null,
  influencerProgrammeAssociation: null,

  influencerCampaignDetails: null,
  influencerCampaignAssociation: null,

  influencerAssociatedInvitations: null,
  influencerSingleProgramme: [],
  influencerSingleCampaign: [],
  permissionGroups: {},
  singleInfluencer: null,

  currentInfluencer: null,

  trackingLinks: [],

  agencyDetails: null,

  paginatedInfluencerCommissions: null,

  InfluencerCommissionsPermission: null,

  isLoading: {
    associationRequests: false,
    associatedInfluencers: false,

    influencerAssociatedProgrammes: false,
    influencerAssociatedCampaigns: false,
    influencerInvitations: false,

    influencerProgrammeDetails: false,
    influencerProgrammeAssociation: false,

    influencerCampaignDetails: false,
    influencerCampaignAssociation: false,

    influencerAssociatedInvitations: false,
    influencerSingleProgramme: false,
    influencerSingleCampaign: false,
    currentMappingRequest: false,
    permissionGroups: false,
    currentInfluencer: false,
    influencerInviteDetails: false,
    programmeAgreement: false,
    postAssociation: false,
    declineInvitation: false,

    trackingLinks: false,

    createTrackingLink: false,
    updateTrackingLink: false,

    agencyDetails: false,

    paginatedInfluencerCommissions: false,
    InfluencerCommissionsPermission: false,
  },
  hasError: {
    associationRequests: null,
    associatedInfluencers: null,

    influencerAssociatedProgrammes: null,
    influencerAssociatedCampaigns: null,
    influencerInvitations: null,

    influencerProgrammeDetails: null,
    influencerProgrammeAssociation: null,

    influencerCampaignDetails: null,
    influencerCampaignAssociation: null,
    influencerAssociatedInvitations: null,
    influencerSingleProgramme: null,
    influencerSingleCampaign: null,
    currentMappingRequest: null,
    permissionGroups: null,
    currentInfluencer: null,
    influencerInviteDetails: null,
    programmeAgreement: null,
    postAssociation: null,
    declineInvitation: null,
    trackingLinks: null,
    createTrackingLink: null,
    updateTrackingLink: null,

    agencyDetails: null,

    paginatedInfluencerCommissions: null,
    InfluencerCommissionsPermission: null,
  },
  influencerInviteDetails: null,
  programmeAgreement: null,
};

import { DispatchFn } from '../../store/state';
import client, { Microservice } from '../../utils/http.utils';

import { captureException } from '@sentry/react';
import { AgencyApplicationFormApi } from './store';

export enum AgencyApplicationActions {
  requestPostAgencyApplicationAttempt = 'REQUEST_POST_AGENCY_APPLICATION_ATTEMPT',
  requestPostAgencyApplicationSuccess = 'REQUEST_POST_AGENCY_APPLICATION_SUCCESS',
  requestPostAgencyApplicationFailure = 'REQUEST_POST_AGENCY_APPLICATION_FAILURE',

  requestPostAgencyApplicationReminderAttempt = 'REQUEST_POST_AGENCY_APPLICATION_REMINDER_ATTEMPT',
  requestPostAgencyApplicationReminderSuccess = 'REQUEST_POST_AGENCY_APPLICATION_REMINDER_SUCCESS',
  requestPostAgencyApplicationReminderFailure = 'REQUEST_POST_AGENCY_APPLICATION_REMINDER_FAILURE',
}

export const RequestPostAgencyApplication = async (
  dispatch: DispatchFn,
  formBody: AgencyApplicationFormApi
): Promise<any> => {
  dispatch({
    type: AgencyApplicationActions.requestPostAgencyApplicationAttempt,
  });

  try {
    const response = await client.requestNoAuth(
      Microservice.AGENCY,
      `/agency_application`,
      'POST',
      {
        ...formBody,
        email_address: formBody.email_address.toLowerCase(),
      }
    );

    dispatch({
      type: AgencyApplicationActions.requestPostAgencyApplicationSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgencyApplicationActions.requestPostAgencyApplicationFailure,
      payload: 'An error occurred trying to send Application.',
    });
    throw e;
  }
};

export const RequestPostAgencyApplicationReminder = async (
  dispatch: DispatchFn,
  emailAddress: { email_address: string }
): Promise<any> => {
  dispatch({
    type: AgencyApplicationActions.requestPostAgencyApplicationReminderAttempt,
  });

  try {
    const response = await client.requestNoAuth(
      Microservice.AGENCY,
      `/agency_application/reminder`,
      'POST',
      emailAddress
    );

    dispatch({
      type: AgencyApplicationActions.requestPostAgencyApplicationReminderSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgencyApplicationActions.requestPostAgencyApplicationReminderFailure,
      payload: 'An error occurred trying to send Application reminder.',
    });
    throw e;
  }
};

import { ProgrammeActions, ProgrammeState } from './store';
import { Action } from '../state';
import {
  getAssociationPayload,
  getAssociationsPayload,
  transformGetProgrammeAgreementPayload,
  transformGetProgrammeCommissionsPayload,
  transformCommissionSummaryPayload,
  getTrackingLinksPayload,
  postTrackingLinksPayload,
  putTrackingLinksPayload,
  transformGetCampaignPayload,
  getTrackingLinkPayload,
  transformDiscountCodesPayload,
} from './payload';

export const ProgrammeReducer = (
  state: ProgrammeState,
  action: Action
): ProgrammeState => {
  switch (action.type) {
    case ProgrammeActions.requestGetProgrammeAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          programme: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetProgrammeSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          programme: false,
        },
        currentProgramme: action.payload,
        error: null,
      };
    case ProgrammeActions.requestGetProgrammeFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          programme: false,
        },
        error: action.payload,
      };
    case ProgrammeActions.requestGetProgrammesAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          programme: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetProgrammesSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          programme: false,
        },
        programmes: action.payload,
        error: null,
      };
    case ProgrammeActions.requestGetProgrammesFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          programme: false,
        },
        error: action.payload,
      };
    case ProgrammeActions.requestGetUserProgrammeAssociationsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          userProgrammeAssociations: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetUserProgrammeAssociationsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          userProgrammeAssociations: false,
        },
        userProgrammeAssociations: action.payload,
      };
    case ProgrammeActions.requestGetUserProgrammeAssociationsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          userProgrammeAssociations: false,
        },
        error: action.payload,
      };
    case ProgrammeActions.requestGetWalletProgrammesAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          walletProgrammes: true,
        },
        errors: { ...state.errors, getWalletProgrammes: null },
      };
    case ProgrammeActions.requestGetWalletProgrammesSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          walletProgrammes: false,
        },
        walletProgrammes: action.payload,
        errors: { ...state.errors, getWalletProgrammes: null },
      };
    case ProgrammeActions.requestGetWalletProgrammesFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          walletProgrammes: false,
        },
        errors: { ...state.errors, getWalletProgrammes: action.payload },
      };
    case ProgrammeActions.requestGetAssociationAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          association: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetAssociationSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          association: false,
        },
        currentAssociation: getAssociationPayload(action.payload, 'programme'),
        error: null,
      };
    case ProgrammeActions.requestGetAssociationFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          association: false,
        },
        error: action.payload,
      };

    case ProgrammeActions.requestGetDiscountCodesAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          discountCodes: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetDiscountCodesSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          discountCodes: false,
        },
        discountCodes: transformDiscountCodesPayload(action.payload),
      };
    case ProgrammeActions.requestGetDiscountCodesFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          discountCodes: false,
        },
        error: action.payload,
      };

    case ProgrammeActions.requestPostAssociationAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          association: true,
        },
        error: null,
      };
    case ProgrammeActions.requestPostAssociationSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          association: false,
        },
        currentAssociation: getAssociationPayload(action.payload, 'programme'),
        error: null,
      };
    case ProgrammeActions.requestPostAssociationFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          association: false,
        },
        error: action.payload,
      };
    case ProgrammeActions.requestGetAssociationsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          association: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetAssociationsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          association: false,
        },
        associations: getAssociationsPayload(action.payload),
        error: null,
      };
    case ProgrammeActions.requestGetAssociationsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          association: false,
        },
        error: action.payload,
      };
    case ProgrammeActions.requestGetAgreementAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          agreement: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetAgreementSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          agreement: false,
        },
        currentProgrammeAgreement: transformGetProgrammeAgreementPayload(
          action.payload
        ),
        error: null,
      };
    case ProgrammeActions.requestGetTrackingLinksAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          trackingLinks: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetTrackingLinksSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          trackingLinks: false,
        },
        currentTrackingLinks: getTrackingLinksPayload(action.payload),
      };
    case ProgrammeActions.requestGetTrackingLinksFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          trackingLinks: false,
        },
        error: action.payload,
      };
    case ProgrammeActions.requestGetTrackingLinksBackgroundAttempt:
      return {
        ...state,
        error: null,
      };
    case ProgrammeActions.requestGetTrackingLinksBackgroundSuccess:
      return {
        ...state,
        currentTrackingLinks: getTrackingLinksPayload(action.payload),
      };
    case ProgrammeActions.requestGetTrackingLinksBackgroundFailure:
      return {
        ...state,
        error: action.payload,
      };
    case ProgrammeActions.requestGetTrackingLinkBackgroundAttempt:
      return {
        ...state,
        error: null,
      };
    case ProgrammeActions.requestGetTrackingLinkBackgroundSuccess:
      return {
        ...state,
        currentTrackingLinks: getTrackingLinkPayload(
          action.payload,
          state.currentTrackingLinks
        ),
      };
    case ProgrammeActions.requestGetTrackingLinkBackgroundFailure:
      return {
        ...state,
        error: action.payload,
      };
    case ProgrammeActions.requestPostTrackingLinkAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postTrackingLink: true,
        },
        postLinkError: null,
      };
    case ProgrammeActions.requestPostTrackingLinkSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postTrackingLink: false,
        },
        currentTrackingLinks: postTrackingLinksPayload(
          action.payload,
          state.currentTrackingLinks
        ),
      };
    case ProgrammeActions.requestPostTrackingLinkFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postTrackingLink: false,
        },
        postLinkError: action.payload,
      };
    case ProgrammeActions.requestPutTrackingLinkAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          updateTrackingLink: true,
        },
        updateLinkError: null,
      };
    case ProgrammeActions.requestPutTrackingLinkSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          updateTrackingLink: false,
        },
        currentTrackingLinks: putTrackingLinksPayload(
          action.payload,
          state.currentTrackingLinks
        ),
      };
    case ProgrammeActions.requestPutTrackingLinkFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          updateTrackingLink: false,
        },
        updateLinkError: action.payload,
      };
    case ProgrammeActions.requestGetAgreementFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          agreement: false,
        },
        error: action.payload,
      };

    case ProgrammeActions.requestGetAssociationAgreementAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          agreement: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetAssociationAgreementSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          agreement: false,
        },
        currentProgrammeAgreement: transformGetProgrammeAgreementPayload(
          action.payload
        ),
        error: null,
      };
    case ProgrammeActions.requestGetAssociationAgreementFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          agreement: false,
        },
        error: action.payload,
      };

    case ProgrammeActions.clearCurrentProgramme:
      return {
        ...state,
        currentProgramme: null,
      };

    case ProgrammeActions.clearCurrentAssociation:
      return {
        ...state,
        currentAssociation: null,
      };
    case ProgrammeActions.clearTrackingFormErrors:
      return {
        ...state,
        error: null,
        postLinkError: null,
        updateLinkError: null,
      };
    case ProgrammeActions.clearCommissions:
      return {
        ...state,
        commissions: null,
      };
    case ProgrammeActions.requestGetCommissionsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          commissions: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetCommissionsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          commissions: false,
        },
        commissions: transformGetProgrammeCommissionsPayload(
          action.payload,
          state.commissions?.commissions
        ),
      };
    case ProgrammeActions.requestGetCommissionsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          commissions: false,
        },
        error: action.payload,
      };
    case ProgrammeActions.requestGetCommissionSummaryAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          commissionSummary: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetCommissionSummarySuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          commissionSummary: false,
        },
        commissionFilteredSummary: transformCommissionSummaryPayload(
          action.payload
        ),
      };
    case ProgrammeActions.requestGetCommissionSummaryFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          commissionSummary: false,
        },
        error: action.payload,
      };

    case ProgrammeActions.requestGetCommissionOverviewAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          commissionOverview: true,
        },
        error: null,
      };
    case ProgrammeActions.requestGetCommissionOverviewSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          commissionOverview: false,
        },
        commissionOverview: transformCommissionSummaryPayload(action.payload),
      };
    case ProgrammeActions.requestGetCommissionOverviewFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          commissionOverview: false,
        },
        error: action.payload,
      };
    case ProgrammeActions.resetAssociations:
      return {
        ...state,
        currentAssociation: null,
        currentProgrammeAgreement: null,
        currentTrackingLinks: null,
        commissionFilteredSummary: null,
        commissionOverview: null,
        commissions: null,
      };
    case ProgrammeActions.setPostAssociationFeedbackOpen:
      return {
        ...state,
        postAssociationFeedbackOpen: action.payload,
      };
    //Applications
    case ProgrammeActions.requestGetApplicationInfoAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          getApplication: true,
        },
        errors: { ...state.errors, getApplication: null },
      };
    case ProgrammeActions.requestGetApplicationInfoSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          getApplication: false,
        },
        applicationInfo: action.payload,
      };
    case ProgrammeActions.requestGetApplicationInfoFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          getApplication: false,
        },
        errors: { ...state.errors, getApplication: action.payload },
      };
    //post application
    case ProgrammeActions.requestPostApplicationAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postApplication: true,
        },
        errors: { ...state.errors, postApplication: null },
      };
    case ProgrammeActions.requestPostApplicationSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postApplication: false,
        },
      };
    case ProgrammeActions.requestPostApplicationFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postApplication: false,
        },
        errors: { ...state.errors, postApplication: action.payload },
      };
    //post application reminder
    case ProgrammeActions.requestPostApplicationReminderAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postApplicationReminder: true,
        },
        errors: { ...state.errors, postApplicationReminder: null },
      };
    case ProgrammeActions.requestPostApplicationReminderSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postApplicationReminder: false,
        },
      };
    case ProgrammeActions.requestPostApplicationReminderFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postApplicationReminder: false,
        },
        errors: { ...state.errors, postApplicationReminder: action.payload },
      };
    //set form
    case ProgrammeActions.setApplicationForm:
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          [action.payload.type]: action.payload.value,
        },
      };

    case ProgrammeActions.requestGetCampaignsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaigns: true,
        },
      };
    case ProgrammeActions.requestGetCampaignsSuccess:
      return {
        ...state,
        campaigns: transformGetCampaignPayload(action.payload),
        isFetching: {
          ...state.isFetching,
          campaigns: false,
        },
      };
    case ProgrammeActions.requestGetCampaignsFailure:
      return {
        ...state,
        campaignError: action.payload,
        isFetching: {
          ...state.isFetching,
          campaigns: false,
        },
      };

    case ProgrammeActions.requestGetCampaignsInviteAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaigns: true,
        },
      };
    case ProgrammeActions.requestGetCampaignsInviteSuccess:
      return {
        ...state,
        campaignInvitations: transformGetCampaignPayload(action.payload),
        isFetching: {
          ...state.isFetching,
          campaigns: false,
        },
      };
    case ProgrammeActions.requestGetCampaignsInviteFailure:
      return {
        ...state,
        campaignError: action.payload,
        isFetching: {
          ...state.isFetching,
          campaigns: false,
        },
      };
    case ProgrammeActions.clearCurrentCampaigns:
      return {
        ...state,
        campaigns: null,
        campaignInvitations: null,
      };

    default:
      return state;
  }
};

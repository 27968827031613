import { useState, useEffect } from 'react';
import { SetClearFacebookLoginError } from '../../../../../../store/Social/actions';
import { useStore } from '../../../../../../store/state';

export const useConnectInstagramModal = () => {
  const [{ socialState }, dispatch] = useStore();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stepOneConsentGiven, setStepOneConsentGiven] = useState(false);
  const [stepTwoConsentGiven, setStepTwoConsentGiven] = useState(false);

  useEffect(() => {
    if (currentIndex === 0 && socialState.errors.facebook !== null) {
      SetClearFacebookLoginError(dispatch);
    }
  }, [currentIndex, dispatch, socialState.errors.facebook]);

  return {
    currentIndex,
    setCurrentIndex,
    stepOneConsentGiven,
    setStepOneConsentGiven,
    stepTwoConsentGiven,
    setStepTwoConsentGiven,
  };
};

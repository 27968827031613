import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breakpoint } from '../../styling/breakpoints';
import {
  HeadingContainer,
  HeadingContent,
  User,
  WelcomeText,
  UserAvatar,
  HeaderLink,
} from './TopBar.styles';
import { UserState } from '../../../store/User/types';
import { NotificationController } from '../../pages/Notifications';
import { useTranslationsController } from '../../../utils/globalTranslationsController';
import { formatString } from '../../../utils/format.utils';

const translationObject = {
  topBar: { 'TOPBAR.LINK.ARIALABEL': `Go to Profile for {0}` },
};

interface TopBarProps {
  user: UserState;
  layout: Breakpoint;
}

export const TopBar: React.FC<TopBarProps> = ({ user, layout }) => {
  const { topBar: t } = useTranslationsController(translationObject);
  const labelText = formatString(t['TOPBAR.LINK.ARIALABEL'], [
    user?.keycloakProfile?.firstName || 'user',
  ]);
  const firstName = user.user?.influencer_first_name;
  return layout >= Breakpoint.DESKTOP ? (
    <HeadingContainer data-testid="t_topbar">
      <HeadingContent>
        <NotificationController />
        <HeaderLink to={'/profile'} aria-label={labelText}>
          <User data-testid="t_topbar_user">
            <WelcomeText data-testid="t_topbar_welcome">
              {firstName}
            </WelcomeText>
            <UserAvatar>
              <FontAwesomeIcon icon={'user'} />
            </UserAvatar>
          </User>
        </HeaderLink>
      </HeadingContent>
    </HeadingContainer>
  ) : (
    <> </>
  );
};

import React, { FC } from 'react';
import { GridContainer as Component } from './GridContainer';
import { GridContainerProps } from './GridContainer.types';

export const GridContainer: FC<GridContainerProps> = ({
  children,
  ...rest
}) => (
  <Component role="list" {...rest}>
    {children}
  </Component>
);

import styled, { css } from 'styled-components/macro';
import { GlobalFontFamily } from '../../styling/css/fontfamily';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

type Theme = 'dark' | 'default' | 'light';
interface WrapperProps {
  selectTheme?: Theme;
  hasValue?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isAutoWidth?: boolean;
  isReadOnly?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
  white-space: nowrap;
  ${props =>
    props.isFullWidth &&
    css`
      width: 100%;
    `}

  & * {
    ${GlobalFontFamily}
  }
  & .MuiInputBase-root {
    ${props => props.isFullWidth && 'width: 100%;'};}

  & .MuiInputBase-root,
  .MuiSvgIcon-root {
      color: ${props =>
        props.selectTheme === 'dark'
          ? 'white'
          : theme(props).COLOUR.BACKGROUND_MEDIUM};
      line-height: ${px2rem(42)};
      height: ${px2rem(42)};
    }

    & .MuiSvgIcon-root {
      top: 0.05rem;
    }

    & .MuiNativeSelect-icon {
      color: white;
      color: ${props =>
        props.selectTheme === 'dark'
          ? 'white'
          : theme(props).COLOUR.BACKGROUND_MEDIUM};
    }
  }

  & .MuiNativeSelect-icon.Mui-disabled {
    color: transparent;
  }

  & .MuiInputLabel-shrink {
    color: ${props =>
      props.selectTheme === 'dark'
        ? 'white'
        : theme(props).COLOUR.BACKGROUND_DARKEST};
    ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
    font-weight: 400;
    transform: none;
    transform-origin: none;
  }

  & .MuiFormHelperText-root {
    ${props => theme(props).TYPOGRAPHY.X_SMALL};
  }

  & .MuiNativeSelect-select {
    color: ${props =>
      props.selectTheme === 'dark'
        ? props.hasValue === true
          ? 'white'
          : '#ffffff69'
        : props.hasValue === true
          ? theme(props).COLOUR.BACKGROUND_DARKEST
          : theme(props).COLOUR.BACKGROUND_MEDIUM};
    border-color: ${props =>
      props.selectTheme === 'dark'
        ? 'white'
        : theme(props).COLOUR.BACKGROUND_MEDIUM};
    height: ${px2rem(40)};
    padding: 0;
    padding-left: ${px2rem(12)};
    line-height: initial;
    will-change: background-color;
    transition: background-color 1.2s;
    background-color: ${props =>
      props.selectTheme === 'light' ? 'white' : 'rgba(255, 255, 255, 0.25)'};
    ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
    border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD} !important;
    ${props =>
      props.selectTheme === 'dark' ? null : `border: ${px2rem(1)} solid`};
    width: ${props =>
      props.isFullWidth ? ' 100%' : props.isAutoWidth ? 'auto' : '160px'};
    &:disabled {
      opacity: ${props => (props.selectTheme === 'dark' ? 0.4 : 1)};
      font-weight: ${props => (props.selectTheme === 'dark' ? 600 : 300)};
      background: ${props =>
        props.selectTheme === 'dark'
          ? theme(props).COLOUR.BACKGROUND_HIGHLIGHT
          : theme(props).COLOUR.BACKGROUND_LIGHTEST};
      cursor: ${props => (props.isReadOnly ? 'default' : 'not-allowed')};
      border: 0 none;
    }
    :not(:focus):invalid {
    border: 2px solid ${props => theme(props).COLOUR.ALERT};
  }
  }


  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  & .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: none;
  }

  & .MuiInputBase-input {
    box-sizing: border-box;
    @media screen and (max-width: ${px2rem(768, 'em')}) {
      font-size: ${px2rem(16)};
    }
  }

  & .MuiInput-underline.Mui-disabled:before {
    border-bottom: none;
  }

  & .MuiNativeSelect-select:not([multiple]) option {
    color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  }
  
`;

import { ProgrammeCommission } from '../Programme/store';

export enum InviteStatus {
  PENDING = 'pending',
  VIEWED = 'viewed',
  DECLINED = 'declined',
}

export interface InvitationCommissions {
  programmeFallback: ProgrammeCommission | null;
  campaignFallback: ProgrammeCommission | null;
  categories: ProgrammeCommission[];
}

export interface InvitationCommissionsApi {
  fallback?: ProgrammeCommission;
  campaign_fallback?: ProgrammeCommission[];
  categories: ProgrammeCommission[];
}

export interface InvitationApi {
  id: string;
  programme_id: string;
  programme_name: string;
  influencer_name: string;
  created_by_marketer_name: string;
  created_by_marketer_id: string;
  created_timestamp: string;
  keycloak_id: string | null;
  status: InviteStatus;
  commissions: InvitationCommissionsApi | null;
  view_commissions: boolean;
  agreement: string;
  campaign_id: string | null;
  socials: Socials | null;
}

export interface Socials {
  partnership: string;
  handle: string | null;
}

export interface Invitation {
  id: string;
  programmeId: string;
  programmeName: string;
  influencerName: string;
  createdByMarketerName: string;
  createdByMarketerId: string;
  createdTimestamp: string;
  keycloakId: string | null;
  status: InviteStatus;
  commissions: InvitationCommissions | null;
  viewCommissions: boolean;
  agreement: string;
  campaignId: string | null;
  socials: Socials | null;
}

export interface InvitationState {
  invitations: Invitation[] | null;
  currentInvitation: Invitation | null;
  invitesNeedRefresh: boolean;
  isLoading: boolean;
  isDeclining: boolean;
  error: string | null;
}

export const defaultInvitationState: InvitationState = {
  invitations: null,
  currentInvitation: null,
  invitesNeedRefresh: false,
  isLoading: false,
  isDeclining: false,
  error: null,
};

export * from './reducer';
export * from './actions';

import React, { FC, ReactNode } from 'react';
import { Dialog } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CustomDialogTitle,
  CloseButton,
  CustomDialogActions,
  CustomDialogContent,
  ModalHeader,
} from './Modal.styles';
import { Spacer } from '..';

export interface ModalProps {
  isOpen: boolean;
  fullScreen: boolean;
  cancelHandler?: () => void;
  actions?: ReactNode;
  titleText?: JSX.Element | string;
  closeLabel?: string;
  maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
}

export const Modal: FC<ModalProps> = ({
  children,
  isOpen,
  fullScreen,
  cancelHandler,
  actions,
  titleText,
  closeLabel = 'Close Button',
  maxWidth = 'sm',
}) => {
  return (
    <Dialog
      data-testid="modal"
      open={isOpen}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth
      aria-labelledby="dialog_label"
    >
      <CustomDialogTitle data-testid="modal-title" id={'dialog_label'}>
        {titleText && <ModalHeader>{titleText}</ModalHeader>}
        {cancelHandler && (
          <CloseButton
            role={'button'}
            data-testid="t_modal_close_btn"
            onClick={cancelHandler}
            aria-label={closeLabel}
          >
            <FontAwesomeIcon icon="times" />
            <span aria-hidden style={{ display: 'none' }}>
              {closeLabel}
            </span>
          </CloseButton>
        )}
      </CustomDialogTitle>

      <CustomDialogContent data-testid="modal-content" fullScreen={fullScreen}>
        {children}
      </CustomDialogContent>
      {actions ? (
        <CustomDialogActions data-testid="modal-actions">
          <div>{actions}</div>
        </CustomDialogActions>
      ) : (
        <Spacer isVertical amount={24} />
      )}
    </Dialog>
  );
};

import { FacebookLogin } from '../LoginButtons/FacebookLogin';
import { YoutubeLogin } from '../LoginButtons/YoutubeLogin';
import { useSocialIntegrations } from '../../../../hooks/useSocialIntegrations';
import { useResponsive } from '../../../../hooks/useResponsive';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { useEffect } from 'react';

export const useSocialDialog = () => {
  const { layout } = useResponsive();
  const {
    allFetched,
    allInitialised,
    isLoading,
    instagramErrors,
    youtubeErrors,
    errors,
    needsFacebookRefresh,
    needsYoutubeRefresh,
    ignoreFacebookRefresh,
    ignoreYoutubeRefresh,
    youtubeConnected,
    facebookConnected,
    daysRemainingInstagram,
    daysRemainingYoutube,
  } = useSocialIntegrations();

  const daysRemaining = Math.max(
    daysRemainingInstagram || 0,
    daysRemainingYoutube || 0
  );

  const requiresSocialConnection = window._env.requiresSocialConnection;

  const [hasSeenReconnectModal, setHasSeenReconnectModal] = useLocalStorage(
    'hasSeenReconnectModal',
    false
  );

  useEffect(() => {
    if (
      hasSeenReconnectModal &&
      !needsFacebookRefresh &&
      !needsYoutubeRefresh
    ) {
      setHasSeenReconnectModal(false);
    }
  }, [
    hasSeenReconnectModal,
    needsFacebookRefresh,
    needsYoutubeRefresh,
    setHasSeenReconnectModal,
  ]);

  const getErrorMessage = () => {
    if (needsFacebookRefresh && !needsYoutubeRefresh) return [instagramErrors];
    if (!needsFacebookRefresh && needsYoutubeRefresh) return [youtubeErrors];
    if (needsFacebookRefresh && needsYoutubeRefresh) return errors;
    return null;
  };

  const getActions = (): React.FC[] => {
    if (needsFacebookRefresh && !needsYoutubeRefresh) return [FacebookLogin];
    if (!needsFacebookRefresh && needsYoutubeRefresh) return [YoutubeLogin];
    if (needsFacebookRefresh && needsYoutubeRefresh)
      return [FacebookLogin, YoutubeLogin];
    return [];
  };

  const handleClose = () => {
    setHasSeenReconnectModal(true);
  };

  const isDialogOpen = () => {
    if (facebookConnected && ignoreFacebookRefresh) return false;
    if (youtubeConnected && ignoreYoutubeRefresh) return false;
    if (
      hasSeenReconnectModal &&
      ((daysRemaining && daysRemaining > 0) || oneSocialNeedsReconnection)
    )
      return false;

    return (
      requiresSocialConnection &&
      allInitialised &&
      allFetched &&
      (!!needsFacebookRefresh || !!needsYoutubeRefresh)
    );
  };

  const oneSocialNeedsReconnection =
    youtubeConnected &&
    facebookConnected &&
    needsFacebookRefresh !== needsYoutubeRefresh;

  return {
    layout,
    isOpen: isDialogOpen(),
    isLoading,
    errorMessage: getErrorMessage(),
    actions: getActions(),
    daysRemaining,
    handleClose,
    needsFacebookRefresh,
    needsYoutubeRefresh,
  };
};

import { InterestOption } from '../components/common/Interests/types';
import { MIN_TOPICS_LENGTH } from '../components/pages/Register/data';
import { isPostalRequired } from './localisation_countries.utils';

export const regexValidator = (val: string, regex: RegExp | null): boolean => {
  if (regex) return regex.test(val);
  return true;
};

export const reservedParamsValidator = (
  link: string,
  onFail?: (link: string) => void
): boolean => {
  const reservedQueryParameters = [
    'affil',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'clickref',
  ];

  const querySeparatorIndex = link.toString().indexOf('?');
  if (querySeparatorIndex !== -1 && querySeparatorIndex !== link.length - 1) {
    const queryString = link.substring(querySeparatorIndex + 1);
    if (
      queryString
        .split('&')
        .filter(kvp => reservedQueryParameters.includes(kvp.split('=')[0]))
        .length > 0
    ) {
      if (onFail) onFail(link);
      return false;
    }
  }

  return true;
};

export const isContactFormValid = (details: any) => {
  const { firstName, lastName, address, postCode, phone, country } = details;
  return (
    firstName?.trim() !== '' &&
    lastName?.trim() !== '' &&
    address?.trim() !== '' &&
    ((isPostalRequired(details.country) && postCode?.trim() !== '') ||
      !isPostalRequired(details.country)) &&
    phone?.trim() !== '' &&
    country !== ''
  );
};

export const isInterestsFormValid = (details: any) => {
  const { interests } = details;
  return (
    interests?.filter((x: InterestOption) => x.active === true).length >=
    MIN_TOPICS_LENGTH
  );
};

import { countryNamesAlpha2 } from '../../../utils/localisation_countries.utils';
import { interestConfig } from '../../../interestsConfig';

export const MIN_TOPICS_LENGTH = 1;
export const MAX_TOPICS_LENGTH = 5;

export const formFields = {
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  ADDRESS: 'ADDRESS',
  POST_CODE: 'POST_CODE',
  PHONE_NUMBER: 'PHONE_NUMBER',
  COUNTRY: 'COUNTRY',
  DOB: 'DOB',
  INTERESTS: 'INTERESTS',
};

export const availableCountries = Object.entries(countryNamesAlpha2).map(x => {
  return { key: x[1], value: x[0] };
});

export const getCountriesSortedByName = () => {
  return availableCountries.sort((a, b) => (a.key < b.key ? -1 : 1));
};

export const defaultDetails = {
  firstName: '',
  lastName: '',
  address: '',
  postCode: '',
  phone: '',
  country: '',
  dob: '',
  interests: interestConfig,
};

export enum Currency {
  GBP = 'GBP',
  USD = 'USD',
  AUD = 'AUD',
  EUR = 'EUR',
  JPY = 'JPY',
  CNY = 'CNY',
  NZD = 'NZD',
  VND = 'VND',
  HKD = 'HKD',
  THB = 'THB',
  BGN = 'BGN',
  BHD = 'BHD',
  BRL = 'BRL',
  BSD = 'BSD',
  CAD = 'CAD',
  CHF = 'CHF',
  CLP = 'CLP',
  CZK = 'CZK',
  DKK = 'DKK',
  HRK = 'HRK',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  KES = 'KES',
  MAD = 'MAD',
  MXN = 'MXN',
  MYR = 'MYR',
  NOK = 'NOK',
  PEN = 'PEN',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  RON = 'RON',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  TRY = 'TRY',
  ZAR = 'ZAR',
  AED = 'AED',
  KWD = 'KWD',
  SAR = 'SAR',
  KRW = 'KRW',
  TWD = 'TWD',
  JOD = 'JOD',
  GTQ = 'GTQ',
  LKR = 'LKR',
  NGN = 'NGN',
  UGX = 'UGX',
  UYU = 'UYU',
  BOB = 'BOB',
  TZS = 'TZS',
  KZT = 'KZT',
  BDT = 'BDT',
  CRC = 'CRC',
  LBP = 'LBP',
  QAR = 'QAR',
}

export const CurrencyCodeLookup: { [index: string]: keyof typeof Currency } = {
  GBP: 'GBP',
  USD: 'USD',
  AUD: 'AUD',
  EUR: 'EUR',
  JPY: 'JPY',
  CNY: 'CNY',
  NZD: 'NZD',
  VND: 'VND',
  HKD: 'HKD',
  THB: 'THB',
  BGN: 'BGN',
  BHD: 'BHD',
  BRL: 'BRL',
  BSD: 'BSD',
  CAD: 'CAD',
  CHF: 'CHF',
  CLP: 'CLP',
  CZK: 'CZK',
  DKK: 'DKK',
  HRK: 'HRK',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  KES: 'KES',
  MAD: 'MAD',
  MXN: 'MXN',
  MYR: 'MYR',
  NOK: 'NOK',
  PEN: 'PEN',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  RON: 'RON',
  RUB: 'RUB',
  SEK: 'SEK',
  SGD: 'SGD',
  TRY: 'TRY',
  ZAR: 'ZAR',
  AED: 'AED',
  KWD: 'KWD',
  SAR: 'SAR',
  KRW: 'KRW',
  TWD: 'TWD',
  JOD: 'JOD',
  GTQ: 'GTQ',
  LKR: 'LKR',
  NGN: 'NGN',
  UGX: 'UGX',
  UYU: 'UYU',
  BOB: 'BOB',
  TZS: 'TZS',
  KZT: 'KZT',
  BDT: 'BDT',
  CRC: 'CRC',
  LBP: 'LBP',
  QAR: 'QAR',
};

export const currenciesWithSymbols: {
  [key in keyof typeof Currency]: string;
} = {
  GBP: 'Pound Sterling (£)',
  USD: 'US Dollar ($)',
  AUD: 'Australian Dollar ($)',
  EUR: 'Euro (€)',
  JPY: '円 (¥)',
  CNY: '元 (¥)',
  NZD: 'New Zealand Dollar ($)',
  VND: 'Dong (₫)',
  HKD: 'Hong Kong Dollar ($)',
  THB: 'Baht (฿)',
  BGN: 'Bulgarian Lev (лв)',
  BHD: 'Bahraini Dinar (ب.د)',
  BRL: 'Brazilian Real (R$)',
  BSD: 'Bahamian Dollar ($)',
  CAD: 'Canadian Dollar ($)',
  CHF: 'Swiss Franc (₣)',
  CLP: 'Chilean Peso ($)',
  CZK: 'Czech Koruna (Kč)',
  DKK: 'Danish Krone (kr)',
  HRK: 'Croatian Kuna (Kn)',
  HUF: 'Forint (Ft)',
  IDR: 'Rupiah (Rp)',
  ILS: 'New Israeli Shekel (₪)',
  INR: 'Indian Rupee (₹)',
  KES: 'Kenyan Shilling (Sh)',
  MAD: 'Moroccan Dirham (د.م.)',
  MXN: 'Mexican Peso ($)',
  MYR: 'Malaysian Ringgit (RM)',
  NOK: 'Norwegian Krone (kr)',
  PEN: 'Nuevo Sol (S/.)',
  PHP: 'Philippine Peso (₱)',
  PKR: 'Pakistan Rupee (₨)',
  PLN: 'PZloty (zł)',
  RON: 'Leu (L)',
  RUB: 'Russian Ruble (р.)',
  SEK: 'Swedish Krona (kr)',
  SGD: 'Singapore Dollar ($)',
  TRY: 'Turkish Lira (₤)',
  ZAR: 'Rand (R)',
  AED: 'UAE Dirham (د.إ)',
  KWD: 'Kuwaiti Dinar (د.ك)',
  SAR: 'Saudi Riyal (ر.س)',
  KRW: '한국 원 (₩)',
  TWD: 'Taiwan Dollar ($)',
  JOD: 'Jordanian Dinar (د.ا)',
  GTQ: 'Quetzal (Q)',
  LKR: 'Sri Lanka Rupee (Rs)',
  NGN: 'Naira (₦)',
  UGX: 'Uganda Shilling (Sh)',
  UYU: 'Peso Uruguayo ($)',
  BOB: 'Boliviano (Bs.)',
  TZS: 'Tanzanian Shilling (Sh)',
  KZT: 'Tenge (〒)',
  BDT: 'Taka (৳)',
  CRC: 'Costa Rican Colon (₡)',
  LBP: 'Lebanese Pound (ل.ل)',
  QAR: 'Qatari Rial (ر.ق)',
};

const currencySymbols: { [key in keyof typeof Currency]: string } = {
  GBP: '£',
  USD: '$',
  AUD: '$',
  EUR: '€',
  JPY: '¥',
  CNY: '¥',
  NZD: '$',
  VND: '₫',
  HKD: '$',
  THB: '฿',
  BGN: 'лв',
  BHD: 'ب.د',
  BRL: 'R$',
  BSD: '$',
  CAD: '$',
  CHF: '₣',
  CLP: '$',
  CZK: 'Kč',
  DKK: 'kr',
  HRK: 'Kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  KES: 'Sh',
  MAD: 'د.م.',
  MXN: '$',
  MYR: 'RM',
  NOK: 'kr',
  PEN: 'S/.',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  RON: 'L',
  RUB: 'р.',
  SEK: 'kr',
  SGD: '$',
  TRY: '₤',
  ZAR: 'R',
  AED: 'د.إ',
  KWD: 'د.ك',
  SAR: 'ر.س',
  KRW: '₩',
  TWD: '$',
  JOD: 'د.ا',
  GTQ: 'Q',
  LKR: 'Rs',
  NGN: '₦',
  UGX: 'Sh',
  UYU: '$',
  BOB: 'Bs.',
  TZS: 'Sh',
  KZT: '〒',
  BDT: '৳',
  CRC: '₡',
  LBP: 'ل.ل',
  QAR: 'ر.',
};

export const currencyNames: {
  [key in keyof typeof Currency]: string;
} = {
  GBP: 'Pound Sterling',
  USD: 'US Dollar',
  AUD: 'Australian Dollar',
  EUR: 'Euro',
  JPY: '円',
  CNY: '元',
  NZD: 'New Zealand Dollar',
  VND: 'Dong',
  HKD: 'Hong Kong Dollar',
  THB: 'Baht',
  BGN: 'Bulgarian Lev',
  BHD: 'Bahraini Dinar',
  BRL: 'Brazilian Real',
  BSD: 'Bahamian Dollar',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  CLP: 'Chilean Peso',
  CZK: 'Czech Koruna',
  DKK: 'Danish Krone',
  HRK: 'Croatian Kuna',
  HUF: 'Forint',
  IDR: 'Rupiah',
  ILS: 'New Israeli Shekel',
  INR: 'Indian Rupee',
  KES: 'Kenyan Shilling',
  MAD: 'Moroccan Dirham',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  NOK: 'Norwegian Krone',
  PEN: 'Nuevo Sol',
  PHP: 'Philippine Peso',
  PKR: 'Pakistan Rupee',
  PLN: 'PZloty',
  RON: 'Leu',
  RUB: 'Russian Ruble',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  TRY: 'Turkish Lira',
  ZAR: 'Rand',
  AED: 'UAE Dirham',
  KWD: 'Kuwaiti Dinar',
  SAR: 'Saudi Riyal',
  KRW: '한국 원',
  TWD: 'Taiwan Dollar',
  JOD: 'Jordanian Dinar',
  GTQ: 'Quetzal',
  LKR: 'Sri Lanka Rupee',
  NGN: 'Naira',
  UGX: 'Uganda Shilling',
  UYU: 'Peso Uruguayo',
  BOB: 'Boliviano',
  TZS: 'Tanzanian Shilling',
  KZT: 'Tenge',
  BDT: 'Taka',
  CRC: 'Costa Rican Colon',
  LBP: 'Lebanese Pound',
  QAR: 'Qatari Rial',
};

export const getCurrencySymbol = (currency: string) => {
  const currencyCode = CurrencyCodeLookup[currency];
  return currencySymbols[currencyCode] || '?';
};

export const getCurrencyName = (currency: string) => {
  const currencyCode = CurrencyCodeLookup[currency];
  return currencyNames[currencyCode] || '?';
};

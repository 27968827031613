import styled from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';

export const PageWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
`;

export const PageContentContainer = styled.main<{
  isDesktop?: boolean;
  isMobile?: boolean;
  isFullWidth?: boolean;
}>`
  flex: 1;
  position: relative;
  top: 0;
  margin: 0 auto;
  overflow-y: auto;
  width: 100%;
  ${props =>
    !props.isFullWidth &&
    `max-width: ${
      props.isDesktop ? `calc(100vw - ${px2rem(225)})` : `${px2rem(1024)}`
    };

      padding: ${
        props.isDesktop
          ? `0 ${px2rem(20)} ${px2rem(200)} ${px2rem(20)}`
          : `${px2rem(60)} ${px2rem(20)} ${px2rem(200)} ${px2rem(20)}`
      };`}

  & > * {
    max-width: 80rem;
    margin-inline: auto;
  }
`;

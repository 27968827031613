import React, { FC } from 'react';
import { ConnectInstagramModal as Component } from './ConnectInstagramModal';
import { ConnectInstagramProps } from './types';
import { useConnectInstagramModal } from './useConnectInstagramModal';

export const ConnectInstagramModal: FC<ConnectInstagramProps> = props => {
  const innerProps = useConnectInstagramModal();

  return <Component {...props} {...innerProps} />;
};

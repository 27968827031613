import styled, { keyframes } from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { ExpandoProps } from './types';

const SlideDown = (props: ExpandoProps) => keyframes`
  to {
    height: ${px2rem(props.dimensions.height)};
    opacity: 1;
  }
  100% {
    overflow: visible;
  }
`;

export const Wrapper = styled.div<ExpandoProps>`
  overflow: hidden;
  height: ${props =>
    props?.config ? px2rem(props?.config?.initialHeight || 0) : 0};
  opacity: 0;
  animation: ${props => SlideDown(props)}
    ${props => props?.config?.timing || 0.7}s ease 0s 1 forwards;
`;

export const Container = styled.div``;

import styled, { keyframes, css } from 'styled-components';
import { Breakpoint } from '../../../styling/breakpoints';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';
import { ButtonProps, StandardButtonStyles } from '../../Button';

const fadeInAnimation = keyframes`
from {
  opacity: 0;
}

to {
  opacity: 1;
}
`;

const InterestsWrapperRegisteredStyles = css`
  max-width: none;
  background: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
  color: white;
`;

const InterestsWrapper = styled.form<{ isRegistered: boolean }>`
  max-width: ${px2rem(880)};
  flex-direction: column;
  align-items: center;
  border-radius: ${px2rem(10)};
  width: 100%;
  color: white;
  padding: ${props => (props.isRegistered ? 0 : theme(props).SIZE.MEDIUM)};
  animation: ${fadeInAnimation} 0.5s linear;
  justify-content: center;
  text-align: center;

  @media (max-width: ${px2rem(748, 'em')}) {
    border-radius: 0;
    opacity: 0.9;
    width: 100%;
    max-width: 100%;
  }

  ${props => (props.isRegistered ? InterestsWrapperRegisteredStyles : '')};
  ${props => (!props.isRegistered ? InterestsWrapperUnregisteredStyles : '')};
`;

const InterestsHeader = styled.h1`
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1};
`;

const InterestsSubheader = styled.h2<{ isRegistered?: boolean }>`
  ${props => theme(props).TYPOGRAPHY.SMALL};
  color: ${props => (props.isRegistered ? 'black' : 'white')};
  text-align: center;
  margin-bottom: ${props => theme(props).SIZE.MEDIUM};
`;

const InterestLayoutWrapper = styled.div`
  width: 100%;
  min-width: 16rem;
  justify-content: center;
  max-width: 48rem;
  margin: auto;
`;

const InterestsActions = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  margin-top: ${props => theme(props).SIZE.MEDIUM};
  justify-content: ${props => (props.isDesktop ? 'flex-end' : 'center')};
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
`;

const InterestsAction = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  margin-top: ${props => theme(props).SIZE.MEDIUM};
  justify-content: ${props => (props.isDesktop ? 'flex-end' : 'center')};
  flex-direction: ${props => (props.isDesktop ? 'row' : 'column')};
`;

const InterestContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;

  @media (max-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    padding-bottom: 5rem;
  }
`;

const CheckBoxButton = styled.div<ButtonProps>`
  ${StandardButtonStyles}
  border: ${px2rem(1)} solid ${props => theme(props).COLOUR.SECONDARY_DARKER};
  color: ${props => theme(props).COLOUR.SECONDARY_DARKER};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    background-color: ${props => `${theme(props).COLOUR.SECONDARY_LIGHTER}25`};
    color: ${props => theme(props).COLOUR.SECONDARY_DARKEST};
  }

  label:hover {
    cursor: pointer;
  }

  &[data-active='true'] {
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.SECONDARY_DARK};
    background-color: ${props => theme(props).COLOUR.SECONDARY_DARK};
    color: white;

    &:hover {
      background-color: ${props => theme(props).COLOUR.SECONDARY_DARKEST};
      text-shadow: none;
    }
  }

  &[data-disabled='true'] {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    transform: none;
    opacity: 0.3;

    label {
      cursor: not-allowed;
    }
  }
`;

const InterestsWrapperUnregisteredStyles = css`
  & ${CheckBoxButton} {
    color: white !important;
  }
`;

export default {
  InterestsWrapper,
  InterestsHeader,
  InterestsSubheader,
  InterestsActions,
  InterestsAction,
  InterestContainer,
  InterestLayoutWrapper,
};

import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum InterestsPage {
  Marketplace = 'Marketplace',
  Profile = 'Profile',
  Register = 'Register',
}

export interface InterestsProps {
  handleInterestsChange: (e: any) => void;
  handleNextButton?: () => void;
  interests: InterestOption[];
  maxTopicsReached: boolean;
  isFormValid: boolean;
  isRegistered?: boolean;
  interestsChanged?: boolean;
  handleSubmit?: (e: any) => void;
  handleCancel?: () => void;
  disableForm?: boolean;
  handleLogout?: () => void;
  headerText?: string;
  subheaderText?: string;
  saveText?: string;
  cancelText?: string;
  updateText?: string;
  getNameHandler?: (option: InterestOption) => string;
  page?: InterestsPage;
  mode?: 'dark' | 'normal';
}

export interface InterestsViewProps {
  interests: InterestOption[];
  handleInterestsChange: (e: any) => void;
  maxTopicsReached: boolean;
  getNameHandler?: (option: InterestOption) => string;
  disableForm?: boolean;
  page?: InterestsPage;
  mode?: 'dark' | 'normal';
}

export type InterestOption = {
  active: boolean;
  value: string;
  name: string;
  iconName: IconProp;
  translateKey: string;
};

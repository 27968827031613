import { BooleanString } from './types.utils';

export const capitalise = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const truncateText = (
  text: string,
  maxChars: number,
  useElipsis = true
) =>
  `${text.substring(0, maxChars)}${
    useElipsis && text.length > maxChars ? '...' : ''
  }`;

export const truncateMarkdownText = (
  text: string,
  maxChars: number,
  useEllipsis = true
) => {
  return truncateText(text.replace(/(<([^>]+)>)/gi, ''), maxChars, useEllipsis);
};

export const renderBoolean = (value: boolean | undefined) => {
  return value ? 'Yes' : 'No';
};

export const booleanStringToBoolean = (str: BooleanString): boolean => {
  return str === 'true';
};

export const replaceAmpersandAndUnderscore = (arrString: string[]): string => {
  return arrString.length
    ? arrString
        .map(value => value.replace('_', ' ').replace('8', ' & '))
        .toString()
    : '';
};

import { useEffect, useState } from 'react';
import { useTranslation } from '../hooks';

export const useTranslationsController = <
  T extends Record<string, Record<string, string>>,
>(
  objectToTranslate: T
) => {
  const translate = useTranslation();
  const [translations, setTranslations] = useState<T>(objectToTranslate);

  useEffect(() => {
    const translationEntries = Object.entries(objectToTranslate);
    const translationEntriesToSet = translationEntries.map(
      ([translationGroup, keyToDefaultText]) => {
        const newTranslationEntries = Object.entries(keyToDefaultText).map(
          ([key, text]) => [key, translate(key, text)]
        );
        const newTranslations = Object.fromEntries(newTranslationEntries);
        return [translationGroup, newTranslations];
      }
    );
    const translationsToSet = Object.fromEntries(translationEntriesToSet);
    setTranslations(translationsToSet);
  }, [translate, objectToTranslate]);
  return translations;
};

import styled from 'styled-components';
import { theme } from '../../styling/theme';

export const FooterGrid = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  justify-content: space-around;
  align-items: ${props => (props.isMobile ? 'start' : 'center')};
  text-align: center;
  width: 100%;

  > * {
    ${props => props.isMobile && 'padding-bottom: 0.5rem'};
  }
`;

export const FooterLinks = styled.span`
  a {
    color: white;
    &:focus {
      font-weight: bold;
    }
  }
`;
export const RegisterFooter = styled.div<{ isMobile?: boolean }>`
  background-color: ${props => theme(props).COLOUR.BACKGROUND_HIGHLIGHT};
  width: 100%;
  color: white;
  display: flex;
  justify-content: ${props => (props.isMobile ? 'start' : 'center')};
  align-items: center;
  padding: ${props => (props.isMobile ? '1rem 1rem 3rem 1rem' : '1rem')};
  text-align: center;
`;

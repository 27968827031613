import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import Keycloak from 'keycloak-js';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { initialiseGA } from './analytics/ga';
import { createBrowserHistory } from 'history';
import process from 'process';

export const browserHistory = createBrowserHistory();
initialiseGA();

if (
  window._env.environment !== 'gb5mocked' &&
  window._env.environment !== 'local'
) {
  Sentry.init({
    dsn: 'https://dbca2abd54624478afed10e5fd9a3c39@o351552.ingest.sentry.io/5478800',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation:
          Sentry.reactRouterV5Instrumentation(browserHistory),
      }),
    ],
    tracesSampleRate: 0.25,
    environment: window._env.environment,
  });
}

declare global {
  interface Window {
    Society: {
      keycloak: Keycloak | null;
    };
    FB: {
      login: (cb: (r: any) => void, o: Record<string, unknown>) => void;
    };
  }
}

window.Society = window.Society || { keycloak: null };
window.FB = window.FB || {};

if (process?.env.NODE_ENV !== 'production' && process.env.REACT_APP_AXE) {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

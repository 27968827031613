import { WalletActions, WalletState, defaultStatistics } from './store';
import { Action } from '../state';
import {
  transformAutomaticWithdrawalSettingsPayload,
  transformReadTransactionsPayload,
  transformStatisticsPayload,
} from './payload';

export const WalletReducer = (
  state: WalletState,
  action: Action
): WalletState => {
  switch (action.type) {
    case WalletActions.requestGetStatisticsAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case WalletActions.requestGetStatisticsSuccess:
      return {
        ...state,
        statistics: transformStatisticsPayload(action.payload),
        isFetching: false,
        error: null,
      };
    case WalletActions.requestGetStatisticsFailure:
      return {
        ...state,
        statistics: defaultStatistics,
        isFetching: false,
        error: 'there was an error',
      };
    case WalletActions.requestGetBalancesAttempt:
      return {
        ...state,
        isFetching: true,
        isFetchingBalances: true,
        error: null,
      };
    case WalletActions.requestGetBalancesSuccess:
      return {
        ...state,
        balances: action.payload,
        isFetching: false,
        isFetchingBalances: false,
        error: null,
      };
    case WalletActions.requestGetBalancesFailure:
      return {
        ...state,
        isFetching: false,
        isFetchingBalances: false,
        error: action.payload,
      };
    case WalletActions.requestGetTransactionsAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case WalletActions.requestGetTransactionsSuccess:
      return {
        ...state,
        ...transformReadTransactionsPayload(
          action.payload,
          state.balances ?? {}
        ),
        isFetching: false,
        error: null,
      };
    case WalletActions.requestGetTransactionsFailure:
      return {
        ...state,
        statistics: defaultStatistics,
        isFetching: false,
        error: action.payload,
      };
    case WalletActions.requestPostWithdrawalAttempt:
      return {
        ...state,
        isFetching: true,
        requestWithdrawalError: null,
      };
    case WalletActions.requestPostWithdrawalSuccess:
      return {
        ...state,
        isFetching: false,
        requestWithdrawalError: null,
      };
    case WalletActions.requestPostWithdrawalFailure:
      return {
        ...state,
        isFetching: false,
        requestWithdrawalError: action.payload,
      };
    case WalletActions.requestPostWithdrawalValidationFailure:
      return {
        ...state,
        isFetching: false,
        requestWithdrawalValidationError: action.payload,
      };
    case WalletActions.clearPostWithdrawalErrors:
      return {
        ...state,
        requestWithdrawalError: null,
        requestWithdrawalValidationError: false,
      };
    case WalletActions.requestGetMinimumWithdrawalAmountsAttempt:
      return {
        ...state,
        isFetchingMinWithdrawalAmounts: true,
        requestMinAmountsError: null,
      };
    case WalletActions.requestGetMinimumWithdrawalAmountsSuccess:
      return {
        ...state,
        isFetchingMinWithdrawalAmounts: false,
        minWithdrawalAmounts: action.payload,
        requestMinAmountsError: null,
      };
    case WalletActions.requestGetMinimumWithdrawalAmountsFailure:
      return {
        ...state,
        isFetchingMinWithdrawalAmounts: false,
        requestMinAmountsError: action.payload,
      };
    case WalletActions.setCurrentBankAccountStatus:
      return {
        ...state,
        currentBankStatus: action.payload,
      };
    case WalletActions.clearCurrentBankAccountStatus:
      return {
        ...state,
        currentBankStatus: null,
      };
    case WalletActions.requestGetAutomaticWithdrawalSettingsAttempt:
      return {
        ...state,
        isFetchingAutomaticWithdrawalSettings: true,
        requestAutomaticWithdrawalSettingsError: null,
      };
    case WalletActions.requestGetAutomaticWithdrawalSettingsSuccess:
      return {
        ...state,
        automaticWithdrawalSettings:
          transformAutomaticWithdrawalSettingsPayload(action.payload),
        isFetchingAutomaticWithdrawalSettings: false,
      };
    case WalletActions.requestGetAutomaticWithdrawalSettingsFailure:
      return {
        ...state,
        isFetchingAutomaticWithdrawalSettings: false,
        requestAutomaticWithdrawalSettingsError: action.payload,
      };
    case WalletActions.requestUpdateAutomaticWithdrawalSettingsAttempt:
      return {
        ...state,
        isSavingAutomaticWithdrawalSettings: {
          ...state.isSavingAutomaticWithdrawalSettings,
          [action.payload.currency]: true,
        },
        saveAutomaticWithdrawalSettingsError: {
          ...state.saveAutomaticWithdrawalSettingsError,
          [action.payload.currency]: null,
        },
      };
    case WalletActions.requestUpdateAutomaticWithdrawalSettingsSuccess:
      return {
        ...state,
        isSavingAutomaticWithdrawalSettings: {
          ...state.isSavingAutomaticWithdrawalSettings,
          [action.payload.currency]: false,
        },
      };
    case WalletActions.requestUpdateAutomaticWithdrawalSettingsFailure:
      return {
        ...state,
        isSavingAutomaticWithdrawalSettings: {
          ...state.isSavingAutomaticWithdrawalSettings,
          [action.payload.currency]: false,
        },
        saveAutomaticWithdrawalSettingsError: {
          ...state.saveAutomaticWithdrawalSettingsError,
          [action.payload.currency]: action.payload.error,
        },
      };
    case WalletActions.requestGetAgencyBalancesAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case WalletActions.requestGetAgencyBalancesSuccess:
      return {
        ...state,
        balances: action.payload,
        isFetching: false,
      };
    case WalletActions.requestGetAgencyBalancesFailure:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case WalletActions.requestGetAgentTransactionsAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case WalletActions.requestGetAgentTransactionsSuccess:
      return {
        ...state,
        ...transformReadTransactionsPayload(
          action.payload,
          state.balances ?? {}
        ),
        isFetching: false,
        error: null,
      };
    case WalletActions.requestGetAgentTransactionsFailure:
      return {
        ...state,
        statistics: defaultStatistics,
        isFetching: false,
        error: 'There was an error in getting agent transactions',
      };
    case WalletActions.requestGetAgencyCurrenciesAttempt:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case WalletActions.requestGetAgencyCurrenciesSuccess:
      return {
        ...state,
        agencyCurrencies: action.payload.map((currency: string) =>
          currency.toUpperCase()
        ),
        isFetching: false,
        error: null,
      };
    case WalletActions.requestGetAgencyCurrenciesFailure:
      return {
        ...state,
        isFetching: false,
        error: 'There was an error in getting agency currencies',
      };
    case WalletActions.requestGetWalletDataAttempt:
      return {
        ...state,
        walletDownload: {
          ...state.walletDownload,
          data: null,
          isLoading: true,
          error: null,
        },
      };
    case WalletActions.requestGetWalletDataSuccess:
      return {
        ...state,
        walletDownload: {
          ...state.walletDownload,
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case WalletActions.requestGetWalletDataFailure:
      return {
        ...state,
        walletDownload: {
          ...state.walletDownload,
          isLoading: false,
          error: action.payload,
        },
      };
    case WalletActions.requestGetFirstTransactionDateAttempt:
      return {
        ...state,
        firstTransactionDate: {
          ...state.firstTransactionDate,
          date: null,
          isLoading: true,
          error: null,
        },
      };
    case WalletActions.requestGetFirstTransactionDateSuccess:
      return {
        ...state,
        firstTransactionDate: {
          ...state.firstTransactionDate,
          date: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case WalletActions.requestGetFirstTransactionDateFailure:
      return {
        ...state,
        firstTransactionDate: {
          ...state.firstTransactionDate,
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

import { KeycloakConfig } from 'keycloak-js';

export interface EnvironmentConfig {
  authConfig: KeycloakConfig;
  apiUrl: string;
  googleClientId: string;
  marketerUrl: string;
  influencerUrl: string;
  influencerRole: string;
  marketerRole: string;
  adminRole: string;
  approverRole: string;
  requiresSocialConnection: boolean;
  environment: '' | 'local' | 'gb5' | 'gb5mocked' | 'live';
  influencerNotifications: boolean;
  showApplications: boolean;
  recaptchaSiteKey: string;
  showPaymentSettings: boolean;
  campaigns: boolean;
  showCookieBanner: boolean;
  canSeeAgencies: boolean;
  showAgentRoutes: boolean;
  showMarketplace: boolean;
  algoliaIndex: string;
  algoliaAppId: string;
  algoliaSearchApiKey: string;
  showWaitlistModal: boolean;
}

declare global {
  interface Window {
    _env: EnvironmentConfig;
    profileToken: string | null;
  }
}

const defaultEnvironmentConfig: EnvironmentConfig = {
  authConfig: {
    url: '',
    realm: '',
    clientId: '',
  },
  apiUrl: '',
  googleClientId: '',
  marketerUrl: '',
  influencerUrl: '',
  influencerRole: '',
  marketerRole: '',
  adminRole: '',
  approverRole: '',
  requiresSocialConnection: false,
  environment: '',
  influencerNotifications: false,
  showApplications: false,
  recaptchaSiteKey: '',
  showPaymentSettings: false,
  campaigns: false,
  showCookieBanner: false,
  canSeeAgencies: false,
  showAgentRoutes: false,
  showMarketplace: false,
  algoliaIndex: '',
  algoliaAppId: '',
  algoliaSearchApiKey: '',
  showWaitlistModal: false,
};

export default window._env || defaultEnvironmentConfig;

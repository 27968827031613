import styled, { css } from 'styled-components';

const DateCellCSS = css`
  vertical-align: middle;
  position: relative;
  width: calc(100% / 7);
  > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderCell = styled.td`
  ${DateCellCSS};
  font-weight: 600;
`;

export const DateCell = styled.td`
  ${DateCellCSS};
  font-weight: 500;
`;

export const HiddenInput = styled.input<{ defaultChecked: any }>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  & + label > span {
    margin: auto;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    padding: 5px 6px;
    text-align: center;
  }
  &:checked + label > span {
    box-shadow: inset 0 0 0 2px black;
  }
  &:disabled + label {
    opacity: 0.3;
    cursor: default;
  }
`;

export const DateLabel = styled.label`
  display: flex;
  cursor: pointer;
`;

export const MonthTable = styled.table`
  table-layout: fixed;
  max-width: 100%;
  min-width: 300px;
  width: 100%;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

import { useEffect } from 'react';
import { useStore } from '../../../store/state';
import { RequestGetInfluencerMarketplaceProgrammeIds } from '../../../store/User/actions';

export const useNavigation = () => {
  const [{ userState }, dispatch] = useStore();
  const currentLanguage = userState.user?.influencer_language;

  useEffect(() => {
    if (
      window._env.showMarketplace &&
      userState.user?.influencer_id &&
      !userState.isLoading.marketplaceProgrammesIds &&
      !userState.marketplaceProgrammesIds
    ) {
      RequestGetInfluencerMarketplaceProgrammeIds(
        dispatch,
        userState.user.influencer_id
      );
    }
  }, [
    dispatch,
    userState.isLoading.marketplaceProgrammesIds,
    userState.marketplaceProgrammesIds,
    userState.user?.influencer_id,
  ]);

  const showMarketplace = !!(
    window._env.showMarketplace && userState.marketplaceProgrammesIds?.length
  );

  return {
    showMarketplace,
    language: currentLanguage === 'en-GB' ? '' : currentLanguage,
  };
};

import React from 'react';
import styled from 'styled-components';
import RadioComponent, {
  RadioProps as RadioComponentProps,
} from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { theme } from '../../styling/theme';
import { GlobalFontFamily } from '../../styling/css/fontfamily';

const RadioWrapper = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${props => theme(props).COLOUR.PRIMARY};
  }

  .MuiSvgIcon-root {
    width: ${props => theme(props).SIZE.MEDIUM};
    height: ${props => theme(props).SIZE.MEDIUM};
  }

  .MuiTypography-body1 {
    ${GlobalFontFamily};
    font-size: 100%;
  }
  .MuiFormControlLabel-label {
    display: flex;
    flex-grow: 1;
  }
  .MuiFormControlLabel-root {
    width: 100%;
  }
`;

interface RadioProps extends RadioComponentProps {
  label: React.ReactNode;
  testId: string;
}

interface RadioInputProps extends React.HTMLAttributes<HTMLInputElement> {
  'data-testid'?: string;
}

export const Radio: React.FC<RadioProps> = props => {
  const { label, testId, ...nativeProps } = props;

  return (
    <RadioWrapper>
      <FormControlLabel
        control={
          <RadioComponent
            {...nativeProps}
            inputProps={{ 'data-testid': testId } as RadioInputProps}
          />
        }
        label={label}
        labelPlacement="end"
      />
    </RadioWrapper>
  );
};

import { BankingLocale, BankingState, Mode } from './models';

export const defaultBankingLocale: BankingLocale = {
  availableCountries: [''],
  availableCurrencies: [''],
  currentCountry: '',
  currentCurrency: '',
};

export const defaultBankingState: BankingState = {
  currentIndex: 0,
  mode: Mode.VIEW,
  accounts: [],
  notices: {},
  formFields: [],
  formValues: {},
  bankingLocale: defaultBankingLocale,
  formNickname: '',
  paypal: { hasPaypalTx: false, hideNotice: false },
  paypalAccount: null,
  duplicateCheck: false,
  isFetching: {
    bankAccounts: false,
    bankNotices: false,
    locale: false,
    paypal: false,
    paymentForm: false,
    duplicateCheck: false,
    putBankDetails: false,
  },
  error: {
    bankAccounts: null,
    bankNotices: null,
    locale: null,
    paypal: null,
    paymentForm: null,
    putBankDetails: null,
  },
};

export * from './models';
export * from './reducer';
export * from './actions';

import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FacebookButton } from './FacebookLogin.styles';
import { useFacebookLogin } from './useFacebookLogin';
import { ConnectInstagramModal } from './ConnectInstagramModal';

export interface FacebookLoginProps
  extends ReturnType<typeof useFacebookLogin> {
  fullWidth?: boolean;
  monochrome?: boolean;
  isModalOpen: boolean;
  buttonText?: string;
}

export const FacebookLogin: FC<FacebookLoginProps> = ({
  loginHandler,
  isLoading,
  onClickHandler,
  isModalOpen,
  fullWidth = false,
  monochrome = false,
  layout,
  buttonText = '',
  errorMessage,
  shouldDisableConnectButton,
}) => {
  return (
    <>
      <FacebookButton
        data-testid="t_fb_login_button"
        onClick={onClickHandler}
        disabled={isLoading}
        fullWidth={fullWidth}
        monochrome={monochrome}
      >
        <FontAwesomeIcon icon={['fab', 'instagram']} />
        <span>{buttonText}</span>
      </FacebookButton>
      {isModalOpen && (
        <ConnectInstagramModal
          layout={layout}
          handleSubmit={loginHandler}
          isModalOpen={isModalOpen}
          handleCancel={onClickHandler}
          errorMessage={errorMessage}
          shouldDisableConnectButton={shouldDisableConnectButton}
        />
      )}
    </>
  );
};

import {
  GlobalAggregations,
  MarketplaceProduct,
  QueryAggregations,
} from '../../components/pages/Marketplace/types';
import { FilterCommissionStatus } from '../../contexts/common/types';

export enum MarketplaceCommissionType {
  fixed = 'fixed',
  percentage = 'percentage',
}

export enum MarketplaceCommissionTarget {
  PROGRAMME = 'programme',
  CAMPAIGN = 'campaign',
}

export interface MarketplaceProgrammeCommission {
  type: MarketplaceCommissionType;
  new: number;
  returning: number;
  target: MarketplaceCommissionTarget;
  category?: string;
}
export interface MarketplaceProgramme {
  status?: string;
  urls: string[];
  id: string;
  brand: string;
  title: string;
  created_by_admin: string;
  commissions: {
    fallback: MarketplaceProgrammeCommission;
    categories: MarketplaceProgrammeCommission[];
  };
  default_currency: string;
  description: string;
  banner_image_id: string | null;
  logo_image_id: string | null;
  thumbnail_image_id: string | null;
  programme_type: string;
}

export interface FetchingMarketplaceState {
  retailers: boolean;
  productTrackingLinks: boolean;
  interests: boolean;
  createTrackingLinks: boolean;
  waitlist: boolean;
  favouriteProducts: boolean;
  postTrackingLinkFeedback: boolean;
  getTrackingLinkFeedbackForm: boolean;
  products: boolean;
  marketplaceTerms: boolean;
}

export interface WaitlistStatusApiPayload {
  has_accepted: boolean;
}

export interface WaitlistStatusApiResponse {
  has_accepted: boolean;
  prompt_user: boolean;
}

export interface ProductFilters {
  brands: string[];
  industries: string[];
  partners: string[];
  categories: string[];
}

export interface MarketplaceState {
  isFetching: FetchingMarketplaceState;
  error: string | null;
  favouriteProductsError: string | null;
  isTrackingLinkFeedbackCompleted: boolean;
  hasTrackingLinkFeedbackForm: boolean | null;
  postTrackingLinkFeedbackOpen: boolean;
  postTrackingLinkFeedbackError: string | null;
  retailers: Retailer[] | null;
  getTrackingLinkError: string | null;
  postTrackingLinkError: string | null;
  productTrackingLinks: Record<string, TrackingLink | null>;
  interests: string[] | null;
  waitlistStatus: WaitlistStatusApiResponse | null;
  favouriteProducts: string[] | null;
  productSearchQuery: string | null;
  productFilters: ProductFilters;
  pagedProducts: {
    products: MarketplaceProduct[][] | null;
    paging: {
      totalHits: number | null;
      totalPages: number | null;
      page: number | null;
      size: number | null;
      isFirstPage: boolean | null;
      isLastPage: boolean | null;
    };
  };
  status: 'idle' | 'loading' | 'error';
  aggregations: {
    globalAggregations: GlobalAggregations | Record<string, never>;
    queryAggregations: QueryAggregations | Record<string, never>;
  };
  hasAcceptedMarketplaceTerms: boolean | null;
}

export const defaultMarketplaceState: MarketplaceState = {
  retailers: null,
  waitlistStatus: null,
  isTrackingLinkFeedbackCompleted: false,
  hasTrackingLinkFeedbackForm: null,
  hasAcceptedMarketplaceTerms: null,
  isFetching: {
    retailers: false,
    productTrackingLinks: false,
    interests: false,
    createTrackingLinks: false,
    waitlist: false,
    favouriteProducts: false,
    postTrackingLinkFeedback: false,
    getTrackingLinkFeedbackForm: false,
    products: false,
    marketplaceTerms: false,
  },
  error: null,
  favouriteProductsError: null,
  getTrackingLinkError: null,
  postTrackingLinkError: null,
  productTrackingLinks: {},
  favouriteProducts: null,
  interests: null,
  productSearchQuery: null,
  postTrackingLinkFeedbackOpen: false,
  postTrackingLinkFeedbackError: null,
  productFilters: {
    brands: [],
    industries: [],
    partners: [],
    categories: [],
  },
  pagedProducts: {
    products: null,
    paging: {
      totalHits: null,
      totalPages: null,
      page: null,
      size: null,
      isFirstPage: null,
      isLastPage: null,
    },
  },
  status: 'idle',
  aggregations: {
    globalAggregations: {},
    queryAggregations: {},
  },
};

export interface TrackingLink {
  tracking_link: string;
  shortened_tracking_link: string;
}

export interface Retailer {
  id: string;
  name: string;
  website_url: string;
  provider_advertiser_id: string;
}

export interface MarketplacePerformanceSummary {
  commissions: { amount: number; currency: string }[];
  sales: number;
  linkClicks: number;
  conversionRate: number;
}

export interface InfluencerPerformance {
  summary: MarketplacePerformanceSummary;
  orders: InfluencerPerformanceOrder[];
  pagination: { limit: number; page: number; total_count: number };
}

export interface InfluencerPerformanceOrder {
  id: string;
  brand_name: string;
  created_at: string;
  value: number;
  commission: number;
  status: FilterCommissionStatus;
  currency: string;
}

export const pageLimits = [15, 30, 60];

export interface MarketplaceInterestsBody {
  influencerInterests: string[];
}
export interface MarketplaceAdvertisersBody {
  programmeIds: string[];
}
export interface FavouriteProductsResponse {
  favouriteProducts: string[];
}

export type FavouriteProductsUpdateAction = 'add' | 'remove';

export interface UpdateFavouriteProductsBody {
  action: FavouriteProductsUpdateAction;
}

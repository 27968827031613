import { DispatchFn } from '../state';
import client, { Microservice } from '../../utils/http.utils';
import {
  ApplicationForm,
  AssociationPostBody,
  CampaignSummaryApi,
  FormValue,
  Programme,
  SocialChannel,
} from './store';
import { transformToApplicationFormBody } from './payload';
import {
  TrackingLinkAPIModel,
  TrackingLinkPostBody,
  CommissionsAPImodel,
  CommissionSummaryAPImodel,
  CommissionFilterQueries,
  PaginatedCommissionFilterQueries,
} from '../../contexts/common/interfaces';
import { apiError, ErrorMessage } from '../../types/error.types';
import { captureException } from '@sentry/react';
import { ProgrammeQuery } from '../../contexts/Campaigns';
import { queryBuilder } from '../../contexts/common/utils';

export enum ProgrammeActions {
  requestGetProgrammesAttempt = 'REQUEST_GET_PROGRAMMES_ATTEMPT',
  requestGetProgrammesSuccess = 'REQUEST_GET_PROGRAMMES_SUCCESS',
  requestGetProgrammesFailure = 'REQUEST_GET_PROGRAMMES_FAILURE',

  requestGetProgrammeAttempt = 'REQUEST_GET_PROGRAMME_ATTEMPT',
  requestGetProgrammeSuccess = 'REQUEST_GET_PROGRAMME_SUCCESS',
  requestGetProgrammeFailure = 'REQUEST_GET_PROGRAMME_FAILURE',

  requestGetWalletProgrammesAttempt = 'REQUEST_GET_WALLET_PROGRAMMES_ATTEMPT',
  requestGetWalletProgrammesSuccess = 'REQUEST_GET_WALLET_PROGRAMMES_SUCCESS',
  requestGetWalletProgrammesFailure = 'REQUEST_GET_WALLET_PROGRAMMES_FAILURE',

  requestGetAssociationAttempt = 'REQUEST_GET_ASSOCIATION_ATTEMPT',
  requestGetAssociationSuccess = 'REQUEST_GET_ASSOCIATION_SUCCESS',
  requestGetAssociationFailure = 'REQUEST_GET_ASSOCIATION_FAILURE',

  requestGetAssociationsAttempt = 'REQUEST_GET_ASSOCIATIONS_ATTEMPT',
  requestGetAssociationsSuccess = 'REQUEST_GET_ASSOCIATIONS_SUCCESS',
  requestGetAssociationsFailure = 'REQUEST_GET_ASSOCIATIONS_FAILURE',

  requestGetDiscountCodesAttempt = 'REQUEST_GET_DISCOUNT_CODES_ATTEMPT',
  requestGetDiscountCodesSuccess = 'REQUEST_GET_DISCOUNT_CODES_SUCCESS',
  requestGetDiscountCodesFailure = 'REQUEST_GET_DISCOUNT_CODES_FAILURE',

  requestPostAssociationAttempt = 'REQUEST_POST_ASSOCIATION_ATTEMPT',
  requestPostAssociationSuccess = 'REQUEST_POST_ASSOCIATION_SUCCESS',
  requestPostAssociationFailure = 'REQUEST_POST_ASSOCIATION_FAILURE',

  requestPutAssociationAttempt = 'REQUEST_PUT_ASSOCIATION_ATTEMPT',
  requestPutAssociationSuccess = 'REQUEST_PUT_ASSOCIATION_SUCCESS',
  requestPutAssociationFailure = 'REQUEST_PUT_ASSOCIATION_FAILURE',

  requestGetAgreementAttempt = 'REQUEST_GET_AGREEMENT_ATTEMPT',
  requestGetAgreementSuccess = 'REQUEST_GET_AGREEMENT_SUCCESS',
  requestGetAgreementFailure = 'REQUEST_GET_AGREEMENT_FAILURE',

  requestGetTrackingLinksAttempt = 'REQUEST_GET_TRACKING_LINKS_ATTEMPT',
  requestGetTrackingLinksSuccess = 'REQUEST_GET_TRACKING_LINKS_SUCCESS',
  requestGetTrackingLinksFailure = 'REQUEST_GET_TRACKING_LINKS_FAILURE',

  requestGetTrackingLinksBackgroundAttempt = 'REQUEST_GET_TRACKING_LINKS_BACKGROUND_ATTEMPT',
  requestGetTrackingLinksBackgroundSuccess = 'REQUEST_GET_TRACKING_LINKS_BACKGROUND_SUCCESS',
  requestGetTrackingLinksBackgroundFailure = 'REQUEST_GET_TRACKING_LINKS_BACKGROUND_FAILURE',

  requestGetTrackingLinkBackgroundAttempt = 'REQUEST_GET_TRACKING_LINK_BACKGROUND_ATTEMPT',
  requestGetTrackingLinkBackgroundSuccess = 'REQUEST_GET_TRACKING_LINK_BACKGROUND_SUCCESS',
  requestGetTrackingLinkBackgroundFailure = 'REQUEST_GET_TRACKING_LINK_BACKGROUND_FAILURE',

  requestPostTrackingLinkAttempt = 'REQUEST_POST_TRACKING_LINK_ATTEMPT',
  requestPostTrackingLinkSuccess = 'REQUEST_POST_TRACKING_LINK_SUCCESS',
  requestPostTrackingLinkFailure = 'REQUEST_POST_TRACKING_LINK_FAILURE',

  requestPutTrackingLinkAttempt = 'REQUEST_PUT_TRACKING_LINK_ATTEMPT',
  requestPutTrackingLinkSuccess = 'REQUEST_PUT_TRACKING_LINK_SUCCESS',
  requestPutTrackingLinkFailure = 'REQUEST_PUT_TRACKING_LINK_FAILURE',

  requestGetAssociationAgreementAttempt = 'REQUEST_GET_ASSOCIATION_AGREEMENT_ATTEMPT',
  requestGetAssociationAgreementSuccess = 'REQUEST_GET_ASSOCIATION_AGREEMENT_SUCCESS',
  requestGetAssociationAgreementFailure = 'REQUEST_GET_ASSOCIATION_AGREEMENT_FAILURE',

  clearCurrentProgramme = 'CLEAR_CURRENT_PROGRAMME',
  clearCurrentAssociation = 'CLEAR_CURRENT_ASSOCIATION',
  clearCommissions = 'CLEAR_COMMISSIONS',
  clearTrackingFormErrors = 'CLEAR_TRACKING_FORM_ERRORS',

  requestGetCommissionsAttempt = 'REQUEST_GET_COMMISSIONS_ATTEMPT',
  requestGetCommissionsSuccess = 'REQUEST_GET_COMMISSIONS_SUCCESS',
  requestGetCommissionsFailure = 'REQUEST_GET_COMMISSIONS_FAILURE',

  requestGetCommissionSummaryAttempt = 'REQUEST_GET_COMMISSION_SUMMARY_ATTEMPT',
  requestGetCommissionSummarySuccess = 'REQUEST_GET_COMMISSION_SUMMARY_SUCCESS',
  requestGetCommissionSummaryFailure = 'REQUEST_GET_COMMISSION_SUMMARY_FAILURE',

  requestGetCommissionOverviewAttempt = 'REQUEST_GET_COMMISSION_OVERVIEW_ATTEMPT',
  requestGetCommissionOverviewSuccess = 'REQUEST_GET_COMMISSION_OVERVIEW_SUCCESS',
  requestGetCommissionOverviewFailure = 'REQUEST_GET_COMMISSION_OVERVIEW_FAILURE',

  resetAssociations = 'RESET_ASSOCIATIONS',

  setPostAssociationFeedbackOpen = 'SET_POST_ASSOCIATION_FEEDBACK_OPEN',

  requestGetApplicationInfoAttempt = 'REQUEST_GET_APPLICATION_INFO_ATTEMPT',
  requestGetApplicationInfoSuccess = 'REQUEST_GET_APPLICATION_INFO_SUCCESS',
  requestGetApplicationInfoFailure = 'REQUEST_GET_APPLICATION_INFO_FAILURE',

  requestPostApplicationAttempt = 'REQUEST_POST_APPLICATION_ATTEMPT',
  requestPostApplicationSuccess = 'REQUEST_POST_APPLICATION_SUCCESS',
  requestPostApplicationFailure = 'REQUEST_POST_APPLICATION_FAILURE',

  requestPostApplicationReminderAttempt = 'REQUEST_POST_APPLICATION_REMINDER_ATTEMPT',
  requestPostApplicationReminderSuccess = 'REQUEST_POST_APPLICATION_REMINDER_SUCCESS',
  requestPostApplicationReminderFailure = 'REQUEST_POST_APPLICATION_REMINDER_FAILURE',

  setApplicationForm = 'SET_APPLICATION_FORM',

  requestGetCampaignsAttempt = 'REQUEST_GET_CAMPAIGNS_ATTEMPT',
  requestGetCampaignsSuccess = 'REQUEST_GET_CAMPAIGNS_SUCCESS',
  requestGetCampaignsFailure = 'REQUEST_GET_CAMPAIGNS_FAILURE',

  requestGetCampaignsInviteAttempt = 'REQUEST_GET_CAMPAIGN_INVITATIONS_ATTEMPT',
  requestGetCampaignsInviteSuccess = 'REQUEST_GET_CAMPAIGN_INVITATIONS_SUCCESS',
  requestGetCampaignsInviteFailure = 'REQUEST_GET_CAMPAIGN_INVITATIONS_FAILURE',

  requestGetUserProgrammeAssociationsAttempt = 'REQUEST_GET_USER_PROGRAMME_ASSOCIATIONS_ATTEMPT',
  requestGetUserProgrammeAssociationsSuccess = 'REQUEST_GET_USER_PROGRAMME_ASSOCIATIONS_SUCCESS',
  requestGetUserProgrammeAssociationsFailure = 'REQUEST_GET_USER_PROGRAMME_ASSOCIATIONS_FAILURE',

  clearCurrentCampaigns = 'CLEAR_CURRENT_CAMPAIGNS',
}

export const RequestGetProgrammesAsync = async (
  dispatch: DispatchFn,
  isMarketplaceIncluded = false
): Promise<void> => {
  dispatch({ type: ProgrammeActions.requestGetProgrammesAttempt });
  try {
    const response: Programme[] = await client.request(
      Microservice.PROGRAMME,
      `/programme?is_marketplace_included=${isMarketplaceIncluded}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetProgrammesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetProgrammesFailure,
      payload: 'An error occurred while fetching Programmes.',
    });
  }
};

export const RequestGetProgrammeAsync = async (
  dispatch: DispatchFn,
  id: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetProgrammeAttempt });
  try {
    const response: Programme = await client.request(
      Microservice.PROGRAMME,
      `/programme/${id}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetProgrammeSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetProgrammeFailure,
      payload: 'An error occurred while fetching Programmes.',
    });
  }
};

export const RequestGetUserProgrammeAssociations = async (
  dispatch: DispatchFn,
  userId: string
): Promise<void> => {
  dispatch({
    type: ProgrammeActions.requestGetUserProgrammeAssociationsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/association/user/${userId}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetUserProgrammeAssociationsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetUserProgrammeAssociationsFailure,
      payload:
        'An error occurred while fetching user associated programme IDs.',
    });
  }
};

export const RequestGetWalletProgrammesAsync = async (
  dispatch: DispatchFn,
  programmeIds: string[],
  isMarketplaceIncluded: boolean | undefined
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetWalletProgrammesAttempt });
  try {
    const response: Programme[] = await client.request(
      Microservice.PROGRAMME,
      `/programme?programme_ids=${programmeIds.join(
        ','
      )}&is_marketplace_included=${isMarketplaceIncluded}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetWalletProgrammesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetWalletProgrammesFailure,
      payload: 'An error occurred while fetching Programmes by IDs.',
    });
  }
};

export const RequestPostAssociationAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  body: AssociationPostBody
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestPostAssociationAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association`,
      'POST',
      body
    );
    dispatch({
      type: ProgrammeActions.requestPostAssociationSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestPostAssociationFailure,
      payload: 'An error occurred while posting Association.',
    });
    throw e;
  }
};

export const RequestGetAssociationAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  userId: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetAssociationAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association/${userId}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetAssociationSuccess,
      payload: response,
    });
  } catch (e: any) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetAssociationFailure,
      payload:
        e.status === ErrorMessage.NOT_FOUND
          ? null
          : 'An error occurred while fetching Association.',
    });
    throw apiError(e.status, e.message);
  }
};

export const RequestGetDiscountCodesAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  userId: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetDiscountCodesAttempt });
  try {
    const response = await client.request(
      Microservice.COMMISSION,
      `/discount/influencer/${userId}/programme/${programmeId}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetDiscountCodesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetDiscountCodesFailure,
      payload: 'An error occurred while fetching Discount Codes.',
    });
  }
};

export const RequestGetTrackingLinksAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  userId: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetTrackingLinksAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association/${userId}/tracking`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetTrackingLinksSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetTrackingLinksFailure,
      payload: 'An error occurred while fetching tracking links.',
    });
  }
};

export const RequestGetTrackingLinksBackgroundAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  userId: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetTrackingLinksBackgroundAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association/${userId}/tracking`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetTrackingLinksBackgroundSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetTrackingLinksBackgroundFailure,
      payload: 'An error occurred while fetching tracking links.',
    });
  }
};

export const RequestGetTrackingLinkBackgroundAsync = async (
  dispatch: DispatchFn,
  trackingLinkId: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetTrackingLinkBackgroundAttempt });
  try {
    const response = await client.request<TrackingLinkAPIModel>(
      Microservice.PROGRAMME,
      `/programme/tracking/${trackingLinkId}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetTrackingLinkBackgroundSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetTrackingLinkBackgroundFailure,
      payload: 'An error occurred while fetching tracking link.',
    });
  }
};

export const RequestPostTrackingLinkAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  influencerId: string,
  body: TrackingLinkPostBody
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestPostTrackingLinkAttempt });
  try {
    const response = await client.request<
      TrackingLinkAPIModel,
      TrackingLinkPostBody
    >(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association/${influencerId}/tracking`,
      'POST',
      body
    );

    dispatch({
      type: ProgrammeActions.requestPostTrackingLinkSuccess,
      payload: response,
    });
  } catch (e: any) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestPostTrackingLinkFailure,
      payload: 'An error occurred while posting Tracking Link.',
    });
    throw apiError(e.status, e.message);
  }
};

export const RequestPutTrackingLinkAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  influencerId: string,
  body: TrackingLinkAPIModel
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestPutTrackingLinkAttempt });
  try {
    await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association/${influencerId}/tracking/${body.id}`,
      'PUT',
      body
    );
    dispatch({
      type: ProgrammeActions.requestPutTrackingLinkSuccess,
      payload: body,
    });
  } catch (e: any) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestPutTrackingLinkFailure,
      payload: 'An error occurred while updating Tracking Link.',
    });
    throw apiError(e.status, e.message);
  }
};

export const ClearTrackingFormErrors = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammeActions.clearTrackingFormErrors });
};

export const RequestGetAssociationsAsync = async (
  dispatch: DispatchFn,
  programmeIds: string[],
  userId: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetAssociationsAttempt });
  try {
    const responses = await Promise.all(
      programmeIds.map((id: string) =>
        client.request(
          Microservice.PROGRAMME,
          `/programme/${id}/association/${userId}`,
          'GET'
        )
      )
    );
    dispatch({
      type: ProgrammeActions.requestGetAssociationsSuccess,
      payload: responses,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetAssociationsFailure,
      payload: 'An error occurred while fetching associations.',
    });
  }
};

export const RequestGetProgrammeAgreementAsync = async (
  dispatch: DispatchFn,
  programmeId: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetAgreementAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/agreement`,
      'GET'
    );
    dispatch({
      type: ProgrammeActions.requestGetAgreementSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetAgreementFailure,
      payload: 'An error occurred while getting agreement.',
    });
  }
};

export const RequestGetAssociationAgreementAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  influencerId: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetAssociationAgreementAttempt });

  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association/${influencerId}/agreement`,
      'GET'
    );
    dispatch({
      type: ProgrammeActions.requestGetAssociationAgreementSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetAssociationsFailure,
      payload: 'An error occurred trying to fetch associated agreement.',
    });
  }
};

export const ClearCurrentProgramme = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammeActions.clearCurrentProgramme });
};

export const ClearCurrentAssociation = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammeActions.clearCurrentAssociation });
};

export const ClearCommissions = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammeActions.clearCommissions });
};

export const RequestGetCommissionsAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  influencerId: string,
  pageObject: PaginatedCommissionFilterQueries
): Promise<void> => {
  dispatch({ type: ProgrammeActions.requestGetCommissionsAttempt });

  const queries = queryBuilder<PaginatedCommissionFilterQueries>(pageObject);
  try {
    const response: CommissionsAPImodel = await client.request(
      Microservice.COMMISSION,
      `/commission/programme/${programmeId}/influencer/${influencerId}/commissions${queries}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetCommissionsSuccess,
      payload: { ...response, pageNumber: pageObject.page },
    });
  } catch (e: any) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetCommissionsFailure,
      payload: 'An error occurred while fetching Commissions.',
    });

    throw apiError(e.status, e.message);
  }
};

export const RequestGetCommissionSummaryAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  influencerId: string,
  queryObject: CommissionFilterQueries
): Promise<void> => {
  dispatch({ type: ProgrammeActions.requestGetCommissionSummaryAttempt });

  const queries = queryBuilder<CommissionFilterQueries>(queryObject);
  try {
    const response: CommissionSummaryAPImodel = await client.request(
      Microservice.COMMISSION,
      `/commission/programme/${programmeId}/influencer/${influencerId}/summary${queries}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetCommissionSummarySuccess,
      payload: { ...response },
    });
  } catch (e: any) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetCommissionSummaryFailure,
      payload: 'An error occurred while fetching the commission summary.',
    });
    throw apiError(e.status, e.message);
  }
};

export const RequestGetCommissionOverviewAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  influencerId: string,
  startDate: string | null
): Promise<void> => {
  dispatch({ type: ProgrammeActions.requestGetCommissionOverviewAttempt });

  const queries = startDate ? `?start_date=${startDate}T00:00:00Z` : '';
  try {
    const response: CommissionSummaryAPImodel = await client.request(
      Microservice.COMMISSION,
      `/commission/programme/${programmeId}/influencer/${influencerId}/overview${queries}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetCommissionOverviewSuccess,
      payload: { ...response },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetCommissionOverviewFailure,
      payload: 'An error occurred while fetching the commission overview.',
    });
  }
};

export const RequestCommissionsAndSummary = async (
  dispatch: DispatchFn,
  programmeId: string,
  influencerId: string,
  pageQuery: PaginatedCommissionFilterQueries
): Promise<void> => {
  RequestGetCommissionsAsync(
    dispatch,
    programmeId,
    influencerId,
    pageQuery
  ).catch(e => captureException(e));
  RequestGetCommissionSummaryAsync(dispatch, programmeId, influencerId, {
    start_date: pageQuery.start_date,
    end_date: pageQuery.end_date,
    status: pageQuery.status,
  }).catch(e => captureException(e));
};

export const ResetAssociations = async (
  dispatch: DispatchFn
): Promise<void> => {
  dispatch({
    type: ProgrammeActions.resetAssociations,
    payload: null,
  });
};

export const SetPostAssociationFeedbackModal = (
  dispatch: DispatchFn,
  shouldOpen: boolean
) => {
  dispatch({
    type: ProgrammeActions.setPostAssociationFeedbackOpen,
    payload: shouldOpen,
  });
};

export const RequestGetApplicationInfoAsync = async (
  dispatch: DispatchFn,
  programmeId: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetApplicationInfoAttempt });
  try {
    const response: Programme[] = await client.requestNoAuth(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/application_info`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetApplicationInfoSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetApplicationInfoFailure,
      payload: 'An error occurred while fetching the application info.',
    });
  }
};

export const RequestPostApplicationAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  applicationForm: ApplicationForm,
  recaptchaToken: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestPostApplicationAttempt });
  const form = transformToApplicationFormBody(applicationForm);
  try {
    await client.requestNoAuth(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/application?recaptcha_token=${recaptchaToken}`,
      'POST',
      form
    );

    dispatch({
      type: ProgrammeActions.requestPostApplicationSuccess,
    });
  } catch (e: any) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestPostApplicationFailure,
      payload: `${e.status}`,
    });
    throw apiError(e.status, e.message);
  }
};

export const RequestPostApplicationReminderAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  email: string,
  recaptchaToken: string
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestPostApplicationReminderAttempt });
  try {
    await client.requestNoAuth(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/application_reminder?recaptcha_token=${recaptchaToken}`,
      'POST',
      { email_address: email }
    );

    dispatch({
      type: ProgrammeActions.requestPostApplicationReminderSuccess,
    });
  } catch (e: any) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestPostApplicationReminderFailure,
      payload: 'An error occurred while posting the application reminder.',
    });
    throw apiError(e.status, e.message);
  }
};

export const SetApplicationForm = (
  dispatch: DispatchFn,
  type: keyof ApplicationForm,
  value: FormValue<string | SocialChannel[] | null>
) => {
  dispatch({
    type: ProgrammeActions.setApplicationForm,
    payload: { type: type, value: value },
  });
};

export const RequestGetCampaignsAsync = async (
  dispatch: DispatchFn,
  programmeQuery: ProgrammeQuery | null
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetCampaignsAttempt });
  const queryString = programmeQuery
    ? queryBuilder<ProgrammeQuery>(programmeQuery)
    : '';
  try {
    const response: CampaignSummaryApi = await client.request(
      Microservice.INFLUENCER,
      `/influencer/influencer_campaign/associations${queryString}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetCampaignsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetCampaignsFailure,
      payload: 'An error occurred while fetching Campaigns.',
    });
  }
};

export const RequestGetCampaignInvitationsAsync = async (
  dispatch: DispatchFn,
  programmeQuery: ProgrammeQuery | null
): Promise<any> => {
  dispatch({ type: ProgrammeActions.requestGetCampaignsInviteAttempt });
  const queryString = programmeQuery
    ? queryBuilder<ProgrammeQuery>(programmeQuery)
    : '';
  try {
    const response: CampaignSummaryApi = await client.request(
      Microservice.INFLUENCER,
      `/influencer/influencer_campaign/invitations${queryString}`,
      'GET'
    );

    dispatch({
      type: ProgrammeActions.requestGetCampaignsInviteSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ProgrammeActions.requestGetCampaignsFailure,
      payload: 'An error occurred while fetching Campaigns.',
    });
  }
};

export const ClearCurrentCampaigns = (dispatch: DispatchFn) => {
  dispatch({ type: ProgrammeActions.clearCurrentCampaigns });
};

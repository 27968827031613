import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { px2rem } from '../../../../styling/sizing';
import { theme, Theme } from '../../../../styling/theme';
import { LinkFocusOverlayStyles } from '../../../../common/Link';
import { GlobalFontFamily } from '../../../../styling/css/fontfamily';

const LinkIcon = styled.span`
  border: ${px2rem(2)} solid white;
  border-radius: ${props => theme(props).EFFECTS.RADIUS_FULL};
  height: ${px2rem(30)};
  width: ${px2rem(30)};
  min-width: ${px2rem(30)};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
`;

const LinkText = styled.span`
  margin-left: 0.75em;
`;

interface MenuButtonProps {
  expanded?: boolean;
}

const MenuButton = styled.button`
  all: unset;
  ${LinkFocusOverlayStyles};
  display: flex;
  width: 100%;
  position: relative;
  background: none;
  border: none;

  & > span:hover {
    background-color: ${props => theme(props).COLOUR.BACKGROUND_HIGHLIGHT};
  }

  &.selected span,
  & > span:hover {
    ${LinkIcon} {
      opacity: 1;
      background-color: white;
      color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
      transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    }
  }
`;

const MenuContent = styled.span<MenuButtonProps>`
  all: unset;
  display: flex;
  color: white;
  width: inherit;
  align-items: center;
  padding: ${props => (props.expanded ? '1em 1.5em' : '1em 0')};
  cursor: pointer;
  justify-content: ${props => (props.expanded ? 'flex-start' : 'center')};
  transition: all ${props => theme(props).EFFECTS.TRANSITION_SLOW};
`;

const RouterLinkStyles = css`
  list-style: none;
  text-decoration: none;
  color: white;
  display: block;

  & > span:hover {
    background-color: ${props => theme(props).COLOUR.BACKGROUND_HIGHLIGHT};
  }
  &:focus {
    overflow: revert;
  }

  &.selected span,
  & > span:hover {
    ${LinkIcon} {
      opacity: 1;
      background-color: white;
      color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
      transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    }
  }
`;

const RouterLink = styled(NavLink)<{ theme: Theme }>`
  ${LinkFocusOverlayStyles};
  ${RouterLinkStyles};
`;

const ExternalRouterLink = styled.a<{ theme: Theme }>`
  ${LinkFocusOverlayStyles};
  ${RouterLinkStyles};
`;

const MenuContentSecondary = styled.span<MenuButtonProps>`
  ${GlobalFontFamily};
  all: unset;
  display: flex;
  color: white;
  width: inherit;
  height: fit-content;
  align-items: flex-start;
  padding: 0em 1em;
  cursor: pointer;
  opacity: 1;
  justify-content: ${props => (props.expanded ? 'flex-start' : 'center')};
  & > span {
    font-weight: 200;
  }
`;

export {
  RouterLink,
  LinkIcon,
  LinkText,
  MenuContent,
  MenuButton,
  MenuContentSecondary,
  ExternalRouterLink,
};

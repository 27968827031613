import type { Icon, IconPathsData, IconPathData } from '../types';
import { iosCircleLock } from './iosCircleLock';

export const iconPathData: { [p in Icon]: IconPathData | IconPathsData } = {
  iosCircleLock,
  default: {
    paths: [
      {
        d: '',
      },
    ],
    defaultProps: {
      fill: 'currentColor',
      viewBox: '0 0 512 512',
    },
  },
};

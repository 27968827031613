import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Label,
  ProgressLine,
  StageBubble,
  StageLabel,
  StagesContainer,
  StagesLabelsContainer,
} from './StageBubbles.styles';

interface BubbleStageProps {
  currentStage: number;
  stageList: string[];
  isDark?: boolean;
  isMobile?: boolean;
  mode?: 'dark' | 'normal';
}
export const StageBubbles: React.FC<BubbleStageProps> = ({
  currentStage,
  stageList,
  isDark = false,
  isMobile = false,
  mode = 'normal',
}) => {
  return (
    <>
      <StagesContainer isMobile={isMobile}>
        {stageList.map((stage, index) => (
          <div key={`stage_${stage}`}>
            <StageBubble
              key={`bubble_${stage}`}
              isActive={currentStage === index}
              isCompleted={currentStage > index}
              isDark={isDark}
              isMobile={isMobile}
            >
              {index < currentStage ? (
                <Label>
                  <FontAwesomeIcon icon="check" />
                </Label>
              ) : (
                <Label>{index + 1}</Label>
              )}
            </StageBubble>
            {index < stageList.length - 1 && (
              <ProgressLine
                isDark={isDark}
                isCompleted={currentStage > index}
                key={`progress_line_${stage}`}
              />
            )}
          </div>
        ))}
      </StagesContainer>
      <StagesLabelsContainer isMobile={isMobile} size={stageList.length}>
        {stageList.map((stage: string, index) => (
          <StageLabel
            isDark={isDark}
            key={`stage_label_${stage}`}
            isActive={currentStage === index}
            data-testid={`t_label_${stage}`}
            role={currentStage === index ? 'heading' : undefined}
            aria-level={currentStage === index ? 1 : undefined}
            mode={mode}
          >
            {stage}
          </StageLabel>
        ))}
      </StagesLabelsContainer>
    </>
  );
};

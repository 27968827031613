import styled, { css } from 'styled-components';
import { GlobalFontFamily } from '../../styling/css/fontfamily';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const SimpleTable = styled.table`
  width: 100%;
  text-align: left;
  font-size: 1rem;

  thead > tr {
    height: 3rem;
  }

  tbody > tr {
    padding: ${props => theme(props).SIZE.MEDIUM} 0;
    border-bottom: 1px solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  }

  tbody > tr > td,
  thead > tr > th {
    padding: 0.5rem;
  }

  th {
    font-weight: bold;
  }

  a {
    color: ${props => theme(props).COLOUR.PRIMARY};
    cursor: pointer;
    text-decoration: none;
  }
`;

export const Table = styled.table<{ isGrey?: boolean }>`
  width: 100%;
  text-align: left;
  ${GlobalFontFamily}
  font-size: 1rem;
  ${props =>
    props.isGrey &&
    css`
      border-collapse: separate;
      border-spacing: 0 ${px2rem(8)};
      border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
    `};

  thead > tr {
    height: 3rem;
  }
  tbody > tr > td:first-child {
    border-top-left-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
    border-bottom-left-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
  }
  tbody > tr > td:last-child {
    border-top-right-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
    border-bottom-right-radius: ${props =>
      theme(props).EFFECTS.RADIUS_STANDARD};
  }
  tbody > tr {
    padding: 20px 0;
    border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};

    ${props =>
      props.isGrey
        ? css`
            background-color: ${theme(props).COLOUR.BACKGROUND_LIGHTEST};
            margin-bottom: : ${theme(props).SIZE.SMALL};
          `
        : `border-bottom: 1px solid ${theme(props).COLOUR.BACKGROUND_MEDIUM};`};
  }

  tbody > tr > td,
  thead > tr > th {
    padding: 0.5rem;
  }
  thead > tr > th:first-child {
    padding-left: ${props => theme(props).SIZE.SMALL};
  }
  tbody > tr > td:first-child {
    padding-left: ${props => theme(props).SIZE.SMALL};
  }
  tbody > tr > td:last-child {
    padding-right: ${props => theme(props).SIZE.SMALL};
  }

  th {
    font-weight: bold;
  }

  a {
    color: ${props => theme(props).COLOUR.PRIMARY};
    cursor: pointer;
    text-decoration: none;
  }
`;

export const CentreTd = styled.td`
  vertical-align: middle;
`;

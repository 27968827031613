export const registerDetailsTranslationObject = {
  registerDetails: {
    'REGISTER.DETAILS.HEADER': 'Contact Information',
    'REGISTER.DETAILS.LABEL.FNAME': 'First Name*',
    'REGISTER.DETAILS.PLACEHOLDER.FNAME': 'Enter your First Name...',
    'REGISTER.DETAILS.LABEL.LNAME': 'Last Name*',
    'REGISTER.DETAILS.PLACEHOLDER.LNAME': 'Enter your Last Name...',
    'REGISTER.DETAILS.PLACEHOLDER.COUNTRY': 'Please enter your country...',
    'REGISTER.DETAILS.LABEL.COUNTRY': 'Country*',
    'REGISTER.DETAILS.LABEL.ADDRESS': 'Address*',
    'REGISTER.DETAILS.TOOLTIP.ADDRESS':
      'This would help us send samples to you, if required!',
    'REGISTER.DETAILS.PLACEHOLDER.ADDRESS': 'Enter Your Address...',
    'REGISTER.DETAILS.LABEL.POSTCODE': 'Postal Code*',
    'REGISTER.DETAILS.PLACEHOLDER.POSTCODE': 'Enter You Postal Code...',
    'REGISTER.DETAILS.LABEL.PHONE': 'Phone Number*',
    'REGISTER.DETAILS.TOOLTIP.PHONE':
      'This would allow us to reach out to you regarding your programmes and campaigns!',
    'REGISTER.DETAILS.PLACEHOLDER.PHONE': 'Phone Number',
    'REGISTER.DETAILS.LABEL.DOB': 'Date of Birth',
    'REGISTER.DETAILS.TOOLTIP.DOB':
      'This would help us in case we need to send over some goodies!',
    'REGISTER.DETAILS.SELECT.YEAR': 'Year',
    'REGISTER.DETAILS.SELECT.MONTH': 'Month',
    'REGISTER.DETAILS.SELECT.DAY': 'Day',
    'REGISTER.DETAILS.MESSAGE.VALID': 'all * fields are required',
    'REGISTER.DETAILS.BUTTON.BACK': 'Back',
    'REGISTER.DETAILS.BUTTON.NEXT': 'Next',
    'REGISTER.SOCIALPROFILE.BUTTON.CREATE': `Create Account`,
  },
};

import React from 'react';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';
import s from '../Modal.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translations } from '../../translations';
import { COLOUR } from '../../../styling/theme/COLOUR';
import { useResponsive } from '../../../../hooks';
import { Breakpoint } from '../../../styling/breakpoints';

export const EmailOpt: React.FC = () => {
  const { emailOptModal: t } = useTranslationsController(translations);
  const { layout } = useResponsive();
  const isMobile = layout < Breakpoint.DESKTOP;

  return (
    <div data-testid={'t_emailOpt_modal_marketplace'}>
      <s.ModalList>
        <s.ModalEmailListItem textSize="medium1">
          {t['EMAIL.OPT.MODAL.JOIN.TITLE']}
        </s.ModalEmailListItem>
        <s.ModalEmailListItem>
          <span className="icon">
            <FontAwesomeIcon icon="link" />
          </span>
          {t['EMAIL.OPT.COLLABORATIONS.LINKS.TEXT']}
        </s.ModalEmailListItem>
        <s.ModalEmailListItem>
          <span className="icon">
            <FontAwesomeIcon icon="award" />
          </span>
          {t['EMAIL.OPT.ACCESS.PRODUCT.CATALOGUE.TEXT']}
        </s.ModalEmailListItem>
        <s.ModalEmailListItem border={true} infoNoticeMargin={isMobile}>
          <span className="icon">
            <FontAwesomeIcon icon="info-circle" color={COLOUR.SECONDARY} />
          </span>
          {t['EMAIL.OPT.MODAL.INSIGHTS.TEXT']}
        </s.ModalEmailListItem>
      </s.ModalList>
    </div>
  );
};

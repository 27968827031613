import styled from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const SubTitle = styled.span`
  color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  text-transform: uppercase;
  letter-spacing: ${px2rem(1)};
  display: inline-flex;
  padding-right: ${px2rem(5)};
  background: #bababa1c;
  flex-grow: 1;
  margin-right: ${px2rem(10)};
  padding: ${px2rem(5)} ${px2rem(5)} 0 ${px2rem(5)};
`;

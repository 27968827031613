import React from 'react';
import { PageContentContainer, PageWrapper } from './PageContainer.styles';
import { TopBar } from '../TopBar';
import { Breakpoint } from '../../styling/breakpoints';
import { useResponsive } from '../../../hooks/useResponsive';
import { ToastProvider } from '../../providers/ToastProvider';

export const PageContainer: React.FC<{ fullWidth?: boolean }> = ({
  children,
  fullWidth,
}) => {
  const { layout } = useResponsive();
  return (
    <PageWrapper>
      <TopBar />
      <PageContentContainer
        data-testid="t_page_container"
        isDesktop={layout >= Breakpoint.DESKTOP}
        isFullWidth={fullWidth}
      >
        <ToastProvider>{children}</ToastProvider>
      </PageContentContainer>
    </PageWrapper>
  );
};

export const PageContainerNoTopBar: React.FC<{ fullWidth?: boolean }> = ({
  children,
  fullWidth,
}) => {
  const { layout } = useResponsive();
  return (
    <PageWrapper>
      <PageContentContainer
        data-testid="t_page_container"
        isDesktop={layout >= Breakpoint.DESKTOP}
        isFullWidth={fullWidth}
        role="main"
      >
        {children}
      </PageContentContainer>
    </PageWrapper>
  );
};

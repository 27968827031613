import React, { FC } from 'react';
import { ActionContainer, ValidationMsg } from '../Register.styles';
import {
  Spacer,
  PrimaryButtonAlternate,
  TertiaryButtonAlternate,
  Select,
  DateDropdown,
  FlexContainer,
  IconButton,
} from '../../../common';
import { formFields, getCountriesSortedByName } from '../data';
import { RegisterProps } from '../types';
import {
  DetailsForm,
  DetailsTitle,
  DetailsWrapper,
  DetailsLabel,
  DetailsInput,
  DetailsTextArea,
  DetailsContainer,
} from './Details.styles';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';
import { registerDetailsTranslationObject } from './translations';

interface DetailsProps extends RegisterProps {
  isMobile: boolean;
}

export const Details: FC<DetailsProps> = ({
  details,
  handleDetailsChange,
  handleStageChange,
  isFormValid,
  requiredPostalCode,
  isMobile,
  handleRegister,
}) => {
  const { firstName, lastName, address, postCode, phone, country } = details;
  const { registerDetails: t } = useTranslationsController(
    registerDetailsTranslationObject
  );
  return (
    <DetailsForm
      data-testid="t_details"
      aria-describedby="valid_msg"
      onSubmit={(e: any) => e.preventDefault()}
    >
      <DetailsTitle id="form_title">
        {t['REGISTER.DETAILS.HEADER']}
      </DetailsTitle>
      <DetailsContainer>
        <DetailsWrapper justifyContent="space-between" fullWidth>
          <FlexContainer
            flexDirection="column"
            fullWidth
            alignItems="flex-start"
          >
            <DetailsLabel id="label_fname" isBold>
              {t['REGISTER.DETAILS.LABEL.FNAME']}
            </DetailsLabel>
            <DetailsInput
              data-testid="t_input_fname"
              aria-labelledby="label_fname form_title"
              placeholder={t['REGISTER.DETAILS.PLACEHOLDER.FNAME']}
              value={firstName}
              onChange={e =>
                handleDetailsChange(
                  formFields.FIRST_NAME,
                  e.currentTarget.value
                )
              }
            />
          </FlexContainer>
          <Spacer amount={16} />
          <FlexContainer
            flexDirection="column"
            fullWidth
            alignItems="flex-start"
          >
            <DetailsLabel id="label_lname" isBold>
              {t['REGISTER.DETAILS.LABEL.LNAME']}
            </DetailsLabel>
            <DetailsInput
              data-testid="t_input_lname"
              aria-labelledby="label_lname form_title"
              placeholder={t['REGISTER.DETAILS.PLACEHOLDER.LNAME']}
              value={lastName}
              onChange={e =>
                handleDetailsChange(formFields.LAST_NAME, e.currentTarget.value)
              }
            />
          </FlexContainer>
        </DetailsWrapper>
        <Spacer amount={16} isVertical />
        <Select
          theme="dark"
          testId="t_input_country"
          placeholder={t['REGISTER.DETAILS.PLACEHOLDER.COUNTRY']}
          title={t['REGISTER.DETAILS.LABEL.COUNTRY']}
          value={country}
          onChange={e =>
            handleDetailsChange(formFields.COUNTRY, e.currentTarget.value)
          }
          options={getCountriesSortedByName()}
          isFullWidth
        />
        <Spacer amount={16} isVertical />
        <DetailsLabel id="label_address" isBold>
          {t['REGISTER.DETAILS.LABEL.ADDRESS']}
          <IconButton
            isInlineText
            isMobile={isMobile}
            tooltip={t['REGISTER.DETAILS.TOOLTIP.ADDRESS']}
            icon={'question-circle'}
          />
        </DetailsLabel>
        <DetailsTextArea
          data-testid="t_input_address"
          aria-labelledby="label_address form_title"
          placeholder={t['REGISTER.DETAILS.PLACEHOLDER.ADDRESS']}
          value={address}
          onChange={e =>
            handleDetailsChange(formFields.ADDRESS, e.currentTarget.value)
          }
        />
        <Spacer amount={16} isVertical />
        <DetailsWrapper justifyContent="space-between" fullWidth>
          {requiredPostalCode && (
            <>
              <FlexContainer
                flexDirection="column"
                fullWidth
                alignItems="flex-start"
              >
                <DetailsLabel id="label_postCode" isBold>
                  {t['REGISTER.DETAILS.LABEL.POSTCODE']}
                </DetailsLabel>
                <DetailsInput
                  data-testid="t_input_postcode"
                  aria-labelledby="label_postCode form_title"
                  placeholder={t['REGISTER.DETAILS.PLACEHOLDER.POSTCODE']}
                  value={postCode}
                  onChange={e =>
                    handleDetailsChange(
                      formFields.POST_CODE,
                      e.currentTarget.value
                    )
                  }
                />
              </FlexContainer>
              <Spacer amount={16} />
            </>
          )}

          <FlexContainer
            flexDirection="column"
            fullWidth
            alignItems="flex-start"
          >
            <DetailsLabel id="label_phone" isBold>
              {t['REGISTER.DETAILS.LABEL.PHONE']}
              <IconButton
                isInlineText
                isMobile={isMobile}
                tooltip={t['REGISTER.DETAILS.TOOLTIP.PHONE']}
                icon={'question-circle'}
              />
            </DetailsLabel>
            <DetailsInput
              data-testid="t_input_phone"
              aria-labelledby="label_phone form_title"
              placeholder={t['REGISTER.DETAILS.PLACEHOLDER.PHONE']}
              value={phone}
              onChange={e =>
                handleDetailsChange(
                  formFields.PHONE_NUMBER,
                  e.currentTarget.value
                )
              }
            />
          </FlexContainer>
        </DetailsWrapper>
        <Spacer amount={16} isVertical />
        <DetailsLabel id="date_of_birth" isBold>
          {t['REGISTER.DETAILS.LABEL.DOB']}
          <IconButton
            isInlineText
            isMobile={isMobile}
            tooltip={t['REGISTER.DETAILS.TOOLTIP.DOB']}
            icon={'question-circle'}
          />
        </DetailsLabel>
        <DateDropdown
          displayDate={details.dob ? new Date(details.dob) : null}
          startDate={
            new Date(
              new Date().setUTCFullYear(new Date().getUTCFullYear() - 99)
            )
          }
          endDate={
            new Date(
              new Date().setUTCFullYear(new Date().getUTCFullYear() - 17)
            )
          }
          onChange={e => handleDetailsChange(formFields.DOB, e.toISOString())}
          darkTheme
          yearLabel={t['REGISTER.DETAILS.SELECT.YEAR']}
          monthLabel={t['REGISTER.DETAILS.SELECT.MONTH']}
          dayLabel={t['REGISTER.DETAILS.SELECT.DAY']}
        />
        <Spacer amount={16} isVertical />
        <ValidationMsg id="valid_msg">
          {t['REGISTER.DETAILS.MESSAGE.VALID']}
        </ValidationMsg>
        <Spacer amount={40} isVertical />
        <ActionContainer isMobile={isMobile}>
          <TertiaryButtonAlternate
            data-testid="t_prev_btn"
            onClick={e => {
              e.preventDefault();
              handleStageChange(1);
            }}
            type="button"
            fullWidth={isMobile}
          >
            {t['REGISTER.DETAILS.BUTTON.BACK']}
          </TertiaryButtonAlternate>
          <PrimaryButtonAlternate
            onClick={handleRegister}
            disabled={!isFormValid}
            aria-disabled={!isFormValid}
            data-testid="t_register_button"
          >
            {t['REGISTER.SOCIALPROFILE.BUTTON.CREATE']}
          </PrimaryButtonAlternate>
        </ActionContainer>
      </DetailsContainer>
    </DetailsForm>
  );
};

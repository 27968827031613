import styled from 'styled-components/macro';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';

interface Props {
  open?: boolean;
}

const SideNavContainer = styled.nav<Props>`
  background: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  min-width: ${props => (props.open ? px2rem(225) : px2rem(70))};
  transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  overflow: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  font-size: ${px2rem(14)};
  overflow-x: hidden;
`;

const SidebarLogo = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 1.5em;
  width: ${props => (props.open ? px2rem(225) : px2rem(70))};

  & svg {
    height: ${px2rem(30)};
  }
`;

const CollapseButton = styled.button<Props>`
  background-color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  color: white;
  border: ${px2rem(4)} solid white;
  border-radius: ${props => theme(props).EFFECTS.RADIUS_FULL};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${px2rem(34)};
  height: ${px2rem(34)};
  position: fixed;
  top: 95%;
  left: ${props => (props.open ? px2rem(208) : px2rem(53))};
  transition: left ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  cursor: pointer;

  &:hover {
    border: ${px2rem(3)} solid white;
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  }

  &:focus {
    &:before {
      content: '';
      background: white;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.2;
    }
  }
`;

const Divider = styled.hr`
  border: ${px2rem(1)} solid rgba(255, 255, 255, 0.1);
  width: 85%;
`;

const PushDownDivider = styled(Divider)`
  margin-top: auto;
`;

const MenuContainer = styled.div<{ expanded: boolean }>`
  background: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  color: white;
  flex-grow: ${props => (props.expanded ? 2 : 0)};
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const PagesContainer = styled.div<{ expanded: boolean }>`
  padding-bottom: ${px2rem(2)};
`;

const LogoutContainer = styled.div`
  padding-bottom: ${px2rem(80)};

  & > div:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  }
`;

export {
  SideNavContainer,
  SidebarLogo,
  CollapseButton,
  Divider,
  MenuContainer,
  PagesContainer,
  LogoutContainer,
  PushDownDivider,
};

import React, { FC } from 'react';
import { FacebookLogin as Component } from './FacebookLogin';
import { useFacebookLogin } from './useFacebookLogin';
import { useTranslationsController } from '../../../../../utils/globalTranslationsController';

const translationObject = {
  facebookLogin: {
    'FACEBOOKLOGIN.BUTTON.CONNECT': 'Connect to Instagram',
  },
};

interface FacebookLoginProps {
  fullWidth?: boolean;
  monochrome?: boolean;
}

export const FacebookLogin: FC<FacebookLoginProps> = ({
  fullWidth,
  monochrome,
}) => {
  const props = useFacebookLogin();
  const { facebookLogin: t } = useTranslationsController(translationObject);
  return (
    <Component
      {...props}
      monochrome={monochrome}
      fullWidth={fullWidth}
      buttonText={t['FACEBOOKLOGIN.BUTTON.CONNECT']}
    />
  );
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  StatusContainer,
  StatusIcon,
  StatusTooltip,
} from './NotificationItem.styles';

export const NotificationItemStatus: React.FC<{
  updateStatus: () => void;
  testId: string;
}> = ({ updateStatus, testId }) => {
  return (
    <StatusContainer>
      <StatusIcon>
        <FontAwesomeIcon
          icon="circle"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            updateStatus();
          }}
          data-testid={testId}
        />
        <StatusTooltip>{`Mark as Read`}</StatusTooltip>
      </StatusIcon>
    </StatusContainer>
  );
};

import { Accordion } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';
import { GlobalFontFamily } from '../../styling/css/fontfamily';

export interface AccordionOptions {
  fullWidth?: boolean;
  status?: string;
}

export const AccordionWrapper = styled.div<AccordionOptions>`
  margin: ${px2rem(4)} 0;
  ${props => props.fullWidth && `width: 100%;`}
  ${props =>
    props.status &&
    `border-right: ${px2rem(4)} solid ${theme(props).getStatusColour(
      props.status
    )};`}
  .MuiExpansionPanel-root {
    width: 100%;
  }

  .MuiExpansionPanel-rounded {
    width: 100%;
  }

  .MuiExpansionPanelSummary-content {
    margin: ${px2rem(4)} 0;

    &.Mui-expanded {
      margin: ${px2rem(6)} 0;
    }
  }

  .MuiExpansionPanelSummary-expandIcon {
    font-size: 1em;
  }

  .MuiPaper-elevation1 {
    box-shadow: 0 ${px2rem(4)} ${px2rem(2)} 0 rgba(0, 0, 0, 0.12);
  }
`;

const DefaultTextStyles = css`
  ${GlobalFontFamily};
  margin: 0 0 0.5em 0;
  padding: 0;
  font-weight: 300;
`;

export const AccordionSummaryContainer = styled.div`
  ${DefaultTextStyles};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${px2rem(5)};
`;

export const AccordionSummaryHeader = styled.div`
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
  align-self: center;
  font-weight: bold;
  margin: 0 0;
`;

export const AccordionSummarySubheader = styled.div`
  ${props => theme(props).TYPOGRAPHY.SMALL};
  align-self: center;
  margin: 0 0;
`;

export const AccordionStyled = styled(Accordion)`
  width: 100%;
`;

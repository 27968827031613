import {
  Agent,
  User,
  UserStatistics,
  SocialRequirement,
  WithdrawalFeedback,
  UserState,
  AssociationFeedback,
  LanguageInterface,
  SocialConnection,
} from './types';

import { MarketplaceFeedback } from '../../contexts/Marketplace/types';

export const defaultWithdrawalFeedback: WithdrawalFeedback = {
  easierToManageTrackingLinks: null,
  manageEarnings: null,
  likelyToRecommend: null,
  anythingElseToSharePostWithdrawal: null,
};

export const defaultSocialRequirement: SocialRequirement = {
  required: true,
  instagramUsername: null,
  youtubeUsername: null,
  lastChangedAt: '',
  lastChangedBy: '',
};

export const defaultSocialConnection: SocialConnection = {
  id: '',
  influencerId: '',
  instagram: null,
  youtube: null,
  lastUpdated: '',
  daysRemaining: null,
  daysRemainingYoutube: null,
  daysRemainingInstagram: null,
};

export const DefaultUserStatistics: UserStatistics = {
  full_name: '',
  avatar_url: '',
  programmes: 0,
  balance: 0,
  clicks: 0,
  conversion_rate: 0,
  sales: 0,
};

export const defaultAgent: Agent = {
  id: 'id',
  agencyId: 'agencyId',
  keycloakId: 'keycloakId',
  firstName: null,
  lastName: null,
  firstLogin: false,
};

export const defaultAssociationFeedback: AssociationFeedback = {
  onboardingEasyToFollow: null,
  satisfiedUsingSociety: null,
  anythingElseToSharePostAssociation: null,
};

export const defaultMarketplaceFeedbackForm: MarketplaceFeedback = {
  easierToFindProductsToPromote: null,
  likelyToRecommendConnect: null,
  improvementsOrChanges: null,
};

export const DefaultLanguage: LanguageInterface = {
  code: 'en-GB',
  name: 'English (United Kingdom)',
};

export const defaultUser: User = {
  influencer_id: '',
  key_cloak_id: '',
  influencer_first_name: '',
  influencer_last_name: '',
  influencer_address: '',
  influencer_country: '',
  influencer_postcode: '',
  influencer_phone_number: '',
  influencer_interests: [],
  influencer_tours_viewed: [],
  influencer_language: DefaultLanguage.code,
};

export const defaultUserState: UserState = {
  keycloakProfile: null,
  fetchError: null,
  isFetching: false,
  userStatistics: null,

  user: null,
  isFetchingUser: false,
  fetchUserError: null,

  isRegisteringUser: false,
  registerUserError: null,
  isRegistrationComplete: null,

  socialRequirement: defaultSocialRequirement,

  permissions: null,

  isAssociationFeedbackCompleted: null,
  showAssociationFeedback: null,

  isWithdrawalFeedbackCompleted: null,
  showWithdrawalFeedback: null,
  socialConnection: null,

  agent: defaultAgent,
  marketplaceProgrammesIds: null,

  isLoading: {
    permissions: false,
    socialRequirement: false,
    getAssociationFeedback: false,
    getWithdrawalFeedback: false,
    postAssociationFeedback: false,
    postWithdrawalFeedback: false,
    socialConnection: false,
    agent: false,
    marketplaceProgrammesIds: false,
  },

  errors: {
    permissions: null,
    socialRequirement: null,
    getAssociationFeedback: null,
    getWithdrawalFeedback: null,
    postAssociationFeedback: null,
    postWithdrawalFeedback: null,
    socialConnection: null,
    agent: null,
    marketplaceProgrammesIds: null,
  },
};

export * from './reducer';
export * from './actions';

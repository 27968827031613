import React from 'react';
import {
  TooltipWrapper,
  DefaultIcon,
  IconWrapper,
  TooltipPositions,
  WrapperPosition,
} from './Tooltip.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type IconType = 'default' | 'info';

interface Props {
  message: string;
  icon?: IconType;
  position?: TooltipPositions;
  wrapperPosition?: WrapperPosition;
  fullWidth?: boolean;
  testId?: string;
  ariaLabel?: string;
}

export const Tooltip: React.FC<Props> = ({
  message,
  children,
  icon = 'default',
  position,
  wrapperPosition,
  fullWidth,
  testId,
  ariaLabel,
}) => {
  const iconObject = {
    default: <DefaultIcon />,
    info: <FontAwesomeIcon icon={'info-circle'} />,
  };
  return (
    <TooltipWrapper
      message={message}
      position={position}
      wrapperPosition={wrapperPosition}
      fullWidth={fullWidth}
      data-testid={testId}
      role={'tooltip'}
      id={testId}
      aria-label={ariaLabel ?? message}
    >
      {children}
      <IconWrapper tabIndex={0} aria-describedby={testId}>
        {iconObject[icon]}
      </IconWrapper>
    </TooltipWrapper>
  );
};

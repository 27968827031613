import { Action } from '../state';
import { ReportingActions } from './actions';
import { ReportingState } from './store';
import transformers from './transformers';

export const ReportingReducer = (
  state: ReportingState,
  action: Action
): ReportingState => {
  switch (action.type) {
    case ReportingActions.requestGetReportAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getReport: true },
        errors: { ...state.errors, getReport: null },
      };
    case ReportingActions.requestGetReportSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, getReport: false },
        report: transformers.toGetReportPayload(action.payload),
      };
    case ReportingActions.requestGetReportFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, getReport: false },
        errors: { ...state.errors, getReport: action.payload },
      };

    case ReportingActions.setSelectedReportingProgramme:
      return {
        ...state,
        selectedReportingProgramme: action.payload,
      };
    case ReportingActions.setSelectedReportingDateRange:
      return {
        ...state,
        selectedReportingDateRange: action.payload,
      };

    case ReportingActions.requestGetTrackingLinksPerformancesAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, trackingLinksPerformances: true },
        errors: { ...state.errors, trackingLinksPerformances: null },
      };
    case ReportingActions.requestGetTrackingLinksPerformancesSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, trackingLinksPerformances: false },
        trackingLinksPerformances:
          transformers.toTrackingLinksPerformancesPayload(action.payload),
      };
    case ReportingActions.requestGetTrackingLinksPerformancesFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, trackingLinksPerformances: false },
        errors: {
          ...state.errors,
          trackingLinksPerformances: action.payload,
        },
      };

    default:
      return state;
  }
};

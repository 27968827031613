import styled, { css } from 'styled-components';
import { GlobalFontFamily } from '../../styling/css/fontfamily';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const SelectWrapper = styled.div<{ isNavBar?: boolean }>`
  white-space: nowrap;
  & * {
    ${GlobalFontFamily}
  }
  select {
    height: 100%;
    width: fit-content;
    max-width: 210px;
    box-sizing: border-box;
    border: none;
    color: white;
    background: transparent;
    align-items: center;
    ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
    ${props =>
      props.isNavBar &&
      css`
        ${props => theme(props).TYPOGRAPHY.SMALL};
        padding: 0 0 0 1.5em;
        text-decoration: none;
        margin-left: ${px2rem(4)};
      `}
    &:focus {
      font-weight: bold;
      outline: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .MuiNativeSelect-select:not([multiple]) option,
  .MuiNativeSelect-select:not([multiple]) optgroup {
    color: ${props => theme(props).COLOUR.TEXT_STANDARD};
    background-color: white;
  }
  svg {
    color: white;
  }
  &:hover {
    cursor: pointer;
  }
`;

import { ErrorMessage } from '../../types/error.types';
import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';
import { parseJws } from '../../utils/token.utils';
import { DispatchFn } from '../state';
import {
  User,
  KeycloakInfo,
  CreateUserPayload,
  EditUserPayload,
  UserPermissionMapApi,
  AssociationFeedbackApi,
  WithdrawalFeedbackApi,
} from './types';

export enum UserActions {
  setKeycloakProfile = 'SET_KEYCLOAK_PROFILE',

  requestUserStatisticsAttempt = 'REQUEST_USER_STATISTICS_ATTEMPT',
  requestUserStatisticsSuccess = 'REQUEST_USER_STATISTICS_SUCCESS',
  requestUserStatisticsFailure = 'REQUEST_USER_STATISTICS_FAILURE',

  requestUserAttempt = 'REQUEST_USER_ATTEMPT',
  requestUserSuccess = 'REQUEST_USER_SUCCESS',
  requestUserFailure = 'REQUEST_USER_FAILURE',

  registerUserAttempt = 'REGISTER_USER_ATTEMPT',
  registerUserSuccess = 'REGISTER_USER_SUCCESS',
  registerUserFailure = 'REGISTER_USER_FAILURE',

  requestPutUserAttempt = 'REQUEST_PUT_USER_ATTEMPT',
  requestPutUserSuccess = 'REQUEST_PUT_USER_SUCCESS',
  requestPutUserFailure = 'REQUEST_PUT_USER_FAILURE',

  requestGetProfileTokenAttempt = 'REQUEST_GET_PROFILE_TOKEN_ATTEMPT',
  requestGetProfileTokenSuccess = 'REQUEST_GET_PROFILE_TOKEN_SUCCESS',
  requestGetProfileTokenFailure = 'REQUEST_GET_PROFILE_TOKEN_FAILURE',

  requestGetSocialRequirementAttempt = 'REQUEST_GET_SOCIAL_REQUIREMENT_ATTEMPT',
  requestGetSocialRequirementSuccess = 'REQUEST_GET_SOCIAL_REQUIREMENT_SUCCESS',
  requestGetSocialRequirementFailure = 'REQUEST_GET_SOCIAL_REQUIREMENT_FAILURE',

  requestPostAssociationFeedbackAttempt = 'REQUEST_POST_ASSOCIATION_FEEDBACK_ATTEMPT',
  requestPostAssociationFeedbackSuccess = 'REQUEST_POST_ASSOCIATION_FEEDBACK_SUCCESS',
  requestPostAssociationFeedbackFailure = 'REQUEST_POST_ASSOCIATION_FEEDBACK_FAILURE',

  requestGetAssociationFeedbackAttempt = 'REQUEST_GET_ASSOCIATION_FEEDBACK_ATTEMPT',
  requestGetAssociationFeedbackSuccess = 'REQUEST_GET_ASSOCIATION_FEEDBACK_SUCCESS',
  requestGetAssociationFeedbackFailure = 'REQUEST_GET_ASSOCIATION_FEEDBACK_FAILURE',

  requestGetWithdrawalFeedbackAttempt = 'REQUEST_GET_WITHDRAWAL_FEEDBACK_ATTEMPT',
  requestGetWithdrawalFeedbackSuccess = 'REQUEST_GET_WITHDRAWAL_FEEDBACK_SUCCESS',
  requestGetWithdrawalFeedbackFailure = 'REQUEST_GET_WITHDRAWAL_FEEDBACK_FAILURE',

  requestPostWithdrawalFeedbackAttempt = 'REQUEST_POST_WITHDRAWAL_FEEDBACK_ATTEMPT',
  requestPostWithdrawalFeedbackSuccess = 'REQUEST_POST_WITHDRAWAL_FEEDBACK_SUCCESS',
  requestPostWithdrawalFeedbackFailure = 'REQUEST_POST_WITHDRAWAL_FEEDBACK_FAILURE',

  requestGetSocialConnectionAttempt = 'REQUEST_GET_SOCIAL_CONNECTION_ATTEMPT',
  requestGetSocialConnectionSuccess = 'REQUEST_GET_SOCIAL_CONNECTION_SUCCESS',
  requestGetSocialConnectionFailure = 'REQUEST_GET_SOCIAL_CONNECTION_FAILURE',

  setRegistrationComplete = 'SET_REGISTRATION_COMPLETE',

  requestGetAgentAttempt = 'REQUEST_GET_AGENT_ATTEMPT',
  requestGetAgentSuccess = 'REQUEST_GET_AGENT_SUCCESS',
  requestGetAgentFailure = 'REQUEST_GET_AGENT_FAILURE',

  requestGetInfluencerProgrammeIdsAttempt = 'REQUEST_GET_INFLUENCER_PROGRAMME_IDS_ATTEMPT',
  requestGetInfluencerProgrammeIdsSuccess = 'REQUEST_GET_INFLUENCER_PROGRAMME_IDS_SUCCESS',
  requestGetInfluencerProgrammeIdsFailure = 'REQUEST_GET_INFLUENCER_PROGRAMME_IDS_FAILURE',
}

export enum UserErrors {
  requestUserError = 'An error occurred while fetching the user.',
  registerUserError = 'An error occurred while creating account.',
  requestPutUserError = 'An error occurred while trying to update your account.',
  requestGetInfluencerProgrammesError = 'An error occurred while fetching the marketplace programmes.',
}

export type UserPermissionMap = Map<string, string>;

export const setKeycloakProfile = (
  dispatch: DispatchFn,
  profile: KeycloakInfo | null
) => {
  dispatch({
    type: UserActions.setKeycloakProfile,
    payload: profile,
  });
};

export const RequestGetUserStatistics = async (
  dispatch: DispatchFn,
  username: string
): Promise<void> => {
  dispatch({ type: UserActions.requestUserStatisticsAttempt });
  try {
    const response = await client.apiRequest(
      `/users/${username}/statistics`,
      'GET'
    );

    dispatch({
      type: UserActions.requestUserStatisticsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({ type: UserActions.requestUserStatisticsFailure });
  }
};

export const RequestGetSocialConnection = async (
  dispatch: DispatchFn,
  influencerId: string
): Promise<void> => {
  dispatch({ type: UserActions.requestGetSocialConnectionAttempt });
  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencerId}/social_connection`,
      'GET'
    );
    dispatch({
      type: UserActions.requestGetSocialConnectionSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.requestGetSocialConnectionFailure,
      payload: 'Something went wrong fetching the social connection.',
    });
  }
};

export const RequestGetUser = async (
  dispatch: DispatchFn,
  signup: boolean
): Promise<void> => {
  dispatch({ type: UserActions.requestUserAttempt });
  try {
    const response = await client.request<User[]>(
      Microservice.INFLUENCER,
      `/influencer?register_flow=${signup}`,
      'GET',
      undefined,
      undefined,
      signup
    );
    if (response.length) {
      dispatch({
        type: UserActions.requestUserSuccess,
        payload: response[0],
      });
    } else {
      dispatch({
        type: UserActions.requestUserFailure,
        payload: UserErrors.requestUserError,
      });
    }
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.requestUserFailure,
      payload: UserErrors.requestUserError,
    });
  }
};

export const RegisterUser = async (
  dispatch: DispatchFn,
  user: CreateUserPayload,
  signup: boolean
): Promise<void> => {
  dispatch({ type: UserActions.registerUserAttempt });
  try {
    await client.request(
      Microservice.INFLUENCER,
      `/influencer?register_flow=${signup}`,
      'POST',
      user,
      undefined,
      signup
    );

    dispatch({ type: UserActions.registerUserSuccess });
    RequestGetUser(dispatch, true);
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.registerUserFailure,
      payload: UserErrors.registerUserError,
    });
  }
};

export const RequestPutUser = async (
  dispatch: DispatchFn,
  user: EditUserPayload,
  signup: boolean
): Promise<void> => {
  dispatch({ type: UserActions.requestPutUserAttempt });
  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${user.influencer_id}?register_flow=${signup}`,
      'PUT',
      user,
      undefined,
      signup
    );
    dispatch({ type: UserActions.requestPutUserSuccess, payload: response });
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.requestPutUserFailure,
      payload: UserErrors.requestPutUserError,
    });
  }
};

export const RequestGetProfileTokenAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({
      type: UserActions.requestGetProfileTokenAttempt,
    });
    const response = await client.requestMinusProfile<string>(
      Microservice.PROFILE,
      '/token',
      'GET',
      null,
      'text/plain'
    );
    const tokenBody = parseJws<UserPermissionMapApi>(response);
    window.profileToken = response;
    dispatch({
      type: UserActions.requestGetProfileTokenSuccess,
      payload: tokenBody,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.requestGetProfileTokenFailure,
      payload: 'An error occurred while fetching profile token',
    });
    throw new Error();
  }
};

export const RequestGetSocialRequirement = async (
  dispatch: DispatchFn,
  influencer_id: string
): Promise<void> => {
  try {
    dispatch({
      type: UserActions.requestGetSocialRequirementAttempt,
    });
    const response = await client.request<string>(
      Microservice.INFLUENCER,
      `/influencer/${influencer_id}/social_requirement`,
      'GET'
    );

    dispatch({
      type: UserActions.requestGetSocialRequirementSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: UserActions.requestGetSocialRequirementFailure,
      payload: 'An error occurred while fetching the social requirements ',
    });
    throw new Error();
  }
};

export const RequestGETAssociationFeedback = async (
  dispatch: DispatchFn,
  influencer_id: string
): Promise<void> => {
  dispatch({ type: UserActions.requestGetAssociationFeedbackAttempt });
  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencer_id}/feedback/post-association`,
      'GET'
    );
    dispatch({
      type: UserActions.requestGetAssociationFeedbackSuccess,
      payload: response,
    });
  } catch (e: any | { status: string }) {
    if (e.status === ErrorMessage.NOT_FOUND) {
      dispatch({
        type: UserActions.requestGetAssociationFeedbackSuccess,
        payload: null,
      });
    } else {
      captureException(e);
      dispatch({
        type: UserActions.requestGetAssociationFeedbackFailure,
        payload: 'An error occurred while fetching your feedback',
      });
    }
  }
};

export const RequestGETWithdrawalFeedback = async (
  dispatch: DispatchFn,
  influencer_id: string
): Promise<void> => {
  dispatch({ type: UserActions.requestGetWithdrawalFeedbackAttempt });
  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencer_id}/feedback/post-withdrawal`,
      'GET'
    );
    dispatch({
      type: UserActions.requestGetWithdrawalFeedbackSuccess,
      payload: response,
    });
  } catch (e: any | { status: string }) {
    if (e.status === ErrorMessage.NOT_FOUND) {
      dispatch({
        type: UserActions.requestGetWithdrawalFeedbackSuccess,
        payload: null,
      });
    } else {
      captureException(e);
      dispatch({
        type: UserActions.requestGetWithdrawalFeedbackFailure,
        payload: 'An error occurred while fetching your feedback',
      });
    }
  }
};

export const RequestPOSTAssociationFeedback = async (
  dispatch: DispatchFn,
  feedback: AssociationFeedbackApi,
  influencer_id: string
): Promise<void> => {
  dispatch({ type: UserActions.requestPostAssociationFeedbackAttempt });
  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencer_id}/feedback/post-association`,
      'POST',
      feedback
    );
    dispatch({
      type: UserActions.requestPostAssociationFeedbackSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.requestPostAssociationFeedbackFailure,
      payload: UserErrors.requestPutUserError,
    });
  }
};

export const RequestPOSTWithdrawalFeedback = async (
  dispatch: DispatchFn,
  feedback: WithdrawalFeedbackApi,
  influencer_id: string
): Promise<void> => {
  dispatch({ type: UserActions.requestPostWithdrawalFeedbackAttempt });
  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencer_id}/feedback/post-withdrawal`,
      'POST',
      feedback
    );
    dispatch({
      type: UserActions.requestPostWithdrawalFeedbackSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.requestPostWithdrawalFeedbackFailure,
      payload: UserErrors.requestPutUserError,
    });
  }
};

export const SetRegistrationComplete = (
  dispatch: DispatchFn,
  value: boolean
) => {
  dispatch({
    type: UserActions.setRegistrationComplete,
    payload: value,
  });
};

export const RequestGetAgentAsync = async (dispatch: DispatchFn) => {
  dispatch({ type: UserActions.requestGetAgentAttempt });
  try {
    const response = await client.request(Microservice.AGENCY, `/agent`, 'GET');
    dispatch({
      type: UserActions.requestGetAgentSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.requestGetAgentFailure,
      payload: 'An error occurred trying to fetch the agent details.',
    });
    throw e;
  }
};

export const RequestGetInfluencerMarketplaceProgrammeIds = async (
  dispatch: DispatchFn,
  influencerId: string
): Promise<void> => {
  dispatch({
    type: UserActions.requestGetInfluencerProgrammeIdsAttempt,
  });
  try {
    const response: string[] = await client.request(
      Microservice.PROGRAMME,
      `/programme/marketplace/${influencerId}`,
      'GET'
    );

    dispatch({
      type: UserActions.requestGetInfluencerProgrammeIdsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: UserActions.requestGetInfluencerProgrammeIdsFailure,
      payload: UserErrors.requestGetInfluencerProgrammesError,
    });
  }
};

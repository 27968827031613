import React, { createContext, Dispatch, useState } from 'react';

export const MenuStateContext = createContext<
  [boolean, Dispatch<React.SetStateAction<any>>]
>([
  false,
  () => {
    return;
  },
]);

export const MenuStateProvider: React.FC = ({ children }) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <MenuStateContext.Provider value={[expanded, setExpanded]}>
      {children}
    </MenuStateContext.Provider>
  );
};

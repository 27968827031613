import React, { MouseEvent } from 'react';
import Keycloak from 'keycloak-js';
import { getKeycloakInstance } from '../../../keycloak/keycloak-init';
import { Breakpoint } from '../../styling/breakpoints';
import { useResponsive } from '../../../hooks/useResponsive';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';

export const AgentNavigation: React.FC<{ kc?: Keycloak }> = ({
  kc = getKeycloakInstance(),
}) => {
  const { layout } = useResponsive();
  const logout = (e: MouseEvent<Element>) => {
    e.preventDefault();
    if (kc) kc.logout();
    else throw new Error('Keycloak instance is not defined');
  };

  return layout < Breakpoint.DESKTOP ? (
    <MobileNav logoutHandler={logout} />
  ) : (
    <DesktopNav logoutHandler={logout} />
  );
};

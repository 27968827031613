import styled, { css } from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';
import { BoxProps } from './types';

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;

  ${props =>
    props.flex &&
    css`
      display: flex;
    `}

  ${props =>
    props.expand &&
    css`
      flex: 1;
    `}

  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}

  ${props =>
    props.align &&
    css`
      align-items: ${props.align};
    `}

  ${props =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `};

  margin: ${props =>
    `${props.marginTop || 0} ${props.marginRight || 0} 
    ${props.marginBottom || 0} ${props.marginLeft || 0}`};

  padding: ${props =>
    `${props.paddingTop || 0} ${props.paddingRight || 0} 
    ${props.paddingBottom || 0} ${props.paddingLeft || 0}`};

  ${props => props.padding && `padding: ${props.padding};`}

  ${props =>
    props.border &&
    css`
      border: ${px2rem(1)} solid ${theme(props).COLOUR.BACKGROUND_MEDIUM};
    `}

  ${props =>
    props.round &&
    css`
      border-radius: ${theme(props).EFFECTS.RADIUS_STANDARD};
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `}

  ${props =>
    props.scroll &&
    css`
      overflow-y: auto;
    `}

  ${props =>
    props.columnGap &&
    css`
      column-gap: ${px2rem(16)};
    `}

  ${props =>
    props.rowGap &&
    css`
      row-gap: ${px2rem(16)};
    `}
`;

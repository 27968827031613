import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';
import { Programme } from '../Programme/store';
import { DispatchFn } from '../state';
import {
  ReportingPeriod,
  ReportingQueries,
  TrackingLinksPerformancesAPI,
} from './store';

export enum ReportingActions {
  requestGetReportAttempt = 'REQUEST_GET_REPORT_ATTEMPT',
  requestGetReportSuccess = 'REQUEST_GET_REPORT_SUCCESS',
  requestGetReportFailure = 'REQUEST_GET_REPORT_FAILURE',

  setSelectedReportingProgramme = 'SET_SELECTED_REPORTING_PROGRAMME',
  setSelectedReportingDateRange = 'SET_SELECTED_REPORTING_DATE_RANGE',

  requestGetTrackingLinksPerformancesAttempt = 'REQUEST_GET_TRACKINGS_LINK_PERFORMANCES_ATTEMPT',
  requestGetTrackingLinksPerformancesSuccess = 'REQUEST_GET_TRACKINGS_LINK_PERFORMANCES_SUCCESS',
  requestGetTrackingLinksPerformancesFailure = 'REQUEST_GET_TRACKINGS_LINK_PERFORMANCES_FAILURE',
}

export enum ReportingErrors {
  getReportError = 'An error occured when fetching report.',
  getTrackingLinksPerformancesError = 'An error occured while fetching performances.',
}

export const RequestReportAsync = async (
  dispatch: DispatchFn,
  influencerId: string,
  queries: ReportingQueries
) => {
  try {
    dispatch({
      type: ReportingActions.requestGetReportAttempt,
    });

    const response = await client.request(
      Microservice.REPORTING,
      `/dashboard/influencer/${influencerId}/programme/${queries.programme}?period=${queries.period}`,
      'GET'
    );

    dispatch({
      type: ReportingActions.requestGetReportSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ReportingActions.requestGetReportFailure,
      payload: ReportingErrors.getReportError,
    });
  }
};

export const SetSelectedReportingProgramme = (
  dispatch: DispatchFn,
  programme: Programme
) => {
  dispatch({
    type: ReportingActions.setSelectedReportingProgramme,
    payload: programme,
  });
};

export const SetSelectedReportingDateRange = (
  dispatch: DispatchFn,
  dateRange: ReportingPeriod
) => {
  dispatch({
    type: ReportingActions.setSelectedReportingDateRange,
    payload: dateRange,
  });
};

export const RequestGetTrackingLinksPerformances = async (
  dispatch: DispatchFn,
  influencerId: string,
  programmeId: string
): Promise<any> => {
  dispatch({
    type: ReportingActions.requestGetTrackingLinksPerformancesAttempt,
  });
  try {
    const response = await client.request<TrackingLinksPerformancesAPI>(
      Microservice.REPORTING,
      `/reporting/programme/${programmeId}/influencer/${influencerId}/performance/tracking-links`,
      'GET'
    );

    dispatch({
      type: ReportingActions.requestGetTrackingLinksPerformancesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: ReportingActions.requestGetTrackingLinksPerformancesFailure,
      payload: ReportingErrors.getTrackingLinksPerformancesError,
    });
  }
};

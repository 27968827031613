import { Action } from '../../store/state';
import { AgentActionTypes } from './actions';
import { AgentState } from './types';

import transformers from './transformers';
import { transformGetProgrammeAgreementPayload } from '../../store/Programme/payload';

export const AgentReducer = (state: AgentState, action: Action): AgentState => {
  switch (action.type) {
    case AgentActionTypes.requestGetAssociationRequestsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          associationRequests: true,
        },
        hasError: {
          ...state.hasError,
          associationRequests: null,
        },
      };
    case AgentActionTypes.requestGetAssociationRequestsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          associationRequests: false,
        },
        associationRequests: transformers.transformAssociationRequests(
          action.payload
        ),
      };
    case AgentActionTypes.requestGetAssociationRequestsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          associationRequests: false,
        },
        hasError: {
          ...state.hasError,
          associationRequests: action.payload,
        },
      };
    case AgentActionTypes.requestGetAssociatedInfluencersAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          associatedInfluencers: true,
        },
        hasError: {
          ...state.hasError,
          associatedInfluencers: null,
        },
      };
    case AgentActionTypes.requestGetAssociatedInfluencersSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          associatedInfluencers: false,
        },
        associatedInfluencers: transformers.transformAssociatedInfluencers(
          action.payload
        ),
      };
    case AgentActionTypes.requestGetAssociatedInfluencersFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          associatedInfluencers: false,
        },
        hasError: {
          ...state.hasError,
          associatedInfluencers: action.payload,
        },
      };
    case AgentActionTypes.requestGetMappingRequestsDetailsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          currentMappingRequest: true,
        },
        hasError: {
          ...state.hasError,
          currentMappingRequest: null,
        },
      };
    case AgentActionTypes.requestGetMappingRequestsDetailsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          currentMappingRequest: false,
        },
        currentMappingRequest: transformers.transformMappingRequestDetails(
          action.payload
        ),
      };
    case AgentActionTypes.requestGetMappingRequestsDetailsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          currentMappingRequest: false,
        },
        hasError: {
          ...state.hasError,
          currentMappingRequest: action.payload,
        },
      };
    case AgentActionTypes.requestGetInfluencerProgrammesAttempt:
      return {
        ...state,
        influencerProgrammes: {
          ...state.influencerProgrammes,
          [action.payload]: null,
        },
      };
    case AgentActionTypes.requestGetInfluencerProgrammesSuccess:
      return {
        ...state,
        influencerProgrammes: {
          ...state.influencerProgrammes,
          [action.payload.influencerId]:
            transformers.transformInfluencerProgrammes(action.payload.response),
        },
      };
    case AgentActionTypes.requestGetInfluencerProgrammesFailure:
      return {
        ...state,
        influencerProgrammes: {
          ...state.influencerProgrammes,
          [action.payload.influencerId]: 'Error fetching data',
        },
      };
    case AgentActionTypes.requestGetInfluencerInvitesAttempt:
      return {
        ...state,
        influencerInvites: {
          ...state.influencerInvites,
          [action.payload]: null,
        },
      };
    case AgentActionTypes.requestGetInfluencerInvitesSuccess:
      return {
        ...state,
        influencerInvites: {
          ...state.influencerInvites,
          [action.payload.influencerId]:
            transformers.transformInfluencerInvites(action.payload.response),
        },
      };
    case AgentActionTypes.requestGetInfluencerInvitesFailure:
      return {
        ...state,
        influencerInvites: {
          ...state.influencerInvites,
          [action.payload.influencerId]: 'Error fetching data',
        },
      };
    case AgentActionTypes.requestGetAgencyPermissionGroupsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          permissionGroups: true,
        },
        hasError: {
          ...state.hasError,
          permissionGroups: null,
        },
      };
    case AgentActionTypes.requestGetAgencyPermissionGroupsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          permissionGroups: false,
        },
        permissionGroups: transformers.transformPermissionGroups(
          action.payload
        ),
      };
    case AgentActionTypes.requestGetAgencyPermissionGroupsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          permissionGroups: false,
        },
        hasError: {
          ...state.hasError,
          permissionGroups: action.payload,
        },
      };

    case AgentActionTypes.requestGetAssociatedInfluencerProgrammesAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedProgrammes: true,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedProgrammes: null,
        },
      };
    case AgentActionTypes.requestGetAssociatedInfluencerProgrammesSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedProgrammes: false,
        },
        influencerAssociatedProgrammes:
          transformers.transformAssociatedProgrammes(action.payload),
      };
    case AgentActionTypes.requestGetAssociatedInfluencerProgrammesFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedProgrammes: false,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedProgrammes: action.payload,
        },
      };
    case AgentActionTypes.requestGetAssociatedInfluencerCampaignsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedCampaigns: true,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedCampaigns: null,
        },
      };
    case AgentActionTypes.requestGetAssociatedInfluencerCampaignsSuccess:
      return {
        ...state,
        influencerAssociatedCampaigns:
          transformers.transformAssociatedCampaigns(action.payload),
        isLoading: {
          ...state.isLoading,
          influencerAssociatedCampaigns: false,
        },
      };
    case AgentActionTypes.requestGetAssociatedInfluencerCampaignsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedCampaigns: false,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedCampaigns: action.payload,
        },
      };
    case AgentActionTypes.requestGetAssociatedInfluencerInvitationsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedInvitations: true,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedInvitations: null,
        },
      };
    case AgentActionTypes.requestGetAssociatedInfluencerInvitationsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedInvitations: false,
        },
        influencerAssociatedInvitations:
          transformers.transformAssociatedInvitations(action.payload),
      };
    case AgentActionTypes.requestGetAssociatedInfluencerInvitationsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedInvitations: false,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedInvitations: action.payload,
        },
      };
    case AgentActionTypes.requestGetInfluencerDataAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          currentInfluencer: true,
        },
        hasError: {
          ...state.hasError,
          currentInfluencer: null,
        },
      };
    case AgentActionTypes.requestGetInfluencerDataSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          currentInfluencer: false,
        },
        currentInfluencer: transformers.transformInfluencerData(action.payload),
      };
    case AgentActionTypes.requestGetInfluencerDataFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          currentInfluencer: false,
        },
        hasError: {
          ...state.hasError,
          currentInfluencer: action.payload,
        },
      };

    case AgentActionTypes.RequestGetAgencyInfluencerProgrammesAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedProgrammes: true,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedProgrammes: null,
        },
      };
    case AgentActionTypes.RequestGetAgencyInfluencerProgrammesSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedProgrammes: false,
        },
        influencerAssociatedProgrammes:
          transformers.transformAgencyInfluencerAssociatedProgrammes(
            action.payload
          ),
      };
    case AgentActionTypes.RequestGetAgencyInfluencerProgrammesFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedProgrammes: false,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedProgrammes: action.payload,
        },
      };

    case AgentActionTypes.RequestGetAgencyInfluencerCampaignsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedCampaigns: true,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedCampaigns: null,
        },
      };
    case AgentActionTypes.RequestGetAgencyInfluencerCampaignsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedCampaigns: false,
        },
        influencerAssociatedCampaigns:
          transformers.transformAgencyInfluencerAssociatedCampaigns(
            action.payload
          ),
      };
    case AgentActionTypes.RequestGetAgencyInfluencerCampaignsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerAssociatedCampaigns: false,
        },
        hasError: {
          ...state.hasError,
          influencerAssociatedCampaigns: action.payload,
        },
      };

    case AgentActionTypes.RequestGetAgencyInfluencerProgrammeDetailsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerProgrammeDetails: true,
        },
        hasError: {
          ...state.hasError,
          influencerProgrammeDetails: null,
        },
      };
    case AgentActionTypes.RequestGetAgencyInfluencerProgrammeDetailsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerProgrammeDetails: false,
        },
        influencerProgrammeDetails:
          transformers.transformAgencyInfluencerProgrammeDetails(
            action.payload
          ),
      };
    case AgentActionTypes.RequestGetAgencyInfluencerProgrammeDetailsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerProgrammeDetails: false,
        },
        hasError: {
          ...state.hasError,
          influencerProgrammeDetails: action.payload,
        },
      };

    case AgentActionTypes.RequestGetAgencyInfluencerProgrammeAssociationAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerProgrammeAssociation: true,
        },
        hasError: {
          ...state.hasError,
          influencerProgrammeAssociation: null,
        },
      };
    case AgentActionTypes.RequestGetAgencyInfluencerProgrammeAssociationSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerProgrammeAssociation: false,
        },
        influencerProgrammeAssociation:
          transformers.transformAgencyInfluencerProgrammeAssociation(
            action.payload
          ),
      };
    case AgentActionTypes.RequestGetAgencyInfluencerProgrammeAssociationFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerProgrammeAssociation: false,
        },
        hasError: {
          ...state.hasError,
          influencerProgrammeAssociation: action.payload,
        },
      };

    case AgentActionTypes.RequestGetAgencyInfluencerCampaignDetailsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerCampaignDetails: true,
        },
        hasError: {
          ...state.hasError,
          influencerCampaignDetails: null,
        },
      };
    case AgentActionTypes.RequestGetAgencyInfluencerCampaignDetailsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerCampaignDetails: false,
        },
        influencerCampaignDetails:
          transformers.transformAgencyInfluencerCampaignDetails(action.payload),
      };
    case AgentActionTypes.RequestGetAgencyInfluencerCampaignDetailsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerCampaignDetails: false,
        },
        hasError: {
          ...state.hasError,
          influencerCampaignDetails: action.payload,
        },
      };

    case AgentActionTypes.RequestGetAgencyInfluencerCampaignAssociationAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerCampaignAssociation: true,
        },
        hasError: {
          ...state.hasError,
          influencerCampaignAssociation: null,
        },
      };
    case AgentActionTypes.RequestGetAgencyInfluencerCampaignAssociationSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerCampaignAssociation: false,
        },
        influencerCampaignAssociation:
          transformers.transformAgencyInfluencerCampaignAssociation(
            action.payload
          ),
      };
    case AgentActionTypes.RequestGetAgencyInfluencerCampaignAssociationFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerCampaignAssociation: false,
        },
        hasError: {
          ...state.hasError,
          influencerCampaignAssociation: action.payload,
        },
      };

    case AgentActionTypes.RequestGetAgencyInfluencerInvitationsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerInvitations: true,
        },
        hasError: {
          ...state.hasError,
          influencerInvitations: null,
        },
      };
    case AgentActionTypes.RequestGetAgencyInfluencerInvitationsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerInvitations: false,
        },
        influencerInvitations:
          transformers.transformAgencyInfluencerAssociatedInvitations(
            action.payload
          ),
      };
    case AgentActionTypes.RequestGetAgencyInfluencerInvitationsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerInvitations: false,
        },
        hasError: {
          ...state.hasError,
          influencerInvitations: action.payload,
        },
      };
    case AgentActionTypes.requestGetInviteDetailsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerInviteDetails: true,
        },
        hasError: {
          ...state.hasError,
          influencerInviteDetails: null,
        },
      };
    case AgentActionTypes.requestGetInviteDetailsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerInviteDetails: false,
        },
        influencerInviteDetails: transformers.transformInvitationOverview(
          action.payload
        ),
      };
    case AgentActionTypes.requestGetInviteDetailsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          influencerInviteDetails: false,
        },
        hasError: {
          ...state.hasError,
          influencerInviteDetails: action.payload,
        },
      };
    case AgentActionTypes.requestPostAssociationAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          postAssociation: true,
        },
        hasError: {
          ...state.hasError,
          postAssociation: null,
        },
      };
    case AgentActionTypes.requestPostAssociationSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          postAssociation: false,
        },
      };
    case AgentActionTypes.requestPostAssociationFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          postAssociation: false,
        },
        hasError: {
          ...state.hasError,
          postAssociation: action.payload,
        },
      };
    case AgentActionTypes.requestPatchInvitationDeclineAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          declineInvitation: true,
        },
        hasError: {
          ...state.hasError,
          declineInvitation: null,
        },
      };
    case AgentActionTypes.requestPatchInvitationDeclineSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          declineInvitation: false,
        },
      };
    case AgentActionTypes.requestPatchInvitationDeclineFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          declineInvitation: false,
        },
        hasError: {
          ...state.hasError,
          declineInvitation: action.payload,
        },
      };
    case AgentActionTypes.requestGetAgreementAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          programmeAgreement: true,
        },
        hasError: {
          ...state.hasError,
          programmeAgreement: null,
        },
      };
    case AgentActionTypes.requestGetAgreementSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          programmeAgreement: false,
        },
        programmeAgreement: transformGetProgrammeAgreementPayload(
          action.payload
        ),
      };
    case AgentActionTypes.requestGetAgreementFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          programmeAgreement: false,
        },
        hasError: {
          ...state.hasError,
          programmeAgreement: action.payload,
        },
      };

    case AgentActionTypes.clearCollaborationsData:
      return {
        ...state,
        influencerProgrammeDetails: null,
        influencerProgrammeAssociation: null,
        influencerCampaignDetails: null,
        influencerCampaignAssociation: null,
        influencerInvitations: null,
        influencerInviteDetails: null,
        programmeAgreement: null,
        influencerAssociatedProgrammes: null,
        influencerAssociatedCampaigns: null,
        influencerAssociatedInvitations: null,
      };
    case AgentActionTypes.requestGetInfluencerTrackingLinksAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          trackingLinks: true,
        },
        hasError: {
          ...state.hasError,
          trackingLinks: null,
        },
      };
    case AgentActionTypes.requestGetInfluencerTrackingLinksSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          trackingLinks: false,
        },
        trackingLinks: transformers.transformTrackingLinks(action.payload),
      };
    case AgentActionTypes.requestGetInfluencerTrackingLinksFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          trackingLinks: false,
        },
        hasError: {
          ...state.hasError,
          trackingLinks: action.payload,
        },
      };
    case AgentActionTypes.requestPostTrackingLinkAttempt: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createTrackingLink: true,
        },
        hasError: {
          ...state.hasError,
          createTrackingLink: null,
        },
      };
    }
    case AgentActionTypes.requestPostTrackingLinkSuccess: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createTrackingLink: false,
        },
      };
    }
    case AgentActionTypes.requestPostTrackingLinkFailure: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          createTrackingLink: false,
        },
        hasError: {
          ...state.hasError,
          createTrackingLink: action.payload,
        },
      };
    }
    case AgentActionTypes.requestPutTrackingLinkAttempt: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateTrackingLink: true,
        },
        hasError: {
          ...state.hasError,
          updateTrackingLink: null,
        },
      };
    }
    case AgentActionTypes.requestPutTrackingLinkSuccess: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateTrackingLink: false,
        },
      };
    }
    case AgentActionTypes.requestPutTrackingLinkFailure: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          updateTrackingLink: false,
        },
        hasError: {
          ...state.hasError,
          updateTrackingLink: action.payload,
        },
      };
    }
    case AgentActionTypes.requestGetAgencyDetailsAttempt: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyDetails: true,
        },
        hasError: {
          ...state.hasError,
          agencyDetails: null,
        },
      };
    }
    case AgentActionTypes.requestGetAgencyDetailsSuccess: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyDetails: false,
        },
        agencyDetails: transformers.transformAgencyDetails(action.payload),
      };
    }
    case AgentActionTypes.requestGetAgencyDetailsFailure: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agencyDetails: false,
        },
        hasError: {
          ...state.hasError,
          agencyDetails: action.payload,
        },
      };
    }
    case AgentActionTypes.clearInfluencerCommissions:
      return {
        ...state,
        paginatedInfluencerCommissions: null,
      };
    case AgentActionTypes.requestGetPaginatedInfluencerCommissionsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          paginatedInfluencerCommissions: true,
        },
        hasError: {
          ...state.hasError,
          paginatedInfluencerCommissions: null,
        },
      };
    case AgentActionTypes.requestGetPaginatedInfluencerCommissionsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          paginatedInfluencerCommissions: false,
        },
        paginatedInfluencerCommissions:
          transformers.transformInfluencerProgrammeCommissions(
            action.payload,
            state.paginatedInfluencerCommissions?.commissions
          ),
      };
    case AgentActionTypes.requestGetPaginatedInfluencerCommissionsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          paginatedInfluencerCommissions: false,
        },
        hasError: {
          ...state.hasError,
          paginatedInfluencerCommissions: action.payload,
        },
      };
    case AgentActionTypes.requestGetCommissionsPermissionCheckAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          InfluencerCommissionsPermission: true,
        },
        hasError: {
          ...state.hasError,
          InfluencerCommissionsPermission: null,
        },
      };
    case AgentActionTypes.requestGetCommissionsPermissionCheckSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          InfluencerCommissionsPermission: false,
        },
        InfluencerCommissionsPermission: action.payload,
      };
    case AgentActionTypes.requestGetCommissionsPermissionCheckFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          InfluencerCommissionsPermission: false,
        },
        hasError: {
          ...state.hasError,
          InfluencerCommissionsPermission: action.payload,
        },
      };
    default:
      return state;
  }
};

import React from 'react';
import styled from 'styled-components';
import Component, {
  CheckboxProps as ComponentProps,
} from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { theme } from '../../styling/theme';

const CheckboxWrapper = styled.div<{ justifyContent: string }>`
  width: 100%;
  & > label {
    width: 100%;
    display: flex;
    justify-content: ${props => props.justifyContent};
    ${props => theme(props).TYPOGRAPHY.BODY_TEXT}
  }

  .MuiFormControlLabel-labelPlacementStart {
    margin: 0;
  }

  .MuiTypography-body1 {
    font-size: 1rem;
    font-weight: 300;
  }

  .MuiCheckbox-root {
    width: fit-content;
    &.Mui-checked {
      color: ${props => theme(props).COLOUR.PRIMARY};
    }
  }

  .MuiFormControlLabel-root.Mui-disabled {
    cursor: not-allowed;
  }
`;

interface CheckboxProps extends ComponentProps {
  label: string | React.ReactNode;
  testId: string;
  ariaLabel?: string;
  labelPlacement?: 'start' | 'end';
  justifyContent?: string;
}

interface CheckboxInputProps extends React.HTMLAttributes<HTMLInputElement> {
  'data-testid'?: string;
  'aria-label'?: string;
}

export const Checkbox: React.FC<CheckboxProps> = props => {
  const {
    label,
    testId,
    ariaLabel,
    labelPlacement = 'end',
    justifyContent = 'start',
    ...nativeProps
  } = props;

  return (
    <CheckboxWrapper justifyContent={justifyContent}>
      <FormControlLabel
        labelPlacement={labelPlacement}
        control={
          <Component
            {...nativeProps}
            inputProps={
              {
                'data-testid': testId,
                'aria-label': ariaLabel,
              } as CheckboxInputProps
            }
          />
        }
        label={label}
      />
    </CheckboxWrapper>
  );
};

import { useState } from 'react';
import { useResponsive } from '../../../../hooks';
import { useStore } from '../../../../store/state';
import { RequestGetAgentAsync } from '../../../../store/User/actions';
import requests from './requests';
import { Breakpoint } from '../../../styling/breakpoints';

const { RequestInitialiseAgentAsync } = requests;

export const useFirstLogin = (t: Record<string, string>) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [{ userState }, dispatch] = useStore();
  const isMobile = useResponsive().layout < Breakpoint.DESKTOP;
  const agent = userState.agent;

  const [error, setError] = useState<string | null>(null);

  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleDetailsSubmit = () => {
    setError(null);
    RequestInitialiseAgentAsync(agent, firstName, lastName)
      .then(() => {
        RequestGetAgentAsync(dispatch).catch(() => {
          setError(t['AGENT.DASHBOARD.FIRST_LOGIN_MODAL.GET_AGENT_ERROR']);
        });
      })
      .catch(() => {
        setError(t['AGENT.DASHBOARD.FIRST_LOGIN_MODAL.UPDATE_ERROR']);
      });
  };

  return {
    handleFirstName,
    handleLastName,
    handleDetailsSubmit,
    firstName,
    lastName,
    error,
    isMobile,
    t,
  };
};

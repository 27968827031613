import { useEffect } from 'react';
import { useState } from 'react';
import {
  RequestLanguagesAsync,
  SetLocalLangCode,
} from '../../../store/Content/actions';
import { useStore } from '../../../store/state';
import { RequestPutUser, RegisterUser } from '../../../store/User/actions';

export const useLanguageSelect = (getState = useStore) => {
  const [{ userState, contentState }, dispatch] = getState();
  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const { availableLanguages, localLangCode } = contentState;

  useEffect(() => {
    if (!isInitialised && !availableLanguages.length) {
      RequestLanguagesAsync(dispatch);
      setIsInitialised(true);
    }
  }, [isInitialised, dispatch, availableLanguages]);

  const findLanguageName = (code: string | undefined) => {
    const name = availableLanguages.filter(
      language => language.code === code
    )[0]?.name;
    return name;
  };

  const handleGlobalSelect = (code: string) => {
    const { keycloakProfile, user } = userState;
    if (!keycloakProfile && !user) {
      SetLocalLangCode(dispatch, code);
    }
    if (keycloakProfile && !user) {
      SetLocalLangCode(dispatch, code);
      RegisterUser(
        dispatch,
        {
          key_cloak_id: keycloakProfile.keycloakId,
          influencer_first_name: keycloakProfile.firstName,
          influencer_last_name: keycloakProfile.lastName,
          influencer_address: '',
          influencer_phone_number: '',
          influencer_postcode: '',
          influencer_country: '',
          influencer_interests: [],
          influencer_language: code,
        },
        false
      );
    } else if (user) {
      RequestPutUser(
        dispatch,
        {
          ...user,
          influencer_language: code,
        },
        false
      );
    }
  };
  return {
    selectedLanguage:
      userState.user?.influencer_language || localLangCode || 'en-GB',
    availableLanguages,
    handleGlobalSelect,
    findLanguageName,
  };
};

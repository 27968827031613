import React, { FC } from 'react';
import { ConnectInstagramInnerProps } from './types';
import { Modal, Stepper } from '../../../../../common';
import { Breakpoint } from '../../../../../styling/breakpoints';
import { FormFooter } from '../../../../../common/FormFooter';
import { StepOne, StepTwo } from './Steps';
import { connectInstagramTranslation } from './translations';
import { useTranslationsController } from '../../../../../../utils/globalTranslationsController';

export const ConnectInstagramModal: FC<ConnectInstagramInnerProps> = ({
  handleSubmit,
  handleCancel,
  isModalOpen,
  layout,
  shouldDisableConnectButton,
  errorMessage,
  currentIndex,
  setCurrentIndex,
  stepOneConsentGiven,
  setStepOneConsentGiven,
  stepTwoConsentGiven,
  setStepTwoConsentGiven,
}) => {
  const isDesktop = layout >= Breakpoint.DESKTOP;
  const { connectInstagramModal: t } = useTranslationsController(
    connectInstagramTranslation
  );

  return (
    <Modal
      isOpen={isModalOpen}
      fullScreen={!isDesktop}
      cancelHandler={handleCancel}
      titleText={t['FACEBOOKLOGIN.CONNECT.TITLE']}
      maxWidth="md"
    >
      <Stepper
        disableClick
        currentIndex={currentIndex}
        introduction={t['FACEBOOKLOGIN.CONNECT.DESCRIPTIONTEXT']}
        subtitle={t['FACEBOOKLOGIN.CONNECT.STEPONE.SUBTITLE']}
        onTitleClick={() => {
          if (currentIndex === 1) setCurrentIndex(0);
        }}
        steps={[
          {
            title: t['FACEBOOKLOGIN.CONNECT.STEPONE.TITLE'],
            content: (
              <StepOne
                t={t}
                onCancel={handleCancel}
                itemOneURL={
                  'https://www.facebook.com/help/instagram/502981923235522'
                }
                itemTwoURL={
                  'https://www.facebook.com/help/instagram/2358103564437429'
                }
                onNext={() => setCurrentIndex(1)}
                consentGiven={stepOneConsentGiven}
                setConsentGiven={setStepOneConsentGiven}
                isMobile={layout <= Breakpoint.MOBILE}
              />
            ),
          },
          {
            title: t['FACEBOOKLOGIN.CONNECT.STEPTWO.TITLE'],
            content: (
              <StepTwo
                t={t}
                shouldDisableConnectButton={shouldDisableConnectButton}
                handleSubmit={handleSubmit}
                itemOneURL={'https://www.facebook.com/pages/creation/'}
                itemTwoURL={'https://www.facebook.com/help/1148909221857370'}
                onCancel={handleCancel}
                consentGiven={stepTwoConsentGiven}
                setConsentGiven={setStepTwoConsentGiven}
                errorMessage={errorMessage}
                isMobile={layout <= Breakpoint.MOBILE}
              />
            ),
          },
        ]}
      />
      <FormFooter />
    </Modal>
  );
};

import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SideNavContainer,
  SidebarLogo,
  CollapseButton,
  Divider,
  MenuContainer,
  PushDownDivider,
} from '../../Navigation/SideNav/SideNav.styles';
import { Logo } from '../../../common/Logo';
import { MenuItem } from '../../Navigation/SideNav/MenuItem/MenuItem';
import { useMenuState } from '../../../../hooks';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';
import { agentNavigationTranslation } from '../translations';

export const DesktopNav: FC<{
  logoutHandler: (e: any) => void;
}> = ({ logoutHandler }) => {
  const [expanded, setExpanded] = useMenuState();
  const { agencyNavigationLabels: t } = useTranslationsController(
    agentNavigationTranslation
  );

  const homeText = t['AGENT.HOME.LABEL'];
  const logoutExpandedText = t['AGENT.LOGOUT.EXPANDED_TEXT'];
  const logoutText = t['AGENT.LOGOUT.LABEL'];
  const toggleText = t['AGENT.TOGGLE.TEXT'];
  const influencerText = t['AGENT.INFLUENCERS.LABEL'];
  const settingsText = t['APP.NAV.SETTINGS'];
  const paymentAccountsText = t['AGENT.PAYMENT_ACCOUNTS.LABEL'];
  const profileText = t['AGENT.PROFILE.LABEL'];
  const walletText = t['AGENT.WALLET'];

  return (
    <SideNavContainer
      open={expanded}
      data-testid="t_side_nav"
      role="navigation"
    >
      <SidebarLogo open={expanded}>
        <Logo collapsed={!expanded}></Logo>
      </SidebarLogo>
      <Divider />
      <MenuContainer expanded={expanded} role="menubar">
        {/* <MenuItem
          title={expanded ? undefined : homeText}
          link
          to="/"
          icon={<FontAwesomeIcon icon={'home'} />}
          expanded={expanded}
          label={homeText}
          matches={['/']}
          exact
        /> */}
        <MenuItem
          title={expanded ? undefined : influencerText}
          link
          to="/influencers"
          icon={<FontAwesomeIcon icon={'users'} />}
          expanded={expanded}
          label={influencerText}
          matches={['/influencers', '/influencer']}
        />
        <MenuItem
          title={expanded ? undefined : paymentAccountsText}
          link
          to="/wallet/bank-accounts"
          icon={<FontAwesomeIcon icon={'university'} />}
          expanded={expanded}
          label={paymentAccountsText}
          matches={['/wallet/bank-accounts', '/wallet/paypal']}
        />
        <MenuItem
          title={expanded ? undefined : walletText}
          link
          to="/wallet"
          icon={<FontAwesomeIcon icon={'wallet'} />}
          expanded={expanded}
          label={walletText}
          exact
          matches={['/wallet']}
        />
        <MenuItem
          title={expanded ? undefined : profileText}
          link
          to="/profile"
          icon={<FontAwesomeIcon icon={'user'} />}
          expanded={expanded}
          label={profileText}
          matches={['/profile']}
        />
        <MenuItem
          title={expanded ? undefined : settingsText}
          link
          to="/settings"
          icon={<FontAwesomeIcon icon={'cog'} />}
          expanded={expanded}
          label={settingsText}
          matches={['/settings']}
        />
        <PushDownDivider />
        <MenuItem
          data-testid="t_side_menu_logout"
          title={expanded ? logoutExpandedText : logoutText}
          expanded={expanded}
          onClick={logoutHandler}
          label={logoutText}
          icon={<FontAwesomeIcon icon="sign-out-alt" />}
        />
      </MenuContainer>

      <CollapseButton
        aria-label={toggleText}
        onClick={() => setExpanded(!expanded)}
        open={expanded}
      >
        <FontAwesomeIcon icon={expanded ? 'chevron-left' : 'bars'} />
      </CollapseButton>
    </SideNavContainer>
  );
};

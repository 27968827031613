import styled, { css, keyframes } from 'styled-components';
import { FlexContainer, FormLabel, TextInput, TextArea } from '../../../common';
import { Breakpoint } from '../../../styling/breakpoints';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const DetailsForm = styled.form`
  border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
  padding: ${props => theme(props).SIZE.MEDIUM};
  width: 95vw;
  animation: ${fadeInAnimation} 0.5s linear;
  max-width: ${px2rem(600)};
  display: flex;
  flex-direction: column;

  @media (max-width: 748px) {
    z-index: 9;
    border-radius: 0;
    opacity: 0.9;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;

export const DetailsWrapper = styled(FlexContainer)`
  @media screen and (max-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    flex-direction: column;

    > div + span {
      margin-top: ${px2rem(20)};
    }
  }
`;

export const InputStyles = css`
  background-color: rgba(255, 255, 255, 0.25);
  width: 100%;
  border: none;
  color: white;

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const DetailsLabel = styled(FormLabel)`
  color: white;

  display: flex;
`;

export const DetailsTitle = styled.h1`
  color: white;
  margin-bottom: ${px2rem(30)};
  text-align: center;
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1};
`;

export const DetailsInput = styled(TextInput)`
  ${InputStyles}
`;

export const DetailsTextArea = styled(TextArea)`
  ${InputStyles}
`;

export const DetailsContainer = styled.div``;

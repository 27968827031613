import styled from 'styled-components/macro';
import { Breakpoint } from '../../../styling/breakpoints';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';

export const Dropdown = styled.div`
  @supports not (grid-gap: 10px) {
    margin: 0 5px;
  }
`;

export const Container = styled.div<{ darkTheme: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;

  @media screen and (min-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    & > ${Dropdown} {
      width: 100%;
    }
  }
  @media screen and (max-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    grid-template-columns: 1fr;

    & > ${Dropdown} + ${Dropdown} {
      margin-top: 8px;
    }
  }

  label,
  input,
  select {
    color: ${props =>
      props.darkTheme ? 'white' : theme(props).COLOUR.TEXT_STANDARD} !important;
  }
`;

import React, { FC, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { defaultTheme } from './styling/theme';
import { buildIconLibrary } from './styling/icons';
import {
  AuthProvider,
  ResponsiveProvider,
  MenuStateProvider,
  RoleSwitcher,
  TranslationProvider,
} from './providers';
import { StoreProvider } from '../store/state';
import { AppRoute, noAuthRoutes } from '../routes';
import { ResetStyles, BaseStyles } from './styling/css';
import { ErrorBoundary, PageContainerNoTopBar } from './layout';
import { browserHistory } from '..';
import { withSentryRouting } from '@sentry/react';
import { AgencyApplicationStoreProvider } from '../contexts/AgencyApplications';
import InfluencerContainer from './app-containers/InfluencerContainer';
import AgentContainer from './app-containers/AgentContainer';
import { LoadingView } from './common/Loading';
import EnvironmentsInit from '../environments/EnvironmentsInit';

const InfluencerNoAuthRoute: FC<{ route: AppRoute }> = ({ route }) => {
  const { container, component, pageName } = route;
  return (
    <ErrorBoundary pageName={pageName}>
      <Suspense fallback={<LoadingView />}>
        {container ? (
          <PageContainerNoTopBar>{component}</PageContainerNoTopBar>
        ) : (
          component
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

// Enables Sentry to track performance for template routes
const SentryRoute = withSentryRouting(Route);

const App: FC = () => {
  buildIconLibrary();

  return (
    <StoreProvider>
      <ResetStyles />
      <BaseStyles />
      <ThemeProvider theme={defaultTheme}>
        <ResponsiveProvider>
          <Router history={browserHistory}>
            <Switch>
              <Route path={noAuthRoutes.societyLandingPage.path}>
                <TranslationProvider>
                  <SentryRoute
                    path={noAuthRoutes.societyLandingPage.path}
                    render={() => (
                      <InfluencerNoAuthRoute
                        route={noAuthRoutes.societyLandingPage}
                      />
                    )}
                    exact={noAuthRoutes.societyLandingPage.exact}
                  />
                </TranslationProvider>
              </Route>
              <Route path={noAuthRoutes.help.path}>
                <Switch>
                  <TranslationProvider>
                    <SentryRoute
                      path={noAuthRoutes.help.path}
                      render={() => (
                        <InfluencerNoAuthRoute route={noAuthRoutes.help} />
                      )}
                      exact={noAuthRoutes.help.exact}
                    />
                    <SentryRoute
                      path={noAuthRoutes.helpTopic.path}
                      render={() => (
                        <InfluencerNoAuthRoute route={noAuthRoutes.helpTopic} />
                      )}
                      exact={noAuthRoutes.helpTopic.exact}
                    />
                    <SentryRoute
                      path={noAuthRoutes.helpQuestion.path}
                      render={() => (
                        <InfluencerNoAuthRoute
                          route={noAuthRoutes.helpQuestion}
                        />
                      )}
                      exact={noAuthRoutes.helpQuestion.exact}
                    />
                  </TranslationProvider>
                </Switch>
              </Route>

              {window._env.showApplications && (
                <Route path={noAuthRoutes.application.path}>
                  <TranslationProvider>
                    <SentryRoute
                      path={noAuthRoutes.application.path}
                      render={() => (
                        <InfluencerNoAuthRoute
                          route={noAuthRoutes.application}
                        />
                      )}
                      exact={noAuthRoutes.application.exact}
                    />
                  </TranslationProvider>
                </Route>
              )}
              {window._env.canSeeAgencies && (
                <Route path={noAuthRoutes.agencyApplication.path}>
                  <TranslationProvider>
                    <AgencyApplicationStoreProvider>
                      <SentryRoute
                        path={noAuthRoutes.agencyApplication.path}
                        render={() => (
                          <InfluencerNoAuthRoute
                            route={noAuthRoutes.agencyApplication}
                          />
                        )}
                        exact={noAuthRoutes.agencyApplication.exact}
                      />
                    </AgencyApplicationStoreProvider>
                  </TranslationProvider>
                </Route>
              )}

              <Route>
                <AuthProvider>
                  <EnvironmentsInit>
                    <TranslationProvider>
                      <MenuStateProvider>
                        <RoleSwitcher
                          influencerContainer={<InfluencerContainer />}
                          agentContainer={
                            window._env.showAgentRoutes ? (
                              <AgentContainer />
                            ) : (
                              <></>
                            )
                          }
                        />
                      </MenuStateProvider>
                    </TranslationProvider>
                  </EnvironmentsInit>
                </AuthProvider>
              </Route>
            </Switch>
          </Router>
        </ResponsiveProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;

import React from 'react';
import { Message } from 'react-hook-form';
import s from './LabelWithError.styles';

interface LabelWithErrorProps {
  label: string;
  error?: Message;
  inputId?: string;
  inputTestId?: string;
}

export const LabelWithError: React.VFC<LabelWithErrorProps> = ({
  label,
  error,
  inputId,
  inputTestId,
}) => {
  const labelTestId = inputTestId ? `${inputTestId}_label` : '';
  const errorTestId = inputTestId ? `${inputTestId}_error` : '';

  return (
    <s.Wrapper>
      <label htmlFor={inputId} data-testid={labelTestId}>
        {label}
      </label>
      <s.Error data-testid={errorTestId}>{error}</s.Error>
    </s.Wrapper>
  );
};

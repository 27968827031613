import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const HeadingContainer = styled.header`
  background-color: transparent;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
`;

export const HeadingContent = styled.div`
  display: flex;
  box-shadow: ${props => theme(props).COLOUR.BACKGROUND_DARK}50 0
    ${px2rem(0.15)} ${px2rem(1.75)} 0;
  align-items: center;
  justify-content: flex-end;
  height: ${px2rem(65)};
  padding: 0 ${px2rem(20)} 0 0;
  margin-inline: auto;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: ${px2rem(66)};
  user-select: none;
  &:focus {
    outline-style: none;
    > svg {
      opacity: 0.9;
    }
  }
  & > svg {
    pointer-events: none;
    width: ${px2rem(40)};
  }
`;
export const WelcomeText = styled.div`
  display: flex;
  align-items: baseline;
  font-weight: 400;
  color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  font-size: 1.25em;
  font-size: 0.9em;
  margin-right: ${px2rem(10)};
`;

export const UserAvatar = styled.div`
  width: ${px2rem(34)};
  height: ${px2rem(34)};
  border-radius: ${props => theme(props).EFFECTS.RADIUS_FULL};
  background: white;
  border: ${px2rem(2)} solid ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 300;
  overflow: hidden;
  svg {
    font-size: 1.5em;
    margin-top: 0.3em;
    > path {
      fill: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
    }
  }
`;

export const DropDown = styled.ul<{ isOpen?: boolean }>`
  background: white;
  cursor: default;
  position: absolute;
  top: ${px2rem(66)};
  right: 0;
  box-shadow: 0 ${px2rem(6)} ${px2rem(6)} 0 #33333338;
  border-radius: 0 0 ${px2rem(5)} ${px2rem(5)};
  overflow: hidden;
  transform: ${props => (props.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  margin-top: ${props => (props.isOpen ? 0 : `calc(-50% - ${px2rem(33)})`)};
  padding: ${px2rem(10)};
`;

export const DropDownItem = styled.li`
  padding: ${px2rem(5)} 0 0 0;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  ${props => theme(props).TYPOGRAPHY.X_SMALL};
  color: #333;
  font-weight: 400;
  align-items: baseline;
  & svg {
    width: ${px2rem(20)} !important;
    margin: 0 ${px2rem(10)} 0 ${px2rem(2)};
    transform: scale(1.2) translateY(${px2rem(4)});
    height: ${px2rem(20)};
    border-radius: ${px2rem(20)};
    padding: ${px2rem(3)};
    border: ${px2rem(2)} solid #333;
  }
`;

export const Divider = styled.hr`
  border: ${px2rem(1)} solid rgba(0, 0, 0, 0.05);
  width: 85%;
`;

export const Label = styled.span`
  text-transform: uppercase;
  margin-right: ${px2rem(20)};
  flex-grow: 2;
`;

export const Value = styled.span`
  padding-right: ${px2rem(5)};
`;

export const HeaderLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { theme, Theme } from '../../styling/theme';
import { LinkFocusOverlayStyles } from '../Link';

export const CookieLink = styled(NavLink)<{ theme: Theme }>`
  ${LinkFocusOverlayStyles};
  color: ${props => theme(props).COLOUR.PRIMARY};
`;

export const ExternalCookieLink = styled.a`
  color: ${props => theme(props).COLOUR.PRIMARY};
`;

import {
  BankingActions,
  BankingState,
  defaultBankingLocale,
  Mode,
} from './store';
import { Action } from '../state';
import {
  transformBankDetailsPayload,
  generateDefaultFormValues,
  transformFormFields,
  transformPaypalDetailsPayload,
} from './payload';

export const BankingReducer = (
  state: BankingState,
  action: Action
): BankingState => {
  switch (action.type) {
    case BankingActions.setBankDetails:
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload },
      };
    case BankingActions.setBankNickname:
      return {
        ...state,
        formNickname: action.payload,
      };
    case BankingActions.setBankLocale:
      return {
        ...state,
        bankingLocale: action.payload,
      };
    case BankingActions.setEditMode:
      return {
        ...state,
        mode: action.payload,
      };
    case BankingActions.setAccountIndex:
      return {
        ...state,
        currentIndex: action.payload,
      };
    // API REDUCERS
    // POST
    case BankingActions.requestPostBankDetailsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          bankAccounts: true,
        },
        error: {
          ...state.error,
          bankAccounts: null,
        },
      };
    case BankingActions.requestPostBankDetailsSuccess:
      return {
        ...state,
        // reset formstate
        formValues: {},
        bankingLocale: defaultBankingLocale,
        mode: Mode.VIEW,
        isFetching: {
          ...state.isFetching,
          bankAccounts: false,
        },
      };
    case BankingActions.requestPostBankDetailsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          bankAccounts: false,
        },
        error: {
          ...state.error,
          bankAccounts: action.payload,
        },
      };
    case BankingActions.clearBankAccountErrors:
      return {
        ...state,
        error: {
          ...state.error,
          bankAccounts: null,
        },
      };

    // GET
    case BankingActions.requestGetBankDetailsAttempt:
      return {
        ...state,
        accounts: [],
        isFetching: {
          ...state.isFetching,
          bankAccounts: true,
        },
        error: {
          ...state.error,
          bankAccounts: null,
        },
      };
    case BankingActions.requestGetBankDetailsSuccess:
      return {
        ...state,
        accounts: transformBankDetailsPayload(action.payload),
        paypalAccount: transformPaypalDetailsPayload(action.payload),
        isFetching: {
          ...state.isFetching,
          bankAccounts: false,
        },
      };
    case BankingActions.requestGetBankDetailsFailure:
      return {
        ...state,
        accounts: [],
        isFetching: {
          ...state.isFetching,
          bankAccounts: false,
        },
        error: {
          ...state.error,
          bankAccounts: action.payload,
        },
      };

    case BankingActions.requestGetBankDetailsNoticesAttempt:
      return {
        ...state,
        notices: {},
        isFetching: {
          ...state.isFetching,
          bankNotices: true,
        },
        error: {
          ...state.error,
          bankNotices: null,
        },
      };
    case BankingActions.requestGetBankDetailsNoticesSuccess:
      return {
        ...state,
        notices: action.payload,
        isFetching: {
          ...state.isFetching,
          bankNotices: false,
        },
      };
    case BankingActions.requestGetBankDetailsNoticesFailure:
      return {
        ...state,
        notices: {},
        isFetching: {
          ...state.isFetching,
          bankNotices: false,
        },
        error: {
          ...state.error,
          bankNotices: action.payload,
        },
      };

    case BankingActions.requestPutBankDetailsAttempt:
      return {
        ...state,
        isFetching: { ...state.isFetching, putBankDetails: true },
        error: { ...state.error, bankAccounts: null },
      };
    case BankingActions.requestPutBankDetailsSuccess:
      return {
        ...state,
        formValues: {},
        mode: Mode.VIEW,
        isFetching: {
          ...state.isFetching,
          putBankDetails: false,
        },
      };
    case BankingActions.requestPutBankDetailsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          putBankDetails: false,
        },
        error: {
          ...state.error,
          bankAccounts: action.payload,
        },
      };

    case BankingActions.requestGetCurrenciesAttempt:
      return {
        ...state,
        formFields: [],
        isFetching: {
          ...state.isFetching,
          locale: true,
        },
        error: {
          ...state.error,
          locale: null,
        },
      };
    case BankingActions.requestGetCurrenciesSuccess:
      return {
        ...state,
        bankingLocale: {
          ...state.bankingLocale,
          availableCurrencies: action.payload,
        },
        isFetching: {
          ...state.isFetching,
          locale: false,
        },
      };
    case BankingActions.requestGetCurrenciesFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          locale: false,
        },
        error: {
          ...state.error,
          locale: action.payload,
        },
      };

    case BankingActions.requestGetCountriesAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          locale: true,
        },
        error: {
          ...state.error,
          locale: null,
        },
      };
    case BankingActions.requestGetCountriesSuccess:
      return {
        ...state,
        bankingLocale: {
          ...state.bankingLocale,
          availableCountries: action.payload,
        },
        isFetching: {
          ...state.isFetching,
          locale: false,
        },
      };
    case BankingActions.requestGetCountriesFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          locale: false,
        },
        error: {
          ...state.error,
          locale: action.payload,
        },
      };

    case BankingActions.requestGetCountryCurrencyDuplicateExistsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          duplicateCheck: true,
        },
      };
    case BankingActions.requestGetCountryCurrencyDuplicateExistsSuccess:
      return {
        ...state,
        duplicateCheck: action.payload,
        isFetching: {
          ...state.isFetching,
          duplicateCheck: false,
        },
      };
    case BankingActions.requestGetCountryCurrencyDuplicateExistsFailure:
      return {
        ...state,
        error: {
          ...state.error,
        },
        isFetching: {
          ...state.isFetching,
          duplicateCheck: false,
        },
      };

    case BankingActions.requestGetPaymentFormAttempt:
      return {
        ...state,
        formFields: [],
        formValues: {},
        isFetching: {
          ...state.isFetching,
          paymentForm: true,
        },
        error: {
          ...state.error,
          paymentForm: null,
        },
      };
    case BankingActions.requestGetPaymentFormSuccess: {
      const fields = transformFormFields(action.payload.fields || []);
      return {
        ...state,
        formFields: fields,
        formValues: generateDefaultFormValues(fields),
        bankingLocale:
          action.payload.fields.length > 0
            ? state.bankingLocale
            : { ...state.bankingLocale, currentCountry: '' },
        isFetching: {
          ...state.isFetching,
          paymentForm: false,
        },
      };
    }
    case BankingActions.requestGetPaymentFormFailure:
      return {
        ...state,
        bankingLocale: { ...state.bankingLocale, currentCountry: '' },
        isFetching: {
          ...state.isFetching,
          paymentForm: false,
        },
        error: {
          ...state.error,
          paymentForm: action.payload,
        },
      };

    // DELETE

    case BankingActions.requestDeleteBankDetailsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          bankAccounts: true,
        },
        error: {
          ...state.error,
          bankAccounts: null,
        },
      };
    case BankingActions.requestDeleteBankDetailsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          bankAccounts: false,
        },
        accounts: [],
        paypalAccount: null,
      };
    case BankingActions.requestDeleteBankDetailsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          bankAccounts: false,
        },
        error: {
          ...state.error,
          bankAccounts: action.payload,
        },
      };
    //paypal
    case BankingActions.requestGetPaypalDetailsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          paypal: true,
        },
        error: {
          ...state.error,
          paypal: null,
        },
      };
    case BankingActions.requestGetPaypalDetailsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          paypal: false,
        },
        paypal: {
          hasPaypalTx: action.payload.has_paypal_tx,
          hideNotice: action.payload.hide_notice,
        },
      };
    case BankingActions.requestGetPaypalDetailsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          paypal: false,
        },
        error: {
          ...state.error,
          paypal: action.payload,
        },
      };
    case BankingActions.requestPutPaypalDetailsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          paypal: true,
        },
        error: {
          ...state.error,
          paypal: null,
        },
      };
    case BankingActions.requestPutPaypalDetailsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          paypal: false,
        },
      };
    case BankingActions.requestPutPaypalDetailsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          paypal: false,
        },
        error: {
          ...state.error,
          paypal: action.payload,
        },
      };
    default:
      return state;
  }
};

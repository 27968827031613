import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import {
  Notification,
  RequestPatchNotificationAsync,
  SetIsShowingNotifications,
} from '../../../../store/Notification/store';
import { DispatchFn } from '../../../../store/state';

const SECONDS_THRESHOLD = 60;
const MINUTES_THRESHOLD = 60;
const HOURS_THRESHOLD = 24;
type TimeUnit = 'second' | 'minute' | 'hour' | 'day';

export const useNotificationItem = (
  notification: Notification,
  dispatch: DispatchFn,
  updateNotification = RequestPatchNotificationAsync,
  setIsNotificationsOpen = SetIsShowingNotifications
) => {
  const formatTime = (diff: number, unit: string) => {
    return `${diff} ${diff > 1 ? `${unit}s` : `${unit}`}`;
  };

  const timeSince = (startTime: Dayjs, targetTime: Dayjs, unit: TimeUnit) => {
    return -startTime.diff(targetTime, unit);
  };

  const renderedTime = useMemo(() => {
    const currentTime = dayjs();
    const notifTime = notification.createdAt;

    if (timeSince(notifTime, currentTime, 'second') < SECONDS_THRESHOLD) {
      return formatTime(timeSince(notifTime, currentTime, 'second'), 'sec');
    } else if (
      timeSince(notifTime, currentTime, 'minute') < MINUTES_THRESHOLD
    ) {
      return formatTime(timeSince(notifTime, currentTime, 'minute'), 'min');
    } else if (timeSince(notifTime, currentTime, 'hour') < HOURS_THRESHOLD) {
      return formatTime(timeSince(notifTime, currentTime, 'hour'), 'hr');
    } else {
      return formatTime(timeSince(notifTime, currentTime, 'day'), 'day');
    }
  }, [notification]);

  const markAsRead = async () => {
    await updateNotification(dispatch, notification.id);
  };

  const handleNotificationClick = () => {
    !notification.read
      ? markAsRead()
          .then(() => setIsNotificationsOpen(dispatch, false))
          .catch(e => void e)
      : setIsNotificationsOpen(dispatch, false);
  };

  return {
    createdAt: renderedTime,
    handleClick: handleNotificationClick,
    markAsRead,
  };
};

import styled from 'styled-components';
import { Breakpoint } from '../../styling/breakpoints';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

const CardWrapper = styled.div<{ fullHeight?: boolean }>`
  box-shadow: inset 0 0 ${px2rem(0.5)} ${px2rem(0.5)} rgba(0, 0, 0, 0.16);
  border: ${px2rem(1)} solid rgba(0, 0, 0, 0.16);
  border-radius: ${px2rem(8)};
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  ${props => props.fullHeight && 'height: 100%;'}
  background-color: ${props => theme(props).COLOUR.WHITE};
  flex-grow: 1;
  overflow: hidden;
`;

const CardImagesContainer = styled.div`
  width: 100%;
  position: relative;
`;

const CardThumbnailImage = styled.img`
  width: 100%;
  background: ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
  height: ${px2rem(72)};
  object-fit: cover;
`;

const CardIconImage = styled.img<{ layout?: Breakpoint }>`
  max-height: ${({ layout }) =>
    layout !== undefined && layout >= Breakpoint.DESKTOP
      ? px2rem(56)
      : px2rem(40)};
  object-fit: contain;
  border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
  border: ${px2rem(2)} solid white;
  position: absolute;
  right: 1rem;
  bottom: -0.5rem;
  background: white;
`;

const CardHeader = styled.header`
  margin-bottom: ${px2rem(8)};
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const CardContent = styled.div`
  display: block;
  position: relative;
  padding: ${px2rem(8)} ${px2rem(16)} ${px2rem(16)};
  flex-grow: 1;
`;

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const CardTitle = styled.strong`
  align-self: center;
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1_alt};
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const CardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: ${px2rem(16)};
  > div {
    display: flex;
  }
  &::after {
    content: '';
    height: ${px2rem(4)};
    position: absolute;
    background: ${props => theme(props).COLOUR.GRADIENT_BAR};
    opacity: 0.1;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 ${px2rem(16)};
  }
`;

export default {
  CardWrapper,
  CardImagesContainer,
  CardThumbnailImage,
  CardIconImage,
  CardHeader,
  CardContent,
  CardText,
  CardTitle,
  CardActions,
};

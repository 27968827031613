import {
  Statistics,
  Transaction,
  PaymentType,
  ReadTransactionsApiModel,
  BalancesApiModel,
  TransactionApiModel,
  Balances,
  TransactionType,
  TransactionStatus,
  AutomaticWithdrawalSettings,
  AutomaticWithdrawalSettingsApiModel,
  AgencyWithdrawalsApi,
  AgencyWithdrawals,
  WithdrawalDetailsApi,
  InfluencerWithdrawalBreakdownAPI,
  AgencyWithdrawalBreakdownAPI,
  WithdrawalBreakdown,
} from './store';
import dayjs from 'dayjs';
import { CurrencyCodeLookup } from '../../utils/localisation_currencies.utils';
import { transformToPagination } from '../../utils/pagination.utils';
import { Pagination } from '../../types/api';

export const transformStatisticsPayload = (response: any): Statistics => {
  const {
    commissions,
    conversions,
    conversion_rate,
    campaigns,
    sales,
    balance,
    clicks,
  } = response;

  return {
    clicks,
    campaigns,
    balance,
    commissions,
    conversions,
    conversionRate: conversion_rate,
    sales,
  };
};

export const transformPaymentTypePayload = (
  response?: string | null
): PaymentType | null => {
  const paymentTypeLookup: { [index: string]: PaymentType } = {
    bonus: PaymentType.BONUS,
    paid: PaymentType.PAID,
    regular: PaymentType.REGULAR,
  };

  return response ? paymentTypeLookup[response] : null;
};

export const transformTransactionTypePayload = (
  response: string
): TransactionType => {
  const transactionTypeLookup: { [index: string]: TransactionType } = {
    commission: TransactionType.COMMISSION,
    bonus: TransactionType.BONUS,
    withdraw: TransactionType.WITHDRAW,
    regular: TransactionType.REGULAR,
  };

  return transactionTypeLookup[response];
};

export const transformTransactionStatusPayload = (
  response: string
): TransactionStatus => {
  const transactionStatusLookup: { [index: string]: TransactionStatus } = {
    pending_approval: TransactionStatus.PENDING_APPROVAL,
    pending_payment: TransactionStatus.PENDING_PAYMENT,
    complete: TransactionStatus.COMPLETE,
    rejected: TransactionStatus.REJECTED,
    cleared: TransactionStatus.CLEARED,
  };

  return transactionStatusLookup[response];
};

export const transformTransactionsPayload = (
  response: TransactionApiModel[]
): Transaction[] => {
  const result = response.reduce(
    (transactions: Transaction[], res: TransactionApiModel) => {
      transactions.push({
        id: res.transaction_id,
        influencerId: res.influencer_id,
        programmeId: res.programme_id || null,
        amount: res.transaction_commission,
        status: transformTransactionStatusPayload(res.transaction_status),
        createdOn: dayjs(res.transaction_created).toISOString(),
        type: transformTransactionTypePayload(res.transaction_type),
        currency: res.transaction_currency,
        paymentType: transformPaymentTypePayload(res.payment_type),
        paymentRef: res.payment_ref || null,
        clearedAt: res.cleared_at ? dayjs(res.cleared_at).toISOString() : null,
        vat: res.vat,
        agencyId: res.agency_id || null,
      });
      return transactions;
    },
    []
  );
  return result;
};

export const transformBalancesPayload = (
  newBalances: BalancesApiModel,
  oldBalances: Balances
): Balances => {
  return Object.keys(newBalances).reduce((acc, currency: string) => {
    return { ...acc, [currency]: newBalances[currency] };
  }, oldBalances);
};

export const transformReadTransactionsPayload = (
  response: ReadTransactionsApiModel,
  oldBalances: Balances
): {
  transactions: Transaction[];
  balances: Balances;
  pagination?: Pagination;
} => {
  return {
    transactions: transformTransactionsPayload(response.transactions),
    balances: transformBalancesPayload(response.balances, oldBalances),
    pagination: response.pagination
      ? transformToPagination(response.pagination)
      : undefined,
  };
};

export const transformAutomaticWithdrawalSettingsPayload = (
  response: AutomaticWithdrawalSettingsApiModel[]
): AutomaticWithdrawalSettings => {
  const transformed: AutomaticWithdrawalSettings = {};

  response.forEach(currencyAutomaticWithdrawalConfig => {
    const currency =
      CurrencyCodeLookup[currencyAutomaticWithdrawalConfig.currency];

    transformed[currency] = {
      enabled: currencyAutomaticWithdrawalConfig.enabled,
      paymentDetailsId: currencyAutomaticWithdrawalConfig.payment_details_id,
      currency: currencyAutomaticWithdrawalConfig.currency,
      id: currencyAutomaticWithdrawalConfig.id,
    };
  });

  return transformed;
};

export const transformInfluencerWithdrawalBreakdownPayload = (
  withdrawalBreakdownApi: InfluencerWithdrawalBreakdownAPI
): WithdrawalBreakdown => {
  return {
    withdrawalId: withdrawalBreakdownApi.withdrawal_id,
    bankAccount: withdrawalBreakdownApi.bank_account,
    invoiceFileId: withdrawalBreakdownApi.invoice_file_id,
    payments: withdrawalBreakdownApi.payments.map(payment => ({
      programmeId: payment.programme_id,
      programmeTitle: payment.programme_title,
      amount: payment.amount,
      transactionType: payment.transaction_type,
    })),
    isAgency: false,
  };
};

export const transformAgencyWithdrawalBreakdownPayload = (
  withdrawalBreakdownApi: AgencyWithdrawalBreakdownAPI
): WithdrawalBreakdown => {
  return {
    withdrawalId: withdrawalBreakdownApi.withdrawal_id,
    bankAccount: withdrawalBreakdownApi.bank_account,
    payments: withdrawalBreakdownApi.payments.map(payment => ({
      paymentId: payment.payment_id,
      influencerName: payment.influencer_name,
      programmeName: payment.programme_name,
      amount: payment.amount,
      transactionType: payment.transaction_type,
    })),
    isAgency: true,
  };
};

export const transformWithdrawalsPayload = (
  withdrawalsApi: AgencyWithdrawalsApi | undefined
): AgencyWithdrawals[] => {
  if (!withdrawalsApi) return [];
  const withdrawals = Object.entries(withdrawalsApi.withdrawals);
  if (withdrawals.length == 0) return [];
  return withdrawals.map(entry => {
    const item: AgencyWithdrawals = {
      withdrawalId: entry[0],
      withdrawalTransactionDetails: entry[1].map((v: WithdrawalDetailsApi) => ({
        influencerName: v.influencer_name,
        programmeName: v.programme_name,
        amount: v.amount,
        paymentType: v.transactionType,
      })),
    };
    return item;
  });
};

import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { Action } from '../../store/state';
import { AgencyApplicationReducer } from './reducer';
import { AgencyApplicationState, defaultAgencyApplicationState } from './store';

interface State {
  agencyApplicationState: AgencyApplicationState;
}

export const defaultState: State = Object.freeze({
  agencyApplicationState: defaultAgencyApplicationState,
});

type Store = [State, Dispatch<any>];

const AgencyApplicationStoreContext = createContext<Store>([
  defaultState,
  () => {
    return;
  },
]);

const rootReducer = (state: State = defaultState, action: Action): State => {
  return {
    agencyApplicationState: AgencyApplicationReducer(
      state.agencyApplicationState,
      action
    ),
  };
};

export const AgencyApplicationStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, defaultState);

  return (
    <AgencyApplicationStoreContext.Provider value={[state, dispatch]}>
      {children}
    </AgencyApplicationStoreContext.Provider>
  );
};

export const useAgencyApplicationStore = () =>
  useContext(AgencyApplicationStoreContext);

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import React, { useState } from 'react';
import { IconButton } from '..';
import {
  ActionListButton,
  ActionListItem,
  ActionsList,
  DropDownActions,
  ListeningContainer,
} from './ActionDropdown.styles';
import { ActionDropdownProps } from './ActionDropdown.types';

export const ActionDropdown: React.FC<ActionDropdownProps> = ({
  disabled,
  actions,
  testId,
  buttonId,
  customAction,
  customButton,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>();

  const renderButton = () => {
    if (customButton) {
      const extraProps = {
        onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          setIsDropdownOpen(!isDropdownOpen);
        },
        disabled: disabled,
        'aria-expanded': isDropdownOpen,
      };
      return React.cloneElement(customButton, extraProps);
    }
    return (
      <IconButton
        variant="circle"
        data-testid={`${testId}_button`}
        icon={'ellipsis-h'}
        tooltip={'Click to view actions'}
        onClick={e => {
          e.preventDefault();
          setIsDropdownOpen(!isDropdownOpen);
        }}
        disabled={disabled}
        aria-expanded={isDropdownOpen}
        id={buttonId}
        aria-label={'Click to view actions'}
      />
    );
  };

  const renderActionsList = () => {
    return (
      <ActionsList id={`${testId}_actions`} data-testid={`${testId}_actions`}>
        {actions.map((action, index) => (
          <ActionListItem key={`${testId}_action_${index}`}>
            <ActionListButton
              data-testid={action.testId}
              onClick={() => action.handler()}
              id={action.id}
              value={action.value}
            >
              {action.label}
            </ActionListButton>
          </ActionListItem>
        ))}
        {customAction}
      </ActionsList>
    );
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsDropdownOpen(false);
      }}
    >
      <span>
        <ListeningContainer>
          {renderButton()}
          {isDropdownOpen && (
            <DropDownActions>{renderActionsList()}</DropDownActions>
          )}
        </ListeningContainer>
      </span>
    </ClickAwayListener>
  );
};

import { Action } from '../state';
import { NotificationState, NotificationActions } from './store';
import {
  transformNotification,
  transformNotificationGroups,
  transformNotifications,
  transformSubscriptions,
  transformSubscriptionsTypes,
  updateNotification,
} from './transformers';

export const NotificationReducer = (
  state: NotificationState,
  action: Action
): NotificationState => {
  switch (action.type) {
    case NotificationActions.requestGetNotificationsAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotifications: true },
        errors: { ...state.errors, getNotifications: null },
      };
    case NotificationActions.requestGetNotificationsSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotifications: false },
        notifications: transformNotifications(action.payload),
      };
    case NotificationActions.requestGetNotificationsFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotifications: false },
        errors: { ...state.errors, getNotifications: action.payload },
      };

    case NotificationActions.requestPatchNotificationAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, patchNotification: true },
        errors: { ...state.errors, patchNotification: null },
      };
    case NotificationActions.requestPatchNotificationSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, patchNotification: false },
        notifications: updateNotification(
          transformNotification(action.payload),
          state.notifications
        ),
      };
    case NotificationActions.requestPatchNotificationFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, patchNotification: false },
        errors: { ...state.errors, patchNotification: action.payload },
      };
    case NotificationActions.requestPutNotificationsAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, putNotifications: true },
        errors: { ...state.errors, putNotifications: null },
      };
    case NotificationActions.requestPutNotificationsSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, putNotifications: false },
        notifications: transformNotifications(action.payload),
      };
    case NotificationActions.requestPutNotificationsFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, putNotifications: false },
        errors: { ...state.errors, putNotifications: action.payload },
      };
    case NotificationActions.setIsShowingNotifications:
      return {
        ...state,
        isShowingNotifications: action.payload,
      };

    //Get Notification Groups
    case NotificationActions.requestGetNotificationGroupsAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotificationGroups: true },
        errors: { ...state.errors, getNotificationGroups: null },
      };
    case NotificationActions.requestGetNotificationGroupsSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotificationGroups: false },
        notificationGroups: transformNotificationGroups(action.payload),
      };
    case NotificationActions.requestGetNotificationGroupsFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNotificationGroups: false },
        errors: { ...state.errors, getNotificationGroups: action.payload },
      };

    //Get Notification Subscription Types
    case NotificationActions.requestGetNotificationSubscriptionTypesAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getNotificationSubscriptionTypes: true,
        },
        errors: { ...state.errors, getNotificationSubscriptionTypes: null },
      };
    case NotificationActions.requestGetNotificationSubscriptionTypesSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getNotificationSubscriptionTypes: false,
        },
        notificationSubscriptionTypes: transformSubscriptionsTypes(
          action.payload
        ),
      };
    case NotificationActions.requestGetNotificationSubscriptionTypesFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getNotificationSubscriptionTypes: false,
        },
        errors: {
          ...state.errors,
          getNotificationSubscriptionTypes: action.payload,
        },
      };

    //Get Notification Subscriptions
    case NotificationActions.requestGetNotificationSubscriptionsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getNotificationSubscriptions: true,
        },
        errors: { ...state.errors, getNotificationSubscriptions: null },
      };
    case NotificationActions.requestGetNotificationSubscriptionsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getNotificationSubscriptions: false,
        },
        notificationSubscriptions: transformSubscriptions(action.payload),
      };
    case NotificationActions.requestGetNotificationSubscriptionsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getNotificationSubscriptions: false,
        },
        errors: {
          ...state.errors,
          getNotificationSubscriptions: action.payload,
        },
      };
    //Put Notification Subscriptions
    case NotificationActions.requestPutNotificationSubscriptionsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          putNotificationSubscriptions: true,
        },
        errors: { ...state.errors, putNotificationSubscriptions: null },
      };
    case NotificationActions.requestPutNotificationSubscriptionsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          putNotificationSubscriptions: false,
        },
        notificationSubscriptions: transformSubscriptions(action.payload),
      };
    case NotificationActions.requestPutNotificationSubscriptionsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          putNotificationSubscriptions: false,
        },
        errors: {
          ...state.errors,
          putNotificationSubscriptions: action.payload,
        },
      };

    default:
      return state;
  }
};

import {
  countryNamesAlpha2,
  countryNamesAlpha3,
} from './localisation_countries.utils';

export const countryAlpha3Codes: {
  [index: string]: keyof typeof countryNamesAlpha3;
} = {
  GBR: 'GBR',
  USA: 'USA',
  AUS: 'AUS',
  AUT: 'AUT',
  BEL: 'BEL',
  BGR: 'BGR',
  CYP: 'CYP',
  CZE: 'CZE',
  DNK: 'DNK',
  EST: 'EST',
  FIN: 'FIN',
  FRA: 'FRA',
  DEU: 'DEU',
  GRC: 'GRC',
  HUN: 'HUN',
  IRL: 'IRL',
  ITA: 'ITA',
  LVA: 'LVA',
  LTU: 'LTU',
  LUX: 'LUX',
  MLT: 'MLT',
  NLD: 'NLD',
  POL: 'POL',
  PRT: 'PRT',
  ROU: 'ROU',
  SVK: 'SVK',
  SVN: 'SVN',
  ESP: 'ESP',
  SWE: 'SWE',
  ISL: 'ISL',
  LIE: 'LIE',
  NOR: 'NOR',
  CHE: 'CHE',
  MCO: 'MCO',
  HRV: 'HRV',
  JPN: 'JPN',
  CHN: 'CHN',
  SGP: 'SGP',
  VNM: 'VNM',
  SRB: 'SRB',
  AND: 'AND',
  AFG: 'AFG',
  ATG: 'ATG',
  AIA: 'AIA',
  ALB: 'ALB',
  ARM: 'ARM',
  AGO: 'AGO',
  ARG: 'ARG',
  ASM: 'ASM',
  ABW: 'ABW',
  AZE: 'AZE',
  BIH: 'BIH',
  BRB: 'BRB',
  BGD: 'BGD',
  BFA: 'BFA',
  BHR: 'BHR',
  BDI: 'BDI',
  BEN: 'BEN',
  BMU: 'BMU',
  BRN: 'BRN',
  BOL: 'BOL',
  BRA: 'BRA',
  BHS: 'BHS',
  BTN: 'BTN',
  BWA: 'BWA',
  BLR: 'BLR',
  BLZ: 'BLZ',
  CAN: 'CAN',
  COD: 'COD',
  CAF: 'CAF',
  COG: 'COG',
  CIV: 'CIV',
  COK: 'COK',
  CHL: 'CHL',
  CMR: 'CMR',
  COL: 'COL',
  CRI: 'CRI',
  CPV: 'CPV',
  DJI: 'DJI',
  DMA: 'DMA',
  DOM: 'DOM',
  DZA: 'DZA',
  ECU: 'ECU',
  EGY: 'EGY',
  ERI: 'ERI',
  ETH: 'ETH',
  FJI: 'FJI',
  FLK: 'FLK',
  FRO: 'FRO',
  GAB: 'GAB',
  GRD: 'GRD',
  GEO: 'GEO',
  GUF: 'GUF',
  GGY: 'GGY',
  GHA: 'GHA',
  GIB: 'GIB',
  GRL: 'GRL',
  GMB: 'GMB',
  GIN: 'GIN',
  GLP: 'GLP',
  GNQ: 'GNQ',
  GTM: 'GTM',
  GUM: 'GUM',
  GNB: 'GNB',
  GUY: 'GUY',
  HKG: 'HKG',
  HND: 'HND',
  HTI: 'HTI',
  IDN: 'IDN',
  ISR: 'ISR',
  IMN: 'IMN',
  IND: 'IND',
  IRQ: 'IRQ',
  JEY: 'JEY',
  JAM: 'JAM',
  JOR: 'JOR',
  KEN: 'KEN',
  KGZ: 'KGZ',
  KHM: 'KHM',
  KIR: 'KIR',
  COM: 'COM',
  KNA: 'KNA',
  KOR: 'KOR',
  KWT: 'KWT',
  CYM: 'CYM',
  KAZ: 'KAZ',
  LAO: 'LAO',
  LBN: 'LBN',
  LCA: 'LCA',
  LKA: 'LKA',
  LBR: 'LBR',
  LSO: 'LSO',
  LBY: 'LBY',
  MAR: 'MAR',
  MDA: 'MDA',
  MNE: 'MNE',
  MDG: 'MDG',
  MKD: 'MKD',
  MLI: 'MLI',
  MNG: 'MNG',
  MAC: 'MAC',
  MTQ: 'MTQ',
  MRT: 'MRT',
  MSR: 'MSR',
  MUS: 'MUS',
  MDV: 'MDV',
  MWI: 'MWI',
  MEX: 'MEX',
  MYS: 'MYS',
  MOZ: 'MOZ',
  NAM: 'NAM',
  NCL: 'NCL',
  NER: 'NER',
  NGA: 'NGA',
  NIC: 'NIC',
  NPL: 'NPL',
  NZL: 'NZL',
  OMN: 'OMN',
  PAN: 'PAN',
  PER: 'PER',
  PYF: 'PYF',
  PNG: 'PNG',
  PHL: 'PHL',
  PAK: 'PAK',
  SPM: 'SPM',
  PRI: 'PRI',
  PRY: 'PRY',
  QAT: 'QAT',
  REU: 'REU',
  RUS: 'RUS',
  RWA: 'RWA',
  SAU: 'SAU',
  SLB: 'SLB',
  SYC: 'SYC',
  SLE: 'SLE',
  SMR: 'SMR',
  SEN: 'SEN',
  SOM: 'SOM',
  SUR: 'SUR',
  STP: 'STP',
  SLV: 'SLV',
  SWZ: 'SWZ',
  TCA: 'TCA',
  TCD: 'TCD',
  TGO: 'TGO',
  THA: 'THA',
  TJK: 'TJK',
  TLS: 'TLS',
  TKM: 'TKM',
  TUN: 'TUN',
  TON: 'TON',
  TUR: 'TUR',
  TTO: 'TTO',
  TUV: 'TUV',
  TWN: 'TWN',
  TZA: 'TZA',
  UKR: 'UKR',
  UGA: 'UGA',
  URY: 'URY',
  UZB: 'UZB',
  VAT: 'VAT',
  VCT: 'VCT',
  VEN: 'VEN',
  VGB: 'VGB',
  VIR: 'VIR',
  VUT: 'VUT',
  WSM: 'WSM',
  YEM: 'YEM',
  MYT: 'MYT',
  ZAF: 'ZAF',
  ZMB: 'ZMB',
  ZWE: 'ZWE',
  ARE: 'ARE',
  BES: 'BES',
  SXM: 'SXM',
};

export const countryAlpha2Codes: {
  [index: string]: keyof typeof countryNamesAlpha2;
} = {
  GB: 'GB',
  US: 'US',
  AU: 'AU',
  AT: 'AT',
  BE: 'BE',
  BG: 'BG',
  CY: 'CY',
  CZ: 'CZ',
  DK: 'DK',
  EE: 'EE',
  FI: 'FI',
  FR: 'FR',
  DE: 'DE',
  GR: 'GR',
  HU: 'HU',
  IE: 'IE',
  IT: 'IT',
  LV: 'LV',
  LT: 'LT',
  LU: 'LU',
  MT: 'MT',
  NL: 'NL',
  PL: 'PL',
  PT: 'PT',
  RO: 'RO',
  SK: 'SK',
  SI: 'SI',
  ES: 'ES',
  SE: 'SE',
  IS: 'IS',
  LI: 'LI',
  NO: 'NO',
  CH: 'CH',
  MC: 'MC',
  HR: 'HR',
  JP: 'JP',
  CN: 'CN',
  SG: 'SG',
  VN: 'VN',
  RS: 'RS',
  AD: 'AD',
  AF: 'AF',
  AG: 'AG',
  AI: 'AI',
  AL: 'AL',
  AM: 'AM',
  AO: 'AO',
  AR: 'AR',
  AS: 'AS',
  AW: 'AW',
  AZ: 'AZ',
  BA: 'BA',
  BB: 'BB',
  BD: 'BD',
  BF: 'BF',
  BH: 'BH',
  BI: 'BI',
  BJ: 'BJ',
  BM: 'BM',
  BN: 'BN',
  BO: 'BO',
  BR: 'BR',
  BS: 'BS',
  BT: 'BT',
  BW: 'BW',
  BY: 'BY',
  BZ: 'BZ',
  CA: 'CA',
  CD: 'CD',
  CF: 'CF',
  CG: 'CG',
  CI: 'CI',
  CK: 'CK',
  CL: 'CL',
  CM: 'CM',
  CO: 'CO',
  CR: 'CR',
  CV: 'CV',
  DJ: 'DJ',
  DM: 'DM',
  DO: 'DO',
  DZ: 'DZ',
  EC: 'EC',
  EG: 'EG',
  ER: 'ER',
  ET: 'ET',
  FJ: 'FJ',
  FK: 'FK',
  FO: 'FO',
  GA: 'GA',
  GD: 'GD',
  GE: 'GE',
  GF: 'GF',
  GG: 'GG',
  GH: 'GH',
  GI: 'GI',
  GL: 'GL',
  GM: 'GM',
  GN: 'GN',
  GP: 'GP',
  GQ: 'GQ',
  GT: 'GT',
  GU: 'GU',
  GW: 'GW',
  GY: 'GY',
  HK: 'HK',
  HN: 'HN',
  HT: 'HT',
  ID: 'ID',
  IL: 'IL',
  IM: 'IM',
  IN: 'IN',
  IQ: 'IQ',
  JE: 'JE',
  JM: 'JM',
  JO: 'JO',
  KE: 'KE',
  KG: 'KG',
  KH: 'KH',
  KI: 'KI',
  KM: 'KM',
  KN: 'KN',
  KR: 'KR',
  KW: 'KW',
  KY: 'KY',
  KZ: 'KZ',
  LA: 'LA',
  LB: 'LB',
  LC: 'LC',
  LK: 'LK',
  LR: 'LR',
  LS: 'LS',
  LY: 'LY',
  MA: 'MA',
  MD: 'MD',
  ME: 'ME',
  MG: 'MG',
  MK: 'MK',
  ML: 'ML',
  MN: 'MN',
  MO: 'MO',
  MQ: 'MQ',
  MR: 'MR',
  MS: 'MS',
  MU: 'MU',
  MV: 'MV',
  MW: 'MW',
  MX: 'MX',
  MY: 'MY',
  MZ: 'MZ',
  NA: 'NA',
  NC: 'NC',
  NE: 'NE',
  NG: 'NG',
  NI: 'NI',
  NP: 'NP',
  NZ: 'NZ',
  OM: 'OM',
  PA: 'PA',
  PE: 'PE',
  PF: 'PF',
  PG: 'PG',
  PH: 'PH',
  PK: 'PK',
  PM: 'PM',
  PR: 'PR',
  PY: 'PY',
  QA: 'QA',
  RE: 'RE',
  RU: 'RU',
  RW: 'RW',
  SA: 'SA',
  SB: 'SB',
  SC: 'SC',
  SL: 'SL',
  SM: 'SM',
  SN: 'SN',
  SO: 'SO',
  SR: 'SR',
  ST: 'ST',
  SV: 'SV',
  SZ: 'SZ',
  TC: 'TC',
  TD: 'TD',
  TG: 'TG',
  TH: 'TH',
  TJ: 'TJ',
  TL: 'TL',
  TM: 'TM',
  TN: 'TN',
  TO: 'TO',
  TR: 'TR',
  TT: 'TT',
  TV: 'TV',
  TW: 'TW',
  TZ: 'TZ',
  UA: 'UA',
  UG: 'UG',
  UY: 'UY',
  UZ: 'UZ',
  VA: 'VA',
  VC: 'VC',
  VE: 'VE',
  VG: 'VG',
  VI: 'VI',
  VU: 'VU',
  WS: 'WS',
  YE: 'YE',
  YT: 'YT',
  ZA: 'ZA',
  ZM: 'ZM',
  ZW: 'ZW',
  AE: 'AE',
  BQ: 'BQ',
  SX: 'SX',
};

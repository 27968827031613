import { useState, useEffect } from 'react';

export const useStepper = (defaultIndex = 0) => {
  const [index, setIndex] = useState<number>(defaultIndex);

  useEffect(() => {
    setIndex(defaultIndex);
  }, [defaultIndex]);

  return {
    currentIndex: index,
    setIndexHandler: setIndex,
  };
};

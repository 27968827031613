import React from 'react';
import { formatString } from '../../../../utils/format.utils';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';
import { Container, Icon, Text } from './Fallback.styles';
import { fallbackTranslation } from './translations';

interface Props {
  pageName: string;
}

export const Fallback: React.FC<Props> = ({ pageName }) => {
  const { fallback: t } = useTranslationsController(fallbackTranslation);

  return (
    <Container data-testid="t_fallback">
      <Icon />
      <Text>
        {formatString(t['ERRORBOUNDARY.FALLBACK.PART1'], [pageName])}
        <br />
        {t['ERRORBOUNDARY.FALLBACK.PART2']}
      </Text>
    </Container>
  );
};

import { useState, useEffect, useReducer } from 'react';
import {
  RegisterUser,
  RequestPutUser,
  SetRegistrationComplete,
} from '../../../store/User/store';
import { User } from '../../../store/User/types';
import { useStore } from '../../../store/state';
import { InterestOption } from '../../common/Interests/types';
import { defaultDetails, formFields, MAX_TOPICS_LENGTH } from './data';
import { detailsReducer, interestsReducer } from './reducers';
import { isPostalRequired } from '../../../utils/localisation_countries.utils';
import { transformAddressToApi } from '../../../store/User/transformers';
import { getKeycloakInstance } from '../../../keycloak/keycloak-init';
import { useResponsive } from '../../../hooks';
import {
  isContactFormValid,
  isInterestsFormValid,
} from '../../../utils/validation.utils';

export const useRegister = (getState = useStore) => {
  const [{ userState, socialState }, dispatch] = getState();
  const { keycloakProfile, isRegisteringUser, user } = userState;
  const [updatedInterests, setUpdatedInterests] = useState<boolean>(false);
  const { layout } = useResponsive();

  const showSocialStage =
    window._env?.requiresSocialConnection &&
    userState.socialRequirement.required;

  const selectStage = () => {
    if (user) {
      if (user.influencer_interests && user.influencer_country !== '') {
        if (showSocialStage) {
          return 3;
        } else {
          SetRegistrationComplete(dispatch, true);
        }
      } else if (
        user.influencer_interests.length > 0 &&
        user.influencer_interests
      ) {
        return 2;
      }
    }
    return 1;
  };

  const defaultStage = selectStage;
  const [maxTopicsReached, setMaxTopicsReached] = useState<boolean>(false);
  const [stage, setStage] = useState<number>(defaultStage);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const facebookConnected = !!socialState.facebook?.handle;
  const youtubeConnected = !!socialState.youtube?.refresh_token;
  const hasOneSocialConnected = facebookConnected || youtubeConnected;

  const findCurrentInterests = (): InterestOption[] => {
    if (user) {
      const mappedInterests = defaultDetails.interests.map(
        (interest: InterestOption) => {
          if (user && user.influencer_interests.includes(interest.value)) {
            return { ...interest, active: true };
          } else {
            return { ...interest, active: false };
          }
        }
      );
      return mappedInterests;
    }
    return defaultDetails.interests;
  };

  const generateDetailsPayload = (user: User | null): typeof defaultDetails => {
    return user
      ? {
          ...defaultDetails,
          firstName: user.influencer_first_name,
          lastName: user.influencer_last_name,
          address: user.influencer_address || defaultDetails.address,
          postCode: user.influencer_postcode || defaultDetails.postCode,
          country: user.influencer_country || defaultDetails.country,
          phone: user.influencer_phone_number || defaultDetails.phone,
          interests: findCurrentInterests(),
          dob: user.influencer_dob || defaultDetails.dob,
        }
      : {
          ...defaultDetails,
          firstName: keycloakProfile ? keycloakProfile.firstName : '',
          lastName: keycloakProfile ? keycloakProfile.lastName : '',
        };
  };

  const [details, detailsDispatch] = useReducer(
    detailsReducer,
    generateDetailsPayload(user)
  );

  const handleStageChange = (targetStage: number) => {
    if (keycloakProfile && stage === 1 && !user) {
      const { firstName, lastName, interests } = details;
      RegisterUser(
        dispatch,
        {
          key_cloak_id: keycloakProfile.keycloakId,
          influencer_first_name: firstName,
          influencer_last_name: lastName,
          influencer_address: '',
          influencer_phone_number: '',
          influencer_postcode: '',
          influencer_country: '',
          influencer_interests: interestsReducer(interests),
          influencer_language: null,
        },
        true
      );
    }
    if (keycloakProfile && stage === 1 && user && updatedInterests) {
      RequestPutUser(
        dispatch,
        {
          key_cloak_id: user.key_cloak_id,
          influencer_first_name: user.influencer_first_name,
          influencer_last_name: user.influencer_last_name,
          influencer_address: '',
          influencer_phone_number: '',
          influencer_postcode: '',
          influencer_country: '',
          influencer_interests: interestsReducer(details.interests),
          influencer_id: user.influencer_id,
          influencer_tours_viewed: [],
          influencer_language: user.influencer_language,
        },
        true
      );
      setUpdatedInterests(false);
    }
    setStage(targetStage);
  };

  const handleDetailsChange = (type: string, payload: string) => {
    detailsDispatch({ type, payload });
    if (type === formFields.INTERESTS) setUpdatedInterests(true);
  };

  const handleRegister = () => {
    if (isContactFormValid(details) && keycloakProfile && user) {
      const { address, postCode, phone, dob, country } = details;
      RequestPutUser(
        dispatch,
        {
          key_cloak_id: user.key_cloak_id,
          influencer_first_name: user.influencer_first_name,
          influencer_last_name: user.influencer_last_name,
          influencer_address: transformAddressToApi(address),
          influencer_phone_number: phone,
          influencer_postcode: isPostalRequired(country) ? postCode : '000 000',
          influencer_country: country,
          influencer_interests: user.influencer_interests,
          influencer_id: user.influencer_id,
          influencer_tours_viewed: [],
          influencer_dob: dob,
          influencer_language: user.influencer_language,
        },
        true
      );
      SetRegistrationComplete(dispatch, true);
    }
  };

  useEffect(() => {
    setMaxTopicsReached(
      details?.interests?.filter((x: InterestOption) => x.active === true)
        .length >= MAX_TOPICS_LENGTH
    );
  }, [details]);

  useEffect(() => {
    const detailsValid =
      (isInterestsFormValid(details) && stage === 1) ||
      (isContactFormValid(details) && stage === 2);
    setIsFormValid(detailsValid && !isRegisteringUser);
  }, [
    details,
    dispatch,
    hasOneSocialConnected,
    isRegisteringUser,
    stage,
    userState.socialRequirement,
  ]);

  const handleLogout = () => {
    const kcInstance = getKeycloakInstance();
    if (kcInstance) kcInstance.logout();
  };

  return {
    details,
    handleDetailsChange,
    handleStageChange,
    handleRegister,
    maxTopicsReached,
    stage,
    isFormValid,
    requiredPostalCode: isPostalRequired(details.country),
    handleLogout,
    interestsChanged: updatedInterests,
    layout,
  };
};

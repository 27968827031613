import { useCallback, useState } from 'react';
import { useStore } from '../../../../../store/state';
import {
  RequestGetUserFacebookTokenStatusAsync,
  RequestPostUserFacebookTokenAsync,
} from '../../../../../store/Social/store';
import { PERMISSIONS_SCOPE, ALPHA_PERMISSIONS_SCOPE } from '../../config';
import { FacebookResponse } from '../../types';
import { useResponsive } from '../../../../../hooks/useResponsive';
import { SetRegistrationComplete } from '../../../../../store/User/actions';

export const useFacebookLogin = (
  getState = useStore,
  getTokenStatusAction = RequestGetUserFacebookTokenStatusAsync,
  postTokenAction = RequestPostUserFacebookTokenAsync,
  setRegistrationComplete = SetRegistrationComplete,
  facebookLogin = window && window.FB && window.FB.login
    ? window.FB.login
    : () => {
        return;
      }
) => {
  const { layout } = useResponsive();
  const [{ userState, socialState }, dispatch] = getState();
  const isLoading =
    !!socialState.isFetching.facebook || !!socialState.isUpdating.facebook;
  const [isModalOpen, setisModalOpen] = useState<boolean>(false);
  const [isFacebookWindowOpen, setIsFacebookWindowOpen] =
    useState<boolean>(false);

  const fetchUserFacebookStatus = useCallback(() => {
    if (userState.user) {
      getTokenStatusAction(dispatch, userState.user.influencer_id).catch(
        e => void e
      );
    }
  }, [dispatch, getTokenStatusAction, userState.user]);

  const onClickHandler = () => {
    setisModalOpen(!isModalOpen);
  };
  const loginHandler = () => {
    setIsFacebookWindowOpen(true);
    const alphaCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('fb_login_alpha='));

    facebookLogin(
      (response: FacebookResponse) => {
        setIsFacebookWindowOpen(false);
        if (
          response &&
          response.authResponse &&
          response.authResponse.accessToken &&
          userState.user
        ) {
          postTokenAction(
            dispatch,
            response.authResponse.accessToken,
            userState.user.influencer_id
          )
            .then(() => {
              fetchUserFacebookStatus();
              if (!userState.isRegistrationComplete) {
                setRegistrationComplete(dispatch, true);
              }
            })
            .catch(e => void e);
        }
      },
      {
        scope:
          alphaCookie === undefined
            ? PERMISSIONS_SCOPE
            : ALPHA_PERMISSIONS_SCOPE,
        return_scopes: true,
      }
    );
  };

  return {
    loginHandler,
    onClickHandler,
    isLoading,
    isModalOpen,
    layout,
    errorMessage: socialState.errors.facebook,
    shouldDisableConnectButton:
      socialState.isUpdating.facebook || isFacebookWindowOpen,
  };
};

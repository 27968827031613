import React from 'react';
import { StandardLoadingBox } from './LoadingBox.styles';

export type LoadingBoxColourVariants = 'normal' | 'light';
export interface LoadingBoxProps {
  visible: boolean;
  testId: string;
  variant: 'md' | 'sm' | 'full';
  overrideWidth?: boolean;
  colourVariant?: LoadingBoxColourVariants;
}

export const LoadingBox: React.FC<LoadingBoxProps> = ({
  visible,
  testId,
  variant,
  overrideWidth,
  colourVariant = 'normal',
}) => (
  <>
    {visible && (
      <StandardLoadingBox
        role="progressbar"
        aria-valuetext={'Loading'}
        aria-label={'Loading'}
        data-testid={testId}
        variant={variant}
        overrideWidth={overrideWidth}
        colourVariant={colourVariant}
      />
    )}
  </>
);

import React, { FC } from 'react';
import { LanguageSelect } from '..';
import { useLanguageExtension } from '../../../hooks/useLanguageExtension';
import { useTranslationsController } from '../../../utils/globalTranslationsController';
import { FooterGrid, FooterLinks, RegisterFooter } from './Footer.styles';

const translationObject = {
  footer: {
    'APP.NAV.TERMS': 'Terms and Conditions',
    'APP.NAV.PRIVACY': 'Privacy Policy',
    'APP.NAV.HELP': 'Help',
  },
};

export const Footer: FC<{
  isMobile: boolean;
  hideLanguageDropdown?: boolean;
}> = ({ isMobile, hideLanguageDropdown = false }) => {
  const { footer: t } = useTranslationsController(translationObject);
  const languageExtension = useLanguageExtension();
  return (
    <RegisterFooter isMobile={isMobile} role="menu">
      <FooterGrid isMobile={isMobile}>
        {!hideLanguageDropdown && (
          <div>
            <LanguageSelect />
          </div>
        )}
        <FooterLinks>
          <a role={'menuitem'} href={`/about/terms${languageExtension}.html`}>
            {t['APP.NAV.TERMS']}
          </a>
        </FooterLinks>
        <FooterLinks>
          <a role={'menuitem'} href={`/about/privacy${languageExtension}.html`}>
            {t['APP.NAV.PRIVACY']}
          </a>
        </FooterLinks>
        <FooterLinks>
          <a role={'menuitem'} href="/help">
            {t['APP.NAV.HELP']}
          </a>
        </FooterLinks>
      </FooterGrid>
    </RegisterFooter>
  );
};

import styled from 'styled-components';
import { theme } from '../../styling/theme';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0.1rem;
`;

const Error = styled.span`
  color: ${props => theme(props).COLOUR.ALERT};

  &:empty {
    display: none;
  }
`;

export default {
  Wrapper,
  Error,
};

import { px2rem } from '../sizing';

export const SIZE = {
  XSMALL: px2rem(4),
  SMALL: px2rem(8),
  MEDIUM: px2rem(16),
  AVERAGE: px2rem(24),
  LARGE: px2rem(32),
  XLARGE: px2rem(40),
  HUGE: px2rem(64),
  MAX_CONTENT_WIDTH: px2rem(1280),
  MAX_CONTENT_WIDTH_2: px2rem(820),
  MOBILE_TOP: px2rem(60),
  MIN_TARGET: px2rem(48),
};

export interface AgencyApplicationState {
  isFetching: { postApplication: boolean; postReminder: boolean };
  errors: { postApplication: string | null; postReminder: string | null };
}

export interface SelectForms {
  markets: Options[];
  social_platforms: Options[];
  industries: Options[];
}

export interface Options {
  name: string;
  id: string;
}

export interface AgencyApplicationFormApi {
  name: string;
  email_address: string;
  active_influencers: number;
  location: string;
  address_line_1: string;
  address_line_2: string | null;
  city: string;
  postcode: string;
  vat_registered: boolean;
  limited_company: boolean;
  company_name: string;
  company_number: string;
  social_platforms: string[];
  markets: string[];
  industries: string[];
  vat_number: string;
  additional_information: string | null;
}

export const defaultAgencyApplicationState: AgencyApplicationState = {
  isFetching: { postApplication: false, postReminder: false },
  errors: { postApplication: null, postReminder: null },
};

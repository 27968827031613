import { FacebookSession, YoutubeSession } from './store';

export const transformGetFacebookResponse = (
  response: any
): FacebookSession => {
  return {
    handle: response?.handle,
    needs_refresh: response?.forceRefreshFlag,
    ignore_refresh: response?.ignoreRefresh,
  };
};

export const transformGetYoutubeResponse = (response: any): YoutubeSession => {
  return {
    access_token: response?.access_token,
    refresh_token: response?.refresh_token,
    influencer_id: response?.influencer_id,
    needs_refresh: response?.force_refresh_flag,
    ignore_refresh: response?.ignore_refresh,
  };
};

import {
  Report,
  ReportAPI,
  MediaObjectAPI,
  MediaObject,
  TrackingLinksPerformancesAPI,
  TrackingLinksPerformances,
  TrackingLinkPerformance,
  TrackingLinkPerformanceAPI,
} from './store';

const toGetReportPayload = (response: ReportAPI): Report => {
  return {
    totalCommissions: response.total_commissions,
    topPosts: {
      instagram: response.top_posts.instagram.map((media: MediaObjectAPI) =>
        toMediaObject(media)
      ),
      youtube: response.top_posts.youtube.map((media: MediaObjectAPI) =>
        toMediaObject(media)
      ),
    },
    socialInsights: {
      instagram: response.social_insights.instagram,
      youtube: response.social_insights.youtube,
    },
    clicks: response.clicks,
    conversionRate: response.conversion_rate,
  };
};

const toMediaObject = (response: MediaObjectAPI): MediaObject => {
  return {
    link: response.link,
    fileUploadId: response.file_upload_id,
    thumbnailFileUploadId: response.thumbnail_file_upload_id,
  };
};

const toTrackingLinkPerformance = (
  performance: TrackingLinkPerformanceAPI
): TrackingLinkPerformance => {
  return {
    totalClicks: performance.total_clicks,
    clickConversionRate: performance.click_conversion_rate,
    averageOrderValue: performance.average_order_value,
    totalPartnerCommission: performance.total_partner_commission,
    earliestTrackingDate: performance.earliest_tracking_date,
  };
};

export const toTrackingLinksPerformancesPayload = (
  response: TrackingLinksPerformancesAPI
): TrackingLinksPerformances => {
  const entries = Object.entries(response).map(
    ([trackingLinkId, apiPerformance]) => {
      return [trackingLinkId, toTrackingLinkPerformance(apiPerformance)];
    }
  );

  return Object.fromEntries(entries);
};

export default {
  toGetReportPayload,
  toTrackingLinksPerformancesPayload,
};

import React, { FC } from 'react';
import { DateDropdownInternalProps } from './types';
import { Container, Dropdown } from './DateDropdown.styles';
import { Select } from '../../NativeSelect';

const convertToSelectOptions = (arr: any[]) =>
  arr.map((val: any) => {
    return { key: val, value: val };
  });

export const DateDropdown: FC<DateDropdownInternalProps> = ({
  displayDate,
  onSelect,
  years,
  months,
  days,
  darkTheme,
  disabled,
  yearLabel,
  monthLabel,
  dayLabel,
}) => {
  return (
    <Container darkTheme={darkTheme || false} data-testid="t_date_dropdown">
      <Dropdown>
        <Select
          title={yearLabel}
          placeholder="----"
          isFullWidth
          testId="t_date_dropdown_year"
          options={convertToSelectOptions(years).reverse()}
          value={displayDate?.getUTCFullYear().toString()}
          onChange={e => onSelect('YEAR', e.currentTarget.value)}
          disabled={disabled}
          theme={darkTheme ? 'dark' : 'default'}
        />
      </Dropdown>
      <Dropdown>
        <Select
          title={monthLabel}
          placeholder="--"
          isFullWidth
          testId="t_date_dropdown_month"
          options={months.map(x => {
            return { key: `${x}`, value: x - 1 };
          })}
          value={displayDate?.getUTCMonth().toString()}
          onChange={e => onSelect('MONTH', e.currentTarget.value)}
          disabled={disabled}
          theme={darkTheme ? 'dark' : 'default'}
        />
      </Dropdown>
      <Dropdown>
        <Select
          title={dayLabel}
          placeholder="--"
          isFullWidth
          testId="t_date_dropdown_day"
          options={convertToSelectOptions(days)}
          value={displayDate?.getUTCDate().toString()}
          onChange={e => onSelect('DAY', e.currentTarget.value)}
          disabled={disabled || !displayDate}
          theme={darkTheme ? 'dark' : 'default'}
        />
      </Dropdown>
    </Container>
  );
};

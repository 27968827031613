import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { SpacerProps } from '.';
import { px2rem } from '../../styling/sizing';

const horizontalCSS = css<SpacerProps>`
  display: inline-block;
  height: ${px2rem(1)};
  width: ${props => (props.amount ? px2rem(props.amount) : 'auto')};
`;

const verticalCSS = css<SpacerProps>`
  display: block;
  height: ${props => (props.amount ? px2rem(props.amount) : 'auto')};
  width: 100%;
  flex-shrink: 100;
`;

const SpacerComponent: FC<SpacerProps> = styled.span<SpacerProps>`
  ${props => (props.isVertical ? verticalCSS : horizontalCSS)}
`;

export const Spacer: FC<SpacerProps> = ({
  isVertical = false,
  amount = 8,
  as,
}) => {
  return <SpacerComponent as={as} isVertical={isVertical} amount={amount} />;
};

import { Action } from '../../store/state';
import { CampaignsActions, CampaignsState } from '.';
import {
  getAssociationPayload,
  getTrackingLinksPayload,
  transformCommissionSummaryPayload,
  transformGetProgrammeCommissionsPayload,
  transformToDiscountPayload,
} from '../../store/Programme/payload';
import { toTrackingLinksPerformancesPayload } from '../../store/Reporting/transformers';

import { CampaignsTransformers } from './transformers';

const { transformCampaignFromApi } = CampaignsTransformers;

export const CampaignsReducer = (
  state: CampaignsState,
  action: Action
): CampaignsState => {
  switch (action.type) {
    // GET Campaign
    case CampaignsActions.requestGetCampaignAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentCampaign: true,
        },
        errors: {
          ...state.errors,
          fetchCurrentCampaign: null,
        },
      };
    case CampaignsActions.requestGetCampaignSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentCampaign: false,
        },
        currentCampaign: transformCampaignFromApi(action.payload),
      };
    case CampaignsActions.requestGetCampaignFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentCampaign: false,
        },
        errors: {
          ...state.errors,
          fetchCurrentCampaign: action.payload,
        },
      };
    // GET Campaign Discount Code
    case CampaignsActions.requestGetCampaignDiscountAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentDiscountCode: true,
        },
        errors: {
          ...state.errors,
          fetchCurrentDiscountCode: null,
        },
      };
    case CampaignsActions.requestGetCampaignDiscountSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentDiscountCode: false,
        },
        currentDiscountCode: transformToDiscountPayload(action.payload),
      };
    case CampaignsActions.requestGetCampaignDiscountFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentDiscountCode: false,
        },
        errors: {
          ...state.errors,
          fetchCurrentDiscountCode: action.payload,
        },
      };
    // GET Campaign Association
    case CampaignsActions.requestGetAssociationAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentAssociation: true,
        },
        errors: {
          ...state.errors,
          fetchCurrentAssociation: null,
        },
      };
    case CampaignsActions.requestGetAssociationSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentAssociation: false,
        },
        currentAssociation: getAssociationPayload(action.payload, 'campaign'),
      };
    case CampaignsActions.requestGetAssociationFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          currentAssociation: false,
        },
        errors: {
          ...state.errors,
          fetchCurrentAssociation: action.payload,
        },
      };
    // Campaign Commissions
    case CampaignsActions.requestGetCampaignCommissionsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignCommissions: true,
        },
        errors: {
          ...state.errors,
          fetchCampaignCommissions: null,
        },
      };
    case CampaignsActions.requestGetCampaignCommissionsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignCommissions: false,
        },
        campaignCommissions: transformGetProgrammeCommissionsPayload(
          action.payload,
          state.campaignCommissions?.commissions
        ),
      };
    case CampaignsActions.requestGetCampaignCommissionsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignCommissions: false,
        },
        errors: {
          ...state.errors,
          fetchCampaignCommissions: action.payload,
        },
      };
    case CampaignsActions.requestGetCampaignCommissionSummaryAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignCommissionsSummary: true,
        },
        errors: {
          ...state.errors,
          fetchCampaignCommissionsSummary: null,
        },
      };
    case CampaignsActions.requestGetCampaignCommissionSummarySuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignCommissionsSummary: false,
        },
        campaignCommissionsSummary: transformCommissionSummaryPayload(
          action.payload
        ),
      };
    case CampaignsActions.requestGetCampaignCommissionSummaryFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignCommissionsSummary: false,
        },
        errors: {
          ...state.errors,
          fetchCampaignCommissionsSummary: action.payload,
        },
      };
    case CampaignsActions.requestGetCampaignCommissionOverviewAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignCommissionsOverview: true,
        },
        errors: {
          ...state.errors,
          fetchCampaignCommissionsOverview: null,
        },
      };
    case CampaignsActions.requestGetCampaignCommissionOverviewSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignCommissionsOverview: false,
        },
        campaignCommissionsOverview: transformCommissionSummaryPayload(
          action.payload
        ),
      };
    case CampaignsActions.requestGetCampaignCommissionOverviewFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignCommissionsOverview: false,
        },
        errors: {
          ...state.errors,
          fetchCampaignCommissionsOverview: action.payload,
        },
      };
    case CampaignsActions.clearCampaignCommissions:
      return {
        ...state,
        campaignCommissions: null,
      };
    // Campaign Tracking Links
    case CampaignsActions.requestGetCampaignTrackingLinksAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignTrackingLinks: true,
        },
        errors: {
          ...state.errors,
          fetchCampaignTrackingLinks: null,
        },
      };
    case CampaignsActions.requestGetCampaignTrackingLinksSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignTrackingLinks: false,
        },
        currentTrackingLinks: getTrackingLinksPayload(action.payload),
      };
    case CampaignsActions.requestGetCampaignTrackingLinksFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignTrackingLinks: false,
        },
        errors: {
          ...state.errors,
          fetchCampaignTrackingLinks: action.payload,
        },
      };
    case CampaignsActions.requestGetCampaignTrackingLinksBackgroundAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignTrackingLinksBackground: true,
        },
        errors: {
          ...state.errors,
          fetchCampaignTrackingLinksBackground: null,
        },
      };
    case CampaignsActions.requestGetCampaignTrackingLinksBackgroundSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignTrackingLinksBackground: false,
        },
        currentTrackingLinks: getTrackingLinksPayload(action.payload),
      };
    case CampaignsActions.requestGetCampaignTrackingLinksBackgroundFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          campaignTrackingLinksBackground: false,
        },
        errors: {
          ...state.errors,
          fetchCampaignTrackingLinksBackground: action.payload,
        },
      };
    case CampaignsActions.requestGetTrackingLinksPerformancesAttempt:
      return {
        ...state,
        isFetching: { ...state.isFetching, trackingLinksPerformances: true },
        errors: { ...state.errors, trackingLinksPerformances: null },
      };
    case CampaignsActions.requestGetTrackingLinksPerformancesSuccess:
      return {
        ...state,
        isFetching: { ...state.isFetching, trackingLinksPerformances: false },
        trackingLinksPerformances: toTrackingLinksPerformancesPayload(
          action.payload
        ),
      };
    case CampaignsActions.requestGetTrackingLinksPerformancesFailure:
      return {
        ...state,
        isFetching: { ...state.isFetching, trackingLinksPerformances: false },
        errors: {
          ...state.errors,
          trackingLinksPerformances: action.payload,
        },
      };
    default:
      return state;
  }
};

import { Action } from '../state';
import { InvitationState } from './store';
import { InvitationActions } from './actions';
import { getInvitationPayload, getInvitationsPayload } from './transformer';

export const InvitationReducer = (
  state: InvitationState,
  action: Action
): InvitationState => {
  switch (action.type) {
    case InvitationActions.requestGetInvitationsAttempt:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case InvitationActions.requestGetInvitationsSuccess:
      return {
        ...state,
        invitations: getInvitationsPayload(action.payload),
        isLoading: false,
        error: null,
        invitesNeedRefresh: false,
      };
    case InvitationActions.requestGetInvitationsFailure:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case InvitationActions.requestGetInvitationAttempt:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case InvitationActions.requestGetInvitationSuccess:
      return {
        ...state,
        currentInvitation: getInvitationPayload(action.payload),
        isLoading: false,
        error: null,
      };
    case InvitationActions.requestGetInvitationFailure:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case InvitationActions.requestPutInvitationAttempt:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case InvitationActions.requestPutInvitationSuccess:
      return {
        ...state,
        currentInvitation: getInvitationPayload(action.payload),
        isLoading: false,
        error: null,
      };
    case InvitationActions.requestPutInvitationFailure:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case InvitationActions.requestPatchInvitationDeclineAttempt:
      return {
        ...state,
        isDeclining: true,
        error: null,
      };
    case InvitationActions.requestPatchInvitationDeclineSuccess:
      return {
        ...state,
        isDeclining: false,
        error: null,
      };
    case InvitationActions.requestPatchInvitationDeclineFailure:
      return {
        ...state,
        isDeclining: false,
        error: action.payload,
      };
    case InvitationActions.setInvitesNeedRefresh:
      return {
        ...state,
        invitesNeedRefresh: action.payload,
      };
    case InvitationActions.clearCurrentInvitation:
      return {
        ...state,
        currentInvitation: null,
      };
    default:
      return state;
  }
};

import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { Action } from '../../store/state';
import { AgentReducer } from './reducer';
import { defaultAgentState } from './store';
import { State, Store } from './types';

export const defaultState: State = Object.freeze({
  agentState: defaultAgentState,
});

const AgentStoreContext = createContext<Store>([
  defaultState,
  () => {
    return;
  },
]);

const rootReducer = (state: State = defaultState, action: Action): State => {
  return {
    agentState: AgentReducer(state.agentState, action),
  };
};

export const AgentStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, defaultState);

  return (
    <AgentStoreContext.Provider
      value={useMemo(() => [state, dispatch], [state])}
    >
      {children}
    </AgentStoreContext.Provider>
  );
};

export const useAgentStore = () => useContext(AgentStoreContext);

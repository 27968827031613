import styled from 'styled-components/macro';
import { Breakpoint } from '../../../styling/breakpoints';
import { theme } from '../../../styling/theme';
import { px2rem } from '../../../styling/sizing';

const SocialDialogFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: flex-start;
  @media screen and (min-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const SocialDialogActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: flex-end;

  & > button {
    width: 100%;
    margin-top: ${props => theme(props).SIZE.MEDIUM};
  }

  padding-bottom: ${props => theme(props).SIZE.MEDIUM};
  @media screen and (min-width: ${px2rem(Breakpoint.TABLET, 'em')}) {
    padding-bottom: 0;
  }

  @media screen and (min-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    flex-direction: row;
    justify-content: flex-end;

    & > button {
      width: fit-content;
      margin-left: ${props => theme(props).SIZE.SMALL};
    }
  }
`;

const SocialDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ErrorContainer = styled.div`
  margin-top: ${props => theme(props).SIZE.LARGE};
  color: ${props => theme(props).COLOUR.ALERT};
`;

const SocialDialogSubtitle = styled.p`
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1_alt}
  padding-bottom: ${props => theme(props).SIZE.SMALL};
`;

const TimeUntilReconnect = styled.div`
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT_alt}
  display: grid;
  padding-bottom: ${props => theme(props).SIZE.SMALL};

  & > p {
    ${props => theme(props).TYPOGRAPHY.BODY_TEXT_alt}
    padding-left: ${props => theme(props).SIZE.SMALL};
  }

  align-items: center;
  grid-template-columns: 1fr 4fr;
  @media screen and (min-width: ${px2rem(Breakpoint.TABLET, 'em')}) {
    grid-template-columns: 1fr 7fr;
  }
`;

const SocialDialogLine = styled.div`
  display: grid;
  align-items: center;
  & > svg {
    justify-self: center;
  }

  & > p {
    ${props => theme(props).TYPOGRAPHY.BODY_TEXT}
    padding: ${props => theme(props).SIZE.SMALL};
  }

  grid-template-columns: 2fr 12fr;
  @media (min-width: ${px2rem(Breakpoint.TABLET, 'em')}) {
    grid-template-columns: 1fr 13fr;
  }
`;

const TimeCircle = styled.div`
  display: flex;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 3px solid;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  & > p {
    ${props => theme(props).TYPOGRAPHY.BODY_TEXT}
    padding-bottom: 0;
  }
  & > :first-child {
    margin-bottom: ${props => theme(props).SIZE.SMALL};
  }
  & > :last-child {
    margin-top: ${props => theme(props).SIZE.SMALL};
  }
`;

export default {
  SocialDialogFooter,
  SocialDialogActions,
  SocialDialogWrapper,
  ErrorContainer,
  SocialDialogSubtitle,
  TimeUntilReconnect,
  SocialDialogLine,
  TimeCircle,
  ContentWrapper,
};

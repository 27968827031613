import React, { Children, FC, isValidElement, ReactNode } from 'react';
import Masonry from 'react-masonry-css';
import { StandardBrick, StandardLayout } from './GridContainer.styles';
import { GridContainerProps } from './GridContainer.types';
import { MasonryWrapper } from './GridContainer.styles';

export const GridContainer: FC<GridContainerProps> = ({
  children,
  testid,
  mode = 'STANDARD',
  childrenAs,
  tabbableChildren = true,
  ...rest
}) => {
  const bricks: JSX.Element[] = [];

  Children.forEach(children, (brick: ReactNode, i) => {
    if (isValidElement(brick)) {
      bricks.push(
        <StandardBrick
          as={childrenAs}
          key={i}
          role="listitem"
          tabIndex={tabbableChildren ? '0' : '-1'}
        >
          {brick}
        </StandardBrick>
      );
    }
  });
  return mode === 'STANDARD' ? (
    <StandardLayout {...rest} data-testid={testid}>
      {bricks.length > 1 ? (
        bricks
      ) : (
        <>
          {bricks}
          <StandardBrick as={childrenAs} />
        </>
      )}
    </StandardLayout>
  ) : (
    <MasonryWrapper>
      <Masonry
        breakpointCols={{
          default: 4,
          1100: 3,
          700: 2,
          500: 1,
        }}
        className="grid"
        columnClassName="column"
      >
        {bricks}
      </Masonry>
    </MasonryWrapper>
  );
};

import React, { useState, FC, SyntheticEvent } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import styled, { keyframes } from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';
import { GlobalFontFamily } from '../../styling/css/fontfamily';

export interface ErrorMessageProps {
  message: string;
  testId?: string;
}

const SlideDown = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: ${px2rem(36)};
  }
`;

const ErrorWrapper = styled.div`
  & .MuiSnackbar-root {
    ${props => theme(props).TYPOGRAPHY.BODY_TEXT};

    background-color: ${props => theme(props).COLOUR.ALERT};
    border-radius: ${px2rem(4)};
    color: white;
    padding: 0 ${px2rem(10)};
    animation-fill-mode: forwards;
    animation: ${SlideDown} 0.3s ease-out;
    overflow: hidden;
  }
`;

const Message = styled.div`
  min-height: ${px2rem(36)};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${px2rem(5)} 0;
`;

const CloseButton = styled.button`
  ${GlobalFontFamily};
  all: unset;
  margin: 0 0 0 ${px2rem(10)};
  background-color: ${props => theme(props).COLOUR.ALERT};
  border-radius: 100%;
  color: white;
  border: ${px2rem(1)} solid;
  width: ${px2rem(20)};
  height: ${px2rem(20)};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => theme(props).TYPOGRAPHY.X_SMALL};
  cursor: pointer;
`;

export const ErrorMessage: FC<ErrorMessageProps> = ({
  message,
  testId = 't_error_msg',
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = (_?: SyntheticEvent, reason?: string) => {
    reason !== 'clickaway' && setOpen(false);
  };
  return (
    <ErrorWrapper data-testid={testId} role="alert">
      <Snackbar open={open}>
        <>
          <Message>{message}</Message>
          <CloseButton onClick={handleClose}>X</CloseButton>
        </>
      </Snackbar>
    </ErrorWrapper>
  );
};

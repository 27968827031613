import { Programme } from '../../../../store/Programme/store';
import {
  PaymentType,
  Transaction,
  WithdrawalBreakdownWrapper,
} from '../../../../store/Wallet/store';
import { formatCurrency } from '../../../../utils/format.utils';
import {
  DetailsItemProps,
  PaymentAmountDetailsProps,
} from '../../../common/PaymentAmountDetails/types';
import { WithdrawalBreakdownDetailsProps } from '../../../common/WithdrawalBreakdownDetail/types';

export const transactionToPaymentAmountDetailsProps = ({
  t,
  locale,
  transaction,
}: {
  t: Record<string, string>;
  locale: string;
  transaction: Transaction;
}): PaymentAmountDetailsProps => {
  const vat = transaction.vat?.amount ?? 0;
  const totalAmount = transaction.amount + vat;

  return {
    headings: {
      label: t['PROGRAMME.LABELS.DETAILS'],
      amount: '',
    },
    items: [
      {
        label: transaction.vat
          ? t['WALLET.TRANSACTION.LABEL.NET_AMOUNT']
          : t['WALLET.TRANSACTION.LABEL.FULL_AMOUNT'],
        amount: formatCurrency(
          transaction.amount,
          locale,
          transaction.currency
        ),
      },
      ...(transaction.vat
        ? [
            {
              label: t['WALLET.TRANSACTION.LABEL.VAT_AMOUNT'],
              amount: formatCurrency(vat, locale, transaction.currency),
            },
          ]
        : []),
    ],
    summary: transaction.vat
      ? {
          label: t['WALLET.TRANSACTION.LABEL.FULL_AMOUNT'],
          amount: formatCurrency(totalAmount, locale, transaction.currency),
        }
      : undefined,
  };
};

export const transactionToWithdrawalBreakdownProps = ({
  t,
  locale,
  withdrawalBreakdown,
  transaction,
  handleTransactionType,
}: {
  t: Record<string, string>;
  locale: string;
  withdrawalBreakdown: WithdrawalBreakdownWrapper;
  transaction: Transaction;
  handleTransactionType: (
    label: string,
    paymentType: PaymentType | null | undefined
  ) => string | undefined | null;
}): WithdrawalBreakdownDetailsProps => {
  const generateItems = () => {
    const paymentsTotal =
      withdrawalBreakdown?.data?.payments.reduce(
        (total, { amount }) => total + amount,
        0
      ) ?? 0;

    if (paymentsTotal == 0)
      return [
        {
          tooltip:
            t[
              'WALLET.TRANSACTIONDETAILS.WITHDRAWAL.BREAKDOWN.AWAITING_DETAILS'
            ],
          label: t['WALLET.TRANSACTIONDETAILS.WITHDRAWAL.BREAKDOWN.PAYMENTS'],
          amount: formatCurrency(
            -transaction.amount,
            locale,
            transaction.currency
          ),
        },
      ];

    const items: DetailsItemProps[] =
      withdrawalBreakdown?.data?.payments.map(payment => {
        return {
          label: withdrawalBreakdown?.data?.isAgency
            ? `${payment.programmeName} ${handleTransactionType(payment.transactionType, transaction.paymentType)} for ${payment.influencerName}`
            : `${payment.programmeTitle} ${handleTransactionType(payment.transactionType, transaction.paymentType)}`,
          amount: formatCurrency(payment.amount, locale, transaction.currency),
        };
      }) ?? [];

    const otherPayments =
      Math.round((-transaction.amount - paymentsTotal) * 100) / 100;
    if (otherPayments > 0) {
      items.push({
        tooltip:
          t['WALLET.TRANSACTIONDETAILS.WITHDRAWAL.BREAKDOWN.AWAITING_DETAILS'],
        label:
          t['WALLET.TRANSACTIONDETAILS.WITHDRAWAL.BREAKDOWN.OTHER_PAYMENT'],
        amount: formatCurrency(otherPayments, locale, transaction.currency),
      });
    }

    return items;
  };

  return {
    isLoading: withdrawalBreakdown.isLoading,
    error: withdrawalBreakdown.hasError
      ? t['WALLET.TRANSACTIONDETAILS.WITHDRAWAL.BREAKDOWN.ERROR']
      : null,
    paymentDetails: withdrawalBreakdown.data
      ? {
          headings: {
            label: t['PROGRAMME.LABELS.DETAILS'],
            amount: '',
          },
          items: generateItems(),
          summary: {
            label: t['WALLET.TRANSACTION.LABEL.FULL_AMOUNT'],
            amount: formatCurrency(
              -transaction.amount,
              locale,
              transaction.currency
            ),
          },
        }
      : undefined,
  };
};

export const transformProgrammeApiToTitle = (programme: Programme) => {
  return programme.title;
};

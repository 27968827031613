import { Action } from '../../../store/state';
import { InterestOption } from '../../common/Interests/types';
import { defaultDetails, formFields } from './data';

const enforceNumbers = (phone: string) => {
  const lastDigit = phone.slice(phone.length - 1);
  if (isNaN(parseInt(lastDigit))) return phone.substring(0, phone.length - 1);
  return phone;
};

const getUpdatedInterests = (
  updatedinterest: InterestOption,
  interests: InterestOption[]
) =>
  interests.map((interest: InterestOption) => {
    return interest.name === updatedinterest.name &&
      interest.value === updatedinterest.value
      ? {
          name: updatedinterest.name,
          value: updatedinterest.value,
          active: !updatedinterest.active,
          iconName: updatedinterest.iconName,
          translateKey: updatedinterest.translateKey,
        }
      : interest;
  });

export const detailsReducer = (
  state: typeof defaultDetails,
  action: Action
): typeof defaultDetails => {
  switch (action.type) {
    case formFields.FIRST_NAME:
      return { ...state, firstName: action.payload };
    case formFields.LAST_NAME:
      return { ...state, lastName: action.payload };
    case formFields.ADDRESS:
      return { ...state, address: action.payload };
    case formFields.POST_CODE:
      return { ...state, postCode: action.payload };
    case formFields.PHONE_NUMBER:
      return { ...state, phone: enforceNumbers(action.payload) };
    case formFields.COUNTRY:
      return { ...state, country: action.payload };
    case formFields.DOB:
      return { ...state, dob: action.payload };
    case formFields.INTERESTS:
      return {
        ...state,
        interests: getUpdatedInterests(action.payload, state.interests),
      };
    default:
      return state;
  }
};

export const interestsReducer = (interests: InterestOption[]): [] => {
  return interests.reduce((acc: [], curr: InterestOption) => {
    if (curr.active === true) acc.push(curr.value as never);
    return acc;
  }, []);
};

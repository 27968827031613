import React, { Suspense } from 'react';
import {
  Navigation,
  PageContainer,
  SocialContainer,
  ConsentBanner,
  ErrorBoundary,
} from '../layout';
import { Switch, Route, Redirect as ReactRedirect } from 'react-router-dom';
import {
  AppRoute,
  appRoutes,
  campaignRoutes,
  marketplaceRoutes,
} from '../../routes';
import { CampaignsStoreProvider } from '../../contexts/Campaigns';
import { MarketplaceStoreProvider } from '../../contexts/Marketplace/state';
import { withSentryRouting } from '@sentry/react';
import { LoadingView } from '../common/Loading';
import { getRoutesListComponents } from '../utils';

const SentryRoute = withSentryRouting(Route);

const InfluencerRoute: React.FC<{ route: AppRoute }> = ({ route }) => {
  const { container, component, pageName } = route;
  return (
    <>
      <ErrorBoundary pageName={pageName}>
        <Suspense fallback={<LoadingView />}>
          {container ? <PageContainer>{component}</PageContainer> : component}
        </Suspense>
      </ErrorBoundary>
      {window._env.showCookieBanner && <ConsentBanner />}
    </>
  );
};

const InfluencerContainer: React.FC = () => {
  return (
    <SocialContainer>
      <Navigation />
      <Switch>
        {getRoutesListComponents(appRoutes, InfluencerRoute)}

        <SentryRoute path={campaignRoutes.campaign.path}>
          <CampaignsStoreProvider>
            <Switch>
              {getRoutesListComponents(campaignRoutes, InfluencerRoute)}
            </Switch>
          </CampaignsStoreProvider>
        </SentryRoute>

        <SentryRoute path={marketplaceRoutes.marketplace.path}>
          <MarketplaceStoreProvider>
            <Switch>
              {getRoutesListComponents(marketplaceRoutes, InfluencerRoute)}
            </Switch>
          </MarketplaceStoreProvider>
        </SentryRoute>

        <SentryRoute path="*">
          <ReactRedirect to="/" />
        </SentryRoute>
      </Switch>
    </SocialContainer>
  );
};

export default InfluencerContainer;

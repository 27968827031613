import React from 'react';
import { Register as Component } from './Register';
import { useRegister } from './useRegister';
import { useTranslation } from '../../../hooks/useTranslation';

export const Register: React.FC = () => {
  const props = useRegister();
  const translate = useTranslation();
  return <Component {...props} translate={translate} />;
};

import { NativeSelect } from '@material-ui/core';
import React from 'react';
import { Languages } from '../../../store/Content/models';
import { SelectWrapper } from './LanguageSelect.styles';
import { useLanguageSelect } from './useLanguageSelect';

interface LanguageSelectProps {
  isNavBar?: boolean;
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({ isNavBar }) => {
  const {
    selectedLanguage,
    handleGlobalSelect,
    availableLanguages,
    findLanguageName,
  } = useLanguageSelect();

  return (
    <SelectWrapper isNavBar={isNavBar}>
      <label aria-label={'Choose language'}>
        <NativeSelect
          disableUnderline
          name="select_language"
          data-testid="t_select_language"
          value={selectedLanguage}
          onChange={e => handleGlobalSelect(e.currentTarget.value)}
          role={'menuitem'}
          aria-label="language_select"
          aria-labelledby="language select"
        >
          {selectedLanguage && (
            <option disabled aria-disabled value={selectedLanguage}>
              {`${findLanguageName(selectedLanguage)}`}
            </option>
          )}
          {!!availableLanguages &&
            availableLanguages.map((languageOption: Languages) => (
              <option
                key={languageOption.code}
                value={languageOption.code}
                data-testid={`t_select_language_${languageOption}`}
                aria-label={`option_${languageOption.code}`}
              >
                {`${languageOption.name}`}
              </option>
            ))}
        </NativeSelect>
      </label>
    </SelectWrapper>
  );
};

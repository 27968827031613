import styled from 'styled-components';
import { Breakpoint } from '../../../styling/breakpoints';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';
import { StandardButtonStyles } from '../../Button';

const InterestsList = styled.ul`
  width: 100%;
  display: grid;
  gap: 4rem 0rem;
  grid-template-columns: repeat(auto-fit, 14rem);
  justify-content: center;

  @media (max-width: ${px2rem(Breakpoint.TABLET, 'em')}) {
    grid-template-columns: repeat(auto-fit, 6.6rem);
    gap: 3rem 1rem;
  }
`;

const InterestContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7rem;
  padding-bottom: 1rem;
`;

const IconHolder = styled.div`
  font-size: 1.5rem;
  padding: 0.5rem;
`;

const IconCheckHolder = styled.div`
  display: none;
  position: absolute;
  margin-bottom: 4rem;
  margin-left: 5.3rem;
  font-size: x-large;

  &[data-active='true'] {
    display: block;
  }
`;

const InterestLabel = styled.div`
  position: absolute;
  margin-top: 4.5rem;
`;

const CheckBoxButton = styled.div`
  ${StandardButtonStyles}
  min-width: auto;
  padding: 0;
  border: ${px2rem(1)} solid ${props => theme(props).COLOUR.SECONDARY_DARKER};
  color: ${props => theme(props).COLOUR.SECONDARY_DARKER};
  border-radius: 3rem;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${props => `${theme(props).COLOUR.SECONDARY_LIGHTER}25`};
    color: ${props => theme(props).COLOUR.SECONDARY_DARKEST};
  }

  label:hover {
    cursor: pointer;
  }

  &[data-disabled='true'] {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    transform: none;
    opacity: 0.3;

    label {
      cursor: not-allowed;
    }
  }
`;

const CheckBoxLabel = styled.label`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
`;

const CheckBoxInput = styled.input`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
`;

const CheckBoxSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InterestItem = styled.li<{ isDesktop?: boolean }>`
  display: flex;
  justify-content: center;
  ${CheckBoxButton} {
    width: 6rem;
    height: 6rem;
  }
`;

export default {
  InterestsList,
  InterestLabel,
  CheckBoxButton,
  CheckBoxLabel,
  CheckBoxInput,
  CheckBoxSpan,
  InterestContent,
  IconHolder,
  IconCheckHolder,
  InterestItem,
};

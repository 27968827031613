import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { Action } from '../../store/state';
import { CampaignsReducer } from './reducer';
import { CampaignsState, defaultCampaignsState } from './store';

interface State {
  campaignsState: CampaignsState;
}

export const defaultState: State = Object.freeze({
  campaignsState: defaultCampaignsState,
});

type Store = [State, Dispatch<any>];

const CampaignsStoreContext = createContext<Store>([
  defaultState,
  () => {
    return;
  },
]);

const rootReducer = (state: State = defaultState, action: Action): State => {
  return {
    campaignsState: CampaignsReducer(state.campaignsState, action),
  };
};

export const CampaignsStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, defaultState);

  return (
    <CampaignsStoreContext.Provider value={[state, dispatch]}>
      {children}
    </CampaignsStoreContext.Provider>
  );
};

export const useCampaignsStore = () => useContext(CampaignsStoreContext);

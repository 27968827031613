export const notificationCenterTranslationObject = {
  notificationCenter: {
    'NOTIFICATIONS.DIVIDER.TODAY': 'today',
    'NOTIFICATIONS.DIVIDER.WEEK': 'this week',
    'NOTIFICATIONS.DIVIDER.OLDER': 'older',
    'NOTIFICATIONS.BUTTON.MARKALL': 'Mark all as read',
    'NOTIFICATIONS.H3': 'Notifications',
    'NOTIFICATIONS.ZERO': 'All caught up',
  },
};

import styled from 'styled-components/macro';
import { px2rem } from '../../../../styling/sizing';
import { theme } from '../../../../styling/theme';
import { ButtonProps, StandardButtonStyles } from '../../../../common/Button';
import { GlobalFontFamily } from '../../../../styling/css/fontfamily';

export const SocialButton = styled.button<ButtonProps>`
  ${StandardButtonStyles};
  ${GlobalFontFamily};
  border-radius: ${px2rem(4)};
  color: white;
  height: ${px2rem(40)};
  display: flex;
  align-items: center;
  ${props => theme(props).TYPOGRAPHY.SMALL};
  padding: 0 ${px2rem(10)};
  outline-style: none;
  cursor: pointer;
  border: 0 none;
  box-shadow: none;
  & > svg {
    margin-right: ${props => theme(props).SIZE.SMALL};
  }
`;

import styled, { css } from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../styling/theme';

export const StandardLinkStyles = css`
  color: ${props => theme(props).COLOUR.PRIMARY};
  text-decoration: none;
  :active {
    text-decoration: none;
    color: ${props => theme(props).COLOUR.PRIMARY_DARKEST};
  }
  :visited {
    color: ${props => theme(props).COLOUR.PRIMARY_DARKEST};
  }
  :hover {
    text-decoration: underline;
  }
  :disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  }
`;

export const LinkFocusOverlayStyles = css`
  position: relative;
  &:focus {
    overflow: hidden;
    &:before {
      content: '';
      opacity: 0.3;
      background: white;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
`;

export const ExternalLink = styled.a<{
  variant?: boolean;
  isWhite?: boolean;
  underline?: boolean;
}>`
  ${StandardLinkStyles};
  ${LinkFocusOverlayStyles};
  ${props => props.underline && `text-decoration: underline;`}
  border: none;
  color: ${props => {
    if (props.isWhite) {
      return 'white;';
    } else {
      return props.variant
        ? theme(props).COLOUR.SECONDARY
        : theme(props).COLOUR.PRIMARY;
    }
  }};
  ${props => !!props.isWhite && '& > span,svg {color: white;};'}
  word-break: break-all;
  &:hover {
    color: ${props =>
      props.variant
        ? theme(props).COLOUR.SECONDARY_DARK
        : theme(props).COLOUR.PRIMARY_DARK};
  }

  &:focus {
    color: ${props =>
      props.variant
        ? theme(props).COLOUR.SECONDARY_DARK
        : theme(props).COLOUR.PRIMARY_DARK};
  }

  &:active {
    color: ${props =>
      props.variant
        ? theme(props).COLOUR.SECONDARY_DARKER
        : theme(props).COLOUR.PRIMARY_DARKER};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: none;
  }

  svg {
    margin-right: ${px2rem(8)};
  }
`;

export const RouterLink = styled(Link)<{
  variant?: boolean;
  isWhite?: boolean;
}>`
  ${StandardLinkStyles};
  ${LinkFocusOverlayStyles};
  ${props => !!props.isWhite && '& > span,svg {color: white;};'}
  border: none;
  &:hover {
    color: ${props =>
      props.variant || !!props.isWhite
        ? theme(props).COLOUR.SECONDARY_DARK
        : theme(props).COLOUR.PRIMARY_DARK};
  }

  &:focus {
    color: ${props =>
      props.variant
        ? theme(props).COLOUR.SECONDARY_DARK
        : theme(props).COLOUR.PRIMARY_DARK};
    text-decoration: underline;
  }

  &:active {
    color: ${props =>
      props.variant
        ? theme(props).COLOUR.SECONDARY_DARKER
        : theme(props).COLOUR.PRIMARY_DARKER};
  }

  &:disabled {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: none;
  }

  svg {
    margin-right: ${px2rem(8)};
  }
`;

interface DirectionalLinkProps {
  fullWidth?: boolean;
  label: string;
  redirectUrl: string;
  onClick?: () => void;
  colorVariant?: 'primary' | 'secondary';
  testId?: string;
  isExternal?: boolean;
  isWhite?: boolean;
}

const BackText = styled.span`
  margin-left: ${px2rem(10)};
`;

export const BackLink: FC<DirectionalLinkProps> = ({
  label,
  redirectUrl,
  onClick,
  colorVariant,
  testId,
  isExternal,
  isWhite,
}) => {
  return isExternal ? (
    <ExternalLink
      isWhite={isWhite}
      data-testid={testId}
      href={redirectUrl}
      variant={colorVariant === 'secondary'}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={'chevron-left'} />
      <BackText>{`${label}`}</BackText>
    </ExternalLink>
  ) : (
    <RouterLink
      isWhite={isWhite}
      data-testid={testId}
      to={redirectUrl}
      variant={colorVariant === 'secondary'}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={'chevron-left'} />
      <BackText>{`${label}`}</BackText>
    </RouterLink>
  );
};

const ForwardText = styled.span`
  margin-right: ${px2rem(10)};
`;
export const ForwardLink: FC<DirectionalLinkProps> = ({
  label,
  redirectUrl,
  colorVariant,
  onClick,
  testId,
  isExternal,
  isWhite,
}) => {
  return isExternal ? (
    <ExternalLink
      isWhite={isWhite}
      data-testid={testId}
      href={redirectUrl}
      variant={colorVariant === 'secondary'}
      onClick={onClick}
    >
      <ForwardText>{`${label}`}</ForwardText>
      <FontAwesomeIcon icon={'chevron-right'} />
    </ExternalLink>
  ) : (
    <RouterLink
      isWhite={isWhite}
      data-testid={testId}
      to={redirectUrl}
      variant={colorVariant === 'secondary'}
      onClick={onClick}
    >
      <ForwardText>{`${label}`}</ForwardText>
      <FontAwesomeIcon icon={'chevron-right'} />
    </RouterLink>
  );
};

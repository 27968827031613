import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fas,
  faMoneyCheck,
  faPiggyBank,
  faCartPlus,
  faCircle,
  faChevronDown,
  faChevronLeft,
  faGlobe,
  faPlaneDeparture,
  faTshirt,
  faCamera,
  faUtensils,
  faBuilding,
  faGamepad,
  faCar,
  faFlask,
  faSwatchbook,
  faDumbbell,
  faMusic,
  faBaby,
  faLaptopMedical,
  faHome,
  faQuestionCircle,
  faCheck,
  faPhotoVideo,
  faShoppingBag,
  faExclamationCircle,
  faInfoCircle,
  faCheckCircle,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faTimes,
  faShieldAlt,
  faUnlink,
  faLock,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {
  fab,
  faFacebook,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons';

export const buildIconLibrary = () => {
  library.add(fas);
  library.add(far);
  library.add(fab);
  library.add(faFacebook);
  library.add(faFacebookSquare);
  library.add(faMoneyCheck);
  library.add(faPiggyBank);
  library.add(faCartPlus);
  library.add(faCircle);
  library.add(faChevronDown);
  library.add(faChevronLeft);
  library.add(faGlobe);
  library.add(faPlaneDeparture);
  library.add(faTshirt);
  library.add(faCamera);
  library.add(faUtensils);
  library.add(faBuilding);
  library.add(faGamepad);
  library.add(faCar);
  library.add(faFlask);
  library.add(faSwatchbook);
  library.add(faDumbbell);
  library.add(faMusic);
  library.add(faBaby);
  library.add(faLaptopMedical);
  library.add(faHome);
  library.add(faQuestionCircle);
  library.add(faCheck);
  library.add(faPhotoVideo);
  library.add(faShoppingBag);
  library.add(faCheckCircle);
  library.add(faExclamationCircle);
  library.add(faInfoCircle);
  library.add(faAngleDoubleUp);
  library.add(faAngleDoubleDown);
  library.add(faTimes);
  library.add(faShieldAlt);
  library.add(faUnlink);
  library.add(faLock);
  library.add(faSearch);
};

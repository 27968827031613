import { iconPathData } from './svg';
import type { SvgDataProps, SvgPath } from './types';

const getIcon = (userProps: SvgDataProps): SvgPath => {
  const xmlns = 'http://www.w3.org/2000/svg';
  const name = userProps.name ? userProps.name : 'default';

  return {
    svg: {
      ...iconPathData[name].defaultProps,
      ...userProps,
      xmlns,
    },
    group: iconPathData[name].group,
    paths: iconPathData[name].paths,
  };
};

export const icons: { icon: (_: SvgDataProps) => SvgPath } = {
  icon: getIcon,
};

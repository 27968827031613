import React, {
  createContext,
  FC,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import {
  RequestTranslationsAsync,
  SetTranslationKeyArray,
  SetLocalLangCode,
} from '../../../store/Content/store';
import { useStore } from '../../../store/state';
import { TranslateFunction } from '../../../hooks';
import { minutesToMilliseconds } from '../../../utils/date.utils';
import { isEqual } from 'lodash';
import { getLanguageHeader } from '../../../utils/http.utils';

export const TranslationContext = createContext<TranslateFunction>(() => {
  return '';
});

export const formatTemplate = (
  result: string,
  values: any[] | null | undefined
) => {
  if (!values || values.length < 1) return result;
  for (let i = 0; i < values.length; i++) {
    result = result.split(`{${i}}`).join(values[i]);
  }
  return result;
};

export const TranslationProvider: FC = ({ children }) => {
  const [initialised, setInitialised] = useState(false);
  const [{ contentState, userState }, dispatch] = useStore();
  const { translations, keyArray, isLoading, error, localLangCode } =
    contentState;

  const [storedTranslations, setStoredTranslations] = useLocalStorage(
    'localisations',
    null
  );

  const [storedLangPref, setStoredLangPref] = useLocalStorage(
    'languagePreference',
    getLanguageHeader()
  );

  const getNewTranslateKeys = useCallback(() => {
    const storedKeys = Object.keys(storedTranslations || {});
    return keyArray.filter(
      (key: string) => !storedKeys.find(storedKey => storedKey === key)
    );
  }, [keyArray, storedTranslations]);

  const makeTranslationRequest = useCallback(
    (keyArray: string[]) => {
      RequestTranslationsAsync(
        dispatch,
        keyArray,
        storedTranslations,
        userState.user?.influencer_language || localLangCode || undefined
      );
    },
    [dispatch, storedTranslations, userState.user, localLangCode]
  );

  useEffect(() => {
    if (!initialised) {
      setStoredTranslations(null);
      SetLocalLangCode(dispatch, storedLangPref);
    }
    setInitialised(true);
  }, [initialised, setStoredTranslations, storedLangPref, dispatch]);

  useEffect(() => {
    const currentLocalStorageLang = getLanguageHeader();
    if (!userState.user) {
      setStoredLangPref(currentLocalStorageLang);
    }
    if (!userState.user && localLangCode !== currentLocalStorageLang) {
      setStoredTranslations(null);
      makeTranslationRequest(keyArray);
      setStoredLangPref(localLangCode);
    }
    if (
      userState.user &&
      storedLangPref !== userState.user?.influencer_language
    ) {
      setStoredTranslations(null);
      makeTranslationRequest(keyArray);
      setStoredLangPref(userState.user?.influencer_language);
    }
  }, [
    keyArray,
    makeTranslationRequest,
    setStoredLangPref,
    setStoredTranslations,
    storedLangPref,
    userState.user,
    localLangCode,
  ]);

  useEffect(() => {
    const deferredCheck = setTimeout(() => {
      const changedKeys = getNewTranslateKeys();
      if (
        storedTranslations === null ||
        (changedKeys.length > 0 && !isLoading && !error)
      ) {
        makeTranslationRequest(changedKeys);
      }
    }, 0);
    return () => clearTimeout(deferredCheck);
  }, [
    dispatch,
    error,
    getNewTranslateKeys,
    isLoading,
    keyArray,
    makeTranslationRequest,
    storedTranslations,
    userState.user,
  ]);

  useEffect(() => {
    const intervalCheck = setInterval(() => {
      if (keyArray.length > 0 && !error) makeTranslationRequest(keyArray);
    }, minutesToMilliseconds(20));
    return () => clearInterval(intervalCheck);
  });

  useEffect(() => {
    if (
      translations &&
      !isEqual(storedTranslations, { ...storedTranslations, ...translations })
    ) {
      setStoredTranslations({ ...storedTranslations, ...translations });
    }
  }, [translations, setStoredTranslations, storedTranslations]);

  const translate = (key: string, fallback: string, values?: any[]): string => {
    const result = (storedTranslations && storedTranslations[key]) || null;
    if (!result) {
      if (!keyArray.find((x: string) => x === key)) {
        SetTranslationKeyArray(dispatch, key);
      }
      return formatTemplate(fallback, values);
    }
    return formatTemplate(result, values);
  };

  return (
    <TranslationContext.Provider value={translate}>
      {children}
    </TranslationContext.Provider>
  );
};

import React, { FC, ReactNode } from 'react';
import {
  Header1 as H1,
  Header2 as H2,
  Header3 as H3,
  Header4 as H4,
} from './Heading.styles';

export interface HeadingProps {
  text?: string;
  align?: 'left' | 'center' | 'right';
  children?: ReactNode;
  testId?: string;
}

export const Header1: FC<HeadingProps> = ({
  align = 'left',
  text,
  children,
  testId,
}: HeadingProps) => (
  <H1 data-testid={testId} align={align}>
    {children ? children : text}
  </H1>
);

export const Header2: FC<HeadingProps> = ({
  align = 'left',
  text,
  children,
}: HeadingProps) => <H2 align={align}>{children ? children : text}</H2>;

export const Header3: FC<HeadingProps> = ({
  align = 'left',
  text,
  children,
}: HeadingProps) => <H3 align={align}>{children ? children : text}</H3>;

export const Header4: FC<HeadingProps> = ({
  align = 'left',
  text,
  children,
}: HeadingProps) => <H4 align={align}>{children ? children : text}</H4>;

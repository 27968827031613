import { ProgrammeAgreementAPIModel } from '../../store/Programme/payload';
import { AssociationPostBody } from '../../store/Programme/store';
import { DispatchFn } from '../../store/state';
import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';
import {
  CommissionsAPImodel,
  InfluencerPaginatedCommissionFilterQueries,
  TrackingLinkAPIModel,
  TrackingLinkPostBody,
} from '../common/interfaces';
import { queryBuilder } from '../common/utils';
import {
  InfluencerMappingAPI,
  InfluencerMappingRequestAPI,
  ProgrammeOverviewApi,
  TrackingLinkAPI,
} from './types';

export enum AgentActionTypes {
  requestGetAssociationRequestsAttempt = 'REQUEST_GET_ASSOCIATION_REQUESTS_ATTEMPT',
  requestGetAssociationRequestsSuccess = 'REQUEST_GET_ASSOCIATION_REQUESTS_SUCCESS',
  requestGetAssociationRequestsFailure = 'REQUEST_GET_ASSOCIATION_REQUESTS_FAILURE',

  requestGetAssociatedInfluencersAttempt = 'REQUEST_GET_ASSOCIATED_INFLUENCERS_ATTEMPT',
  requestGetAssociatedInfluencersSuccess = 'REQUEST_GET_ASSOCIATED_INFLUENCERS_SUCCESS',
  requestGetAssociatedInfluencersFailure = 'REQUEST_GET_ASSOCIATED_INFLUENCERS_FAILURE',

  requestGetMappingRequestsDetailsAttempt = 'REQUEST_GET_MAPPING_REQUESTS_DETAILS_ATTEMPT',
  requestGetMappingRequestsDetailsSuccess = 'REQUEST_GET_MAPPING_REQUESTS_DETAILS_SUCCESS',
  requestGetMappingRequestsDetailsFailure = 'REQUEST_GET_MAPPING_REQUESTS_DETAILS_FAILURE',

  requestGetInfluencerProgrammesAttempt = 'REQUEST_GET_INFLUENCER_PROGRAMMES_ATTEMPT',
  requestGetInfluencerProgrammesSuccess = 'REQUEST_GET_INFLUENCER_PROGRAMMES_SUCCESS',
  requestGetInfluencerProgrammesFailure = 'REQUEST_GET_INFLUENCER_PROGRAMMES_FAILURE',

  requestGetInfluencerInvitesAttempt = 'REQUEST_GET_INFLUENCER_INVITES_ATTEMPT',
  requestGetInfluencerInvitesSuccess = 'REQUEST_GET_INFLUENCER_INVITES_SUCCESS',
  requestGetInfluencerInvitesFailure = 'REQUEST_GET_INFLUENCER_INVITES_FAILURE',

  requestGetAgencyPermissionGroupsAttempt = 'REQUEST_GET_AGENCY_PERMISSION_GROUPS_ATTEMPT',
  requestGetAgencyPermissionGroupsSuccess = 'REQUEST_GET_AGENCY_PERMISSION_GROUPS_SUCCESS',
  requestGetAgencyPermissionGroupsFailure = 'REQUEST_GET_AGENCY_PERMISSION_GROUPS_FAILURE',

  RequestGetAgencyInfluencerProgrammeDetailsAttempt = 'REQUEST_GET_AGENCY_INFLUENCER_PROGRAMME_DETAILS_ATTEMPT',
  RequestGetAgencyInfluencerProgrammeDetailsSuccess = 'REQUEST_GET_AGENCY_INFLUENCER_PROGRAMME_DETAILS_SUCCESS',
  RequestGetAgencyInfluencerProgrammeDetailsFailure = 'REQUEST_GET_AGENCY_INFLUENCER_PROGRAMME_DETAILS_FAILURE',

  RequestGetAgencyInfluencerProgrammeAssociationAttempt = 'REQUEST_GET_AGENCY_INFLUENCER_PROGRAMME_ASSOCIATION_ATTEMPT',
  RequestGetAgencyInfluencerProgrammeAssociationSuccess = 'REQUEST_GET_AGENCY_INFLUENCER_PROGRAMME_ASSOCIATION_SUCCESS',
  RequestGetAgencyInfluencerProgrammeAssociationFailure = 'REQUEST_GET_AGENCY_INFLUENCER_PROGRAMME_ASSOCIATION_FAILURE',

  RequestGetAgencyInfluencerProgrammesAttempt = 'REQUEST_GET_AGENCY_INFLUENCER_PROGRAMME_ATTEMPT',
  RequestGetAgencyInfluencerProgrammesSuccess = 'REQUEST_GET_AGENCY_INFLUENCER_PROGRAMME_SUCCESS',
  RequestGetAgencyInfluencerProgrammesFailure = 'REQUEST_GET_AGENCY_INFLUENCER_PROGRAMME_FAILURE',

  RequestGetAgencyInfluencerCampaignDetailsAttempt = 'REQUEST_GET_AGENCY_INFLUENCER_CAMPAIGN_DETAILS_ATTEMPT',
  RequestGetAgencyInfluencerCampaignDetailsSuccess = 'REQUEST_GET_AGENCY_INFLUENCER_CAMPAIGN_DETAILS_SUCCESS',
  RequestGetAgencyInfluencerCampaignDetailsFailure = 'REQUEST_GET_AGENCY_INFLUENCER_CAMPAIGN_DETAILS_FAILURE',

  RequestGetAgencyInfluencerCampaignAssociationAttempt = 'REQUEST_GET_AGENCY_INFLUENCER_CAMPAIGN_ASSOCIATION_ATTEMPT',
  RequestGetAgencyInfluencerCampaignAssociationSuccess = 'REQUEST_GET_AGENCY_INFLUENCER_CAMPAIGN_ASSOCIATION_SUCCESS',
  RequestGetAgencyInfluencerCampaignAssociationFailure = 'REQUEST_GET_AGENCY_INFLUENCER_CAMPAIGN_ASSOCIATION_FAILURE',

  RequestGetAgencyInfluencerCampaignsAttempt = 'REQUEST_GET_AGENCY_INFLUENCER_CAMPAIGN_ATTEMPT',
  RequestGetAgencyInfluencerCampaignsSuccess = 'REQUEST_GET_AGENCY_INFLUENCER_CAMPAIGN_SUCCESS',
  RequestGetAgencyInfluencerCampaignsFailure = 'REQUEST_GET_AGENCY_INFLUENCER_CAMPAIGN_FAILURE',

  RequestGetAgencyInfluencerInvitationsAttempt = 'REQUEST_GET_AGENCY_INFLUENCER_INVITATIONS_ATTEMPT',
  RequestGetAgencyInfluencerInvitationsSuccess = 'REQUEST_GET_AGENCY_INFLUENCER_INVITATIONS_SUCCESS',
  RequestGetAgencyInfluencerInvitationsFailure = 'REQUEST_GET_AGENCY_INFLUENCER_INVITATIONS_FAILURE',
  requestGetAssociatedInfluencerProgrammesAttempt = 'REQUEST_GET_ASSOCIATED_INFLUENCER_PROGRAMMES_ATTEMPT',
  requestGetAssociatedInfluencerProgrammesSuccess = 'REQUEST_GET_ASSOCIATED_INFLUENCER_PROGRAMMES_SUCCESS',
  requestGetAssociatedInfluencerProgrammesFailure = 'REQUEST_GET_ASSOCIATED_INFLUENCER_PROGRAMMES_FAILURE',

  requestGetAssociatedInfluencerCampaignsAttempt = 'REQUEST_GET_ASSOCIATED_INFLUENCER_CAMPAIGNS_ATTEMPT',
  requestGetAssociatedInfluencerCampaignsSuccess = 'REQUEST_GET_ASSOCIATED_INFLUENCER_CAMPAIGNS_SUCCESS',
  requestGetAssociatedInfluencerCampaignsFailure = 'REQUEST_GET_ASSOCIATED_INFLUENCER_CAMPAIGNS_FAILURE',

  requestGetAssociatedInfluencerInvitationsAttempt = 'REQUEST_GET_ASSOCIATED_INFLUENCER_INVITATIONS_ATTEMPT',
  requestGetAssociatedInfluencerInvitationsSuccess = 'REQUEST_GET_ASSOCIATED_INFLUENCER_INVITATIONS_SUCCESS',
  requestGetAssociatedInfluencerInvitationsFailure = 'REQUEST_GET_ASSOCIATED_INFLUENCER_INVITATIONS_FAILURE',

  requestGetInfluencerDataAttempt = 'REQUEST_GET_INFLUENCER_DATA_ATTEMPT',
  requestGetInfluencerDataSuccess = 'REQUEST_GET_INFLUENCER_DATA_SUCCESS',
  requestGetInfluencerDataFailure = 'REQUEST_GET_INFLUENCER_DATA_FAILURE',

  requestGetInviteDetailsAttempt = 'REQUEST_GET_INVITE_DETAILS_ATTEMPT',
  requestGetInviteDetailsSuccess = 'REQUEST_GET_INVITE_DETAILS_SUCCESS',
  requestGetInviteDetailsFailure = 'REQUEST_GET_INVITE_DETAILS_FAILURE',

  requestPostAssociationAttempt = 'REQUEST_POST_ASSOCIATION_ATTEMPT',
  requestPostAssociationSuccess = 'REQUEST_POST_ASSOCIATION_SUCCESS',
  requestPostAssociationFailure = 'REQUEST_POST_ASSOCIATION_FAILURE',

  requestPatchInvitationDeclineAttempt = 'REQUEST_PATCH_INVITATION_DECLINE_ATTEMPT',
  requestPatchInvitationDeclineSuccess = 'REQUEST_PATCH_INVITATION_DECLINE_SUCCESS',
  requestPatchInvitationDeclineFailure = 'REQUEST_PATCH_INVITATION_DECLINE_FAILURE',

  requestGetAgreementAttempt = 'REQUEST_GET_AGREEMENT_ATTEMPT',
  requestGetAgreementSuccess = 'REQUEST_GET_AGREEMENT_SUCCESS',
  requestGetAgreementFailure = 'REQUEST_GET_AGREEMENT_FAILURE',

  clearCollaborationsData = 'CLEAR_COLLABORATIONS_DATA',

  requestGetInfluencerTrackingLinksAttempt = 'REQUEST_GET_INFLUENCER_TRACKING_LINKS_ATTEMPT',
  requestGetInfluencerTrackingLinksSuccess = 'REQUEST_GET_INFLUENCER_TRACKING_LINKS_SUCCESS',
  requestGetInfluencerTrackingLinksFailure = 'REQUEST_GET_INFLUENCER_TRACKING_LINKS_FAILURE',

  requestGetInfluencerTrackingLinksPerformanceAttempt = 'REQUEST_GET_INFLUENCER_TRACKING_LINKS_PERFORMANCE_ATTEMPT',
  requestGetInfluencerTrackingLinksPerformanceSuccess = 'REQUEST_GET_INFLUENCER_TRACKING_LINKS_PERFORMANCE_SUCCESS',
  requestGetInfluencerTrackingLinksPerformanceFailure = 'REQUEST_GET_INFLUENCER_TRACKING_LINKS_PERFORMANCE_FAILURE',

  requestPostTrackingLinkAttempt = 'REQUEST_POST_TRACKING_LINK_ATTEMPT',
  requestPostTrackingLinkSuccess = 'REQUEST_POST_TRACKING_LINK_SUCCESS',
  requestPostTrackingLinkFailure = 'REQUEST_POST_TRACKING_LINK_FAILURE',

  requestPutTrackingLinkAttempt = 'REQUEST_PUT_TRACKING_LINK_ATTEMPT',
  requestPutTrackingLinkSuccess = 'REQUEST_PUT_TRACKING_LINK_SUCCESS',
  requestPutTrackingLinkFailure = 'REQUEST_PUT_TRACKING_LINK_FAILURE',

  requestGetAgencyDetailsAttempt = 'REQUEST_GET_AGENCY_DETAILS_ATTEMPT',
  requestGetAgencyDetailsSuccess = 'REQUEST_GET_AGENCY_DETAILS_SUCCESS',
  requestGetAgencyDetailsFailure = 'REQUEST_GET_AGENCY_DETAILS_FAILURE',

  clearInfluencerCommissions = 'CLEAR_INFLUENCER_COMMISSIONS',
  requestGetPaginatedInfluencerCommissionsAttempt = 'REQUEST_GET_PAGINATED_INFLUENCER_COMMISSIONS_ATTEMPT',
  requestGetPaginatedInfluencerCommissionsSuccess = 'REQUEST_GET_PAGINATED_INFLUENCER_COMMISSIONS_SUCCESS',
  requestGetPaginatedInfluencerCommissionsFailure = 'REQUEST_GET_PAGINATED_INFLUENCER_COMMISSIONS_FAILURE',

  requestGetCommissionsPermissionCheckAttempt = 'REQUEST_GET_COMMISSIONS_PERMISSION_CHECK_ATTEMPT',
  requestGetCommissionsPermissionCheckSuccess = 'REQUEST_GET_COMMISSIONS_PERMISSION_CHECK_SUCCESS',
  requestGetCommissionsPermissionCheckFailure = 'REQUEST_GET_COMMISSIONS_PERMISSION_CHECK_FAILURE',

  requestGetAgencyMappingPermissionsAttempt = 'REQUEST_GET_AGENCY_MAPPING_PERMISSIONS_CHECK_ATTEMPT',
  requestGetAgencyMappingPermissionsSuccess = 'REQUEST_GET_AGENCY_MAPPING_PERMISSIONS_CHECK_SUCCESS',
  requestGetAgencyMappingPermissionsFailure = 'REQUEST_GET_AGENCY_MAPPING_PERMISSIONS_CHECK_FAILURE',
}

export const RequestGetAssociatedInfluencersAsync = async (
  dispatch: DispatchFn,
  agencyId: string
) => {
  dispatch({ type: AgentActionTypes.requestGetAssociatedInfluencersAttempt });
  try {
    const response: { influencers: InfluencerMappingAPI[] } =
      await client.request(
        Microservice.INFLUENCER,
        `/influencer/agency?agency_id=${agencyId}`,
        'GET'
      );
    dispatch({
      type: AgentActionTypes.requestGetAssociatedInfluencersSuccess,
      payload: response['influencers'],
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetAssociatedInfluencersFailure,
      payload: 'An error occurred trying to fetch associated influencers.',
    });
    throw e;
  }
};

export const RequestGetAssociationRequestsAsync = async (
  dispatch: DispatchFn,
  agencyId: string
) => {
  dispatch({ type: AgentActionTypes.requestGetAssociationRequestsAttempt });
  try {
    const response: { influencers: InfluencerMappingRequestAPI[] } =
      await client.request(
        Microservice.INFLUENCER,
        `/influencer/agency/mapping_requests?agency_id=${agencyId}`,
        'GET'
      );
    dispatch({
      type: AgentActionTypes.requestGetAssociationRequestsSuccess,
      payload: response['influencers'],
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetAssociationRequestsFailure,
      payload: 'An error occurred trying to fetch association requests.',
    });
    throw e;
  }
};

export const RequestGetMappingRequestsDetailsAsync = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetMappingRequestsDetailsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.AGENCY,
      `/agency_mappings/agency/${agencyId}/influencers/${influencerId}/requests`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetMappingRequestsDetailsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetMappingRequestsDetailsFailure,
      payload: 'An error occurred trying to fetch mapping requests details.',
    });
    throw e;
  }
};

export const RequestGetInfluencerProgrammesAsync = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetInfluencerProgrammesAttempt,
    payload: influencerId,
  });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/association/agency/${agencyId}/influencer/${influencerId}`,
      'GET'
    );

    dispatch({
      type: AgentActionTypes.requestGetInfluencerProgrammesSuccess,
      payload: { influencerId, response },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetInfluencerProgrammesFailure,
      payload: {
        influencerId,
        error: 'An error occurred trying to fetch influencer programmes.',
      },
    });
    throw e;
  }
};

export const RequestGetInfluencerInvitesAsync = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetInfluencerInvitesAttempt,
    payload: influencerId,
  });
  try {
    const response = await client.request(
      Microservice.INVITATION,
      `/invitation/agency/${agencyId}/influencer/${influencerId}`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetInfluencerInvitesSuccess,
      payload: { influencerId, response },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetInfluencerInvitesFailure,
      payload: {
        influencerId,
        error: 'An error occurred trying to fetch influencer invites.',
      },
    });
    throw e;
  }
};
export const RequestGetCollaborationProgrammesAsync = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetAssociatedInfluencerProgrammesAttempt,
  });
  try {
    const response: { programmes: ProgrammeOverviewApi[] } =
      await client.request(
        Microservice.PROGRAMME,
        `/collaborations/agency/${agencyId}/influencer/${influencerId}/programmes`,
        'GET'
      );

    dispatch({
      type: AgentActionTypes.requestGetAssociatedInfluencerProgrammesSuccess,
      payload: response['programmes'],
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetAssociatedInfluencerProgrammesFailure,
      payload:
        'An error occurred trying to fetch influencers associated programmes.',
    });
    throw e;
  }
};

export const RequestGetCollaborationCampaignsAsync = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetAssociatedInfluencerCampaignsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/collaborations/agency/${agencyId}/influencer/${influencerId}/campaigns`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetAssociatedInfluencerCampaignsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetAssociatedInfluencerCampaignsFailure,
      payload:
        'An error occurred trying to fetch influencers associated campaigns.',
    });
  }
};

export const RequestGetAgencyPermissionGroupsAsync = async (
  dispatch: DispatchFn
): Promise<any> => {
  dispatch({
    type: AgentActionTypes.requestGetAgencyPermissionGroupsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.AGENCY,
      `/agency_mappings/permission_groups`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetAgencyPermissionGroupsSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: AgentActionTypes.requestGetAgencyPermissionGroupsFailure,
      payload: 'An error occurred trying to fetch agency permission groups.',
    });
    captureException(e);
    throw e;
  }
};
export const RequestGetAgencyInfluencerProgrammeDetails = async (
  dispatch: DispatchFn,
  programmeId: string
): Promise<any> => {
  dispatch({
    type: AgentActionTypes.RequestGetAgencyInfluencerProgrammeDetailsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/collaborations/programme/${programmeId}`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerProgrammeDetailsSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerProgrammeDetailsFailure,
      payload: 'An error occurred trying to get programme information.',
    });
    captureException(e);
    throw e;
  }
};

export const RequestGetAgencyInfluencerProgrammeAssociation = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string,
  programmeId: string
): Promise<any> => {
  dispatch({
    type: AgentActionTypes.RequestGetAgencyInfluencerProgrammeAssociationAttempt,
  });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/collaborations/agency/${agencyId}/influencer/${influencerId}/programme/${programmeId}/association`,
      'GET'
    );

    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerProgrammeAssociationSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerProgrammeAssociationFailure,
      payload: 'An error occurred trying to get programme information.',
    });
    captureException(e);
    throw e;
  }
};

export const RequestGetAgencyInfluencerProgrammes = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
): Promise<any> => {
  dispatch({
    type: AgentActionTypes.RequestGetAgencyInfluencerProgrammesAttempt,
  });
  try {
    const response = await client.request(
      Microservice.AGENCY,
      `/collaborations/agency/${agencyId}/influencer/${influencerId}/programmes`,
      'GET'
    );

    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerProgrammesSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerProgrammesFailure,
      payload: 'An error occurred trying to get programme information.',
    });
    captureException(e);
    throw e;
  }
};

export const RequestGetAgencyInfluencerCampaignDetails = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string
): Promise<any> => {
  dispatch({
    type: AgentActionTypes.RequestGetAgencyInfluencerCampaignDetailsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/collaborations/programme/${programmeId}/campaign/${campaignId}`,
      'GET'
    );

    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerCampaignDetailsSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerCampaignDetailsFailure,
      payload: 'An error occurred trying to get campaign information.',
    });
    captureException(e);
    throw e;
  }
};

export const RequestGetAgencyInfluencerCampaignAssociation = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string,
  programmeId: string,
  campaignId: string
): Promise<any> => {
  dispatch({
    type: AgentActionTypes.RequestGetAgencyInfluencerCampaignAssociationAttempt,
  });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/collaborations/agency/${agencyId}/influencer/${influencerId}/programme/${programmeId}/campaign/${campaignId}/association`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerCampaignAssociationSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerCampaignAssociationFailure,
      payload: 'An error occurred trying to get Campaign information.',
    });
    captureException(e);
    throw e;
  }
};

export const RequestGetAgencyInfluencerCampaigns = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
): Promise<any> => {
  dispatch({
    type: AgentActionTypes.RequestGetAgencyInfluencerCampaignsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.AGENCY,
      `/collaborations/agency/${agencyId}/influencer/${influencerId}/campaigns`,
      'GET'
    );

    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerCampaignsSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerCampaignsFailure,
      payload: 'An error occurred trying to get Campaign information.',
    });
    captureException(e);
    throw e;
  }
};

export const RequestGetAgencyInfluencerInvitations = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
): Promise<any> => {
  dispatch({
    type: AgentActionTypes.RequestGetAgencyInfluencerInvitationsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.AGENCY,
      `/collaborations/agency/${agencyId}/influencer/${influencerId}/invitations`,
      'GET'
    );

    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerInvitationsSuccess,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: AgentActionTypes.RequestGetAgencyInfluencerInvitationsFailure,
      payload: 'An error occurred trying to get Invitation information.',
    });
    captureException(e);
    throw e;
  }
};

export const RequestGetCollaborationInvitationsAsync = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetAssociatedInfluencerInvitationsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.INVITATION,
      `/collaborations/agency/${agencyId}/influencer/${influencerId}/invitations`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetAssociatedInfluencerInvitationsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetAssociatedInfluencerInvitationsFailure,
      payload:
        'An error occurred trying to fetch influencers associated Invitations.',
    });
  }
};

export const RequestGetInfluencerData = async (
  dispatch: DispatchFn,
  influencerId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetInfluencerDataAttempt,
  });
  try {
    const response = await client.request(
      Microservice.INFLUENCER,
      `/influencer/${influencerId}`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetInfluencerDataSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetInfluencerDataFailure,
      payload: 'An error occurred trying to fetch influencer data',
    });
  }
};

export const RequestGetInviteDetails = async (
  dispatch: DispatchFn,
  invitationId: string,
  agencyId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetInviteDetailsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.INVITATION,
      `/invitation/${invitationId}?agency_id=${agencyId}`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetInviteDetailsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetInviteDetailsFailure,
      payload: 'An error occurred trying to fetch invite details',
    });
  }
};

export const RequestPostAssociationAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  body: AssociationPostBody
): Promise<any> => {
  dispatch({ type: AgentActionTypes.requestPostAssociationAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/association`,
      'POST',
      body
    );
    dispatch({
      type: AgentActionTypes.requestPostAssociationSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestPostAssociationFailure,
      payload: 'An error occurred while posting Association.',
    });
    throw e;
  }
};

export const RequestPatchDeclineInvitationAsync = async (
  dispatch: DispatchFn,
  invitationId: string
): Promise<void> => {
  dispatch({ type: AgentActionTypes.requestPatchInvitationDeclineAttempt });
  try {
    await client.request(
      Microservice.INVITATION,
      `/invitation/${invitationId}/decline`,
      'PATCH'
    );
    dispatch({
      type: AgentActionTypes.requestPatchInvitationDeclineSuccess,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestPatchInvitationDeclineFailure,
      payload: 'There was an error communicating with the server',
    });
    throw e;
  }
};

export const RequestGetProgrammeAgreementAsync = async (
  dispatch: DispatchFn,
  programmeId: string
): Promise<any> => {
  dispatch({ type: AgentActionTypes.requestGetAgreementAttempt });
  try {
    const response = await client.request<ProgrammeAgreementAPIModel>(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/agreement`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetAgreementSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetAgreementFailure,
      payload: 'An error occurred while getting agreement.',
    });
  }
};

export const ClearCollaborationsData = (dispatch: DispatchFn) => {
  dispatch({ type: AgentActionTypes.clearCollaborationsData });
};

export const RequestGetInfluencerTrackingLinks = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
) => {
  dispatch({ type: AgentActionTypes.requestGetInfluencerTrackingLinksAttempt });
  try {
    const response = await client.request<{
      tracking_links: TrackingLinkAPI[];
    }>(
      Microservice.PROGRAMME,
      `/collaborations/agency/${agencyId}/influencer/${influencerId}/tracking_links`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetInfluencerTrackingLinksSuccess,
      payload: response['tracking_links'],
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetInfluencerTrackingLinksFailure,
      payload: 'An error has occurred while fetching tracking links',
    });
  }
};

export const RequestGetInfluencerTrackingLinksPerformance = async (
  dispatch: DispatchFn,
  agencyId: string,
  programmeId: string,
  influencerId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetInfluencerTrackingLinksPerformanceAttempt,
  });
  try {
    const response = await client.request(
      Microservice.REPORTING,
      `/reporting/agency_id/${agencyId}/programme/${programmeId}/influencer/${influencerId}/performance/tracking-links`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetInfluencerTrackingLinksPerformanceSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetInfluencerTrackingLinksPerformanceFailure,
      payload:
        'An error has occurred while fetching tracking links performance',
    });
  }
};

export const RequestPostTrackingLink = async (
  dispatch: DispatchFn,
  agencyId: string,
  programmeId: string,
  influencerId: string,
  body: TrackingLinkPostBody
) => {
  dispatch({ type: AgentActionTypes.requestPostTrackingLinkAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/collaborations/programme/${programmeId}/agency/${agencyId}/influencer/${influencerId}/tracking_links`,
      'POST',
      body
    );
    dispatch({
      type: AgentActionTypes.requestPostTrackingLinkSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestPostTrackingLinkFailure,
      payload: 'An error occurred while creating the tracking link',
    });
  }
};
export const RequestPutTrackingLink = async (
  dispatch: DispatchFn,
  agencyId: string,
  programmeId: string,
  influencerId: string,
  trackingLinkId: string,
  body: TrackingLinkAPIModel
) => {
  dispatch({ type: AgentActionTypes.requestPutTrackingLinkAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/collaborations/programme/${programmeId}/agency/${agencyId}/influencer/${influencerId}/tracking_links/${trackingLinkId}`,
      'PUT',
      body
    );
    dispatch({
      type: AgentActionTypes.requestPutTrackingLinkSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestPutTrackingLinkFailure,
      payload: 'An error occurred while updating the tracking link',
    });
  }
  return null;
};

export const RequestAgencyDetails = async (
  dispatch: DispatchFn,
  agencyId: string
) => {
  dispatch({ type: AgentActionTypes.requestGetAgencyDetailsAttempt });
  try {
    const response = await client.request(
      Microservice.AGENCY,
      `/agency/${agencyId}`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetAgencyDetailsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetAgencyDetailsFailure,
      payload: 'An error occurred while fetching agency details',
    });
  }
};

export const ClearInfluencerCommissions = (dispatch: DispatchFn) => {
  dispatch({ type: AgentActionTypes.clearInfluencerCommissions });
};

export const RequestGetPaginatedInfluencerCommissions = async (
  dispatch: DispatchFn,
  influencerId: string,
  queryObject: InfluencerPaginatedCommissionFilterQueries
) => {
  dispatch({
    type: AgentActionTypes.requestGetPaginatedInfluencerCommissionsAttempt,
  });
  const queries =
    queryBuilder<InfluencerPaginatedCommissionFilterQueries>(queryObject);
  try {
    const response: CommissionsAPImodel = await client.request(
      Microservice.COMMISSION,
      `/commission/influencer/${influencerId}/commissions/paginated${queries}`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetPaginatedInfluencerCommissionsSuccess,
      payload: { ...response, pageNumber: queryObject.page },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetPaginatedInfluencerCommissionsFailure,
      payload: 'An error occured trying to get influencer commissions',
    });
  }
};

export const RequestGetCommissionsPermissionCheck = async (
  dispatch: DispatchFn,
  agencyId: string,
  influencerId: string
) => {
  dispatch({
    type: AgentActionTypes.requestGetCommissionsPermissionCheckAttempt,
  });
  try {
    const response = await client.request(
      Microservice.COMMISSION,
      `/commission/permission/agency/${agencyId}/influencer/${influencerId}`,
      'GET'
    );
    dispatch({
      type: AgentActionTypes.requestGetCommissionsPermissionCheckSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: AgentActionTypes.requestGetCommissionsPermissionCheckFailure,
      payload:
        'An error occured trying to get influencer commissions permission check',
    });
  }
};

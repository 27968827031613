import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { NotificationCenter } from '../NotificationCenter/NotificationCenter';
import { ErrorMessage } from '../../../common/ErrorMessage';
import {
  NotificationContainer,
  NotificationCount,
  NotificationIcon,
} from './NotificationController.styles';
import { useNotificationController } from './useNotificationController';
import { IconButtonOutline } from '../../../common/IconButtonOutline';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';

const translationObject = {
  notificationController: {
    'NOTIFICATIONS.TOOLTIP.TOGGLE': 'Toggle Notifications',
  },
};

export const NotificationController: FC = () => {
  const {
    unreadCount,
    toggleHandler,
    isShowingNotifications,
    hasError,
    isMobile,
    hasViewedNotifications,
  } = useNotificationController();
  const { notificationController: t } =
    useTranslationsController(translationObject);
  return (
    <>
      <NotificationContainer isMobile={isMobile}>
        <Badge
          color="secondary"
          data-testid="t_notifications_badge"
          invisible={hasViewedNotifications}
          badgeContent={
            <NotificationCount data-testid={'t_notifications_badge_count'}>
              {unreadCount}
            </NotificationCount>
          }
          overlap={isMobile ? 'circular' : 'rectangular'}
          variant={isMobile ? 'dot' : 'standard'}
        >
          {!isMobile ? (
            <IconButtonOutline
              onClick={() => toggleHandler(!isShowingNotifications)}
              aria-label={t['NOTIFICATIONS.TOOLTIP.TOGGLE']}
              aria-expanded={isShowingNotifications}
              data-testid="t_notifications_button"
              tooltip={t['NOTIFICATIONS.TOOLTIP.TOGGLE']}
              icon="bell"
            />
          ) : (
            <NotificationIcon>
              <FontAwesomeIcon
                icon="bell"
                onClick={() => toggleHandler(!isShowingNotifications)}
                data-testid="t_notifications_icon"
              />
            </NotificationIcon>
          )}
        </Badge>

        {isShowingNotifications && (
          <ClickAwayListener onClickAway={() => toggleHandler(false)}>
            <div>
              <NotificationCenter />
            </div>
          </ClickAwayListener>
        )}
      </NotificationContainer>

      {!!hasError && (
        <ErrorMessage
          message={hasError}
          testId={`t_notifications_error_toast`}
        />
      )}
    </>
  );
};

import { Action } from '../../store/state';
import { AgencyApplicationActions } from './actions';
import { AgencyApplicationState } from './store';

export const AgencyApplicationReducer = (
  state: AgencyApplicationState,
  action: Action
): AgencyApplicationState => {
  switch (action.type) {
    case AgencyApplicationActions.requestPostAgencyApplicationAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postApplication: true,
        },
        errors: {
          ...state.errors,
          postApplication: null,
        },
      };
    case AgencyApplicationActions.requestPostAgencyApplicationSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postApplication: false,
        },
      };
    case AgencyApplicationActions.requestPostAgencyApplicationFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postApplication: false,
        },
        errors: {
          ...state.errors,
          postApplication: action.payload,
        },
      };
    case AgencyApplicationActions.requestPostAgencyApplicationReminderAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postReminder: true,
        },
        errors: {
          ...state.errors,
          postReminder: null,
        },
      };
    case AgencyApplicationActions.requestPostAgencyApplicationReminderSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postReminder: false,
        },
      };
    case AgencyApplicationActions.requestPostAgencyApplicationReminderFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postReminder: false,
        },
        errors: {
          ...state.errors,
          postReminder: action.payload,
        },
      };
    default:
      return state;
  }
};

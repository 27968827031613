import React, { FC } from 'react';
import { Spacer as Component } from './Spacer';

export interface SpacerProps {
  isVertical?: boolean;
  amount?: number;
  as?: string;
}

export const Spacer: FC<SpacerProps> = props => <Component {...props} />;

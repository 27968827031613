import styled, { css } from 'styled-components';
import { HelpTwoTone } from '@material-ui/icons';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export type TooltipPositions = 'top' | 'bottom' | 'right' | 'left' | 'topRight';

export type WrapperPosition = 'default' | 'topRight' | 'right' | 'far-right';

const TooltipTopStyles = css`
  top: 200%;
  right: 0;
  left: 0;
`;

const TooltipBottomStyles = css`
  bottom: 200%;
  right: 0;
  left: 0;
`;

const TooltipRightStyles = css`
  top: 0;
  bottom: 0;
  left: 200%;
`;

const TooltipLeftStyles = css`
  top: 0;
  bottom: 0;
  right: 200%;
`;

const TooltipTopRightStyles = css`
  top: -30px;
  left: 20px;
`;

const getTooltipPositions = (position: TooltipPositions) => {
  switch (position) {
    case 'top':
      return TooltipTopStyles;
    case 'bottom':
      return TooltipBottomStyles;
    case 'right':
      return TooltipRightStyles;
    case 'left':
      return TooltipLeftStyles;
    case 'topRight':
      return TooltipTopRightStyles;
    default:
      return TooltipRightStyles;
  }
};

const getWrapperPositions = (position: WrapperPosition) => {
  switch (position) {
    case 'topRight':
      return css`
        padding-right: 1.8rem;
        bottom: 8px;
      `;
    case 'far-right':
      return css`
        padding-right: 10rem;
      `;
    case 'right':
      return css`
        padding-right: 6.5rem;
      `;
    default:
      return css`
        padding-right: ${px2rem(22)};
      `;
  }
};

interface TooltipProps {
  message: string;
  icon?: any;
  position?: TooltipPositions;
  wrapperPosition?: WrapperPosition;
  fullWidth?: boolean;
}

export const IconWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  right: ${px2rem(12)};
  cursor: pointer;
  &:hover {
    overflow: visible;

    & path {
      fill: ${props => theme(props).COLOUR.BACKGROUND_DARK};
    }
    & path:first-child {
      fill: ${props => theme(props).COLOUR.BACKGROUND_DARK};
    }
  }
`;

export const TooltipWrapper = styled.span<TooltipProps>`
  position: relative;
  ${props =>
    props.wrapperPosition
      ? getWrapperPositions(props.wrapperPosition)
      : getWrapperPositions('default')};

  & > ${IconWrapper}::after {
    content: '${props => props.message}';
    display: block;
    width: ${props => (props.fullWidth ? 'fit-content' : px2rem(200))};
    height: fit-content;
    position: absolute;
    background: ${props => theme(props).COLOUR.BACKGROUND_DARK};
    ${props => theme(props).TYPOGRAPHY.X_SMALL};
    white-space: pre-wrap;
    padding: ${px2rem(4)};
    border-radius: ${px2rem(8)};
    color: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};

    ${props =>
      props.position
        ? getTooltipPositions(props.position)
        : getTooltipPositions('right')};
  }
`;

export const DefaultIcon = styled(HelpTwoTone)`
  width: ${px2rem(18)} !important;
  height: auto !important;

  & path {
    fill: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
  }
  & path:first-child {
    fill: white;
  }
`;

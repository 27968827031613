import styled, { keyframes, css } from 'styled-components';
import { theme } from '../../../styling/theme';
import { Scale } from './Pulse';

const pulseAnimate = (props: any) => keyframes`
    from {
    opacity: 1;
    transform: scale(1);
    background-color:${theme(props).COLOUR.PRIMARY};
  }
  to {
    opacity: 0.25;
    transform: scale(0.75);
    background-color: ${theme(props).COLOUR.SECONDARY};
  }
`;

const cssAnimate = (
  name?: any,
  duration?: string,
  timingFunc?: string,
  delay?: string,
  iterations?: string,
  fillMode?: string
) => css`
  animation: ${name && name} ${duration && duration} ${timingFunc && timingFunc}
    ${delay && delay} ${iterations && iterations} ${fillMode && fillMode};
`;

const StandardPulseBubble = css`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: white;
  margin: 0 0.25rem;
`;

export const PulseBubble = styled.div<{ variant: number }>`
  ${StandardPulseBubble}
  ${props => {
    switch (props.variant) {
      case 1:
        return cssAnimate(
          pulseAnimate(props),
          '0.4s',
          'ease',
          '0.2s',
          'infinite',
          'alternate'
        );
      case 2:
        return cssAnimate(
          pulseAnimate(props),
          '0.4s',
          'ease',
          '0.4s',
          'infinite',
          'alternate'
        );
      default:
        return cssAnimate(
          pulseAnimate(props),
          '0.4s',
          'ease',
          '0s',
          'infinite',
          'alternate'
        );
    }
  }}
`;

export const PulseContainer = styled.div<{ scale?: Scale }>`
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.scale && `transform: scale(${props.scale})`}
`;

import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs, { Dayjs } from 'dayjs';
import { Modal } from '../../../common/Modal';
import { NotificationItem } from '../NotificationItem/NotificationItem';
import { NotificationZero } from '../NotificationItem/NotificationZero';
import {
  NotificationDateDivider,
  NotificationCenterContent,
  NotificationItemsContainer,
  NotificationListItem,
  NotificationsHeader,
  NotificationsList,
  NotificationsTitle,
  NotificationsTitleContainer,
  MarkAllButtonContainer,
} from './NotificationCenter.styles';
import { useNotificationCenter } from './useNotificationCenter';
import { Header3, TextButton } from '../../../common';
import { Notification } from '../../../../store/Notification/store';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';
import { notificationCenterTranslationObject } from './translations';

export const NotificationCenter: FC = () => {
  const {
    notifications,
    dispatch,
    isMobile,
    toggleHandler,
    handleMarkAsRead,
    hasUnread,
  } = useNotificationCenter();

  const { notificationCenter: t } = useTranslationsController(
    notificationCenterTranslationObject
  );

  const renderDateDivider = (title: string, key: string) => {
    return (
      <NotificationDateDivider key={key} data-testid={`t_${key}`}>
        {title.toUpperCase()}
      </NotificationDateDivider>
    );
  };

  const renderNotificationItems = (notifications: Notification[]) => {
    const dividers = [
      {
        component: renderDateDivider(
          t['NOTIFICATIONS.DIVIDER.TODAY'],
          'today-divider'
        ),
        comparator: (date: Dayjs) => {
          return date.isSame(dayjs(), 'day');
        },
        used: false,
      },
      {
        component: renderDateDivider(
          t['NOTIFICATIONS.DIVIDER.WEEK'],
          'week-divider'
        ),
        comparator: (date: Dayjs) => {
          return date.isSame(dayjs(), 'week') && !date.isSame(dayjs(), 'day');
        },
        used: false,
      },
      {
        component: renderDateDivider(
          t['NOTIFICATIONS.DIVIDER.OLDER'],
          'older-divider'
        ),
        comparator: (date: Dayjs) => {
          return !date.isSame(dayjs(), 'week');
        },
        used: false,
      },
    ];

    const componentsToRender: JSX.Element[] = [];

    notifications.forEach((notif, index) => {
      dividers
        .filter(div => !div.used)
        .forEach(divider => {
          if (divider.comparator(notif.createdAt) && !divider.used) {
            componentsToRender.push(divider.component);
            divider.used = true;
          }
        });

      componentsToRender.push(
        <NotificationListItem key={`notification-list-item-${index}`}>
          <NotificationItem
            notification={notif}
            dispatch={dispatch}
            testId={`t_notification_item_${index}`}
          />
        </NotificationListItem>
      );
    });

    return componentsToRender;
  };

  const renderMarkAllReadButton = () => {
    return (
      hasUnread && (
        <TextButton
          onClick={e => {
            e.preventDefault();
            handleMarkAsRead();
          }}
          role={'button'}
          data-testid={'t_notifications_mark_all_read_btn'}
        >
          {t['NOTIFICATIONS.BUTTON.MARKALL']}
        </TextButton>
      )
    );
  };

  const renderNotificationsHeader = () => {
    return (
      <Header3>
        <NotificationsHeader>
          <NotificationsTitleContainer>
            <NotificationsTitle>{t['NOTIFICATIONS.H3']}</NotificationsTitle>
            <FontAwesomeIcon icon={'bell'} />
          </NotificationsTitleContainer>
          {!isMobile && renderMarkAllReadButton()}
        </NotificationsHeader>
      </Header3>
    );
  };

  const renderNotificationsList = () => {
    return (
      <NotificationsList>
        {notifications.length > 0 && (
          <>{renderNotificationItems(notifications)}</>
        )}
      </NotificationsList>
    );
  };

  const renderMobileNotifications = () => {
    return (
      <Modal
        fullScreen
        titleText={renderNotificationsHeader()}
        data-testid={'t_mobile_notifications'}
        cancelHandler={() => toggleHandler(false)}
        isOpen={true}
      >
        {notifications.length ? (
          <>
            <MarkAllButtonContainer>
              {renderMarkAllReadButton()}
            </MarkAllButtonContainer>
            {renderNotificationsList()}
          </>
        ) : (
          <NotificationZero
            isMobile={isMobile}
            text={t['NOTIFICATIONS.ZERO']}
          />
        )}
      </Modal>
    );
  };

  const renderDesktopNotifications = () => {
    return (
      <NotificationCenterContent>
        {renderNotificationsHeader()}
        {notifications.length ? (
          <NotificationItemsContainer data-testid={'t_desktop_notifications'}>
            {renderNotificationsList()}
          </NotificationItemsContainer>
        ) : (
          <NotificationZero
            isMobile={isMobile}
            text={t['NOTIFICATIONS.ZERO']}
          />
        )}
      </NotificationCenterContent>
    );
  };

  return (
    <>{isMobile ? renderMobileNotifications() : renderDesktopNotifications()}</>
  );
};

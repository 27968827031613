import { TranslationContext } from '../components/providers';
import { useContext } from 'react';

export const useTranslation = () => {
  return useContext(TranslationContext);
};

export type TranslateFunction = (
  key: string,
  fallback: string,
  values?: any[]
) => string;

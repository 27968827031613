import React, { FC } from 'react';
import {
  RedirectTitle,
  RedirectContainer,
  RedirectText,
  LogoutBox,
  RedirectContent,
  ActionsContainer,
} from '../../common/RoleRedirect/RoleRedirect.styles';
import {
  Alignment,
  Direction,
  Padding,
  BackLink,
  ForwardLink,
  TextButtonLogo,
} from '../../common';
import environment from '../../../environments/environment';
import { useStore } from '../../../store/state';
import Keycloak from 'keycloak-js';
import { getKeycloakInstance } from '../../../keycloak/keycloak-init';
import { useSocialIntegrations } from '../../../hooks/useSocialIntegrations';
import { useTranslationsController } from '../../../utils/globalTranslationsController';
import { roleSwitcherTranslations } from './translations';

export const RoleSwitcher: React.FC<{
  kc?: Keycloak;
  influencerContainer: JSX.Element;
  agentContainer: JSX.Element;
}> = ({ kc = getKeycloakInstance(), influencerContainer, agentContainer }) => {
  const [{ userState }] = useStore();
  const { hasOneSocialConnected } = useSocialIntegrations();

  const userType = userState.permissions?.type;

  const hasPermissionsError =
    (!!userState.user && userState.errors.permissions) ||
    (userState.user && userState.permissions == null);

  const hasSocialError =
    userState.errors.socialRequirement && !hasOneSocialConnected;

  const logout = kc?.logout;

  const refreshPage = () => {
    window.location.reload();
  };

  const { roleSwitcher: t } = useTranslationsController(
    roleSwitcherTranslations
  );

  const RedirectWrapper: FC = ({ children }) => {
    return (
      <RedirectContainer
        flex
        justify={Alignment.CENTER}
        align={Alignment.CENTER}
      >
        <RedirectContent
          flex
          align={Alignment.CENTER}
          direction={Direction.COLUMN}
          padding={Padding.LARGE}
        >
          <RedirectTitle>
            {t['ROLE_SWITCHER.REDIRECT_WRAPPER.TITLE']}
          </RedirectTitle>
          {children}
          <LogoutBox>
            <BackLink
              redirectUrl={'#'}
              onClick={logout}
              label={t['ROLE_SWITCHER.REDIRECT_WRAPPER.LOGOUT']}
              colorVariant={'secondary'}
              testId={'t_logout_button'}
              isWhite
            />
          </LogoutBox>
        </RedirectContent>
      </RedirectContainer>
    );
  };

  const nonInfluencerRedirect = () => {
    return (
      <RedirectWrapper>
        <RedirectText>
          {t['ROLE_SWITCHER.NON_INFLUENCER.REDIRECT_TEXT']}
        </RedirectText>
        <ActionsContainer
          flex
          direction={Direction.COLUMN}
          justify={Alignment.CENTER}
        >
          <ForwardLink
            label={t['ROLE_SWITCHER.NON_INFLUENCER.REDIRECT_REDIRECT_LABEL']}
            testId={'t_redirect_button'}
            redirectUrl={environment.marketerUrl}
            colorVariant={'secondary'}
            isWhite
            isExternal
          />
        </ActionsContainer>
      </RedirectWrapper>
    );
  };

  const errorRedirect = () => {
    return (
      <RedirectWrapper>
        <RedirectText>{t['ROLE_SWITCHER.ERROR.REDIRECT_TEXT']}</RedirectText>
        <ActionsContainer
          flex
          direction={Direction.COLUMN}
          justify={Alignment.CENTER}
        >
          <TextButtonLogo onClick={() => refreshPage()}>
            {t['ROLE_SWITCHER.ERROR.RETRY_MESSAGE']}
          </TextButtonLogo>
        </ActionsContainer>
      </RedirectWrapper>
    );
  };

  if (hasPermissionsError || hasSocialError) {
    return errorRedirect();
  }

  switch (userType) {
    case 'non_influencer':
      return nonInfluencerRedirect();
    case 'influencer_agent':
      return agentContainer;
    case 'influencer':
      return influencerContainer;
    default:
      return errorRedirect();
  }
};

import { InterestOption } from './components/common/Interests/types';

export const interestConfig: InterestOption[] = [
  {
    name: 'Travel',
    value: 'travel',
    active: false,
    iconName: 'plane',
    translateKey: 'INTERESTS.OPTION.TRAVEL',
  },
  {
    name: 'Fashion',
    value: 'fashion',
    active: false,
    iconName: 'tshirt',
    translateKey: 'INTERESTS.OPTION.FASHION',
  },
  {
    name: 'Photography',
    value: 'photography',
    active: false,
    iconName: 'camera',
    translateKey: 'INTERESTS.OPTION.PHOTOGRAPHY',
  },
  {
    name: 'Food & Drink',
    value: 'food_drink',
    active: false,
    iconName: 'utensils',
    translateKey: 'INTERESTS.OPTION.FOOD',
  },
  {
    name: 'Architecture',
    value: 'architecture',
    active: false,
    iconName: 'building',
    translateKey: 'INTERESTS.OPTION.ARCHITECTURE',
  },
  {
    name: 'Gaming',
    value: 'gaming',
    active: false,
    iconName: 'gamepad',
    translateKey: 'INTERESTS.OPTION.GAMING',
  },
  {
    name: 'Beauty & Personal Care',
    value: 'beauty_personal_care',
    active: false,
    iconName: 'shopping-bag',
    translateKey: 'INTERESTS.OPTION.BEAUTY',
  },
  {
    name: 'Automotives',
    value: 'automotives',
    active: false,
    iconName: 'car',
    translateKey: 'INTERESTS.OPTION.CARS',
  },
  {
    name: 'Science',
    value: 'science',
    active: false,
    iconName: 'flask',
    translateKey: 'INTERESTS.OPTION.SCIENCE',
  },
  {
    name: 'Design',
    value: 'design',
    active: false,
    iconName: 'swatchbook',
    translateKey: 'INTERESTS.OPTION.DESIGN',
  },
  {
    name: 'Fitness & Sports',
    value: 'fitness_sports',
    active: false,
    iconName: 'dumbbell',
    translateKey: 'INTERESTS.OPTION.FITNESS',
  },
  {
    name: 'Music & Entertainment',
    value: 'music_entertainment',
    active: false,
    iconName: 'music',
    translateKey: 'INTERESTS.OPTION.MUSIC',
  },
  {
    name: 'Family & Babies',
    value: 'family_babies',
    active: false,
    iconName: 'baby',
    translateKey: 'INTERESTS.OPTION.FAMILY',
  },
  {
    name: 'Health & Wellness',
    value: 'health_wellness',
    active: false,
    iconName: 'laptop-medical',
    translateKey: 'INTERESTS.OPTION.HEALTH',
  },
  {
    name: 'Social Media & Web',
    value: 'social_media_web',
    active: false,
    iconName: 'photo-video',
    translateKey: 'INTERESTS.OPTION.SOCIAL',
  },
  {
    name: 'Home & Garden',
    value: 'home_garden',
    active: false,
    iconName: 'home',
    translateKey: 'INTERESTS.OPTION.HOME',
  },
];

import { Dayjs } from 'dayjs';
import {
  Association,
  Commissions,
  CommissionSummary,
  DiscountCode,
} from '../../store/Programme/store';
import { TrackingLink } from '../common/interfaces';

// Query Models
export type ProgrammeQuery = {
  programme_id: string | null;
};

// API Models
export interface CampaignLinkAPI {
  title: string;
  description: string;
  destination_url: string;
}

export interface ReadCampaignAPI {
  name: string;
  brand_name: string;
  brief: string;
  start_date: string;
  end_date: string;
  time_zone: string;
  campaign_banner_id: string;
  visual_direction: string[];
  connectors: string[];
  links: CampaignLinkAPI[];
  dos: string[];
  donts: string[];
  additional_images: string[];
  programme_id: string;
  campaign_id: string;
  is_active: boolean;
  has_expired: boolean;
  days_to_expiry?: number;
}

// Local Models
export interface CampaignLink {
  title: string;
  description: string;
  destinationUrl: string;
}

export interface ReadCampaign {
  [key: string]: any;
  name: string;
  brandName: string;
  brief: string;
  startDate: Dayjs;
  endDate: Dayjs;
  timeZone: string;
  campaignBannerId: string;
  visualDirection: string[];
  connectors: string[];
  links: CampaignLink[];
  dos: string[];
  donts: string[];
  additionalImages: string[];
  programmeId: string;
  campaignId: string;
  isActive: boolean;
  hasExpired: boolean;
  daysToExpiry?: number;
}

// Campaign Context State

type StateError = string | null;

export interface CampaignsState {
  isFetching: {
    campaigns: boolean;
    currentCampaign: boolean;
    currentAssociation: boolean;
    currentDiscountCode: boolean;
    currentTrackingLinks: boolean;
    campaignCommissions: boolean;
    campaignCommissionsSummary: boolean;
    campaignCommissionsOverview: boolean;
    campaignTrackingLinks: boolean;
    campaignTrackingLinksBackground: boolean;
    postCampaignTrackingLink: boolean;
    deleteCampaignTrackingLink: boolean;
    updateCampaignTrackingLink: boolean;
    trackingLinksPerformances: boolean;
  };
  errors: {
    fetchCampaigns: StateError;
    fetchCurrentCampaign: StateError;
    fetchCurrentDiscountCode: StateError;
    fetchCurrentAssociation: StateError;
    fetchCurrentTrackingLinks: StateError;
    fetchCampaignCommissions: StateError;
    fetchCampaignCommissionsSummary: StateError;
    fetchCampaignCommissionsOverview: StateError;
    fetchCampaignTrackingLinks: StateError;
    fetchCampaignTrackingLinksBackground: StateError;
    postCampaignTrackingLink: StateError;
    deleteCampaignTrackingLink: StateError;
    updateCampaignTrackingLink: StateError;
    trackingLinksPerformances: StateError;
  };

  campaigns: ReadCampaign[];
  currentCampaign: ReadCampaign | null;
  currentDiscountCode: DiscountCode | null;
  currentAssociation: Association | null;
  currentTrackingLinks: TrackingLink[];
  campaignCommissions: Commissions | null;
  campaignCommissionsSummary: CommissionSummary | null;
  campaignCommissionsOverview: CommissionSummary | null;
  trackingLinksPerformances: TrackingLinksPerformances | null;
}

export const defaultCampaignsState: CampaignsState = {
  isFetching: {
    campaigns: false,
    currentCampaign: false,
    currentDiscountCode: false,
    currentAssociation: false,
    currentTrackingLinks: false,
    campaignCommissions: false,
    campaignCommissionsSummary: false,
    campaignCommissionsOverview: false,
    campaignTrackingLinks: false,
    campaignTrackingLinksBackground: false,
    postCampaignTrackingLink: false,
    deleteCampaignTrackingLink: false,
    updateCampaignTrackingLink: false,
    trackingLinksPerformances: false,
  },
  errors: {
    fetchCampaigns: null,
    fetchCurrentCampaign: null,
    fetchCurrentDiscountCode: null,
    fetchCurrentAssociation: null,
    fetchCurrentTrackingLinks: null,
    fetchCampaignCommissions: null,
    fetchCampaignCommissionsSummary: null,
    fetchCampaignCommissionsOverview: null,
    fetchCampaignTrackingLinks: null,
    fetchCampaignTrackingLinksBackground: null,
    postCampaignTrackingLink: null,
    deleteCampaignTrackingLink: null,
    updateCampaignTrackingLink: null,
    trackingLinksPerformances: null,
  },

  campaigns: [],
  currentCampaign: null,
  currentDiscountCode: null,
  currentAssociation: null,
  currentTrackingLinks: [],
  campaignCommissions: null,
  campaignCommissionsSummary: null,
  campaignCommissionsOverview: null,
  trackingLinksPerformances: null,
};

export interface TrackingLinkPerformanceAPI {
  total_clicks: number;
  click_conversion_rate: number;
  average_order_value: number;
  total_partner_commission: number;
  earliest_tracking_date: string;
}

export interface TrackingLinkPerformance {
  totalClicks: number;
  clickConversionRate: number;
  averageOrderValue: number;
  totalPartnerCommission: number;
  earliestTrackingDate: string;
}

export type TrackingLinksPerformancesAPI = Record<
  string,
  TrackingLinkPerformanceAPI
>;
export type TrackingLinksPerformances = Record<string, TrackingLinkPerformance>;

import {
  countryAlpha2Codes,
  countryAlpha3Codes,
} from './localisation_lookups.utils';

export const countryNamesAlpha2: { [key: string]: string } = {
  GB: 'United Kingdom',
  US: 'United States',
  AU: 'Australia',
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CY: 'Cyprus',
  CZ: 'Česká Republika',
  DK: 'Danmark',
  EE: 'Estonia',
  FI: 'Suomi',
  FR: 'France',
  DE: 'Deutschland',
  GR: 'Ελλάδα',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italia',
  LV: 'Latvia',
  LT: 'Lietuva',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Nederland',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovensko',
  SI: 'Slovenia',
  ES: 'España',
  SE: 'Sverige',
  IS: 'Iceland',
  LI: 'Liechtenstein',
  NO: 'Norway',
  CH: 'Switzerland',
  MC: 'Monaco',
  HR: 'Croatia',
  JP: '日本',
  CN: '中国',
  SG: 'Singapore',
  VN: 'Viet Nam',
  RS: 'Serbia',
  AD: 'Andorra',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AR: 'Argentina',
  AS: 'American Samoa',
  AW: 'Aruba',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BF: 'Burkina Faso',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia (Plurinational State of)',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CD: 'Congo (the Democratic Republic of the)',
  CF: 'Central African Republic',
  CG: 'Congo',
  CI: "Côte d'Ivoire",
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CV: 'Cabo Verde',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EG: 'Egypt',
  ER: 'Eritrea',
  ET: 'Ethiopia',
  FJ: 'Fiji',
  FK: 'Falkland Islands [Malvinas]',
  FO: 'Faroe Islands',
  GA: 'Gabon',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HT: 'Haiti',
  ID: 'Indonesia',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IQ: 'Iraq',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KR: '대한민국',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: "Lao People's Democratic Republic",
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LY: 'Libya',
  MA: 'Morocco',
  MD: 'Moldova (the Republic of)',
  ME: 'Montenegro',
  MG: 'Madagascar',
  MK: 'Republic of North Macedonia',
  ML: 'Mali',
  MN: 'Mongolia',
  MO: 'Macao',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NP: 'Nepal',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PM: 'Saint Pierre and Miquelon',
  PR: 'Puerto Rico',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RU: 'Российская Федерация',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SZ: 'Eswatini',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania, United Republic of',
  UA: 'Ukraine',
  UG: 'Uganda',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Holy See',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela (Bolivarian Republic of)',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  VU: 'Vanuatu',
  WS: 'Samoa',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AE: 'United Arab Emirates',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  SX: 'Sint Maarten (Dutch part)',
};

export const countryNamesAlpha3 = {
  GBR: 'United Kingdom',
  USA: 'United States',
  AUS: 'Australia',
  AUT: 'Austria',
  BEL: 'Belgium',
  BGR: 'Bulgaria',
  CYP: 'Cyprus',
  CZE: 'Česká Republika',
  DNK: 'Danmark',
  EST: 'Estonia',
  FIN: 'Suomi',
  FRA: 'France',
  DEU: 'Deutschland',
  GRC: 'Ελλάδα',
  HUN: 'Hungary',
  IRL: 'Ireland',
  ITA: 'Italia',
  LVA: 'Latvia',
  LTU: 'Lietuva',
  LUX: 'Luxembourg',
  MLT: 'Malta',
  NLD: 'Nederland',
  POL: 'Poland',
  PRT: 'Portugal',
  ROU: 'Romania',
  SVK: 'Slovensko',
  SVN: 'Slovenia',
  ESP: 'España',
  SWE: 'Sverige',
  ISL: 'Iceland',
  LIE: 'Liechtenstein',
  NOR: 'Norway',
  CHE: 'Switzerland',
  MCO: 'Monaco',
  HRV: 'Croatia',
  JPN: '日本',
  CHN: '中国',
  SGP: 'Singapore',
  VNM: 'Viet Nam',
  SRB: 'Serbia',
  AND: 'Andorra',
  AFG: 'Afghanistan',
  ATG: 'Antigua and Barbuda',
  AIA: 'Anguilla',
  ALB: 'Albania',
  ARM: 'Armenia',
  AGO: 'Angola',
  ARG: 'Argentina',
  ASM: 'American Samoa',
  ABW: 'Aruba',
  AZE: 'Azerbaijan',
  BIH: 'Bosnia and Herzegovina',
  BRB: 'Barbados',
  BGD: 'Bangladesh',
  BFA: 'Burkina Faso',
  BHR: 'Bahrain',
  BDI: 'Burundi',
  BEN: 'Benin',
  BMU: 'Bermuda',
  BRN: 'Brunei Darussalam',
  BOL: 'Bolivia (Plurinational State of)',
  BRA: 'Brazil',
  BHS: 'Bahamas',
  BTN: 'Bhutan',
  BWA: 'Botswana',
  BLR: 'Belarus',
  BLZ: 'Belize',
  CAN: 'Canada',
  COD: 'Congo (the Democratic Republic of the)',
  CAF: 'Central African Republic',
  COG: 'Congo',
  CIV: "Côte d'Ivoire",
  COK: 'Cook Islands',
  CHL: 'Chile',
  CMR: 'Cameroon',
  COL: 'Colombia',
  CRI: 'Costa Rica',
  CPV: 'Cabo Verde',
  DJI: 'Djibouti',
  DMA: 'Dominica',
  DOM: 'Dominican Republic',
  DZA: 'Algeria',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  ERI: 'Eritrea',
  ETH: 'Ethiopia',
  FJI: 'Fiji',
  FLK: 'Falkland Islands [Malvinas]',
  FRO: 'Faroe Islands',
  GAB: 'Gabon',
  GRD: 'Grenada',
  GEO: 'Georgia',
  GUF: 'French Guiana',
  GGY: 'Guernsey',
  GHA: 'Ghana',
  GIB: 'Gibraltar',
  GRL: 'Greenland',
  GMB: 'Gambia',
  GIN: 'Guinea',
  GLP: 'Guadeloupe',
  GNQ: 'Equatorial Guinea',
  GTM: 'Guatemala',
  GUM: 'Guam',
  GNB: 'Guinea-Bissau',
  GUY: 'Guyana',
  HKG: 'Hong Kong',
  HND: 'Honduras',
  HTI: 'Haiti',
  IDN: 'Indonesia',
  ISR: 'Israel',
  IMN: 'Isle of Man',
  IND: 'India',
  IRQ: 'Iraq',
  JEY: 'Jersey',
  JAM: 'Jamaica',
  JOR: 'Jordan',
  KEN: 'Kenya',
  KGZ: 'Kyrgyzstan',
  KHM: 'Cambodia',
  KIR: 'Kiribati',
  COM: 'Comoros',
  KNA: 'Saint Kitts and Nevis',
  KOR: '대한민국',
  KWT: 'Kuwait',
  CYM: 'Cayman Islands',
  KAZ: 'Kazakhstan',
  LAO: "Lao People's Democratic Republic",
  LBN: 'Lebanon',
  LCA: 'Saint Lucia',
  LKA: 'Sri Lanka',
  LBR: 'Liberia',
  LSO: 'Lesotho',
  LBY: 'Libya',
  MAR: 'Morocco',
  MDA: 'Moldova (the Republic of)',
  MNE: 'Montenegro',
  MDG: 'Madagascar',
  MKD: 'Republic of North Macedonia',
  MLI: 'Mali',
  MNG: 'Mongolia',
  MAC: 'Macao',
  MTQ: 'Martinique',
  MRT: 'Mauritania',
  MSR: 'Montserrat',
  MUS: 'Mauritius',
  MDV: 'Maldives',
  MWI: 'Malawi',
  MEX: 'Mexico',
  MYS: 'Malaysia',
  MOZ: 'Mozambique',
  NAM: 'Namibia',
  NCL: 'New Caledonia',
  NER: 'Niger',
  NGA: 'Nigeria',
  NIC: 'Nicaragua',
  NPL: 'Nepal',
  NZL: 'New Zealand',
  OMN: 'Oman',
  PAN: 'Panama',
  PER: 'Peru',
  PYF: 'French Polynesia',
  PNG: 'Papua New Guinea',
  PHL: 'Philippines',
  PAK: 'Pakistan',
  SPM: 'Saint Pierre and Miquelon',
  PRI: 'Puerto Rico',
  PRY: 'Paraguay',
  QAT: 'Qatar',
  REU: 'Réunion',
  RUS: 'Российская Федерация',
  RWA: 'Rwanda',
  SAU: 'Saudi Arabia',
  SLB: 'Solomon Islands',
  SYC: 'Seychelles',
  SLE: 'Sierra Leone',
  SMR: 'San Marino',
  SEN: 'Senegal',
  SOM: 'Somalia',
  SUR: 'Suriname',
  STP: 'Sao Tome and Principe',
  SLV: 'El Salvador',
  SWZ: 'Eswatini',
  TCA: 'Turks and Caicos Islands',
  TCD: 'Chad',
  TGO: 'Togo',
  THA: 'Thailand',
  TJK: 'Tajikistan',
  TLS: 'Timor-Leste',
  TKM: 'Turkmenistan',
  TUN: 'Tunisia',
  TON: 'Tonga',
  TUR: 'Turkey',
  TTO: 'Trinidad and Tobago',
  TUV: 'Tuvalu',
  TWN: 'Taiwan',
  TZA: 'Tanzania, United Republic of',
  UKR: 'Ukraine',
  UGA: 'Uganda',
  URY: 'Uruguay',
  UZB: 'Uzbekistan',
  VAT: 'Holy See',
  VCT: 'Saint Vincent and the Grenadines',
  VEN: 'Venezuela (Bolivarian Republic of)',
  VGB: 'Virgin Islands (British)',
  VIR: 'Virgin Islands (U.S.)',
  VUT: 'Vanuatu',
  WSM: 'Samoa',
  YEM: 'Yemen',
  MYT: 'Mayotte',
  ZAF: 'South Africa',
  ZMB: 'Zambia',
  ZWE: 'Zimbabwe',
  ARE: 'United Arab Emirates',
  BES: 'Bonaire, Sint Eustatius and Saba',
  SXM: 'Sint Maarten (Dutch part)',
};

export const countryCodeConversion: {
  [key in keyof typeof countryNamesAlpha2]: string;
} = {
  GB: 'GBR',
  US: 'USA',
  AU: 'AUS',
  AT: 'AUT',
  BE: 'BEL',
  BG: 'BGR',
  CY: 'CYP',
  CZ: 'CZE',
  DK: 'DNK',
  EE: 'EST',
  FI: 'FIN',
  FR: 'FRA',
  DE: 'DEU',
  GR: 'GRC',
  HU: 'HUN',
  IE: 'IRL',
  IT: 'ITA',
  LV: 'LVA',
  LT: 'LTU',
  LU: 'LUX',
  MT: 'MLT',
  NL: 'NLD',
  PL: 'POL',
  PT: 'PRT',
  RO: 'ROU',
  SK: 'SVK',
  SI: 'SVN',
  ES: 'ESP',
  SE: 'SWE',
  IS: 'ISL',
  LI: 'LIE',
  NO: 'NOR',
  CH: 'CHE',
  MC: 'MCO',
  HR: 'HRV',
  JP: 'JPN',
  CN: 'CHN',
  SG: 'SGP',
  VN: 'VNM',
  RS: 'SRB',
  AD: 'AND',
  AF: 'AFG',
  AG: 'ATG',
  AI: 'AIA',
  AL: 'ALB',
  AM: 'ARM',
  AO: 'AGO',
  AR: 'ARG',
  AS: 'ASM',
  AW: 'ABW',
  AZ: 'AZE',
  BA: 'BIH',
  BB: 'BRB',
  BD: 'BGD',
  BF: 'BFA',
  BH: 'BHR',
  BI: 'BDI',
  BJ: 'BEN',
  BM: 'BMU',
  BN: 'BRN',
  BO: 'BOL',
  BR: 'BRA',
  BS: 'BHS',
  BT: 'BTN',
  BW: 'BWA',
  BY: 'BLR',
  BZ: 'BLZ',
  CA: 'CAN',
  CD: 'COD',
  CF: 'CAF',
  CG: 'COG',
  CI: 'CIV',
  CK: 'COK',
  CL: 'CHL',
  CM: 'CMR',
  CO: 'COL',
  CR: 'CRI',
  CV: 'CPV',
  DJ: 'DJI',
  DM: 'DMA',
  DO: 'DOM',
  DZ: 'DZA',
  EC: 'ECU',
  EG: 'EGY',
  ER: 'ERI',
  ET: 'ETH',
  FJ: 'FJI',
  FK: 'FLK',
  FO: 'FRO',
  GA: 'GAB',
  GD: 'GRD',
  GE: 'GEO',
  GF: 'GUF',
  GG: 'GGY',
  GH: 'GHA',
  GI: 'GIB',
  GL: 'GRL',
  GM: 'GMB',
  GN: 'GIN',
  GP: 'GLP',
  GQ: 'GNQ',
  GT: 'GTM',
  GU: 'GUM',
  GW: 'GNB',
  GY: 'GUY',
  HK: 'HKG',
  HN: 'HND',
  HT: 'HTI',
  ID: 'IDN',
  IL: 'ISR',
  IM: 'IMN',
  IN: 'IND',
  IQ: 'IRQ',
  JE: 'JEY',
  JM: 'JAM',
  JO: 'JOR',
  KE: 'KEN',
  KG: 'KGZ',
  KH: 'KHM',
  KI: 'KIR',
  KM: 'COM',
  KN: 'KNA',
  KR: 'KOR',
  KW: 'KWT',
  KY: 'CYM',
  KZ: 'KAZ',
  LA: 'LAO',
  LB: 'LBN',
  LC: 'LCA',
  LK: 'LKA',
  LR: 'LBR',
  LS: 'LSO',
  LY: 'LBY',
  MA: 'MAR',
  MD: 'MDA',
  ME: 'MNE',
  MG: 'MDG',
  MK: 'MKD',
  ML: 'MLI',
  MN: 'MNG',
  MO: 'MAC',
  MQ: 'MTQ',
  MR: 'MRT',
  MS: 'MSR',
  MU: 'MUS',
  MV: 'MDV',
  MW: 'MWI',
  MX: 'MEX',
  MY: 'MYS',
  MZ: 'MOZ',
  NA: 'NAM',
  NC: 'NCL',
  NE: 'NER',
  NG: 'NGA',
  NI: 'NIC',
  NP: 'NPL',
  NZ: 'NZL',
  OM: 'OMN',
  PA: 'PAN',
  PE: 'PER',
  PF: 'PYF',
  PG: 'PNG',
  PH: 'PHL',
  PK: 'PAK',
  PM: 'SPM',
  PR: 'PRI',
  PY: 'PRY',
  QA: 'QAT',
  RE: 'REU',
  RU: 'RUS',
  RW: 'RWA',
  SA: 'SAU',
  SB: 'SLB',
  SC: 'SYC',
  SL: 'SLE',
  SM: 'SMR',
  SN: 'SEN',
  SO: 'SOM',
  SR: 'SUR',
  ST: 'STP',
  SV: 'SLV',
  SZ: 'SWZ',
  TC: 'TCA',
  TD: 'TCD',
  TG: 'TGO',
  TH: 'THA',
  TJ: 'TJK',
  TL: 'TLS',
  TM: 'TKM',
  TN: 'TUN',
  TO: 'TON',
  TR: 'TUR',
  TT: 'TTO',
  TV: 'TUV',
  TW: 'TWN',
  TZ: 'TZA',
  UA: 'UKR',
  UG: 'UGA',
  UY: 'URY',
  UZ: 'UZB',
  VA: 'VAT',
  VC: 'VCT',
  VE: 'VEN',
  VG: 'VGB',
  VI: 'VIR',
  VU: 'VUT',
  WS: 'WSM',
  YE: 'YEM',
  YT: 'MYT',
  ZA: 'ZAF',
  ZM: 'ZMB',
  ZW: 'ZWE',
  AE: 'ARE',
  BQ: 'BES',
  SX: 'SXM',
};

export const countryCodeConversionReverse: {
  [key in keyof typeof countryNamesAlpha3]: string;
} = {
  GBR: 'GB',
  USA: 'US',
  AUS: 'AU',
  AUT: 'AT',
  BEL: 'BE',
  BGR: 'BG',
  CYP: 'CY',
  CZE: 'CZ',
  DNK: 'DK',
  EST: 'EE',
  FIN: 'FI',
  FRA: 'FR',
  DEU: 'DE',
  GRC: 'GR',
  HUN: 'HU',
  IRL: 'IE',
  ITA: 'IT',
  LVA: 'LV',
  LTU: 'LT',
  LUX: 'LU',
  MLT: 'MT',
  NLD: 'NL',
  POL: 'PL',
  PRT: 'PT',
  ROU: 'RO',
  SVK: 'SK',
  SVN: 'SI',
  ESP: 'ES',
  SWE: 'SE',
  ISL: 'IS',
  LIE: 'LI',
  NOR: 'NO',
  CHE: 'CH',
  MCO: 'MC',
  HRV: 'HR',
  JPN: 'JP',
  CHN: 'CN',
  SGP: 'SG',
  VNM: 'VN',
  SRB: 'RS',
  AND: 'AD',
  AFG: 'AF',
  ATG: 'AG',
  AIA: 'AI',
  ALB: 'AL',
  ARM: 'AM',
  AGO: 'AO',
  ARG: 'AR',
  ASM: 'AS',
  ABW: 'AW',
  AZE: 'AZ',
  BIH: 'BA',
  BRB: 'BB',
  BGD: 'BD',
  BFA: 'BF',
  BHR: 'BH',
  BDI: 'BI',
  BEN: 'BJ',
  BMU: 'BM',
  BRN: 'BN',
  BOL: 'BO',
  BRA: 'BR',
  BHS: 'BS',
  BTN: 'BT',
  BWA: 'BW',
  BLR: 'BY',
  BLZ: 'BZ',
  CAN: 'CA',
  COD: 'CD',
  CAF: 'CF',
  COG: 'CG',
  CIV: 'CI',
  COK: 'CK',
  CHL: 'CL',
  CMR: 'CM',
  COL: 'CO',
  CRI: 'CR',
  CPV: 'CV',
  DJI: 'DJ',
  DMA: 'DM',
  DOM: 'DO',
  DZA: 'DZ',
  ECU: 'EC',
  EGY: 'EG',
  ERI: 'ER',
  ETH: 'ET',
  FJI: 'FJ',
  FLK: 'FK',
  FRO: 'FO',
  GAB: 'GA',
  GRD: 'GD',
  GEO: 'GE',
  GUF: 'GF',
  GGY: 'GG',
  GHA: 'GH',
  GIB: 'GI',
  GRL: 'GL',
  GMB: 'GM',
  GIN: 'GN',
  GLP: 'GP',
  GNQ: 'GQ',
  GTM: 'GT',
  GUM: 'GU',
  GNB: 'GW',
  GUY: 'GY',
  HKG: 'HK',
  HND: 'HN',
  HTI: 'HT',
  IDN: 'ID',
  ISR: 'IL',
  IMN: 'IM',
  IND: 'IN',
  IRQ: 'IQ',
  JEY: 'JE',
  JAM: 'JM',
  JOR: 'JO',
  KEN: 'KE',
  KGZ: 'KG',
  KHM: 'KH',
  KIR: 'KI',
  COM: 'KM',
  KNA: 'KN',
  KOR: 'KR',
  KWT: 'KW',
  CYM: 'KY',
  KAZ: 'KZ',
  LAO: 'LA',
  LBN: 'LB',
  LCA: 'LC',
  LKA: 'LK',
  LBR: 'LR',
  LSO: 'LS',
  LBY: 'LY',
  MAR: 'MA',
  MDA: 'MD',
  MNE: 'ME',
  MDG: 'MG',
  MKD: 'MK',
  MLI: 'ML',
  MNG: 'MN',
  MAC: 'MO',
  MTQ: 'MQ',
  MRT: 'MR',
  MSR: 'MS',
  MUS: 'MU',
  MDV: 'MV',
  MWI: 'MW',
  MEX: 'MX',
  MYS: 'MY',
  MOZ: 'MZ',
  NAM: 'NA',
  NCL: 'NC',
  NER: 'NE',
  NGA: 'NG',
  NIC: 'NI',
  NPL: 'NP',
  NZL: 'NZ',
  OMN: 'OM',
  PAN: 'PA',
  PER: 'PE',
  PYF: 'PF',
  PNG: 'PG',
  PHL: 'PH',
  PAK: 'PK',
  SPM: 'PM',
  PRI: 'PR',
  PRY: 'PY',
  QAT: 'QA',
  REU: 'RE',
  RUS: 'RU',
  RWA: 'RW',
  SAU: 'SA',
  SLB: 'SB',
  SYC: 'SC',
  SLE: 'SL',
  SMR: 'SM',
  SEN: 'SN',
  SOM: 'SO',
  SUR: 'SR',
  STP: 'ST',
  SLV: 'SV',
  SWZ: 'SZ',
  TCA: 'TC',
  TCD: 'TD',
  TGO: 'TG',
  THA: 'TH',
  TJK: 'TJ',
  TLS: 'TL',
  TKM: 'TM',
  TUN: 'TN',
  TON: 'TO',
  TUR: 'TR',
  TTO: 'TT',
  TUV: 'TV',
  TWN: 'TW',
  TZA: 'TZ',
  UKR: 'UA',
  UGA: 'UG',
  URY: 'UY',
  UZB: 'UZ',
  VAT: 'VA',
  VCT: 'VC',
  VEN: 'VE',
  VGB: 'VG',
  VIR: 'VI',
  VUT: 'VU',
  WSM: 'WS',
  YEM: 'YE',
  MYT: 'YT',
  ZAF: 'ZA',
  ZMB: 'ZM',
  ZWE: 'ZW',
  ARE: 'AE',
  BES: 'BQ',
  SXM: 'SX',
};

export const countryPostalPatterns: {
  [key in keyof typeof countryNamesAlpha2]: RegExp | null;
} = {
  AF: /[0-9]{4}/g,
  AL: /(120|122)[0-9]{2}/g,
  DZ: /[0-9]{5}/g,
  AS: /[0-9]{5}/g,
  AD: /[0-9]{5}/g,
  AO: null,
  AI: /AI-2640/g,
  AG: /^\\d{5}(?:[-\\s]\\d{4})?$/g,
  AR: /[A-Z]{1}[0-9]{4}[A-Z]{3}/g,
  AM: /[0-9]{4}/g,
  AW: null,
  AU: /[0-9]{4}/g,
  AT: /[0-9]{4}/g,
  AZ: /[0-9]{4}/g,
  BS: null,
  BH: null,
  BD: /[0-9]{4}/g,
  BB: /BB[0-9]{5}/g,
  BY: /[0-9]{6}/g,
  BE: /[0-9]{4}/g,
  BZ: null,
  BJ: null,
  BM: /[A-Z]{2}[0-9]{2}/g,
  BT: /[0-9]{5}/g,
  BO: null,
  BQ: null,
  BA: /[0-9]{5}/g,
  BW: null,
  BR: /[0-9]{5}-[0-9]{3}/g,
  BN: /[A-Z]{2}[0-9]{4}/g,
  BG: /[0-9]{4}/g,
  BF: null,
  BI: null,
  KH: /[0-9]{5}/g,
  CM: null,
  CA: /[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]/g,
  CI: null,
  CV: /[0-9]{4}/g,
  KY: /[A-Z]{2}[0-9]-[0-9]{4}/g,
  CF: null,
  TD: null,
  CL: /[0-9]{7}/g,
  CN: /[0-9]{5}/g,
  CO: /[0-9]{6}/g,
  KM: null,
  CG: null,
  CD: null,
  CK: null,
  CR: /[0-9]{5}/g,
  HR: /[0-9]{5}/g,
  CY: /[0-9]{4}/g,
  CZ: /[0-9]{3} [0-9]{2}/g,
  DK: /[0-9]{5}/g,
  DJ: null,
  DM: null,
  DO: /[0-9]{5}/g,
  TL: null,
  EC: /[0-9]{6}/g,
  EG: /[0-9]{5}/g,
  SV: /[0-9]{4}/g,
  ER: null,
  EE: /[0-9]{5}/g,
  ET: /[0-9]{4}/g,
  FK: /FIQQ 1ZZ/g,
  FO: /[0-9]{3}/g,
  FJ: null,
  FI: /[0-9]{5}/g,
  FR: /[0-9]{5}/g,
  PF: /[0-9]{5}/g,
  GA: null,
  GM: null,
  GE: null,
  DE: /[0-9]{5}/g,
  GH: null,
  GI: /GX11 1AA/g,
  GR: /[0-9]{3} [0-9]{2}/g,
  GL: /[0-9]{4}/g,
  GD: null,
  GP: /971[0-9]{2}/g,
  GU: /^\\d{5}(?:[-\\s]\\d{4})?$/g,
  GT: /[0-9]{5}/g,
  GG: /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})/g,
  GW: /[0-9]{4}/g,
  GQ: null,
  GN: /[0-9]{3}/g,
  GY: null,
  GF: /973[0-9]{2}/g,
  HT: /[0-9]{4}/g,
  HN: /[0-9]{5}/g,
  HK: null,
  HU: /[0-9]{4}/g,
  IS: /[0-9]{3}/g,
  IN: /^[1-9][0-9]{5}$/g,
  ID: /[0-9]{5}/g,
  IQ: /[0-9]{5}/g,
  IE: /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/g,
  IL: /[0-9]{5}|[0-9]{7}/g,
  IT: /[0-9]{5}/g,
  JM: null,
  JP: /[0-9]{3}-[0-9]{4}/g,
  JE: /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})/g,
  JO: /[0-9]{5}/g,
  KZ: /[0-9]{6}/g,
  KE: /[0-9]{5}/g,
  KI: null,
  KR: /[0-9]{5}/g,
  KW: /[0-9]{5}/g,
  KG: /[0-9]{6}/g,
  LA: /[0-9]{5}/g,
  LV: /LV-[0-9]{4}/g,
  LB: /[0-9]{4} [0-9]{4}/g,
  LS: /[0-9]{3}/g,
  LR: /[0-9]{4}/g,
  LY: null,
  LI: /[0-9]{4}/g,
  LT: /LT-[0-9]{5}/g,
  LU: /[0-9]{4}/g,
  MO: null,
  MK: /[0-9]{4}/g,
  MG: /[0-9]{3}/g,
  MW: null,
  MY: /[0-9]{5}/g,
  MV: /[0-9]{5}/g,
  ML: null,
  MT: /[A-Z]{3} [0-9]{4}/g,
  MQ: /972[0-9]{2}/g,
  MR: null,
  MU: /[0-9]{5}/g,
  YT: /976[0-9]{2}/g,
  MX: /[0-9]{5}/g,
  MD: /MD-?[0-9]{4}/g,
  MC: /980[0-9]{2}/g,
  MN: /[0-9]{5}/g,
  ME: /[0-9]{5}/g,
  MS: /MSR [0-9]{4}/g,
  MA: /[0-9]{5}/g,
  MZ: /[0-9]{4}/g,
  NA: null,
  NP: /[0-9]{5}/g,
  NL: /^(?:NL-)?(\\d{4})\\s*([A-Z]{2})$/i,
  NC: /988[0-9]{2}/g,
  NZ: /[0-9]{4}/g,
  NI: null,
  NE: /[0-9]{4}/g,
  NG: /[0-9]{6}/g,
  NO: /[0-9]{4}/g,
  OM: /[0-9]{3}/g,
  PK: /[0-9]{5}/g,
  PA: /[0-9]{4}/g,
  PG: /[0-9]{3}/g,
  PY: /[0-9]{4}/g,
  PE: /[0-9]{5}/g,
  PH: /[0-9]{4}/g,
  PL: /[0-9]{2}-[0-9]{3}/g,
  PT: /[0-9]{4}-[0-9]{3}/g,
  PR: /^\\d{5}(?:[-\\s]\\d{4})?$/g,
  QA: null,
  RE: /974[0-9]{2}/g,
  RO: /[0-9]{6}/g,
  RU: /[0-9]{6}/g,
  RW: null,
  WS: /WS[0-9]{4}/g,
  ST: null,
  SA: /[0-9]{5}(-[0-9]{4})?/g,
  SN: /[0-9]{5}/g,
  RS: /[0-9]{5}/g,
  SC: null,
  SL: null,
  SG: /[0-9]{6}/g,
  SK: /[0-9]{3} [0-9]{2}/g,
  SI: /[0-9]{4}/g,
  SB: null,
  SO: /[A-Z]{2} [0-9]{5}/g,
  ZA: /[0-9]{4}/g,
  ES: /[0-9]{5}/g,
  LK: /[0-9]{4}/g,
  VI: /^\\d{5}(?:[-\\s]\\d{4})?$/g,
  SE: /[0-9]{3} [0-9]{2}/g,
  KN: /[A-Z]{2}[0-9]{4}/g,
  LC: /[A-Z]{2}[0-9]{2} [0-9]{3}/g,
  SX: null,
  VC: /VC[0-9]{4}/g,
  SR: null,
  SZ: /[A-Z]{1}[0-9]{3}/g,
  CH: /[0-9]{4}/g,
  TW: /[0-9]{3}(-[0-9]{2})?/g,
  TZ: /[0-9]{5}/g,
  TH: /[0-9]{5}/g,
  TG: null,
  TO: null,
  VG: /VG[0-9]{4}/g,
  TT: /[0-9]{6}/g,
  TN: /[0-9]{4}/g,
  TR: /[0-9]{5}/g,
  TM: /[0-9]{6}/g,
  TC: /TKCA 1ZZ/g,
  TV: null,
  UG: null,
  UA: /[0-9]{5}/g,
  AE: null,
  GB: /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/g,
  US: /^\\d{5}(?:[-\\s]\\d{4})?$/g,
  UY: /[0-9]{5}/g,
  UZ: /[0-9]{6}/g,
  VU: null,
  VE: /[0-9]{4}(-[A-Z]{1})?/g,
  VN: /[0-9]{6}/g,
  YE: null,
  ZM: /[0-9]{5}/g,
  ZW: null,
  IM: null,
  PM: null,
  SM: null,
  TJ: null,
  VA: null,
};

export const getCountryPostalPattern = (country: string) => {
  const countryCode = countryAlpha2Codes[country];
  if (countryPostalPatterns[countryCode] === undefined) return null;
  return countryPostalPatterns[countryCode] || null;
};

export const getCountryNameWithAlpha2 = (country: string) => {
  const countryCode = countryAlpha2Codes[country];
  return countryNamesAlpha2[countryCode] || '?';
};

export const getCountryNameWithAlpha3 = (country: string) => {
  const countryCode = countryAlpha3Codes[country];
  return countryNamesAlpha3[countryCode] || '?';
};

export const isPostalRequired = (country: string) => {
  const countryCode = countryAlpha2Codes[country];
  return getCountryPostalPattern(countryCode) !== null;
};

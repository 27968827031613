import styled from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';
import { GlobalFontFamily } from '../../styling/css/fontfamily';

export enum Variant {
  LIGHT = 'WHITE',
  PALE = 'GREY_LIGHT',
  TRANSLUCENT = '#ffffff25',
}

interface ContentCellProps {
  variant?: Variant;
  marginBottom?: boolean;
}

export const ContentCell = styled.div<ContentCellProps>`
  ${GlobalFontFamily};
  all: unset;
  position: relative;
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
  border-radius: ${px2rem(8)};
  display: flex;
  flex-direction: column;
  background-color: ${props =>
    props.variant
      ? props.theme.COLOUR[props.variant]
      : theme(props).COLOUR.BACKGROUND_LIGHTEST};
  padding: ${px2rem(15)};
  justify-content: flex-start;
  margin-bottom: ${props => (props.marginBottom ? px2rem(10) : '')};
  & > * {
    z-index: 1;
  }
`;

export const ContentCellInner = styled.div`
  box-sizing: border-box;
  color: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
`;

import { DispatchFn } from '../state';
import {
  NotificationActions,
  NotificationErrors,
  NotificationSubscriptionAPI,
} from './store';
import client, { Microservice } from '../../utils/http.utils';
import { captureException } from '../../utils/sentry.utils';

export const RequestGetNotificationsAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({
      type: NotificationActions.requestGetNotificationsAttempt,
    });

    const response = await client.request(
      Microservice.NOTIFICATION,
      `/notification`,
      'GET'
    );

    dispatch({
      type: NotificationActions.requestGetNotificationsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: NotificationActions.requestGetNotificationsFailure,
      payload: NotificationErrors.requestGetNotificationsError,
    });
  }
};

export const RequestPatchNotificationAsync = async (
  dispatch: DispatchFn,
  notificationId: string
): Promise<void> => {
  try {
    dispatch({
      type: NotificationActions.requestPatchNotificationAttempt,
    });

    const response = await client.request(
      Microservice.NOTIFICATION,
      `/notification/${notificationId}`,
      'PATCH'
    );

    dispatch({
      type: NotificationActions.requestPatchNotificationSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: NotificationActions.requestPatchNotificationFailure,
      payload: NotificationErrors.requestPatchNotificationError,
    });
  }
};

export const RequestPutNotificationsAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({
      type: NotificationActions.requestPutNotificationsAttempt,
    });

    const response = await client.request(
      Microservice.NOTIFICATION,
      `/notification`,
      'PUT'
    );

    dispatch({
      type: NotificationActions.requestPutNotificationsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: NotificationActions.requestPutNotificationsFailure,
      payload: NotificationErrors.requestPutNotificationsError,
    });
  }
};

export const RequestGetNotificationGroupsAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({
      type: NotificationActions.requestGetNotificationGroupsAttempt,
    });
    const response = await client.request(
      Microservice.NOTIFICATION,
      `/notification-group`,
      'GET'
    );
    dispatch({
      type: NotificationActions.requestGetNotificationGroupsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: NotificationActions.requestGetNotificationGroupsFailure,
      payload: NotificationErrors.requestGetNotificationGroupsError,
    });
  }
};

export const RequestGetSubscriptionTypesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  try {
    dispatch({
      type: NotificationActions.requestGetNotificationSubscriptionTypesAttempt,
    });
    const response = await client.request(
      Microservice.NOTIFICATION,
      `/notification-group-subscription-type`,
      'GET'
    );
    dispatch({
      type: NotificationActions.requestGetNotificationSubscriptionTypesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: NotificationActions.requestGetNotificationSubscriptionTypesFailure,
      payload: NotificationErrors.requestGetNotificationSubscriptionTypesError,
    });
  }
};
export const RequestGetNotificationSubscriptions = async (
  dispatch: DispatchFn,
  keycloakId: string
): Promise<void> => {
  try {
    dispatch({
      type: NotificationActions.requestGetNotificationSubscriptionsAttempt,
    });

    const response = await client.request(
      Microservice.NOTIFICATION,
      `/notification-group-subscription/${keycloakId}`,
      'GET'
    );
    dispatch({
      type: NotificationActions.requestGetNotificationSubscriptionsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: NotificationActions.requestGetNotificationSubscriptionsFailure,
      payload: NotificationErrors.requestGetNotificationSubscriptionsError,
    });
  }
};

export const RequestPutNotificationSubscriptions = async (
  dispatch: DispatchFn,
  keycloakId: string,
  updatedSubscriptions: NotificationSubscriptionAPI[]
): Promise<void> => {
  try {
    dispatch({
      type: NotificationActions.requestPutNotificationSubscriptionsAttempt,
    });

    const response = await client.request(
      Microservice.NOTIFICATION,
      `/notification-group-subscription/${keycloakId}`,
      'PUT',
      updatedSubscriptions
    );
    dispatch({
      type: NotificationActions.requestPutNotificationSubscriptionsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: NotificationActions.requestPutNotificationSubscriptionsFailure,
      payload: NotificationErrors.requestPutNotificationSubscriptionsError,
    });
  }
};

export const SetIsShowingNotifications = async (
  dispatch: DispatchFn,
  value: boolean
): Promise<void> => {
  dispatch({
    type: NotificationActions.setIsShowingNotifications,
    payload: value,
  });
};

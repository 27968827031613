/* eslint-disable @typescript-eslint/ban-types */

const getValueByKeyTyped =
  <T extends object, U extends keyof T>(key: U) =>
  (obj: T) =>
    obj[key];

// Grab a value within an object by its key. Because we can't have MyObject[myKey] in Typescript, now can we?
// If you ever feel like disabling the 'no implicit any' rule, this can be disposed of.
export const getValueByKey = (object: any, key: any) =>
  getValueByKeyTyped<typeof object, typeof key>(key)(object);

export const connectionTypeRequired = (
  partnership: string | undefined,
  facebookConnected: boolean,
  youtubeConnected: boolean,
  daysRemainingInstagram: number,
  daysRemainingYoutube: number
) => {
  if (partnership === 'instagram') {
    if (!facebookConnected || daysRemainingInstagram <= 7) return 'instagram';
  }
  if (partnership === 'youtube') {
    if (!youtubeConnected || daysRemainingYoutube <= 7) return 'youtube';
  }
  if (partnership === 'instagram_youtube') {
    if (
      (!youtubeConnected || daysRemainingYoutube <= 7) &&
      (!facebookConnected || daysRemainingInstagram <= 7)
    )
      return 'instagram_youtube';
    if (!facebookConnected || daysRemainingInstagram <= 7) return 'instagram';
    if (!youtubeConnected || daysRemainingYoutube <= 7) return 'youtube';
  }
  if (partnership === 'either') {
    if (
      (!youtubeConnected || daysRemainingYoutube <= 7) &&
      (!facebookConnected || daysRemainingInstagram <= 7)
    )
      return 'either';
  }
  return null;
};

export const shouldDisableInvitationActions = (
  partnership: string | undefined,
  facebookConnected: boolean,
  youtubeConnected: boolean,
  daysRemainingInstagram: number,
  daysRemainingYoutube: number
) => {
  if (partnership === 'instagram') {
    if (!facebookConnected || daysRemainingInstagram <= 0) return true;
  }
  if (partnership === 'youtube') {
    if (!youtubeConnected || daysRemainingYoutube <= 0) return true;
  }
  if (partnership === 'instagram_youtube') {
    if (
      !youtubeConnected ||
      !facebookConnected ||
      daysRemainingInstagram <= 0 ||
      daysRemainingYoutube <= 0
    )
      return true;
  }
  if (partnership === 'either') {
    if (
      (!youtubeConnected && !facebookConnected) ||
      (daysRemainingInstagram <= 0 && daysRemainingYoutube <= 0)
    )
      return true;
  }
  return false;
};

import {
  defaultSocialConnection,
  defaultSocialRequirement,
  UserActions,
} from './store';
import { Action } from '../state';
import {
  checkAssociationFeedbackFromApi,
  checkWithdrawalFeedbackFromApi,
  parseUserPermissionMap,
  transformAddressFromApi,
  transformAgentFromAPI,
  transformGetSocialRequirements,
  transformSocialConnection,
} from './transformers';
import { UserState } from './types';

export const UserReducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case UserActions.setKeycloakProfile:
      return {
        ...state,
        keycloakProfile: action.payload,
      };
    //request user stats
    case UserActions.requestUserStatisticsAttempt:
      return {
        ...state,
        isFetching: true,
        fetchError: null,
      };
    case UserActions.requestUserStatisticsSuccess:
      return {
        ...state,
        isFetching: false,
        userStatistics: action.payload,
        fetchError: null,
      };
    case UserActions.requestUserStatisticsFailure:
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload,
      };
    //request user
    case UserActions.requestUserAttempt:
      return {
        ...state,
        isFetchingUser: true,
        fetchUserError: null,
      };
    case UserActions.requestUserSuccess:
      return {
        ...state,
        isFetchingUser: false,
        user: {
          ...action.payload,
          influencer_address: transformAddressFromApi(
            action.payload.influencer_address
          ),
        },
      };
    case UserActions.requestUserFailure:
      return {
        ...state,
        isFetchingUser: false,
        fetchUserError: action.payload,
      };
    //register user
    case UserActions.registerUserAttempt:
      return {
        ...state,
        isRegisteringUser: true,
        registerUserError: null,
      };
    case UserActions.registerUserSuccess:
      return {
        ...state,
        isRegisteringUser: false,
      };
    case UserActions.registerUserFailure:
      return {
        ...state,
        isRegisteringUser: false,
        registerUserError: action.payload,
      };
    //put user attempt
    case UserActions.requestPutUserAttempt:
      return {
        ...state,
        isFetching: true,
      };
    case UserActions.requestPutUserSuccess:
      return {
        ...state,
        isFetching: false,
        user: action.payload,
      };
    case UserActions.requestPutUserFailure:
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload,
      };
    //get profile token
    case UserActions.requestGetProfileTokenAttempt:
      return {
        ...state,
        errors: {
          ...state.errors,
          permissions: null,
        },
      };
    case UserActions.requestGetProfileTokenSuccess:
      return {
        ...state,
        permissions: parseUserPermissionMap(action.payload),
      };
    case UserActions.requestGetProfileTokenFailure:
      return {
        ...state,
        errors: {
          ...state.errors,
          permissions: action.payload,
        },
      };
    case UserActions.requestGetSocialRequirementAttempt:
      return {
        ...state,
        socialRequirement: defaultSocialRequirement,
        errors: {
          ...state.errors,
          socialRequirement: null,
        },
        isLoading: {
          ...state.isLoading,
          socialRequirement: true,
        },
      };
    case UserActions.requestGetSocialRequirementSuccess:
      return {
        ...state,
        socialRequirement: transformGetSocialRequirements(action.payload),
        isLoading: {
          ...state.isLoading,
          socialRequirement: false,
        },
      };
    case UserActions.requestGetSocialRequirementFailure:
      return {
        ...state,
        socialRequirement: action.payload,
        errors: {
          ...state.errors,
          socialRequirement: action.payload,
        },
        isLoading: {
          ...state.isLoading,
          socialRequirement: false,
        },
      };
    case UserActions.requestPostAssociationFeedbackAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          postAssociationFeedback: true,
        },
        isAssociationFeedbackCompleted: false,
        errors: {
          ...state.errors,
          postAssociationFeedback: null,
        },
      };
    case UserActions.requestPostAssociationFeedbackSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          postAssociationFeedback: false,
        },
        isAssociationFeedbackCompleted: true,
      };
    case UserActions.requestPostAssociationFeedbackFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          postAssociationFeedback: false,
        },
        errors: {
          ...state.errors,
          postAssociationFeedback: action.payload,
        },
      };
    case UserActions.requestGetAssociationFeedbackAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getAssociationFeedback: true,
        },
        errors: {
          ...state.errors,
          getAssociationFeedback: null,
        },
      };
    case UserActions.requestGetAssociationFeedbackSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getAssociationFeedback: false,
        },
        showAssociationFeedback: !checkAssociationFeedbackFromApi(
          action.payload
        ),
      };
    case UserActions.requestGetAssociationFeedbackFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getAssociationFeedback: false,
        },
        errors: {
          ...state.errors,
          getAssociationFeedback: action.payload,
        },
      };
    case UserActions.requestPostWithdrawalFeedbackAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          postWithdrawalFeedback: true,
        },
        errors: {
          ...state.errors,
          postWithdrawalFeedback: null,
        },
      };
    case UserActions.requestPostWithdrawalFeedbackSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          postWithdrawalFeedback: false,
        },
        isWithdrawalFeedbackCompleted: true,
      };
    case UserActions.requestPostWithdrawalFeedbackFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          postWithdrawalFeedback: false,
        },
        errors: {
          ...state.errors,
          postWithdrawalFeedback: action.payload,
        },
      };
    case UserActions.requestGetWithdrawalFeedbackAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getWithdrawalFeedback: true,
        },
        errors: {
          ...state.errors,
          getWithdrawalFeedback: null,
        },
      };
    case UserActions.requestGetWithdrawalFeedbackSuccess:
      return {
        ...state,
        showWithdrawalFeedback: !checkWithdrawalFeedbackFromApi(action.payload),
        isLoading: {
          ...state.isLoading,
          getWithdrawalFeedback: false,
        },
      };
    case UserActions.requestGetWithdrawalFeedbackFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getWithdrawalFeedback: false,
        },
        errors: {
          ...state.errors,
          getWithdrawalFeedback: action.payload,
        },
      };
    case UserActions.setRegistrationComplete:
      return {
        ...state,
        isRegistrationComplete: action.payload,
      };
    case UserActions.requestGetAgentAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agent: true,
        },
        errors: {
          ...state.errors,
          agent: null,
        },
      };
    case UserActions.requestGetAgentSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agent: false,
        },
        agent: transformAgentFromAPI(action.payload),
      };
    case UserActions.requestGetAgentFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          agent: false,
        },
        errors: {
          ...state.errors,
          agent: action.payload,
        },
      };

    case UserActions.requestGetSocialConnectionAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          socialConnection: true,
        },
      };
    case UserActions.requestGetSocialConnectionSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          socialConnection: false,
        },
        socialConnection: transformSocialConnection(action.payload),
      };
    case UserActions.requestGetSocialConnectionFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          socialConnection: false,
        },
        socialConnection: defaultSocialConnection,
        errors: {
          ...state.errors,
          socialConnection: action.payload,
        },
      };
    case UserActions.requestGetInfluencerProgrammeIdsAttempt:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          marketplaceProgrammesIds: true,
        },
        errors: {
          ...state.errors,
          marketplaceProgrammesIds: null,
        },
      };
    case UserActions.requestGetInfluencerProgrammeIdsSuccess:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          marketplaceProgrammesIds: false,
        },
        marketplaceProgrammesIds: action.payload,
        errors: {
          ...state.errors,
          marketplaceProgrammesIds: null,
        },
      };
    case UserActions.requestGetInfluencerProgrammeIdsFailure:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          marketplaceProgrammesIds: false,
        },
        errors: {
          ...state.errors,
          marketplaceProgrammesIds: action.payload,
        },
      };
    default:
      return state;
  }
};

import React, { FC } from 'react';
import { LoadingPageContainer, LogoContainer } from './LoadingPage.styles';
import { Spinner, Logo } from '../../common';
import { useTranslationsController } from '../../../utils/globalTranslationsController';

const translationObject = {
  loadingPage: {
    'APP.LOGO.ALT': 'THG Society',
  },
};

export const LoadingPage: FC = () => {
  const { loadingPage: t } = useTranslationsController(translationObject);
  return (
    <LoadingPageContainer data-testid="t_loading_page">
      <LogoContainer>
        <Logo collapsed={false} altText={t['APP.LOGO.ALT']} />
      </LogoContainer>
      <Spinner size={60} colour="white" />
    </LoadingPageContainer>
  );
};

export const cookiesBannerTranslation = {
  cookiesBanner: {
    'CONSENTBANNER.ARIALABEL.ACCEPT':
      'This website uses cookies to enhance the user experience - I understand',
    'CONSENTBANNER.BUTTON.ACCEPT': 'I understand',
    'CONSENTBANNER.MAINTEXT.PREFIX': `Our partners and us collect data and use cookies, email pixels and similar
    tools to enhance your experience, analyse traffic and for ad personalisation and measurement.
    One such partner is Google. For more information see our`,
    'CONSENTBANNER.LINK.TEXT': 'cookie policy',
    'CONSENTBANNER.LINK.SUFFIX': '.',
  },
};

import styled, { css } from 'styled-components';
import { theme } from '../../styling/theme';
import { px2rem } from '../../styling/sizing';

const ButtonsWrapper = styled.div<{
  modalType: 'waitlist' | 'emailOpt';
  isMobile?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${props =>
    props.modalType === 'emailOpt' &&
    props.isMobile &&
    css`
      flex-direction: column-reverse;

      button {
        width: 100%;
      }
    `}

  gap: ${props => theme(props).SIZE.MEDIUM};
`;

const ModalText = styled.p`
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
  padding-bottom: ${props => theme(props).SIZE.SMALL};
`;

const ModalList = styled.ul`
  list-style: none;
  padding-bottom: ${px2rem(8)};
`;

const ModalListItem = styled.li`
  ${props => theme(props).TYPOGRAPHY.SMALL};
  display: flex;
  margin-bottom: ${props => theme(props).SIZE.MEDIUM};
  .icon {
    display: flex;
    align-items: center;
    width: ${px2rem(24)};
    margin-right: ${px2rem(4)};
  }
  .icon svg {
    width: ${px2rem(24)};
    margin-right: ${px2rem(8)};
    font-size: ${px2rem(18)};
  }
`;

const ModalEmailListItem = styled.li<{
  infoNoticeMargin?: boolean;
  textSize?: 'small' | 'medium1';
  border?: boolean;
}>`
  ${props =>
    props.textSize === 'medium1'
      ? theme(props).TYPOGRAPHY.MEDIUM_1
      : theme(props).TYPOGRAPHY.SMALL};
  display: flex;
  margin-bottom: ${props => theme(props).SIZE.MEDIUM};
  align-items: center;

  ${props =>
    props.border &&
    css`
      border: 1px solid ${props => theme(props).COLOUR.SECONDARY};
      border-radius: 6px;
      padding: ${props => theme(props).SIZE.SMALL} 0
        ${props => theme(props).SIZE.SMALL} 0;
    `}

  ${props =>
    props.infoNoticeMargin &&
    css`
      margin-bottom: ${px2rem(44)};
      margin-top: ${px2rem(34)};
    `}



  .icon {
    display: flex;
    align-items: center;
    width: ${px2rem(24)};
    margin-right: ${px2rem(24)};
  }

  .icon svg {
    width: ${px2rem(24)};
    margin-left: ${px2rem(12)};
    font-size: ${px2rem(28)};

    ${props =>
      props.border &&
      css`
        margin-left: ${px2rem(8)};
        width: auto;
      `}
  }
`;

export default {
  ButtonsWrapper,
  ModalText,
  ModalList,
  ModalListItem,
  ModalEmailListItem,
};

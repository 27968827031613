import ReactGA from 'react-ga4';

/**
 * Google Analytics Event Trigger
 *
 * When an Influencer registers their interest to join society
 */
const registerInterest = () => {
  ReactGA.event({
    category: 'Register Interest to join society',
    action: 'Influencer registers their interest to join society',
    label: 'Success',
  });
};

export default registerInterest;

import React from 'react';
import { LocationDescriptor } from 'history';
import {
  RouterLink,
  LinkIcon,
  LinkText,
  MenuContent,
  MenuButton,
  MenuContentSecondary,
  ExternalRouterLink,
} from './MenuItem.styles';
import { useRouteMatch } from 'react-router-dom';

export interface GenericMenuItemProps
  extends React.HTMLAttributes<HTMLElement> {
  link?: boolean;
  to?: LocationDescriptor;
  expanded?: boolean;
  active?: boolean;
  activeClassName?: string;
  exact?: boolean;
  clickHandler?: (e: any) => void;
  href?: string;
  matches?: string[];
}

interface MenuItemWithLabel extends GenericMenuItemProps {
  label: string;
  icon?: React.ReactElement;
}

interface MenuItemWithIcon extends GenericMenuItemProps {
  label?: string;
  icon: React.ReactElement;
}

type MenuItemProps =
  | MenuItemWithLabel
  | MenuItemWithIcon
  | (MenuItemWithLabel & MenuItemWithIcon);

const MenuItemContent: React.FC<MenuItemProps> = ({
  expanded,
  icon,
  label,
  ...props
}) => (
  <MenuContent {...props} aria-label={label} expanded={expanded}>
    {icon && <LinkIcon>{icon}</LinkIcon>}
    {label && expanded && <LinkText>{label}</LinkText>}
  </MenuContent>
);

const MenuItemSecondaryContent: React.FC<MenuItemProps> = ({
  expanded,
  label,
  ...props
}) => (
  <MenuContentSecondary {...props} aria-label={label} expanded={expanded}>
    {label && expanded && <LinkText>{label}</LinkText>}
  </MenuContentSecondary>
);

export const MenuItem: React.FC<MenuItemProps> = props => {
  const { link, to, exact, onClick, matches = [] } = props;
  const isMatch = !!useRouteMatch(matches);
  const isExactMatch = useRouteMatch(matches)?.isExact;
  const isSelected = exact ? isExactMatch : isMatch;
  return link && to ? (
    <RouterLink
      to={to}
      className={isSelected ? 'selected' : undefined}
      role="menuitem"
      data-testid={`t_${to}`}
    >
      <MenuItemContent {...props} />
    </RouterLink>
  ) : (
    <MenuButton onClick={onClick}>
      <MenuItemContent {...props} />
    </MenuButton>
  );
};

export const MenuItemSecondary: React.FC<MenuItemProps> = ({
  link,
  to,
  exact,
  onClick,
  ...props
}) =>
  link && to ? (
    <RouterLink
      to={to}
      activeClassName="selected"
      exact={exact}
      role="menuitem"
    >
      <MenuItemSecondaryContent {...props} />
    </RouterLink>
  ) : (
    <MenuButton onClick={onClick}>
      <MenuItemSecondaryContent {...props} />
    </MenuButton>
  );

export const ExternalMenuItemSecondary: React.FC<MenuItemProps> = ({
  link,
  href,
  onClick,
  ...props
}) =>
  link && href ? (
    <ExternalRouterLink href={href} target="_blank">
      <MenuItemSecondaryContent {...props} />
    </ExternalRouterLink>
  ) : (
    <MenuButton onClick={onClick}>
      <MenuItemSecondaryContent {...props} />
    </MenuButton>
  );

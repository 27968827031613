import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SideNavContainer,
  SidebarLogo,
  CollapseButton,
  Divider,
  MenuContainer,
  PagesContainer,
  PushDownDivider,
} from './SideNav.styles';
import { Logo } from '../../../common/Logo';
import {
  MenuItem,
  MenuItemSecondary,
  ExternalMenuItemSecondary,
} from './MenuItem/MenuItem';
import { useMenuState } from '../../../../hooks/';
import { LanguageSelect } from '../../../common';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';
import { sideNavTranslations } from './translations';
import marketplaceEvents from '../../../../analytics/gaEvents/Marketplace/MarketplaceRequests';

export const SideNav: FC<{
  logoutHandler: (e: any) => void;
  language?: string;
  showMarketplace: boolean;
}> = ({ logoutHandler, language = '', showMarketplace }) => {
  const [expanded, setExpanded] = useMenuState();
  const { sideNav: t } = useTranslationsController(sideNavTranslations);

  const homeText = t['APP.NAV.HOME'];
  const progText = t['APP.NAV.PROGRAMMES'];
  const campaignText = t['APP.NAV.CAMPAIGNS'];
  const marketplaceText = t['APP.NAV.MARKETPLACE'];
  const profileText = t['APP.NAV.PROFILE'];
  const walletText = t['APP.NAV.WALLET'];
  const settingsText = t['APP.NAV.SETTINGS'];
  const payText = t['APP.NAV.PAYMENT'];
  const logoutExpandedText = t['APP.NAV.LOGOUT.TEXT'];
  const logoutText = t['APP.NAV.LOGOUT'];
  const contactText = t['APP.NAV.CONTACT'];
  const termsText = t['APP.NAV.TERMS'];
  const privacyText = t['APP.NAV.PRIVACY'];
  const helpText = t['APP.NAV.HELP'];
  const toggleText = t['APP.NAV.TOGGLE'];

  return (
    <SideNavContainer
      open={expanded}
      data-testid="t_side_nav"
      role="navigation"
    >
      <SidebarLogo open={expanded}>
        <Logo collapsed={!expanded}></Logo>
      </SidebarLogo>
      <Divider />
      <MenuContainer expanded={expanded} role="menubar">
        <MenuItem
          title={expanded ? undefined : homeText}
          link
          to="/"
          icon={<FontAwesomeIcon icon={'home'} />}
          expanded={expanded}
          label={homeText}
          matches={['/']}
          exact
        />
        <MenuItem
          data-tour={`programmes_link`}
          title={expanded ? undefined : progText}
          link
          to="/programmes"
          icon={<FontAwesomeIcon icon={'bullhorn'} />}
          expanded={expanded}
          label={progText}
          matches={['/programmes']}
        />

        {window._env.campaigns && (
          <MenuItem
            data-tour={`campaigns_link`}
            title={expanded ? undefined : campaignText}
            link
            to="/campaigns"
            icon={<FontAwesomeIcon icon={'star'} />}
            expanded={expanded}
            label={campaignText}
            matches={['/campaigns']}
          />
        )}
        {showMarketplace && (
          <MenuItem
            data-tour={`marketplace_link`}
            title={expanded ? undefined : marketplaceText}
            link
            to="/connect"
            icon={<FontAwesomeIcon icon="shopping-bag" />}
            expanded={expanded}
            label={marketplaceText}
            matches={['/connect']}
            onClick={() => marketplaceEvents.openMarketplaceTabSuccess()}
          />
        )}
        <MenuItem
          data-tour={`profile_link`}
          title={expanded ? undefined : profileText}
          link
          to="/profile"
          icon={<FontAwesomeIcon icon={'user'} />}
          expanded={expanded}
          label={profileText}
          matches={['/profile']}
        />
        <MenuItem
          data-tour="wallet_link"
          title={expanded ? undefined : walletText}
          link
          to="/wallet"
          icon={<FontAwesomeIcon icon={'wallet'} />}
          expanded={expanded}
          label={walletText}
          matches={['/wallet']}
          exact
        />
        <MenuItem
          data-tour="accounts_link"
          title={expanded ? undefined : payText}
          link
          to="/wallet/bank_accounts"
          icon={<FontAwesomeIcon icon={'university'} />}
          expanded={expanded}
          label={payText}
          matches={['/wallet/bank_accounts', '/wallet/paypal']}
        />
        <MenuItem
          title={expanded ? undefined : settingsText}
          link
          to="/settings"
          icon={<FontAwesomeIcon icon={'cog'} />}
          expanded={expanded}
          label={settingsText}
          matches={['/settings']}
        />
        <PushDownDivider />
        {expanded && (
          <>
            <PagesContainer
              expanded={expanded}
              data-testid="t_extra_pages_side_nav"
            >
              <LanguageSelect isNavBar />
              <MenuItemSecondary
                title={contactText}
                link
                to="/contact"
                expanded={expanded}
                label={contactText}
              />
              <ExternalMenuItemSecondary
                title={termsText}
                link
                expanded={expanded}
                label={termsText}
                href={`/about/terms${language ? '.' + language : ''}.html`}
              />
              <ExternalMenuItemSecondary
                title={privacyText}
                link
                expanded={expanded}
                label={privacyText}
                href={`/about/privacy${language ? '.' + language : ''}.html`}
              />
              <ExternalMenuItemSecondary
                title={helpText}
                link
                expanded={expanded}
                label={helpText}
                href={`/help`}
              />
            </PagesContainer>
            <Divider />
          </>
        )}
        <MenuItem
          data-testid="t_side_menu_logout"
          title={expanded ? logoutExpandedText : logoutText}
          expanded={expanded}
          onClick={logoutHandler}
          label={logoutText}
          icon={<FontAwesomeIcon icon="sign-out-alt" />}
          matches={[]}
        />
      </MenuContainer>

      <CollapseButton
        aria-label={toggleText}
        onClick={() => setExpanded(!expanded)}
        open={expanded}
      >
        <FontAwesomeIcon icon={expanded ? 'chevron-left' : 'bars'} />
      </CollapseButton>
    </SideNavContainer>
  );
};

import { useState, useEffect } from 'react';
import {
  generateDateArray,
  getDaysInMonth,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../../../utils/date.utils';

const generateDefaultStartDate = (d: Date) =>
  new Date(new Date().setUTCFullYear(d.getUTCFullYear()));

export const useDateDropdown = (
  start = generateDefaultStartDate(new Date()),
  end = new Date(),
  initialDate: Date | null | undefined,
  onChange: ((d: Date) => void) | undefined
) => {
  const [displayDate, setDisplayDate] = useState<Date | undefined | null>(
    initialDate
  );
  const [dateString, setDateString] = useState<string | null>(null);

  const getYears = () => {
    const yearsArray = generateDateArray(start, end, 'YEAR');
    return yearsArray.map(dt => dt.getUTCFullYear());
  };

  const getDays = () => {
    if (!displayDate) return [];
    const daysArray = generateDateArray(
      getFirstDayOfMonth(
        displayDate.getUTCFullYear(),
        displayDate.getUTCMonth()
      ),
      getLastDayOfMonth(displayDate.getUTCFullYear(), displayDate.getUTCMonth())
    );
    return daysArray.map(dt => dt.getDate());
  };

  const selectHandler = (t: 'YEAR' | 'MONTH' | 'DAY', v: number) => {
    const newDate = displayDate || new Date();
    const day: number = newDate.getDate();
    if (t === 'YEAR') {
      setDisplayDate(new Date(newDate.setUTCFullYear(v)));
    }
    if (t === 'MONTH') {
      setDisplayDate(new Date(newDate.setUTCMonth(v) + 0));
    }
    if (t === 'DAY') setDisplayDate(new Date(newDate.setUTCDate(v)));
    else if (
      day > getDaysInMonth(newDate.getUTCFullYear(), newDate.getUTCMonth())
    ) {
      setDisplayDate(new Date(newDate.setUTCDate(1)));
    }
  };

  useEffect(() => {
    if (!displayDate) return;
    if (onChange && dateString !== displayDate.toDateString()) {
      onChange(displayDate);
      setDateString(displayDate.toDateString());
    }
  }, [displayDate, dateString, onChange]);

  return {
    displayDate,
    onSelect: selectHandler,
    years: getYears(),
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    days: getDays(),
  };
};

import styled from 'styled-components';
import { theme } from '../../styling/theme';

export const ButtonContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;

  button {
    margin-left: ${props => theme(props).SIZE.MEDIUM};
  }
`;

export const ErrorContainer = styled.div`
  margin: ${props => theme(props).SIZE.SMALL};
  color: ${props => theme(props).COLOUR.ALERT};
  width: 100%;
  display: flex;
  justify-content: flex-start;

  &:empty {
    display: none;
  }
`;

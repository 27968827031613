import { useState, useEffect } from 'react';
import { useStore } from '../../../store/state';
import { useResponsive } from '../../../hooks/useResponsive';
import { Breakpoint } from '../../styling/breakpoints';

export const useTopBar = (getState = useStore) => {
  const [isOpen, setIsOpen] = useState(false);
  const [{ userState }] = getState();
  const { layout } = useResponsive();

  const setOpenHandler = (e: boolean) => {
    setIsOpen(e);
  };

  useEffect(() => {
    if (layout < Breakpoint.DESKTOP) {
      setIsOpen(false);
    }
  }, [layout]);

  return {
    layout,
    isOpen,
    setOpenHandler,
    user: userState,
  };
};

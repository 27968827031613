import { captureException } from '@sentry/browser';
import { transformProgrammeApiToTitle } from '../../components/pages/agent/Wallet/transformers';
import client, { Microservice } from '../../utils/http.utils';
import { Programme } from './store';

export const RequestGetProgrammeImageAsync = async (
  imageId: string,
  withAuth = true
) => {
  try {
    const response = await client.requestMedia(
      Microservice.FILE,
      `/file/${imageId}`,
      'GET',
      withAuth
    );
    return response;
  } catch {
    return null;
  }
};

export const RequestGetProgrammeTitleAsync = async (
  id: string
): Promise<string> => {
  return await client
    .request<Programme>(Microservice.PROGRAMME, `/programme/${id}`, 'GET')
    .then(res => {
      return transformProgrammeApiToTitle(res);
    })
    .catch(e => {
      captureException(e);
      throw e;
    });
};

import client, { Microservice } from '../../utils/http.utils';
import { DispatchFn } from '../state';
import { YoutubeTokenPayload, CreateUserCodeResponse } from './store';
import { apiError, ErrorMessage } from '../../types/error.types';
import { captureException } from '../../utils/sentry.utils';

export enum SocialActions {
  requestPostFacebookTokenAttempt = 'REQUEST_FACEBOOK_TOKEN_ATTEMPT',
  requestPostFacebookTokenSuccess = 'REQUEST_FACEBOOK_TOKEN_SUCCESS',
  requestPostFacebookTokenFailure = 'REQUEST_FACEBOOK_TOKEN_FAILURE',

  requestGetFacebookStatusAttempt = 'REQUEST_USER_FACEBOOK_STATUS_ATTEMPT',
  requestGetFacebookStatusSuccess = 'REQUEST_USER_FACEBOOK_STATUS_SUCCESS',
  requestGetFacebookStatusFailure = 'REQUEST_USER_FACEBOOK_STATUS_FAILURE',

  requestPostYoutubeTokenAttempt = 'REQUEST_POST_YOUTUBE_TOKEN_ATTEMPT',
  requestPostYoutubeTokenSuccess = 'REQUEST_POST_YOUTUBE_TOKEN_SUCCESS',
  requestPostYoutubeTokenFailure = 'REQUEST_POST_YOUTUBE_TOKEN_FAILURE',

  requestGetYoutubeStatusAttempt = 'REQUEST_GET_YOUTUBE_STATUS_ATTEMPT',
  requestGetYoutubeStatusSuccess = 'REQUEST_GET_YOUTUBE_STATUS_SUCCESS',
  requestGetYoutubeStatusFailure = 'REQUEST_GET_YOUTUBE_STATUS_FAILURE',

  clearFacebookLoginError = 'CLEAR_FACEBOOK_LOGIN_ERROR',
}

export const RequestGetUserFacebookTokenStatusAsync = async (
  dispatch: DispatchFn,
  userId: string
): Promise<void> => {
  try {
    dispatch({ type: SocialActions.requestGetFacebookStatusAttempt });

    const response = await client.request(
      Microservice.INSTAGRAM,
      `/instagram/${userId}`,
      'GET'
    );

    dispatch({
      type: SocialActions.requestGetFacebookStatusSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    if (e.status === ErrorMessage.NOT_FOUND) {
      dispatch({
        type: SocialActions.requestGetFacebookStatusSuccess,
        payload: null,
      });
    } else {
      dispatch({
        type: SocialActions.requestGetFacebookStatusFailure,
        payload: 'An error occurred while fetching the Facebook connection.',
      });
    }
    throw apiError(e.status, e.message);
  }
};

export const RequestPostUserFacebookTokenAsync = async (
  dispatch: DispatchFn,
  token: string,
  userId: string
): Promise<void> => {
  try {
    dispatch({ type: SocialActions.requestPostFacebookTokenAttempt });

    const response = await client.request(
      Microservice.INSTAGRAM,
      `/instagram/${userId}`,
      'POST',
      {
        shortToken: token,
      }
    );
    dispatch({
      type: SocialActions.requestPostFacebookTokenSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: SocialActions.requestPostFacebookTokenFailure,
      payload:
        (e.status === ErrorMessage.BAD_REQUEST ||
          e.status === ErrorMessage.INTERNAL_SERVER_ERROR) &&
        e.customMessage['FacebookLogin']
          ? e.customMessage['FacebookLogin']
          : 'An error occurred while linking your Instagram account. Please try again.',
    });

    throw apiError(e.status, e.message);
  }
};

export const RequestPostYoutubeTokenAsync = async (
  dispatch: DispatchFn,
  token: YoutubeTokenPayload,
  influencerId: string
) => {
  try {
    dispatch({ type: SocialActions.requestPostYoutubeTokenAttempt });

    const response = await client.request<
      CreateUserCodeResponse,
      YoutubeTokenPayload
    >(Microservice.GOOGLE, `/user/${influencerId}/code`, 'POST', token);

    dispatch({
      type: SocialActions.requestPostYoutubeTokenSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: SocialActions.requestPostYoutubeTokenFailure,
      payload: e.customMessage['YoutubeCreateUserCode'],
    });
    throw new Error(
      e.customMessage['YoutubeCreateUserCode'] ||
        'An error occurred whilst connecting Youtube channel.'
    );
  }
};

export const RequestGetYoutubeStatusAsync = async (
  dispatch: DispatchFn,
  influencerId: string
) => {
  try {
    dispatch({ type: SocialActions.requestGetYoutubeStatusAttempt });

    const response = await client.request(
      Microservice.GOOGLE,
      `/influencer/${influencerId}/session`,
      'GET'
    );

    dispatch({
      type: SocialActions.requestGetYoutubeStatusSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    if (e.message === `${ErrorMessage.NOT_FOUND}`) {
      dispatch({
        type: SocialActions.requestGetYoutubeStatusSuccess,
        payload: null,
      });
    } else {
      dispatch({
        type: SocialActions.requestGetYoutubeStatusFailure,
        payload: 'An error occurred while fetching the YouTube connection.',
      });
      throw new Error();
    }
  }
};

export const SetClearFacebookLoginError = (dispatch: DispatchFn) => {
  dispatch({ type: SocialActions.clearFacebookLoginError });
};

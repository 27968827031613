import styled from 'styled-components';
import { ErrorOutline } from '@material-ui/icons';
import { theme } from '../../../styling/theme';
import { px2rem } from '../../../styling/sizing';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const Icon = styled(ErrorOutline)`
  color: ${props => theme(props).COLOUR.ALERT_DARK};
  width: ${px2rem(32)};
  height: auto;
  margin: ${props => theme(props).SIZE.SMALL};
`;

export const Text = styled.div`
  width: 60%;
`;

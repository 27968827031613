import React, { FC } from 'react';
import { InterestsPage, InterestsProps } from './types';
import {
  PrimaryButton,
  PrimaryButtonAlternate,
  SecondaryButton,
  TertiaryButtonAlternate,
} from '..';
import s from './styles/Interest.styles';
import { InterestsView } from './InterestsView';
import { Spacer } from '../Spacer/Spacer';
import { Breakpoint } from '../../styling/breakpoints';
import { useResponsive } from '../../../hooks';

export const Interests: FC<InterestsProps> = ({
  isFormValid,
  handleInterestsChange,
  handleNextButton,
  handleSubmit,
  handleCancel,
  disableForm,
  interests,
  maxTopicsReached,
  isRegistered = false,
  interestsChanged,
  handleLogout,
  headerText = `Select your interests`,
  subheaderText = `Choose between one and five topics. Don't worry, you can update
    them at any time from your profile.`,
  saveText = `Save & Continue`,
  cancelText = 'Cancel',
  updateText = `Update`,
  getNameHandler,
  page = InterestsPage.Profile,
  mode,
}) => {
  const { layout } = useResponsive();
  const isDesktop = layout >= Breakpoint.DESKTOP;

  return (
    <s.InterestsWrapper
      data-testid="t_interests"
      onSubmit={(e: any) => e.preventDefault()}
      isRegistered={isRegistered}
    >
      {!isRegistered && (
        <>
          <s.InterestsHeader>{headerText}</s.InterestsHeader>
          <s.InterestsSubheader>{subheaderText}</s.InterestsSubheader>
        </>
      )}

      <s.InterestContainer data-testid="t_interest_container">
        <s.InterestLayoutWrapper>
          <InterestsView
            interests={interests}
            handleInterestsChange={handleInterestsChange}
            maxTopicsReached={maxTopicsReached}
            getNameHandler={getNameHandler}
            disableForm={disableForm}
            page={page}
            mode={mode}
          />
          {!isRegistered && !!handleLogout ? (
            <s.InterestsActions isDesktop={isDesktop}>
              <TertiaryButtonAlternate
                data-testid="t_logout_button"
                aria-disabled={!isFormValid}
                onClick={e => {
                  e.preventDefault();
                  handleLogout();
                }}
                fullWidth={!isDesktop}
              >
                {cancelText}
              </TertiaryButtonAlternate>
              <Spacer amount={16} isVertical={!isDesktop} />
              <PrimaryButtonAlternate
                data-testid="t_next_button"
                disabled={!isFormValid}
                aria-disabled={!isFormValid}
                onClick={e => {
                  e.preventDefault();
                  handleNextButton && handleNextButton();
                }}
                fullWidth={!isDesktop}
              >
                {saveText}
              </PrimaryButtonAlternate>
            </s.InterestsActions>
          ) : (
            interestsChanged && (
              <s.InterestsAction isDesktop={isDesktop}>
                <SecondaryButton
                  data-testid="t_cancel_button"
                  disabled={disableForm}
                  aria-disabled={disableForm}
                  onClick={handleCancel}
                  fullWidth={!isDesktop}
                >
                  {cancelText}
                </SecondaryButton>
                <Spacer amount={16} isVertical={!isDesktop} />
                <PrimaryButton
                  data-testid="t_update_button"
                  disabled={disableForm ?? !isFormValid}
                  aria-disabled={disableForm ?? !isFormValid}
                  onClick={handleSubmit && (e => handleSubmit(e))}
                  fullWidth={!isDesktop}
                >
                  {updateText}
                </PrimaryButton>
              </s.InterestsAction>
            )
          )}
        </s.InterestLayoutWrapper>
      </s.InterestContainer>
    </s.InterestsWrapper>
  );
};

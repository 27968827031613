import React from 'react';
import { YoutubeLogin as Component } from './YoutubeLogin';
import { useYoutubeLogin } from './useYoutubeLogin';
import environment from '../../../../../environments/environment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useTranslationsController } from '../../../../../utils/globalTranslationsController';

const translationObject = {
  youtubeLogin: {
    'YOUTUBELOGIN.BUTTON.TEXT': 'Connect YouTube',
  },
};

interface YoutubeLoginProps {
  fullWidth?: boolean;
  monochrome?: boolean;
}
export const YoutubeLogin: React.FC<YoutubeLoginProps> = ({
  fullWidth,
  monochrome,
}) => {
  const props = useYoutubeLogin();
  const { youtubeLogin: t } = useTranslationsController(translationObject);
  return (
    <GoogleOAuthProvider clientId={environment.googleClientId}>
      <Component
        {...props}
        fullWidth={fullWidth}
        monochrome={monochrome}
        buttonText={t['YOUTUBELOGIN.BUTTON.TEXT']}
      />
    </GoogleOAuthProvider>
  );
};

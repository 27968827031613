import {
  GlobalAggregations,
  GlobalAggregationsApi,
  QueryAggregations,
  QueryAggregationsApi,
} from '../../components/pages/Marketplace/types';
import { Retailer } from './store';
import {
  MarketplaceFeedback,
  MarketplaceFeedbackApi,
  MarketplaceFeedbackFromApi,
} from './types';

export const transformMarketplaceFeedbackToApi = (
  payload: MarketplaceFeedback
): MarketplaceFeedbackApi | null => {
  if (
    payload.easierToFindProductsToPromote &&
    payload.likelyToRecommendConnect
  ) {
    return {
      easier_to_find_products_to_promote: payload.easierToFindProductsToPromote,
      likely_to_recommend: payload.likelyToRecommendConnect,
      improvements_or_changes: payload.improvementsOrChanges,
    };
  }
  return null;
};

export const checkMarketplaceFeedbackFromApi = (
  payload: MarketplaceFeedbackFromApi | null
): boolean => {
  if (payload && payload.created_at) {
    return true;
  }
  return false;
};

export const transformAggregationsFromApi = (
  payload: Record<string, never> | GlobalAggregationsApi | QueryAggregationsApi,
  retailers: Retailer[] | null
): Record<string, never> | GlobalAggregations | QueryAggregations => {
  if (Object.keys(payload).length === 0 || !retailers) {
    return {
      categoryGroupId: {},
      advertiserName: {},
      brands: {},
      merchantCategory: {},
    };
  }
  const aggregations = {
    categoryGroupId: payload.categoryGroupId,
    advertiserName: {},
    brands: {},
    merchantCategory: {},
  };
  const advertiserEntries = Object.entries(payload.advertiserId);
  if (advertiserEntries.length === 0) {
    return aggregations;
  }

  return {
    ...aggregations,
    advertiserName: Object.fromEntries(
      advertiserEntries.map(entry => {
        const retailer = retailers.find(e => entry[0] === e.id);
        return [retailer?.name, entry[1]];
      })
    ),
  };
};

import { StringMap } from '../../types';

export interface YoutubeTokenPayload {
  user_code: string;
}

export enum MediaType {
  image = 'image',
  video = 'video',
  story = 'story',
  carousel = 'carousel',
}

export interface YoutubeSession {
  access_token: string;
  refresh_token: string;
  influencer_id: string;
  needs_refresh: boolean;
  ignore_refresh: boolean;
}

export interface FacebookSession {
  handle: string;
  needs_refresh: boolean;
  ignore_refresh: boolean;
}

export interface SocialMap<T> {
  facebook: T;
  youtube: T;
}

export interface SocialState {
  facebook: FacebookSession | null;
  youtube: YoutubeSession | null;
  isInitialised: SocialMap<boolean>;
  isRequested: SocialMap<boolean>;
  isFetching: SocialMap<boolean>;
  isUpdating: SocialMap<boolean>;
  errors: SocialMap<string | null>;
}

export interface CreateUserCodeResponse {
  success: boolean;
  error_message?: StringMap<string>;
}

export const defaultSocialState: SocialState = {
  facebook: null,
  youtube: null,
  isInitialised: {
    facebook: false,
    youtube: false,
  },
  isRequested: {
    facebook: false,
    youtube: false,
  },
  isFetching: {
    facebook: false,
    youtube: false,
  },
  isUpdating: {
    facebook: false,
    youtube: false,
  },
  errors: {
    facebook: null,
    youtube: null,
  },
};

export * from './reducer';
export * from './actions';

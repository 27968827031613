import styled from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const ListeningContainer = styled.div`
  position: relative;
`;

export const DropDownActions = styled.div`
  width: ${px2rem(240)};
  right: 0;
  height: auto;
  max-height: ${px2rem(540)};
  margin-top: ${props => theme(props).SIZE.SMALL};
  background-color: white;
  border: 1px solid ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
  position: absolute;
  border-radius: ${props => theme(props).SIZE.SMALL};
  overflow-y: auto;
  z-index: 10;
`;

export const ActionsList = styled.ul`
  all: unset;

  & > li {
    border-bottom: 1px solid ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
  }

  & > li:last-of-type {
    border-bottom: none;
  }
`;

export const ActionListItem = styled.li`
  list-style-type: none;
  margin: 0;
`;

export const ActionListButton = styled.button`
  all: unset;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: ${props => theme(props).SIZE.SMALL};
  margin: 0;
  display: flex;
  justify-content: space-between;

  &:hover,
  &:focus {
    color: white;
    background-color: ${props => theme(props).COLOUR.PRIMARY_DARK};
  }
`;

import { CustomErrorMessage } from '../../types/error.types';

export enum Mode {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export interface FormValue<T = any> {
  value: T;
  isValid: boolean;
  isRequired: boolean;
}

export interface BankingLocale {
  availableCountries: string[];
  availableCurrencies: string[];
  currentCountry: string;
  currentCurrency: string;
}

export type AccountDetails = {
  [key: string]: string;
};

export interface BankAccount {
  id: string;
  details: AccountDetails;
  locale: {
    country: string;
    currency: string;
  };
}

export interface FormField {
  field_name: string;
  display_name: string;
  field_type: string;
  validation_regex: string;
  priority: number;
  options: {
    key: string;
    value: string;
  }[];
  rules: string[];
}

export type FormFields = FormField[];

export type FormValues = {
  [key: string]: FormValue;
};

export type APIPost = {
  values: [{ key: string; value: string }];
  country: string;
  currency: string;
  nickname: string;
};

export interface BankDetails {
  bank_details_id: string;
  country: string;
  currency: string;
  influencer_id: string;
  nickname: string;
  values: Record<string, never>;
}

export interface PaypalState {
  hasPaypalTx: boolean;
  hideNotice: boolean;
}

export interface BankingState {
  mode: Mode;
  currentIndex: number;
  accounts: BankAccount[];
  notices: Record<string, string[]>;
  paypalAccount: BankAccount | null;
  formFields: FormFields;
  formValues: FormValues;
  bankingLocale: BankingLocale;
  formNickname: string;
  duplicateCheck: boolean;
  isFetching: {
    bankNotices: boolean;
    bankAccounts: boolean;
    locale: boolean;
    paymentForm: boolean;
    paypal: boolean;
    duplicateCheck: boolean;
    putBankDetails: boolean;
  };
  error: {
    bankNotices: CustomErrorMessage | null;
    putBankDetails: string | null;
    bankAccounts: CustomErrorMessage | null;
    locale: string | null;
    paymentForm: string | null;
    paypal: string | null;
  };
  paypal: PaypalState;
}

import allsole from './images/allsole.png';
import ameliorate from './images/ameliorate.png';
import asda from './images/asda.png';
import christopheRobin from './images/christopheRobin.png';
import coggles from './images/coggles.png';
import cultBeauty from './images/cultBeauty.png';
import dufry from './images/dufry.png';
import elemis from './images/elemis.png';
import espa from './images/espa.png';
import eyekoLondon from './images/eyekoLondon.png';
import gardenOfLife from './images/gardenOfLife.png';
import glossyBox from './images/glossyBox.png';
import growGorgeous from './images/growGorgeous.png';
import hBeauty from './images/hBeauty.png';
import illamasqua from './images/illamasqua.png';
import lookFantastic from './images/lookFantastic.png';
import loreal from './images/loreal.png';
import mamaMio from './images/mamaMio.png';
import mio from './images/mio.png';
import myBag from './images/myBag.png';
import myProtein from './images/myProtein.png';
import myVegan from './images/myVegan.png';
import myVitamins from './images/myVitamins.png';
import perriconeMD from './images/perriconeMD.png';
import whiteStuff from './images/whiteStuff.png';

export default [
  {
    image: allsole,
    alt: 'Allsole logo',
  },
  {
    image: ameliorate,
    alt: 'Ameliorate logo',
  },
  {
    image: asda,
    alt: 'Asda logo',
  },
  {
    image: christopheRobin,
    alt: 'Christhope Robin logo',
  },
  {
    image: coggles,
    alt: 'Coggles logo',
  },
  {
    image: cultBeauty,
    alt: 'Cult Beauty logo',
  },
  {
    image: dufry,
    alt: 'Dufry logo',
  },
  {
    image: elemis,
    alt: 'Elemis logo',
  },
  {
    image: espa,
    alt: 'Espa logo',
  },
  {
    image: eyekoLondon,
    alt: 'Eyeko London logo',
  },
  {
    image: gardenOfLife,
    alt: 'Garden of Life logo',
  },
  {
    image: glossyBox,
    alt: 'Glossy Box logo',
  },
  {
    image: growGorgeous,
    alt: 'Grow Gorgeous logo',
  },
  {
    image: hBeauty,
    alt: 'H Beauty logo',
  },
  {
    image: illamasqua,
    alt: 'Illamasqua logo',
  },
  {
    image: lookFantastic,
    alt: 'Look Fantastic logo',
  },
  {
    image: loreal,
    alt: "L'Oréal logo",
  },
  {
    image: mamaMio,
    alt: 'Mama Mio logo',
  },
  {
    image: mio,
    alt: 'Mio logo',
  },
  {
    image: myBag,
    alt: 'My Bag logo',
  },
  {
    image: myProtein,
    alt: 'My Protein logo',
  },
  {
    image: myVegan,
    alt: 'My Vegan logo',
  },
  {
    image: myVitamins,
    alt: 'My Vitamins logo',
  },
  {
    image: perriconeMD,
    alt: 'Perricone MD logo',
  },
  {
    image: whiteStuff,
    alt: 'White Stuff logo',
  },
];

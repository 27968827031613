import { Agent, AgentAPI } from '../../../../store/User/types';
import client, { Microservice } from '../../../../utils/http.utils';
import { captureException } from '../../../../utils/sentry.utils';

const RequestInitialiseAgentAsync = async (
  agent: Agent,
  firstName: string,
  lastName: string
) => {
  const body: Partial<AgentAPI> = {
    first_name: firstName,
    last_name: lastName,
    id: agent.id,
    agency_id: agent.agencyId,
    keycloak_id: agent.keycloakId,
  };
  try {
    await client.request(Microservice.AGENCY, `/agent/initialise`, 'PUT', body);
  } catch (e) {
    captureException(e);
    throw e;
  }
};

export default {
  RequestInitialiseAgentAsync,
};

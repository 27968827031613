import React, { ReactNode, lazy } from 'react';
import SocietyLandingPage from './components/pages/noauth/SocietyLandingPage/SocietyLandingPage';
const AgentInfluencer = lazy(
  () => import('./components/pages/agent/InfluencerView/AgentInfluencer')
);
const AgentDashboard = lazy(
  () => import('./components/pages/agent/Dashboard/AgentDashboard')
);
const Influencers = lazy(() => import('./components/pages/agent/Influencers'));

const AgentPaymentAccounts = lazy(
  () => import('./components/pages/agent/PaymentAccounts/PaymentAccounts')
);
const AgentSettings = lazy(
  () => import('./components/pages/agent/Settings/AgentSettings')
);
const AgentWallet = lazy(
  () => import('./components/pages/agent/Wallet/AgentWallet')
);
const AgentProgrammeView = lazy(
  () =>
    import(
      './components/pages/agent/InfluencerView/Collaborations/ProgrammeView/ProgrammeView'
    )
);
const AgentCampaignView = lazy(
  () =>
    import(
      './components/pages/agent/InfluencerView/Collaborations/CampaignView/CampaignView'
    )
);

const AgentProfile = lazy(
  () => import('./components/pages/agent/Profile/Profile')
);

const Dashboard = lazy(() => import('./components/pages/Dashboard'));
const Application = lazy(() => import('./components/pages/Application'));
const Contact = lazy(() => import('./components/pages/Contact'));
const HelpCentre = lazy(() => import('./components/pages/HelpCentre'));
const Invitation = lazy(() => import('./components/pages/Invitation'));
const UserProfile = lazy(() => import('./components/pages/Profile'));
const Programme = lazy(() => import('./components/pages/Programme'));
const Programmes = lazy(() => import('./components/pages/Programmes'));
const Campaigns = lazy(() => import('./components/pages/Campaigns'));
const Campaign = lazy(() => import('./components/pages/Campaign'));
const Settings = lazy(() => import('./components/pages/Settings/Settings'));
const Wallet = lazy(() => import('./components/pages/Wallet'));
const Marketplace = lazy(
  () => import('./components/pages/Marketplace/Marketplace')
);
const InvitationCampaign = lazy(
  () => import('./components/pages/InvitationCampaign')
);
const AgencyApplication = lazy(
  () => import('./components/pages/AgencyApplication')
);
const PaymentAccounts = lazy(
  () => import('./components/pages/PaymentAccounts')
);

export interface AppRoute {
  path: string;
  component: ReactNode;
  container: boolean;
  exact: boolean;
  pageName: string;
  redirect?: {
    from: string;
    to: string;
    exact: boolean;
  };
}

export interface RoutePairs {
  [index: string]: AppRoute;
}

export const appRoutes: RoutePairs = {
  home: {
    path: '/',
    component: <Dashboard />,
    container: true,
    exact: true,
    pageName: 'Dashboard',
  },
  profile: {
    path: '/profile/:tab',
    component: <UserProfile />,
    container: true,
    exact: true,
    pageName: 'Profile',
  },
  profileDefaultTab: {
    path: '',
    component: null,
    container: true,
    exact: true,
    pageName: 'Profile',
    redirect: {
      from: '/profile',
      to: '/profile/details',
      exact: true,
    },
  },
  wallet: {
    path: '/wallet',
    component: <Wallet />,
    container: true,
    exact: true,
    pageName: 'Wallet',
  },
  bankAccounts: {
    path: '/wallet/:tab',
    component: <PaymentAccounts />,
    container: true,
    exact: true,
    pageName: 'Bank Accounts',
  },
  invite: {
    path: '/invitation/:invitationId',
    component: <Invitation />,
    container: true,
    exact: true,
    pageName: 'Invitation',
  },
  inviteCampaign: {
    path: '/invitation/:invitationId/campaign',
    component: <InvitationCampaign />,
    container: true,
    exact: true,
    pageName: 'Campaign Invitation',
  },
  programmes: {
    path: '/programmes',
    component: <Programmes />,
    container: true,
    exact: true,
    pageName: 'Programmes',
  },
  programmeInvite: {
    path: '/programmes/:programmeId/invitation/:invitationId',
    component: <Programme />,
    container: true,
    exact: true,
    pageName: 'Programme Invitation',
  },
  programme: {
    path: '/programmes/:programmeId/:tab',
    component: <Programme />,
    container: true,
    exact: true,
    pageName: 'Programme',
  },
  campaignDefaultTab: {
    path: '',
    component: null,
    container: true,
    exact: true,
    pageName: 'Campaign',
    redirect: {
      from: '/programmes/:programmeId/campaigns/:campaignId',
      to: '/programmes/:programmeId/campaigns/:campaignId/overview',
      exact: true,
    },
  },
  programmeDefaultTab: {
    path: '',
    component: null,
    container: true,
    exact: true,
    pageName: 'Programme',
    redirect: {
      from: '/programmes/:programmeId',
      to: '/programmes/:programmeId/overview',
      exact: true,
    },
  },
  contact: {
    path: '/contact',
    component: <Contact />,
    container: true,
    exact: true,
    pageName: 'Contact',
  },

  campaigns: {
    path: '/campaigns',
    component: <Campaigns />,
    container: true,
    exact: true,
    pageName: 'Campaigns',
  },
  settings: {
    path: '/settings/:tab',
    component: <Settings />,
    exact: true,
    container: true,
    pageName: 'Settings',
  },
  settingsDefaultTab: {
    path: '',
    component: null,
    container: true,
    exact: true,
    pageName: 'Settings',
    redirect: {
      from: '/settings',
      to: '/settings/notifications',
      exact: true,
    },
  },
};

export const noAuthRoutes: RoutePairs = {
  societyLandingPage: {
    path: '/apply',
    component: <SocietyLandingPage />,
    container: false,
    exact: true,
    pageName: 'Welcome to Society',
  },
  help: {
    path: '/help',
    component: <HelpCentre />,
    container: false,
    exact: true,
    pageName: 'Help',
  },
  helpTopic: {
    path: '/help/:topicId/',
    component: <HelpCentre />,
    container: false,
    exact: true,
    pageName: 'Help',
  },
  helpQuestion: {
    path: '/help/:topicId/:questionId',
    component: <HelpCentre />,
    container: false,
    exact: true,
    pageName: 'Help',
  },
  application: {
    path: '/application/:programmeId',
    component: <Application />,
    container: false,
    exact: true,
    pageName: 'Application',
  },
  agencyApplication: {
    path: '/agency-application',
    component: <AgencyApplication />,
    container: false,
    exact: true,
    pageName: 'Agency Application',
  },
};

export const marketplaceRoutes: RoutePairs = {
  marketplace: {
    path: '/connect',
    component: <Marketplace />,
    container: true,
    exact: true,
    pageName: 'Connect',
  },
};

export const campaignRoutes: RoutePairs = {
  campaign: {
    path: '/programmes/:programmeId/campaigns/:campaignId/:tab',
    component: <Campaign />,
    container: true,
    exact: true,
    pageName: 'Campaign',
  },
  campaignInvite: {
    path: '/programmes/:programmeId/campaigns/:campaignId/invitation/:invitationId',
    component: <Campaign />,
    container: true,
    exact: true,
    pageName: 'Campaign Invitation',
  },
};

export const agentRoutes: RoutePairs = {
  // home: {
  //   path: '/',
  //   component: <AgentDashboard />,
  //   container: true,
  //   exact: true,
  //   pageName: 'Dashboard',
  // },
  home: {
    path: '/',
    component: <Influencers />,
    container: true,
    exact: true,
    pageName: 'Influencers',
  },
  influencers: {
    path: '/influencers/:tab',
    component: <Influencers />,
    container: true,
    exact: true,
    pageName: 'Influencers',
  },
  influencersDefaultTab: {
    path: '',
    component: null,
    container: true,
    exact: true,
    pageName: 'Influencers',
    redirect: {
      from: '/influencers',
      to: '/influencers/associations',
      exact: true,
    },
  },
  agentSettings: {
    path: '/settings/:tab',
    component: <AgentSettings />,
    exact: true,
    container: true,
    pageName: 'Settings',
  },
  agentSettingsDefaultTab: {
    path: '',
    component: null,
    container: true,
    exact: true,
    pageName: 'Settings',
    redirect: {
      from: '/settings',
      to: '/settings/notifications',
      exact: true,
    },
  },
  agentWallet: {
    path: '/wallet',
    component: <AgentWallet />,
    container: true,
    exact: true,
    pageName: 'Wallet',
  },
  bankAccounts: {
    path: '/wallet/:tab',
    component: <AgentPaymentAccounts />,
    container: true,
    exact: true,
    pageName: 'Bank Accounts',
  },
  influencer: {
    path: '/influencer/:influencerId/:tab',
    component: <AgentInfluencer />,
    container: true,
    exact: true,
    pageName: 'Influencer view',
  },
  influencerDefaultTab: {
    path: '',
    component: null,
    container: true,
    exact: true,
    pageName: 'Influencer view',
    redirect: {
      from: '/influencer/:influencerId',
      to: '/influencer/:influencerId/collaborations',
      exact: true,
    },
  },
  influencerProgramme: {
    path: '/influencer/:influencerId/collaborations/programme/:programmeId',
    component: <AgentProgrammeView />,
    container: true,
    exact: true,
    pageName: 'Agent Influencers programme',
  },
  influencerProgrammeInvitation: {
    path: '/influencer/:influencerId/collaborations/programme/:programmeId/invitation/:invitationId',
    component: <AgentProgrammeView />,
    container: true,
    exact: true,
    pageName: 'Agent Influencers programme invitation',
  },
  influencerCampaign: {
    path: '/influencer/:influencerId/collaborations/programme/:programmeId/campaign/:campaignId',
    component: <AgentCampaignView />,
    container: true,
    exact: true,
    pageName: 'Agent Influencers campaign',
  },
  influencerCampaignInvitation: {
    path: '/influencer/:influencerId/collaborations/programme/:programmeId/campaign/:campaignId/invitation/:invitationId',
    component: <AgentCampaignView />,
    container: true,
    exact: true,
    pageName: 'Agent Influencers campaign invitation',
  },
  profile: {
    path: '/profile/:tab',
    component: <AgentProfile />,
    container: true,
    exact: true,
    pageName: 'Profile',
  },
  profileDefaultTab: {
    path: '',
    component: null,
    container: true,
    exact: true,
    pageName: 'Profile',
    redirect: {
      from: '/profile',
      to: '/profile/basic-information',
      exact: true,
    },
  },
};

import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const addDays = (date: Date, days: number) =>
  new Date(new Date(date).setDate(date.getUTCDate() + days));

export const formatDate = (dateTime: string, locale = 'en-GB'): string => {
  const options = {
    year: 'numeric' as 'numeric' | '2-digit',
    month: 'long' as 'numeric' | '2-digit',
    day: '2-digit' as 'numeric' | '2-digit',
  };
  return new Date(dateTime).toLocaleDateString(locale, options);
};

export const stringifyDateTime = (dateTime: Dayjs): string =>
  dateTime.format('D MMMM YYYY hh:mm A');

export const getWeekDays = (locale = 'en-GB') => {
  const d = new Date(Date.UTC(2018, 9)); // arbitrary week beginning in a monday
  const weekDays = [1, 2, 3, 4, 5, 6, 7];
  return weekDays.map(wD => {
    d.setDate(wD);
    return d.toLocaleString(locale, { weekday: 'short' });
  });
};

export const getCalendarMonth = (y: number, m: number, locale = 'en-GB') => {
  const d = new Date(Date.UTC(y, m));
  return d.toLocaleString(locale, { month: 'long', year: 'numeric' });
};

type ArrayStep = 'YEAR' | 'MONTH' | 'DAY';

export const generateDateArray = (
  start: Date,
  end: Date,
  step: ArrayStep = 'DAY'
) => {
  const arr = [];
  const dt = toDayPrecision(start);
  const endDt = toDayPrecision(end);

  while (dt < endDt) {
    arr.push(new Date(dt));

    if (step === 'YEAR') dt.setUTCFullYear(dt.getUTCFullYear() + 1);
    if (step === 'MONTH') dt.setUTCMonth(dt.getUTCMonth() + 1);
    if (step === 'DAY') dt.setUTCDate(dt.getUTCDate() + 1);
  }
  return arr;
};

const toDayPrecision = (date: Date) => {
  let newDate = new Date(date.setMilliseconds(0));
  newDate = new Date(newDate.setSeconds(0));
  newDate = new Date(newDate.setMinutes(0));
  newDate = new Date(newDate.setHours(0));

  return newDate;
};

export const getFirstDayOfMonth = (year: number, month: number) =>
  new Date(Date.UTC(year, month, 1, 0, 0));

export const getLastDayOfMonth = (year: number, month: number) =>
  addDays(getFirstDayOfMonth(year, month + 1), 0);

export const getDaysInMonth = (year: number, month: number) =>
  new Date(year, month, 0).getDate();

export const isSunday = (day: number) => day === 0;

export const minutesToMilliseconds = (minutes: number) => minutes * 60 * 1000;

import React from 'react';
import { useLocation } from 'react-router';
import {
  RouterLink,
  LinkText,
  RouterLinkLogout,
  ExternalRouterLink,
} from './MenuItem.styles';

interface Props {
  route: string;
  label: string;
  icon: JSX.Element;
  toggleNavigation: () => void;
}
interface TextProps {
  route: string;
  label: string;
  toggleNavigation: () => void;
}

export const MenuItem: React.FC<Props> = ({
  route,
  label,
  icon,
  toggleNavigation,
}: Props) => {
  const location = useLocation();

  const isSelected = (): boolean => location.pathname === route;

  return (
    <RouterLink to={route} onClick={toggleNavigation} selected={isSelected()}>
      {icon}
      <LinkText>{label}</LinkText>
    </RouterLink>
  );
};

export const MenuText: React.FC<TextProps> = ({
  route,
  label,
  toggleNavigation,
}: TextProps) => {
  const location = useLocation();

  const isSelected = (): boolean => location.pathname === route;

  return (
    <RouterLink to={route} onClick={toggleNavigation} selected={isSelected()}>
      <LinkText>{label}</LinkText>
    </RouterLink>
  );
};

export const MenuTextExternalLink: React.FC<TextProps> = ({
  route,
  label,
  toggleNavigation,
}: TextProps) => {
  return (
    <ExternalRouterLink href={route} onClick={toggleNavigation} target="_blank">
      <LinkText>{label}</LinkText>
    </ExternalRouterLink>
  );
};

export const MenuItemLogout: React.FC<Props> = ({
  route,
  label,
  icon,
}: Props) => {
  const location = useLocation();

  const isSelected = (): boolean => location.pathname === route;

  return (
    <RouterLinkLogout to={route} selected={isSelected()}>
      {icon}
      <LinkText>{label}</LinkText>
    </RouterLinkLogout>
  );
};

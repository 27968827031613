export const translations = {
  emailOptModal: {
    'EMAIL.OPT.MODAL.REMIND': 'No, Thank you',
    'EMAIL.OPT.MODAL.JOIN.BUTTON': 'Count me in',
    'EMAIL.OPT.COLLABORATIONS.LINKS.TEXT':
      'Be the first to know about new collaborations and partnerships!',
    'EMAIL.OPT.ACCESS.PRODUCT.CATALOGUE.TEXT':
      'Get exclusive updates on latest Society features and tools to boost your Influencer journey',
    'EMAIL.OPT.MODAL.JOIN.TITLE':
      'We would like to keep you updated to exclusive Society offers, news and updates via email!',
    'EMAIL.OPT.MODAL.INSIGHTS.TEXT':
      'Please note that you can update your preferences anytime in your notifications settings',
    'EMAIL.OPT.SUCCESS.TEXT': 'Thank you for your response',
  },
};

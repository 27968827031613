import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useResponsive } from '../../../../hooks';
import { Logo, PrimaryButton, Spacer } from '../../../common';
import { ExternalLinkButton } from '../../../common/Button';
import { Footer } from '../../../common/Footer/Footer';
import { Breakpoint } from '../../../styling/breakpoints';
import registerInterest from '../../../../analytics/gaEvents/RegisterInterest/RegisterInterest';

import Logos from './Logos';
import s from './SocietyLandingPage.styles';

const SocietyLandingPage = () => {
  const { layout } = useResponsive();
  const isMobile = layout < Breakpoint.DESKTOP;

  return (
    <>
      <s.LandingPageWrapper role={'main'}>
        <s.Header>
          <s.SocietyLogoWrapper isMobile={isMobile}>
            <Logo />
          </s.SocietyLogoWrapper>
          <Spacer amount={16} isVertical />
          <s.HeaderMessage>
            {'Become a part of the THG Society Community!'}
          </s.HeaderMessage>
        </s.Header>
        <s.PerkWrapper isMobile={isMobile}>
          <s.Perk>
            <FontAwesomeIcon icon={'tag'} size={'5x'} />
            <p>
              {
                "Benefit from THG's brand relations for competitive commission rates"
              }
            </p>
          </s.Perk>
          <s.Perk>
            <FontAwesomeIcon icon={'chart-pie'} size={'5x'} />
            <p>
              {
                'Get visibility on your performance including in-depth insights on the products and brands that your audience is buying'
              }
            </p>
          </s.Perk>
          <s.Perk>
            <FontAwesomeIcon icon={'link'} size={'5x'} />
            <p>
              {
                'Manage all collaborations from a single place through our mobile-friendly application'
              }
            </p>
          </s.Perk>
        </s.PerkWrapper>

        <ExternalLinkButton
          to={
            'https://forms.office.com/Pages/ResponsePage.aspx?id=OwE7Iz7Yek-G__Lk5-aUa6ypzB66nrJMm55NuXvHkpxUNlNLUkVBU0dWQUhHQlQ0RVozTlFORjVEOS4u'
          }
          buttonType={PrimaryButton}
          onClick={registerInterest}
        >
          {'Register your interest'}
        </ExternalLinkButton>
        <Spacer amount={16} isVertical />
        <s.MessageWrapper>
          <s.BrandsMessage>
            {
              'Get a chance to collaborate as a creator with hundreds of brands including below and many more!'
            }
          </s.BrandsMessage>
        </s.MessageWrapper>
        <s.BrandsLogos>
          {Logos.map(logo => (
            <s.LogoWrapper key={logo.image}>
              <img src={logo.image} alt={logo.alt} />
            </s.LogoWrapper>
          ))}
        </s.BrandsLogos>
      </s.LandingPageWrapper>
      <s.FooterWrapper role={'contentinfo'}>
        <Footer isMobile={isMobile} hideLanguageDropdown />
      </s.FooterWrapper>
    </>
  );
};
export default SocietyLandingPage;

import React, { FC } from 'react';
import { useDateDropdown } from './useDateDropdown';
import { DateDropdown as Component } from './DateDropdown';
import { DateDropdownProps } from './types';

export const DateDropdown: FC<DateDropdownProps> = ({
  startDate: start,
  endDate: end,
  displayDate,
  onChange,
  darkTheme,
  disabled,
  yearLabel = 'Year',
  monthLabel = 'Month',
  dayLabel = 'Day',
}) => {
  const props = useDateDropdown(start, end, displayDate, onChange);
  return (
    <Component
      {...props}
      darkTheme={darkTheme}
      disabled={disabled}
      yearLabel={yearLabel}
      monthLabel={monthLabel}
      dayLabel={dayLabel}
    />
  );
};

export const connectInstagramTranslation = {
  connectInstagramModal: {
    'FACEBOOKLOGIN.CONNECT.STEPONE.TITLE':
      'You have an Instagram professional account (business or creator)',
    'FACEBOOKLOGIN.CONNECT.STEPTWO.TITLE':
      'Ensure that you have a Facebook page and your instagram account is connected to it',
    'FACEBOOKLOGIN.CONNECT.STEPONE.SUBTITLE':
      'Before you connect, please ensure the following',
    'FACEBOOKLOGIN.CONNECT.TITLE': 'Connect Instagram',
    'FACEBOOKLOGIN.CONNECT.DESCRIPTIONTEXT': `To work with brands through THG Society, we need you to connect your professional Instagram account(s) and share content and insights data with us. This allows us to effectively track performance and see relevant audience insights. THG Society will only have read access to this information.`,
  },
  stepOne: {
    'FACEBOOKLOGIN.CONNECT.CANCELBUTTON': 'Cancel',
    'FACEBOOKLOGIN.CONNECT.SUBMITBUTTON': 'Confirm',
    'FACEBOOKLOGIN.CONNECT.STEPONE.TEXT':
      'If you have an Instagram personal account then you would need to switch it to a business or creator account.',
    'FACEBOOKLOGIN.CONNECT.STEPONE.ITEMONE':
      'Instructions to switch your account to a business account can be found',
    'FACEBOOKLOGIN.CONNECT.STEPONE.ITEMONE.LINKTEXT': 'here',
    'FACEBOOKLOGIN.CONNECT.STEPONE.ITEMTWO':
      'Instructions to switch your account to a creator account can be found ',
    'FACEBOOKLOGIN.CONNECT.STEPONE.ITEMTWO.LINKTEXT': 'here',
    'FACEBOOKLOGIN.CONNECT.STEPONE.CONFIRMTEXT':
      'I have an Instagram professional account',
  },
  stepTwo: {
    'FACEBOOKLOGIN.CONNECT.STEPTWO.ITEMONE':
      'If you do not already have a Facebook page then you can create one by visiting ',
    'FACEBOOKLOGIN.CONNECT.STEPTWO.ITEMONE.LINKTEXT': 'here',
    'FACEBOOKLOGIN.CONNECT.STEPTWO.ITEMTWO':
      'If you already have a Facebook page then you can connect it to your Instagram professional account by following the instructions ',
    'FACEBOOKLOGIN.CONNECT.STEPTWO.ITEMTWO.LINKTEXT': 'here',
    'FACEBOOKLOGIN.CONNECT.STEPTWO.CONFIRMTEXT':
      'I confirm that I have a Facebook page and my Instagram account is connected to it.',
  },
};

export const agentNavigationTranslation = {
  agencyNavigationLabels: {
    'AGENT.HOME.LABEL': 'Home',
    'AGENT.LOGOUT.LABEL': 'Log Out',
    'AGENT.TOGGLE.TEXT': 'toggle menu',
    'AGENT.LOGOUT.EXPANDED_TEXT': 'Click to Log Out',
    'AGENT.INFLUENCERS.LABEL': 'Influencers',
    'APP.NAV.SETTINGS': 'Settings',
    'AGENT.PAYMENT_ACCOUNTS.LABEL': 'Payment Accounts',
    'AGENT.WALLET': 'Wallet',
    'AGENT.PROFILE.LABEL': 'Profile',
  },
};

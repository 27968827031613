import { withSentryRouting } from '@sentry/react';
import React, { Suspense } from 'react';
import { Switch, Route, Redirect as ReactRedirect } from 'react-router-dom';
import { agentRoutes, AppRoute } from '../../routes';
import { LoadingView } from '../common/Loading';
import {
  PageContainer,
  ErrorBoundary,
  ConsentBanner,
  AgentNavigation,
} from './../layout';
import { getRoutesListComponents } from './../utils';
import { AgentStoreProvider } from '../../contexts/Agent/state';
import AgentFirstLoginWrapper from '../layout/AgentFirstLoginWrapper';

const SentryRoute = withSentryRouting(Route);

const AgentRoute: React.FC<{ route: AppRoute }> = ({ route }) => {
  const { container, component, pageName } = route;
  return (
    <>
      <ErrorBoundary pageName={pageName}>
        <Suspense fallback={<LoadingView />}>
          {container ? <PageContainer>{component}</PageContainer> : component}
        </Suspense>
      </ErrorBoundary>
      {window._env.showCookieBanner && <ConsentBanner />}
    </>
  );
};

const AgentContainer: React.FC = () => {
  return (
    <AgentFirstLoginWrapper>
      <AgentNavigation />
      <SentryRoute path={agentRoutes.home.path}>
        <AgentStoreProvider>
          <Switch>
            {getRoutesListComponents(agentRoutes, AgentRoute)}
            <ReactRedirect to="/" />
          </Switch>
        </AgentStoreProvider>
      </SentryRoute>
    </AgentFirstLoginWrapper>
  );
};

export default AgentContainer;

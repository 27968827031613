export const roleSwitcherTranslations = {
  roleSwitcher: {
    'ROLE_SWITCHER.REDIRECT_WRAPPER.TITLE': 'Welcome to the society.',
    'ROLE_SWITCHER.REDIRECT_WRAPPER.LOGOUT': 'Logout',
    'ROLE_SWITCHER.NON_INFLUENCER.REDIRECT_TEXT':
      'Please follow the link below to be redirected to the Admin portal',
    'ROLE_SWITCHER.NON_INFLUENCER.REDIRECT_REDIRECT_LABEL': 'Admin Portal',
    'ROLE_SWITCHER.ERROR.REDIRECT_TEXT': 'Sorry something went wrong.',
    'ROLE_SWITCHER.ERROR.RETRY_MESSAGE': 'Click here to try again',
  },
};

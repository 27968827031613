import React, { FC, MouseEvent } from 'react';
import { MobileNav } from './MobileNav';
import { SideNav } from './SideNav';
import { getKeycloakInstance } from '../../../keycloak/keycloak-init';
import { Breakpoint } from '../../styling/breakpoints';
import { useResponsive } from '../../../hooks/useResponsive';
import { useNavigation } from './useNavigation';
import Keycloak from 'keycloak-js';

export const Navigation: FC<{ kc?: Keycloak.KeycloakInstance }> = ({
  kc = getKeycloakInstance(),
}) => {
  const { layout } = useResponsive();
  const { language, showMarketplace } = useNavigation();

  const logout = (e: MouseEvent<Element>) => {
    e.preventDefault();
    kc!.logout();
  };

  return layout < Breakpoint.DESKTOP ? (
    <MobileNav
      logoutHandler={logout}
      language={language}
      showMarketplace={showMarketplace}
    />
  ) : (
    <SideNav
      logoutHandler={logout}
      language={language}
      showMarketplace={showMarketplace}
    />
  );
};

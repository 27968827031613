import { useEffect, useState } from 'react';

export const useDebouncedState = <T>(
  initialValue: T,
  timeout: number
): [T, T, (a: T) => void] => {
  const [liveValue, setLiveValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const ref = setTimeout(() => {
      setDebouncedValue(liveValue);
    }, timeout);

    return () => {
      clearTimeout(ref);
    };
  }, [liveValue, timeout]);

  return [liveValue, debouncedValue, setLiveValue];
};

import styled from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const StyledList = styled.ul`
  background-color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
  display: grid;
  grid-gap: ${px2rem(10)};
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
  width: 100%;
  padding: ${px2rem(10)};
`;

export const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  & > span > span {
    display: flex;
  }
`;

export const KeyText = styled.span`
  background-color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
`;

export const ValueText = styled.span`
  color: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
  padding: ${px2rem(5)} ${px2rem(5)} 0 0;
`;

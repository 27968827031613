import styled from 'styled-components';
import { theme } from '../../../styling/theme';

const LandingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const SocietyLogoWrapper = styled.div<{ isMobile: boolean }>`
  max-width: ${props => (props.isMobile ? '100%' : '33vw')};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => theme(props).SIZE.LARGE};
  background-color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  color: ${props => theme(props).COLOUR.WHITE};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const HeaderMessage = styled.h1`
  ${props => theme(props).TYPOGRAPHY.LARGE_2_alt};
`;

const BrandsLogos = styled.div`
  display: grid;
  width: 70vw;
  padding: ${props => theme(props).SIZE.LARGE};
  grid-gap: ${props => theme(props).SIZE.HUGE};
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  justify-content: center;
  align-items: center;
`;

const LogoWrapper = styled.div`
  & > img {
    max-width: 100%;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  background-color: ${props => theme(props).COLOUR.SUCCESS_DARKER};
  color: ${props => theme(props).COLOUR.WHITE};
  width: 100%;
  justify-content: center;
`;

const BrandsMessage = styled.p`
  ${props => theme(props).TYPOGRAPHY.LARGE_1_alt};
  padding: ${props => theme(props).SIZE.LARGE};
`;

const PerkWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-gap: ${props => theme(props).SIZE.HUGE};
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  ${props => !props.isMobile && 'width: 70vw'};
  padding-top: ${props => theme(props).SIZE.LARGE};
  padding-bottom: ${props => theme(props).SIZE.LARGE};
`;

const Perk = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${props => theme(props).SIZE.LARGE};
  padding-right: ${props => theme(props).SIZE.LARGE};

  & > p {
    padding-top: ${props => theme(props).SIZE.LARGE};
    text-align: center;
  }
`;

const FooterWrapper = styled.div`
  background-color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
`;

export default {
  SocietyLogoWrapper,
  LandingPageWrapper,
  Header,
  HeaderMessage,
  Perk,
  PerkWrapper,
  BrandsMessage,
  BrandsLogos,
  LogoWrapper,
  MessageWrapper,
  FooterWrapper,
};

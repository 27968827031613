import styled from 'styled-components';
import { theme } from '../styling/theme';

const MainContentWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
  display: flex;
  animation: ${props => theme(props).EFFECTS.FADE_IN_ANIMATION} 0.2s linear;
  width: 100%;
`;

export { MainContentWrapper };

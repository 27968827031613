import ReactGA from 'react-ga4';
import { EnvironmentConfig } from '../environments/environment';

export const initialiseGA = () => {
  const envToTrackingId: Record<EnvironmentConfig['environment'], string> = {
    live: 'G-TFZ0YP0G46',
    gb5mocked: 'G-W7MNNV6DBP',
    gb5: 'G-W7MNNV6DBP',
    local: 'G-W7MNNV6DBP',
    '': '',
  };

  const trackingId = envToTrackingId[window._env.environment];
  if (!trackingId) return;

  ReactGA.initialize(trackingId);
};

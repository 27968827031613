import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PrimaryButton,
  PrimaryButtonAlternate,
  SecondaryButton,
  SecondaryButtonAlternate,
  TextButton,
  RejectButton,
} from './index';

type ButtonType =
  | typeof PrimaryButton
  | typeof PrimaryButtonAlternate
  | typeof SecondaryButton
  | typeof SecondaryButtonAlternate
  | typeof TextButton
  | typeof RejectButton;

interface LinkButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  to: string;
  onClick?: (e: any) => void;
  buttonType: ButtonType;
}

export const LinkButton: FC<LinkButtonProps> = ({
  to,
  onClick = () => {
    return;
  },
  buttonType: ButtonType,
  ...rest
}) => {
  const { push } = useHistory();
  return (
    <ButtonType
      {...rest}
      onClick={(event: any) => {
        onClick(event);
        push(to);
      }}
    />
  );
};

export const ExternalLinkButton: FC<LinkButtonProps> = ({
  to,
  onClick = () => {
    return;
  },
  buttonType: ButtonType,
  ...rest
}) => {
  return (
    <ButtonType
      role="link"
      {...rest}
      onClick={(event: any) => {
        onClick(event);
        window.location.href = to;
      }}
    />
  );
};

import React from 'react';
import { FC } from 'react';
import {
  Box,
  Checkbox,
  Direction,
  Padding,
  SecondaryButton,
  Spacer,
} from '../../../../../common';
import {
  StepPara,
  StepCheckbox,
  StepList,
  StepLink,
} from './ConnectInstagramModal.styles';
import { ErrorMessageText } from '../FacebookLogin.styles';
import { PrimaryButton } from '../../../../../common/Button/index';
import { StepOneProps, StepTwoProps } from './types';
import { useTranslationsController } from '../../../../../../utils/globalTranslationsController';
import { connectInstagramTranslation } from './translations';

export const StepOne: FC<StepOneProps> = ({
  itemOneURL,
  itemTwoURL,
  onNext,
  onCancel,
  consentGiven,
  setConsentGiven,
  isMobile,
}) => {
  const { stepOne: t } = useTranslationsController(connectInstagramTranslation);
  return (
    <div data-testid="t_step_one">
      <StepPara>{t['FACEBOOKLOGIN.CONNECT.STEPONE.TEXT']}</StepPara>
      <StepList>
        <li>
          {t['FACEBOOKLOGIN.CONNECT.STEPONE.ITEMONE']}{' '}
          <StepLink href={itemOneURL} target="blank">
            {t['FACEBOOKLOGIN.CONNECT.STEPONE.ITEMONE.LINKTEXT']}
          </StepLink>
        </li>
        <li>
          {t['FACEBOOKLOGIN.CONNECT.STEPONE.ITEMTWO']}{' '}
          <StepLink href={itemTwoURL} target="blank">
            {t['FACEBOOKLOGIN.CONNECT.STEPONE.ITEMTWO.LINKTEXT']}
          </StepLink>
        </li>
      </StepList>
      <StepCheckbox>
        <Checkbox
          label={t['FACEBOOKLOGIN.CONNECT.STEPONE.CONFIRMTEXT']}
          testId="t_step_one_checkbox"
          checked={consentGiven}
          onClick={() => setConsentGiven(!consentGiven)}
        />
      </StepCheckbox>
      <Box
        flex
        paddingBottom={Padding.XSMALL}
        direction={isMobile ? Direction.COLUMN : Direction.ROW}
      >
        <SecondaryButton onClick={onCancel} fullWidth={isMobile}>
          {t['FACEBOOKLOGIN.CONNECT.CANCELBUTTON']}
        </SecondaryButton>
        <Spacer amount={16} isVertical={isMobile} />
        <PrimaryButton
          onClick={onNext}
          disabled={!consentGiven}
          data-testid="t_step_one_submit"
          fullWidth={isMobile}
        >
          {t['FACEBOOKLOGIN.CONNECT.SUBMITBUTTON']}
        </PrimaryButton>
      </Box>
    </div>
  );
};

export const StepTwo: FC<StepTwoProps> = ({
  handleSubmit,
  itemOneURL,
  itemTwoURL,
  onCancel,
  consentGiven,
  setConsentGiven,
  shouldDisableConnectButton,
  errorMessage,
  isMobile,
}) => {
  const { stepTwo: t, stepOne: translation } = useTranslationsController(
    connectInstagramTranslation
  );
  return (
    <div data-testid="t_step_two">
      <StepList>
        <li>
          {t['FACEBOOKLOGIN.CONNECT.STEPTWO.ITEMONE']}{' '}
          <StepLink href={itemOneURL} target="blank">
            {t['FACEBOOKLOGIN.CONNECT.STEPTWO.ITEMONE.LINKTEXT']}
          </StepLink>
        </li>
        <li>
          {t['FACEBOOKLOGIN.CONNECT.STEPTWO.ITEMTWO']}{' '}
          <StepLink href={itemTwoURL} target="blank">
            {t['FACEBOOKLOGIN.CONNECT.STEPTWO.ITEMTWO.LINKTEXT']}
          </StepLink>
        </li>
      </StepList>
      <StepCheckbox>
        <Checkbox
          label={t['FACEBOOKLOGIN.CONNECT.STEPTWO.CONFIRMTEXT']}
          testId="t_step_two_checkbox"
          checked={consentGiven}
          onClick={() => setConsentGiven(!consentGiven)}
        />
      </StepCheckbox>
      <Box flex paddingBottom={Padding.XSMALL}>
        <SecondaryButton onClick={onCancel} fullWidth={isMobile}>
          {translation['FACEBOOKLOGIN.CONNECT.CANCELBUTTON']}
        </SecondaryButton>
        <Spacer amount={16} />
        <PrimaryButton
          fullWidth={isMobile}
          data-testid="t_connect_social"
          onClick={handleSubmit}
          disabled={!consentGiven || shouldDisableConnectButton}
          aria-disabled={!consentGiven || shouldDisableConnectButton}
        >
          {translation['FACEBOOKLOGIN.CONNECT.SUBMITBUTTON']}
        </PrimaryButton>
      </Box>
      {errorMessage && (
        <>
          <ErrorMessageText data-testid="t_error_message">
            {errorMessage}
          </ErrorMessageText>
          <Spacer amount={20} />
        </>
      )}
    </div>
  );
};

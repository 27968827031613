export const registerTranslationObject = {
  register: {
    'PROFILE.INTERESTS.HEADER': `Select your interests`,
    'PROFILE.INTERESTS.SUBHEADER': `Choose between one and five topics. Don't worry, you can update
      them at any time from your profile.`,
    'PROFILE.INTERESTS.SAVE': `Save & Continue`,
    'PROFILE.INTERESTS.CANCEL': 'Cancel',
    'PROFILE.INTERESTS.UPDATE': `Update`,
    'REGISTER.STAGE.INTEREST.TITLE': 'Interests',
    'REGISTER.STAGE.CONTACT.TITLE': 'Contact Info',
    'REGISTER.STAGE.SOCIAL.TITLE': 'Social profiles',
    'REGISTER.BUTTON.LOGOUT': 'Log out',
    'REGISTER.HEADER': 'Welcome {0} to THG Society!',
    'REGISTER.BUTTON.BACK': '< Back',
  },
};

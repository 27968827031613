export const formatName = (name: string) => {
  const capitalisedWords = name
    .toLowerCase()
    .replace(/ [a-zA-Z]/g, str => ` ${str[1].toUpperCase()}`);
  return `${capitalisedWords.slice(0, 1).toUpperCase()}${capitalisedWords.slice(
    1
  )}`;
};

// XXX is the international code for no currency symbol
export const formatCurrency = (
  amount: number,
  locale = 'en-GB',
  currency = 'XXX',
  hideCurrencySign = false
) => {
  if (currency === '') currency = 'XXX';
  if (locale === '') locale = 'en-GB';

  const currencyDisplay = hideCurrencySign ? 'code' : 'symbol';
  const formattedCurrency = new Intl.NumberFormat(locale, {
    currency: currency.toUpperCase(),
    style: 'currency',
    currencyDisplay,
  }).format(amount);

  if (hideCurrencySign) {
    return formattedCurrency.replace(/[a-z]{3}/i, '').trim();
  }

  return formattedCurrency;
};

export const formatValidString = (
  original: string | null | undefined,
  args: (string | number)[]
) => {
  if (original) return formatString(original, args);
  return '';
};

export const formatString = (original: string, args: (string | number)[]) => {
  return original.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined'
      ? args[number].toString()
      : match;
  });
};

export const removeWhitespace = (str: string) => {
  return str.replace(/\s/g, '');
};

export const removeUnderScore = (value: string) => {
  return value.replaceAll('_', '');
};

export const formatFiltersQuery = (filters: any) => {
  let query = undefined;

  if (filters.brands.length) {
    const brandsQuery = filters.brands
      .map((b: string) => `brandName:${encodeURIComponent(b)}`)
      .join(' OR ');
    query = `${query ? `${query} OR` : ''} ${brandsQuery}`;
  }
  if (filters.categories.length) {
    const categoriesQuery = filters.categories
      .map(
        (c: string) => `hierarchicalCategories.lvl0:${encodeURIComponent(c)}`
      )
      .join(' OR ');
    query = `${query ? `${query} OR` : ''} ${categoriesQuery}`;
  }
  if (filters.industries.length) {
    const industriesQuery = filters.industries
      .map((i: string) => `categoryGroupId:${industriesObj[i]}`)
      .join(' OR ');
    query = `${query ? `${query} OR` : ''} ${industriesQuery}`;
  }
  if (filters.partners.length) {
    const partnersQuery = filters.partners
      .map((p: string) => `advertiserName:${encodeURIComponent(p)}`)
      .join(' OR ');
    query = `${query ? `${query} OR` : ''} ${partnersQuery}`;
  }

  return query;
};

import React, { createContext, useContext, Dispatch, useReducer } from 'react';
import { WalletState, WalletReducer, defaultWalletState } from './Wallet/store';
import {
  BankingReducer,
  BankingState,
  defaultBankingState,
} from './BankAccount/store';
import { defaultUserState, UserReducer } from './User/store';
import { UserState } from './User/types';
import { SocialState, defaultSocialState, SocialReducer } from './Social/store';
import { defaultInvitationState, InvitationState } from './Invitation/store';
import { InvitationReducer } from './Invitation/reducer';
import {
  ProgrammeState,
  defaultProgrammeState,
  ProgrammeReducer,
} from './Programme/store';
import {
  defaultNotificationState,
  NotificationReducer,
  NotificationState,
} from './Notification/store';
import { ContentState } from './Content/models';
import { defaultContentState } from './Content/store';
import { ContentReducer } from './Content/reducer';
import { defaultReportingState, ReportingState } from './Reporting/store';
import { ReportingReducer } from './Reporting/reducer';
import { EnvironmentsState } from './Environments/types';
import { EnvironmentsReducer } from './Environments/reducer';
import { defaultEnvironmentsState } from './Environments/store';

export interface Action<T = any> {
  type: string;
  payload?: T;
}

export type DispatchFn = (action: Action) => void;

interface Props {
  children: JSX.Element[] | JSX.Element;
}

export interface State {
  userState: UserState;
  environmentsState: EnvironmentsState;
  walletState: WalletState;
  bankingState: BankingState;
  socialState: SocialState;
  invitationState: InvitationState;
  programmeState: ProgrammeState;
  notificationState: NotificationState;
  contentState: ContentState;
  reportingState: ReportingState;
}

export const defaultState: State = Object.freeze({
  userState: defaultUserState,
  environmentsState: defaultEnvironmentsState,
  walletState: defaultWalletState,
  bankingState: defaultBankingState,
  invitationState: defaultInvitationState,
  socialState: defaultSocialState,
  programmeState: defaultProgrammeState,
  notificationState: defaultNotificationState,
  contentState: defaultContentState,
  reportingState: defaultReportingState,
});

const rootReducer = (state: State = defaultState, action: Action): State => {
  return {
    userState: UserReducer(state.userState, action),
    environmentsState: EnvironmentsReducer(state.environmentsState, action),
    walletState: WalletReducer(state.walletState, action),
    bankingState: BankingReducer(state.bankingState, action),
    socialState: SocialReducer(state.socialState, action),
    invitationState: InvitationReducer(state.invitationState, action),
    programmeState: ProgrammeReducer(state.programmeState, action),
    notificationState: NotificationReducer(state.notificationState, action),
    contentState: ContentReducer(state.contentState, action),
    reportingState: ReportingReducer(state.reportingState, action),
  };
};

type Store = [State, Dispatch<any>];

const StoreContext = createContext<Store>([
  defaultState,
  () => {
    return;
  },
]);

export const StoreProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(rootReducer, defaultState);

  return (
    <StoreContext.Provider value={[state, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);

import React, { ReactNode, useState } from 'react';
import { AccordionProps } from '@material-ui/core/Accordion';
import AccordianSummary from '@material-ui/core/AccordionSummary';
import AccordianDetails from '@material-ui/core/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AccordionWrapper,
  AccordionSummaryContainer,
  AccordionSummaryHeader,
  AccordionSummarySubheader,
  AccordionStyled,
} from './Accordion.styles';

type ExpandIconVariants = 'default';

const expandIconRenderer = (variant: ExpandIconVariants = 'default') => {
  switch (variant) {
    default:
      return <FontAwesomeIcon icon="chevron-down" />;
  }
};

export interface ExtendedAccordionProps extends AccordionProps {
  labelHeader: ReactNode;
  labelSubheader?: ReactNode;
  testId: string;
  status?: string;
  fullWidth?: boolean;
  overrideOpen?: boolean;
}

interface AccordionTestProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

export const Accordion: React.FC<ExtendedAccordionProps> = props => {
  const {
    labelHeader,
    labelSubheader,
    testId,
    children,
    status,
    fullWidth,
    overrideOpen,
    ...nativeProps
  } = props;

  const [shouldOverrideOpen, setShouldOverrideOpen] = useState(
    overrideOpen || false
  );
  const hasOverride = overrideOpen !== undefined;

  return (
    <AccordionWrapper status={status} fullWidth={fullWidth || false}>
      <AccordionStyled
        {...nativeProps}
        expanded={hasOverride ? shouldOverrideOpen : undefined}
        onChange={e => {
          e.preventDefault();
          hasOverride && setShouldOverrideOpen(!shouldOverrideOpen);
        }}
        TransitionProps={{ 'data-testid': testId } as AccordionTestProps}
      >
        <AccordianSummary
          expandIcon={expandIconRenderer()}
          id={`${testId}_content`}
          data-testid={`${testId}_content`}
          aria-controls={`${testId}_expanded`}
        >
          <AccordionSummaryContainer>
            <AccordionSummaryHeader>{labelHeader}</AccordionSummaryHeader>
            <AccordionSummarySubheader>
              {labelSubheader}
            </AccordionSummarySubheader>
          </AccordionSummaryContainer>
        </AccordianSummary>
        <AccordianDetails>{children}</AccordianDetails>
      </AccordionStyled>
    </AccordionWrapper>
  );
};

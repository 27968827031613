import {
  SocialConnectionApi,
  SocialConnection,
  AgentAPI,
  Agent,
  SocialRequirement,
  SocialRequirementApi,
  AssociationFeedback,
  AssociationFeedbackApi,
  AssociationFeedbackFromApi,
  WithdrawalFeedbackFromApi,
  WithdrawalFeedback,
  WithdrawalFeedbackApi,
  UserPermissionMapApi,
  UserPermissionMap,
} from './types';

export const transformAddressToApi = (address: string): string => {
  return address.split('\n').join(',');
};

export const transformAddressFromApi = (address: string): string => {
  return address.split(',').join('\n');
};

export const parseUserPermissionMap = (
  payload: UserPermissionMapApi
): UserPermissionMap => {
  const {
    global_permissions,
    group_permissions,
    programme_permission,
    keycloak_id,
    keycloak_token_id,
    type,
  } = payload;

  return {
    globalPermissions: global_permissions,
    groupPermissions: group_permissions,
    programmePermissions: programme_permission,
    keycloakId: keycloak_id,
    keycloakTokenId: keycloak_token_id,
    type: type,
  };
};

export const transformGetSocialRequirements = (
  payload: SocialRequirementApi
): SocialRequirement => {
  return {
    required: payload.required,
    instagramUsername: payload.instagram_username,
    youtubeUsername: payload.youtube_username,
    lastChangedAt: payload.last_changed_at,
    lastChangedBy: payload.last_changed_by,
  };
};

export const transformAssociationFeedbackToApi = (
  payload: AssociationFeedback
): AssociationFeedbackApi | null => {
  if (payload.onboardingEasyToFollow && payload.satisfiedUsingSociety) {
    return {
      onboarding_easy_to_follow: payload.onboardingEasyToFollow,
      satisfied_using_society: payload.satisfiedUsingSociety,
      anything_else_to_share_post_association:
        payload.anythingElseToSharePostAssociation,
    };
  }
  return null;
};

export const checkAssociationFeedbackFromApi = (
  payload: AssociationFeedbackFromApi | null
): boolean => {
  if (payload && payload.created_at) {
    return true;
  }
  return false;
};

export const transformWithdrawalFeedbackToApi = (
  payload: WithdrawalFeedback
): WithdrawalFeedbackApi | null => {
  if (
    payload.easierToManageTrackingLinks &&
    payload.likelyToRecommend &&
    payload.manageEarnings
  ) {
    return {
      easier_to_manage_tracking_links: payload.easierToManageTrackingLinks,
      likely_to_recommend: payload.likelyToRecommend,
      manage_earnings: payload.manageEarnings,
      anything_else_to_share_post_withdrawal:
        payload.anythingElseToSharePostWithdrawal,
    };
  }
  return null;
};

export const checkWithdrawalFeedbackFromApi = (
  payload: WithdrawalFeedbackFromApi | null
): boolean => {
  if (payload && payload.created_at) {
    return true;
  }
  return false;
};

export const transformSocialConnection = (
  socialConnection: SocialConnectionApi
): SocialConnection => {
  return {
    id: socialConnection.id,
    influencerId: socialConnection.influencer_id,
    instagram: socialConnection.instagram,
    youtube: socialConnection.youtube,
    lastUpdated: socialConnection.last_updated,
    daysRemaining: socialConnection.days_remaining,
    daysRemainingInstagram: socialConnection.days_remaining_instagram,
    daysRemainingYoutube: socialConnection.days_remaining_youtube,
  };
};

export const transformAgentFromAPI = (agent: AgentAPI): Agent => {
  return {
    id: agent.id,
    agencyId: agent.agency_id,
    keycloakId: agent.keycloak_id,
    firstName: agent.first_name,
    lastName: agent.last_name,
    firstLogin: agent.first_login,
  };
};

import styled from 'styled-components';
import { StandardButtonStyles } from '../../common/Button';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';
import { Breakpoint } from '../../styling/breakpoints';

export const RegisterContainer = styled.div`
  background: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  min-height: 100vh;
`;

export const LogoContainer = styled.div`
  margin: 0 auto;
  width: 60%;
  transform: scale(0.75);
  @media only screen and (min-width: ${Breakpoint.TABLET}em) {
    padding: ${px2rem(20)};
  }
`;
export const RegisterHeader = styled.h1`
  color: white;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  padding-bottom: ${props => theme(props).SIZE.SMALL};
`;

export const ActionContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  justify-content: ${props => (props.isMobile ? 'center' : 'flex-end')};
  align-items: center;
  gap: ${props => theme(props).SIZE.MEDIUM};
  & > button {
    width: ${props => (props.isMobile ? '100%' : 'auto')};
  }
`;

export const PrevButton = styled.button`
  ${StandardButtonStyles};
  max-width: fit-content;
  background: transparent;
  color: white;
  border: 0 none;
  box-shadow: none;
  min-width: 0;
`;

export const ValidationMsg = styled.div`
  color: white;
  text-align: right;
  height: ${px2rem(20)};
`;

export const InterestBox = styled.div`
  border-radius: ${px2rem(10)};
  margin-bottom: 1rem;
  width: 65vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    padding: 0.2rem;
    width: 100vw;
  }
`;

export const LogoutContainer = styled.div`
  position: fixed;
  top: 50px;
  right: 50px;
  width: 100px;
  height: 50px;
  @media (max-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    display: none !important;
  }
`;

export const ActionContainerMobile = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${props => theme(props).SIZE.MEDIUM};
  @media (min-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    display: none !important;
  }
`;

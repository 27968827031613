import styled from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';
import { StandardButtonStyles } from '../Button';
import { StandardInputStyles } from '../Input';

export const Container = styled.span`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
  position: relative;
  z-index: 1;
  padding: ${px2rem(2)};
  width: 100%;
  & * {
    ${props => theme(props).TYPOGRAPHY.X_SMALL};
  }
`;

export const TextArea = styled.input`
  ${StandardInputStyles}
  width: 100%;
  padding-right: 2rem;
  :read-only {
    border: solid ${px2rem(1)} ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
    background: white;
  }
`;

export const Button = styled.button`
  ${StandardButtonStyles};
  width: 2rem;
  min-width: 0;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  background-color: ${props => theme(props).COLOUR.PRIMARY};
  border-radius: 0 ${px2rem(8)} ${px2rem(8)} 0;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

import React from 'react';
import { Modal, SecondaryButton, PrimaryButton } from '../../common';
import { useTranslationsController } from '../../../utils/globalTranslationsController';
import s from './Modal.styles';
import { marketplaceTranslations } from '../../pages/Marketplace/translations';
import { translations } from '../translations';
import { MarketplaceWaitlist } from './content/MarketplaceWaitlist';
import { EmailOpt } from './content/EmailOpt';
import { Breakpoint } from '../../styling/breakpoints';
import { useResponsive } from '../../../hooks';

export interface ModalProps {
  handleResponse: (e: boolean) => void;
  isModalOpen: boolean;
  modalType: 'waitlist' | 'emailOpt';
}

export const NotificationModal: React.FC<ModalProps> = ({
  handleResponse,
  isModalOpen,
  modalType = 'waitlist',
}) => {
  const { waitlistModal: t } = useTranslationsController(
    marketplaceTranslations
  );
  const { emailOptModal: e } = useTranslationsController(translations);

  const { layout } = useResponsive();
  const isMobile = layout < Breakpoint.DESKTOP;

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        titleText={
          modalType === 'waitlist'
            ? t['MARKETPLACE.WAITLIST.MODAL.JOIN.TITLE']
            : ''
        }
        fullScreen={false}
        cancelHandler={
          modalType === 'waitlist' ? () => handleResponse(false) : undefined
        }
      >
        {modalType === 'waitlist' ? <MarketplaceWaitlist /> : <EmailOpt />}
        <s.ButtonsWrapper modalType={modalType} isMobile={isMobile}>
          <SecondaryButton
            onClick={() => handleResponse(false)}
            data-testid={'t_modal_button_remind'}
          >
            {modalType === 'waitlist'
              ? t['MARKETPLACE.WAITLIST.MODAL.REMIND']
              : e['EMAIL.OPT.MODAL.REMIND']}
          </SecondaryButton>
          <PrimaryButton
            onClick={() => handleResponse(true)}
            data-testid={'t_modal_button_join'}
          >
            {modalType === 'waitlist'
              ? t['MARKETPLACE.WAITLIST.MODAL.JOIN.BUTTON']
              : e['EMAIL.OPT.MODAL.JOIN.BUTTON']}
          </PrimaryButton>
        </s.ButtonsWrapper>
      </Modal>
    </>
  );
};

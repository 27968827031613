import { IconPathsData } from '../types';

export const iosCircleLock: IconPathsData = {
  paths: [
    {
      d: 'M21.277,10.846H20.032V8.423a4.981,4.981,0,1,0-9.962-.067v2.49H8.825A2.081,2.081,0,0,0,6.75,12.921v9.962a2.081,2.081,0,0,0,2.075,2.075H21.277a2.081,2.081,0,0,0,2.075-2.075V12.921A2.081,2.081,0,0,0,21.277,10.846Zm-5.5,6.475v3.57a.744.744,0,0,1-.69.747.727.727,0,0,1-.763-.726v-3.59a1.66,1.66,0,1,1,1.453,0Zm2.8-6.475H11.523V8.356a3.528,3.528,0,0,1,7.056,0Z',
      id: 'Icon_ionic-ios-lock',
      transform: 'translate(776.209 352)',
      fill: '#182b3a',
      viewBox: '0 0 512 512',
    },
    {
      d: 'M24.016,3.07v1.4A2.011,2.011,0,0,0,25.5,6.4a15.412,15.412,0,1,1-8.324,0,2.011,2.011,0,0,0,1.482-1.932V3.07a2.012,2.012,0,0,0-2.517-1.946,20.773,20.773,0,1,0,10.373-.006A2.007,2.007,0,0,0,24.016,3.07Z',
      id: 'Icon_awesome-circle-notch',
      transform: 'translate(769.925 346.754)',
      fill: '#182b3a',
    },
  ],
  group: {
    id: 'lock-in-circle',
    transform: 'translate(-770.487 -347.809)',
  },
  defaultProps: {
    width: '2.5em',
    height: '2.5em',
    viewBox: '0 0 41.546 40.958',
  },
};

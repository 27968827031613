import styled, { css } from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

interface InputProps {
  error?: boolean;
  limitWidth?: boolean;
}

export const StandardInputStyles = css`
  all: unset;
  width: 100%;
  border: solid ${px2rem(1)};
  padding: 0 ${px2rem(12)};
  height: ${px2rem(40)};
  border-radius: ${px2rem(8)};
  box-sizing: border-box;
  background-color: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  will-change: background-color;
  transition: background-color 1.2s;
  border-color: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT}
  &::placeholder {
    opacity: 0.6;
  }
  &:disabled,
  :read-only {
    border: 0 none;
    background: ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
  }
  :not(:focus):invalid {
    border: 2px solid ${props => theme(props).COLOUR.ALERT};
  }
`;
export const TextInput = styled.input<InputProps>`
  ${StandardInputStyles}
  ${props => props.error && `border-color: ${theme(props).COLOUR.ALERT}`};
  ${props => props.limitWidth && `max-width: 20rem`};
`;

export const TextArea = styled.textarea<InputProps>`
  ${StandardInputStyles}
  ${props => props.error && `border-color: ${theme(props).COLOUR.ALERT}`};
  ${props => props.limitWidth && `max-width: 20rem`};
  padding: ${px2rem(8)} ${px2rem(12)};
  min-height: ${px2rem(100)};
  max-height: ${px2rem(200)};
  resize: vertical;
  white-space: pre-wrap;
`;

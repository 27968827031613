import React, { FC } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import Snackbar from '@material-ui/core/Snackbar';
import {
  Container,
  TextArea,
  Button,
  InputContainer,
} from './Clipboard.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ClipboardProps {
  value?: string;
  duration?: number;
  name?: string;
  copiedMessage?: string;
  titleTemplate?: string;
}

export const Clipboard: FC<ClipboardProps> = ({
  value,
  duration = 900,
  titleTemplate = `click to copy: ${value}`,
  copiedMessage = 'Copied to Clipboard',
  ...rest
}) => {
  const { target, copy, copied } = useClipboard({
    copiedTimeout: duration,
  });
  return (
    <Container data-testid="t_clipboard">
      <InputContainer>
        <TextArea
          disabled
          aria-label={value}
          readOnly
          ref={target}
          value={value || '...'}
          {...rest}
        />
        <Button
          title={titleTemplate}
          onClick={copy}
          disabled={!value}
          aria-disabled={!value}
        >
          <FontAwesomeIcon icon={'copy'} />
        </Button>
      </InputContainer>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={copied}
        autoHideDuration={duration}
        message={copiedMessage}
      />
    </Container>
  );
};

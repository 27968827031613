import React from 'react';
import { ErrorContainer, ButtonContainer } from './FormFooter.styles';
import { Box, Direction } from '../Box';

interface FormFooterProps {
  error?: string | null;
}

export const FormFooter: React.FC<FormFooterProps> = ({ error, children }) => {
  return (
    <Box width={'100%'} flex direction={Direction.COLUMN}>
      <ErrorContainer data-testid={'t_error'}>{error}</ErrorContainer>
      <ButtonContainer>{children}</ButtonContainer>
    </Box>
  );
};

import { DispatchFn } from '../../store/state';
import { BankingLocale, FormValues, Mode } from './store';
import client, { Microservice } from '../../utils/http.utils';
import { createPostPayload } from './payload';
import { captureException } from '../../utils/sentry.utils';
import { CustomError } from '../../types/error.types';

//ACTIONS
export enum BankingActions {
  setBankDetails = 'SET_BANK_DETAILS',
  setBankNickname = 'SET_BANK_NICKNAME',
  setBankLocale = 'SET_BANK_LOCALE',
  setEditMode = 'SET_EDIT_MODE',
  setAccountIndex = 'SET_ACCOUNTS_INDEX',
  setPaymentMethod = 'SET_PAYMENT_METHOD',

  requestGetCurrenciesAttempt = 'REQUEST_GET_CURRENCIES_ATTEMPT',
  requestGetCurrenciesSuccess = 'REQUEST_GET_CURRENCIES_SUCCESS',
  requestGetCurrenciesFailure = 'REQUEST_GET_CURRENCIES_FAILURE',

  requestGetCountriesAttempt = 'REQUEST_GET_COUNTRIES_ATTEMPT',
  requestGetCountriesSuccess = 'REQUEST_GET_COUNTRIES_SUCCESS',
  requestGetCountriesFailure = 'REQUEST_GET_COUNTRIES_FAILURE',

  requestGetCountryCurrencyDuplicateExistsAttempt = 'REQUEST_GET_COUNTRY_CURRENCY_DUPLICATE_EXISTS_ATTEMPT',
  requestGetCountryCurrencyDuplicateExistsSuccess = 'REQUEST_GET_COUNTRY_CURRENCY_DUPLICATE_EXISTS_SUCCESS',
  requestGetCountryCurrencyDuplicateExistsFailure = 'REQUEST_GET_COUNTRY_CURRENCY_DUPLICATE_EXISTS_FAILURE',

  requestGetPaymentFormAttempt = 'REQUEST_GET_PAYMENT_FORM_ATTEMPT',
  requestGetPaymentFormSuccess = 'REQUEST_GET_PAYMENT_FORM_SUCCESS',
  requestGetPaymentFormFailure = 'REQUEST_GET_PAYMENT_FORM_FAILURE',

  requestPostBankDetailsAttempt = 'REQUEST_POST_BANK_DETAILS_ATTEMPT',
  requestPostBankDetailsSuccess = 'REQUEST_POST_BANK_DETAILS_SUCCESS',
  requestPostBankDetailsFailure = 'REQUEST_POST_BANK_DETAILS_FAILURE',

  requestDeleteBankDetailsAttempt = 'REQUEST_DELETE_BANK_DETAILS_ATTEMPT',
  requestDeleteBankDetailsSuccess = 'REQUEST_DELETE_BANK_DETAILS_SUCCESS',
  requestDeleteBankDetailsFailure = 'REQUEST_DELETE_BANK_DETAILS_FAILURE',

  requestGetBankDetailsAttempt = 'REQUEST_GET_BANK_DETAILS_ATTEMPT',
  requestGetBankDetailsSuccess = 'REQUEST_GET_BANK_DETAILS_SUCCESS',
  requestGetBankDetailsFailure = 'REQUEST_GET_BANK_DETAILS_FAILURE',

  requestGetBankDetailsNoticesAttempt = 'REQUEST_GET_BANK_DETAILS_NOTICES_ATTEMPT',
  requestGetBankDetailsNoticesSuccess = 'REQUEST_GET_BANK_DETAILS_NOTICES_SUCCESS',
  requestGetBankDetailsNoticesFailure = 'REQUEST_GET_BANK_DETAILS_NOTICES_FAILURE',

  requestGetPaypalDetailsAttempt = 'REQUEST_GET_PAYPAL_ATTEMPT',
  requestGetPaypalDetailsSuccess = 'REQUEST_GET_PAYPAL_SUCCESS',
  requestGetPaypalDetailsFailure = 'REQUEST_GET_PAYPAL_FAILURE',

  requestPutPaypalDetailsAttempt = 'REQUEST_PUT_PAYPAL_ATTEMPT',
  requestPutPaypalDetailsSuccess = 'REQUEST_PUT_PAYPAL_SUCCESS',
  requestPutPaypalDetailsFailure = 'REQUEST_PUT_PAYPAL_FAILURE',

  requestPutBankDetailsAttempt = 'REQUEST_PUT_BANK_DETAILS_ATTEMPT',
  requestPutBankDetailsSuccess = 'REQUEST_PUT_BANK_DETAILS_SUCCESS',
  requestPutBankDetailsFailure = 'REQUEST_PUT_BANK_DETAILS_FAILURE',

  requestHidePaypalNoticeAttempt = 'REQUEST_HIDE_PAYPAL_NOTICE_ATTEMPT',
  requestHidePaypalNoticeSuccess = 'REQUEST_HIDE_PAYPAL_NOTICE_SUCCESS',
  requestHidePaypalNoticeFailure = 'REQUEST_HIDE_PAYPAL_NOTICE_FAILURE',

  clearBankAccountErrors = 'CLEAR_BANK_ACCOUNT_ERRORS',
}

//DIRECT ACTIONS
export const SetBankingDetails = (
  dispatch: DispatchFn,
  details: FormValues
) => {
  dispatch({
    type: BankingActions.setBankDetails,
    payload: details,
  });
};

export const SetBankingNickname = (dispatch: DispatchFn, nickname: string) => {
  dispatch({
    type: BankingActions.setBankNickname,
    payload: nickname,
  });
};

export const SetBankingLocale = (
  dispatch: DispatchFn,
  locale: BankingLocale
) => {
  dispatch({
    type: BankingActions.setBankLocale,
    payload: locale,
  });
};

export const SetEditMode = (dispatch: DispatchFn, mode: Mode) => {
  dispatch({
    type: BankingActions.setEditMode,
    payload: mode,
  });
};

export const SetAccountsIndex = (dispatch: DispatchFn, index: number) => {
  dispatch({
    type: BankingActions.setAccountIndex,
    payload: index,
  });
};

// ASYNC ACTIONS
export const RequestGetCurrenciesAsync = async (
  dispatch: DispatchFn
): Promise<void> => {
  dispatch({ type: BankingActions.requestGetCurrenciesAttempt });
  try {
    const response = await client.request(
      Microservice.PAYMENT,
      `/payment_details/currency`,
      'GET'
    );

    dispatch({
      type: BankingActions.requestGetCurrenciesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestGetCurrenciesFailure,
      payload: { error: 'An error occurred while getting valid currencies' },
    });
  }
};

export const RequestGetCountriesAsync = async (
  dispatch: DispatchFn,
  currency: string
): Promise<void> => {
  dispatch({ type: BankingActions.requestGetCountriesAttempt });
  try {
    const response = await client.request(
      Microservice.PAYMENT,
      `/payment_details/currency/${currency}/country`,
      'GET'
    );
    dispatch({
      type: BankingActions.requestGetCountriesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestGetCountriesFailure,
      payload: 'An error occurred while getting valid countries',
    });
  }
};

export const RequestGetPaymentFormAsync = async (
  dispatch: DispatchFn,
  currency: string,
  country: string
): Promise<void> => {
  dispatch({ type: BankingActions.requestGetPaymentFormAttempt });
  try {
    const response = await client.request(
      Microservice.PAYMENT,
      `/payment_details/currency/${currency}/country/${country}/payment_form`,
      'GET'
    );
    dispatch({
      type: BankingActions.requestGetPaymentFormSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestGetPaymentFormFailure,
      payload: 'An error occurred while getting valid fields',
    });
  }
};

export const RequestGetCountryCurrencyExistsAsync = async (
  dispatch: DispatchFn,
  currency: string,
  country: string,
  influencerId: string
): Promise<any> => {
  dispatch({
    type: BankingActions.requestGetCountryCurrencyDuplicateExistsAttempt,
  });
  try {
    const response = await client.request(
      Microservice.PAYMENT,
      `/payment_details/duplicate_exists/influencer/${influencerId}/currency/${currency}/country/${country}`,
      'GET'
    );
    dispatch({
      type: BankingActions.requestGetCountryCurrencyDuplicateExistsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestGetCountryCurrencyDuplicateExistsFailure,
      payload:
        "An error occured while getting the country-currency pair's duplicate existence",
    });
  }
};
//ACTION CREATORS
export const RequestPostBankingDetailsAsync = async (
  dispatch: DispatchFn,
  details: FormValues,
  locale: BankingLocale,
  influencer_id: string
): Promise<void> => {
  dispatch({ type: BankingActions.requestPostBankDetailsAttempt });
  try {
    await client.request(
      Microservice.PAYMENT,
      `/payment_details/influencer/${influencer_id}/international`,
      'POST',
      createPostPayload(details, locale)
    );
    dispatch({
      type: BankingActions.requestPostBankDetailsSuccess,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestPostBankDetailsFailure,
      payload: (e as CustomError).customMessage ?? {
        error: 'An error occurred while posting bank details',
      },
    });

    throw new Error();
  }
};

export const ClearBankAccountErrors = (dispatch: DispatchFn) => {
  dispatch({ type: BankingActions.clearBankAccountErrors });
};

export const RequestGetBankingDetailsNoticesAsync = async (
  dispatch: DispatchFn,
  influencer_id: string
): Promise<void> => {
  dispatch({ type: BankingActions.requestGetBankDetailsNoticesAttempt });
  try {
    const response = await client.request(
      Microservice.PAYMENT,
      `/payment_details/influencer/${influencer_id}/notices`,
      'GET'
    );
    dispatch({
      type: BankingActions.requestGetBankDetailsNoticesSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestGetBankDetailsNoticesFailure,
      payload: 'An error occurred while getting bank details notices',
    });
  }
};

export const RequestGetBankingDetailsAsync = async (
  dispatch: DispatchFn,
  influencer_id: string
): Promise<void> => {
  dispatch({ type: BankingActions.requestGetBankDetailsAttempt });
  try {
    const response = await client.request(
      Microservice.PAYMENT,
      `/payment_details/influencer/${influencer_id}/international`,
      'GET'
    );
    dispatch({
      type: BankingActions.requestGetBankDetailsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestGetBankDetailsFailure,
      payload: 'An error occurred while getting bank details',
    });
  }
};

export const RequestDeleteBankingDetailsAsync = async (
  dispatch: DispatchFn,
  influencerId: string,
  bankAccountId: string
): Promise<void> => {
  dispatch({ type: BankingActions.requestDeleteBankDetailsAttempt });
  try {
    await client.request(
      Microservice.PAYMENT,
      `/payment_details/influencer/${influencerId}/international/${bankAccountId}`,
      'DELETE'
    );
    dispatch({
      type: BankingActions.requestDeleteBankDetailsSuccess,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestDeleteBankDetailsFailure,
      payload: 'An error occurred while deleting bank details',
    });
    throw new Error();
  }
};

export const RequestPutBankingDetailsAsync = async (
  dispatch: DispatchFn,
  details: FormValues,
  locale: BankingLocale,
  bankAccountId: string,
  influencerId: string
): Promise<void> => {
  dispatch({ type: BankingActions.requestPutBankDetailsAttempt });
  try {
    await client.request(
      Microservice.PAYMENT,
      `/payment_details/influencer/${influencerId}/international/${bankAccountId}`,
      'PUT',
      createPostPayload(details, locale)
    );
    dispatch({
      type: BankingActions.requestPutBankDetailsSuccess,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestPutBankDetailsFailure,
      payload: (e as CustomError).customMessage ?? {
        error: 'An error occurred while editing bank details',
      },
    });
    throw new Error();
  }
};

export const RequestGetPayPalDetails = async (
  dispatch: DispatchFn,
  influencerId: string
): Promise<void> => {
  dispatch({ type: BankingActions.requestGetPaypalDetailsAttempt });
  try {
    const response = await client.request(
      Microservice.PAYMENT,
      `/payment_details/influencer/${influencerId}/paypal`,
      'GET'
    );
    dispatch({
      type: BankingActions.requestGetPaypalDetailsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestGetPaypalDetailsFailure,
      payload: 'An error occurred while fetching paypal details',
    });
  }
};

export const RequestHidePaypalNotice = async (
  dispatch: DispatchFn,
  influencerId: string
): Promise<void> => {
  dispatch({ type: BankingActions.requestPutPaypalDetailsAttempt });
  try {
    const response = await client.request(
      Microservice.PAYMENT,
      `/payment_details/influencer/${influencerId}/paypal`,
      'PUT'
    );
    dispatch({
      type: BankingActions.requestPutPaypalDetailsSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: BankingActions.requestPutPaypalDetailsFailure,
      payload: 'An error occurred while dismissing the notice',
    });
  }
};

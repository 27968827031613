import styled from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';
import { Box } from '../Box';

export const RedirectTitle = styled.h1`
  color: white;
  ${props => theme(props).TYPOGRAPHY.X_LARGE_2};
  line-height: ${props => theme(props).TYPOGRAPHY.X_LARGE_2};
  text-align: center;
  margin-bottom: ${props => theme(props).SIZE.LARGE};
`;

export const RedirectText = styled.h3`
  color: white;
  ${props => theme(props).TYPOGRAPHY.SMALL};
  text-align: center;
`;

export const RedirectContainer = styled(Box)`
  height: 100vh;
  width: 100vw;
  background-color: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
`;

export const RedirectContent = styled(Box)`
  border-radius: ${px2rem(8)};
  background-color: rgba(55, 79, 99, 0.76);
`;

export const LogoutBox = styled.div`
  align-self: end;
  justify-self: start;
  color: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
`;

export const ActionsContainer = styled(Box)`
  margin-top: ${px2rem(16)};
  margin-bottom: ${px2rem(16)};
`;

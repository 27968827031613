import React from 'react';
import { useResponsive } from '../../../../hooks/useResponsive';
import { Notification } from '../../../../store/Notification/store';
import { DispatchFn } from '../../../../store/state';
import { Breakpoint } from '../../../styling/breakpoints';
import {
  NotificationItemContainer,
  NotificationItemDuration,
  NotificationItemMessage,
  NotificationItemTextContainer,
} from './NotificationItem.styles';
import { NotificationItemIcon } from './NotificationItemIcon';
import { NotificationItemStatus } from './NotificationItemStatus';
import { useNotificationItem } from './useNotificationItem';

export const NotificationItem: React.FC<{
  notification: Notification;
  dispatch: DispatchFn;
  testId: string;
}> = ({ notification, dispatch, testId }) => {
  const { layout } = useResponsive();
  const { createdAt, markAsRead, handleClick } = useNotificationItem(
    notification,
    dispatch
  );
  const isMobile = layout < Breakpoint.DESKTOP;

  return (
    <NotificationItemContainer
      isMobile={isMobile}
      to={notification.url}
      data-testid={testId}
      onClick={handleClick}
    >
      <NotificationItemIcon notification={notification} />
      <NotificationItemTextContainer>
        <NotificationItemMessage>
          {notification.message}
        </NotificationItemMessage>
        <NotificationItemDuration>{createdAt}</NotificationItemDuration>
      </NotificationItemTextContainer>
      {!notification.read && (
        <NotificationItemStatus
          updateStatus={markAsRead}
          testId={`${testId}_status`}
        />
      )}
    </NotificationItemContainer>
  );
};

import {
  getKeycloakInstance,
  setKeycloakInstance,
} from '../keycloak/keycloak-init';
import { KeycloakTokenMap, UserPermissionMapApi } from '../store/User/types';
import { CustomErrorMessage, httpError } from '../types/error.types';
import { parseJws, setProfileToken } from './token.utils';

const ERR_NOT_AUTHED =
  'There was an error authenticating your request, please try again later.';
const ERR_PROFILE =
  'There was an error fetching your profile, please try again later.';

type HTTPMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

const EMPTY_RESPONSE = {};

const TEXT_CONTENT_TYPES = ['text/csv', 'text/plain'];

// Refresh KC Access Token when current token is about to expire in less than this threshold (ms)
const KC_EXPIRY_THRESHOLD = 120;

export enum Microservice {
  MANAGER = '/api/manager',
  PROGRAMME = '/api/programme',
  INFLUENCER = '/api/influencer',
  INVITATION = '/api/invitation',
  INSTAGRAM = '/api/instagram-integration',
  TRANSACTION = '/api/transaction',
  PAYMENT = '/api/payment-details',
  GOOGLE = '/api/google-integration',
  COMMISSION = '/api/commission',
  FILE = '/api/fileupload',
  NOTIFICATION = '/api/notification',
  PROFILE = '/api/profile',
  LOCALISATION = '/api/localisation',
  REPORTING = '/api/reporting',
  AGENCY = '/api/agency',
  MARKETPLACE = '/api/marketplace',
  FEATURE = '/api/feature',
  SEARCH = '/api/search',
}

const refreshProfileToken = async () => {
  await requestMinusProfile<string>(
    Microservice.PROFILE,
    '/token',
    'GET',
    undefined,
    'text/plain'
  )
    .then(token => setProfileToken(token))
    .catch(e => {
      throw e;
    });
};

const authRefreshIfRequired = async (
  skip?: boolean
): Promise<string | null> => {
  if (skip) return getProfileToken();
  const kc = getKeycloakInstance();
  if (!kc) {
    throw new Error(ERR_NOT_AUTHED);
  }
  let profileToken = getProfileToken();

  await kc
    .updateToken(KC_EXPIRY_THRESHOLD)
    .then(async refreshed => {
      if (refreshed && kc) {
        setKeycloakInstance(kc);
        if (profileToken && kc && kc.token) {
          await refreshProfileToken().catch(() => {
            throw new Error(ERR_PROFILE);
          });
        }
      } else {
        if (profileToken && kc && kc.token) {
          const profileKeycloakTokenId =
            parseJws<UserPermissionMapApi>(profileToken).keycloak_token_id;
          const keycloakTokenId = parseJws<KeycloakTokenMap>(kc.token).jti;

          if (profileKeycloakTokenId !== keycloakTokenId) {
            await refreshProfileToken().catch(() => {
              throw new Error(ERR_PROFILE);
            });
          }
        }
      }
    })
    .catch(() => {
      throw new Error(ERR_NOT_AUTHED);
    });

  profileToken = getProfileToken();

  if (!profileToken) throw new Error(ERR_PROFILE);
  return profileToken;
};

const getMediaUrl = (blob: Blob): string => {
  return URL.createObjectURL(blob);
};

const handleError = async (res: Response) => {
  return res
    .text()
    .then(message => {
      if (message && message.length > 0) {
        const errorObject = JSON.parse(message);
        const errMap = errorObject.validation_errors;
        return httpError(
          res.status,
          errMap ? (errMap as CustomErrorMessage) : 'An error occurred'
        );
      } else {
        return httpError(res.status, 'An error occurred');
      }
    })
    .catch(e => {
      return e;
    });
};

const getProfileToken = () => {
  return window.profileToken;
};

export const getLanguageHeader = () => {
  const langPref = window.localStorage?.getItem('languagePreference');
  return langPref && JSON.parse(langPref) !== ''
    ? JSON.parse(langPref)
    : 'en-GB';
};

/**
 * Sends a new HTTP request using window.fetch, will handle parsing to and from JSON automatically.
 * - Note: this function does not have error handling - this should be handled wherever it is used.
 * @param url - The URL to hit (full)
 * @param method - GET | POST | PUT | DELETE
 * @param body - JS body - Not JSON
 */
const httpReq = async <T, V = null>(
  url: string,
  method: HTTPMethod,
  contentType: string,
  body?: V | null,
  skipProfile?: boolean
) => {
  const profileToken = await authRefreshIfRequired(skipProfile);
  const kc = getKeycloakInstance();
  if (!kc) {
    throw new Error(ERR_NOT_AUTHED);
  }

  const request: RequestInit = {
    method,
    headers: {
      Authorization: `Bearer ${kc.token}`,
      ...(profileToken && { Profile: profileToken }),
      'X-Society-Language': getLanguageHeader(),
    },
  };
  if (contentType) {
    request.headers = {
      ...request.headers,
      'Content-Type': contentType,
    };
  }

  if (body) {
    if (contentType) {
      request.body = JSON.stringify(body) as any;
    } else {
      request.body = body as any;
    }
  }

  return window
    .fetch(url, request)
    .then(async res => {
      if (!res.ok) {
        const errr = await handleError(res.clone());
        throw errr;
      } else {
        return res.text();
      }
    })
    .then(res => {
      if (contentType && TEXT_CONTENT_TYPES.includes(contentType)) {
        return res as unknown as Promise<T>;
      }
      return res.length > 0
        ? (JSON.parse(res) as Promise<T>)
        : (EMPTY_RESPONSE as Promise<T>);
    });
};

/**
 * Sends a new HTTP request using window.fetch, will handle parsing to and from JSON automatically.
 * - Note: this function does not have error handling - this should be handled wherever it is used.
 * @param url - The URL to hit (full)
 * @param method - GET | POST | PUT | DELETE
 * @param body - JS body - Not JSON
 */
const httpMediaReq = async <V = null>(
  url: string,
  method: HTTPMethod,
  contentType: string | null,
  body?: V | null
) => {
  const profileToken = await authRefreshIfRequired();
  const kc = getKeycloakInstance();
  if (!kc) {
    throw new Error(ERR_NOT_AUTHED);
  }

  const request: RequestInit = {
    method,
    headers: {
      Authorization: `Bearer ${kc.token}`,
      ...(profileToken && { Profile: profileToken }),
    },
  };

  if (contentType) {
    request.headers = {
      ...request.headers,
      'Content-Type': contentType,
    };
  }

  if (body) {
    request.body = body as any;
  }

  return window
    .fetch(url, request)
    .then(res => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.blob();
    })
    .then(res => {
      return res.size > 0 ? getMediaUrl(res) : null;
    });
};

/**
 * Sends a new HTTP request using window.fetch, will handle parsing to and from JSON automatically.
 * Receives file name from header.
 * - Note: this function does not have error handling - this should be handled wherever it is used.
 * @param url - The URL to hit (full)
 * @param method - GET | POST | PUT | DELETE
 * @param body - JS body - Not JSON
 */
const httpMediaReqWithHeader = async <V = null>(
  url: string,
  method: HTTPMethod,
  contentType?: string,
  body?: V | null
) => {
  const profileToken = await authRefreshIfRequired();
  const kc = getKeycloakInstance();

  if (!kc) {
    throw new Error('ERR_NOT_AUTHED');
  }

  const request: RequestInit = {
    method,
    headers: {
      Authorization: `Bearer ${kc.token}`,
      ...(profileToken && { Profile: profileToken }),
    },
  };

  if (contentType) {
    request.headers = {
      ...request.headers,
      'Content-Type': contentType,
    };
  }

  if (body) {
    request.body = body as any;
  }

  let filename: string | null = null;

  return window
    .fetch(url, request)
    .then(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : null;
      if (!res.ok) {
        throw new Error(res.statusText);
      }

      return res.blob();
    })
    .then(blob => {
      const urlBlob = blob.size > 0 ? getMediaUrl(blob) : null;
      return { url: urlBlob, filename };
    });
};

const httpMediaReqNoAuth = async <V = null>(
  url: string,
  method: HTTPMethod,
  contentType: string | null,
  body?: V | null
) => {
  const request: RequestInit = {
    method,
  };

  if (contentType) {
    request.headers = {
      ...request.headers,
      'Content-Type': contentType,
    };
  }

  if (body) {
    request.body = body as any;
  }

  return window
    .fetch(url, request)
    .then(res => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.blob();
    })
    .then(res => {
      return res.size > 0 ? getMediaUrl(res) : null;
    });
};

const httpReqMinusProfile = async <T, V = null>(
  url: string,
  method: HTTPMethod,
  contentType: string,
  body?: V | null
) => {
  const kc = getKeycloakInstance();
  if (!kc) {
    throw new Error(ERR_NOT_AUTHED);
  }

  const request: RequestInit = {
    method,
    headers: {
      Authorization: `Bearer ${kc.token}`,
      'X-Society-Language': getLanguageHeader(),
    },
  };
  if (contentType) {
    request.headers = {
      ...request.headers,
      'Content-Type': contentType,
    };
  }

  if (body) {
    if (contentType) {
      request.body = JSON.stringify(body) as any;
    } else {
      request.body = body as any;
    }
  }

  return window
    .fetch(url, request)
    .then(async res => {
      if (!res.ok) {
        const errr = await handleError(res.clone());
        throw errr;
      } else {
        return res.text();
      }
    })
    .then(res => {
      if (contentType && TEXT_CONTENT_TYPES.includes(contentType)) {
        return res as unknown as Promise<T>;
      }
      return res.length > 0
        ? (JSON.parse(res) as Promise<T>)
        : (EMPTY_RESPONSE as Promise<T>);
    });
};

const requestStatus = async (url: string): Promise<boolean> => {
  return await httpStatusReq(url, 'GET').catch(() => {
    return false;
  });
};

const httpStatusReq = async (
  url: string,
  method: HTTPMethod
): Promise<boolean> => {
  const kc = getKeycloakInstance();
  if (!kc) {
    throw new Error(ERR_NOT_AUTHED);
  }

  const request: RequestInit = {
    method,
  };

  return window.fetch(url, request).then(res => {
    return res.ok;
  });
};

const httpReqNoAuth = async <T, V = null>(
  url: string,
  method: HTTPMethod,
  contentType: string,
  body?: V | null
) => {
  const request: RequestInit = {
    method,
    headers: {
      'X-Society-Language': getLanguageHeader(),
    },
  };
  if (contentType) {
    request.headers = {
      ...request.headers,
      'Content-Type': contentType,
    };
  }

  if (body) {
    if (contentType) {
      request.body = JSON.stringify(body) as any;
    } else {
      request.body = body as any;
    }
  }

  return window
    .fetch(url, request)
    .then(async res => {
      if (!res.ok) {
        const errr = await handleError(res.clone());
        throw errr;
      } else {
        return res.text();
      }
    })
    .then(res => {
      if (contentType && TEXT_CONTENT_TYPES.includes(contentType)) {
        return res as unknown as Promise<T>;
      }
      return res.length > 0
        ? (JSON.parse(res) as Promise<T>)
        : (EMPTY_RESPONSE as Promise<T>);
    });
};

/**
 * Sends a new request to the chosen Microservice
 * @param microservice - The microservice that the request should be forwarded to
 * @param url - The path (everything after the domain name)
 * @param method - HTTP Method -- GET | POST | PUT | DELETE
 * @param body - JS body (not JSON)
 */
const request = <T, V = null>(
  microservice: Microservice,
  url: string,
  method: HTTPMethod,
  body?: V,
  contentType = 'application/json',
  skipProfile?: boolean
) => {
  const fullUrl = `${microservice}${url}`;
  return httpReq<T, V>(fullUrl, method, contentType, body, skipProfile);
};

/**
 * Sends a new request to the chosen Microservice
 * @param microservice - The microservice that the request should be forwarded to
 * @param url - The path (everything after the domain name)
 * @param method - HTTP Method -- GET | POST | PUT | DELETE
 * @param body - JS body (not JSON)
 */
const requestMedia = <V = null>(
  microservice: Microservice,
  url: string,
  method: HTTPMethod,
  withAuth = true,
  body?: V
) => {
  const fullUrl = `${microservice}${url}`;
  return withAuth
    ? httpMediaReq<V>(fullUrl, method, null, body)
    : httpMediaReqNoAuth<V>(fullUrl, method, null, body);
};

/**
 * Sends a new request to the chosen Microservice
 * Receives file name from header
 * @param microservice - The microservice that the request should be forwarded to
 * @param url - The path (everything after the domain name)
 * @param method - HTTP Method -- GET | POST | PUT | DELETE
 * @param body - JS body (not JSON)
 */
const requestMediaWithHeader = async <V = null>(
  microservice: Microservice,
  url: string,
  method: HTTPMethod,
  body?: V,
  contentType?: string
): Promise<{ url: string | null; filename: string | null }> => {
  const fullUrl = `${microservice}${url}`;
  return await httpMediaReqWithHeader<V>(fullUrl, method, contentType, body);
};

/**
 * Sends a new request to the chosen Microservice
 * @param microservice - The microservice that the request should be forwarded to
 * @param url - The path (everything after the domain name)
 * @param method - HTTP Method -- GET | POST | PUT | DELETE
 * @param body - JS body (not JSON)
 */
const requestMinusProfile = <T, V = null>(
  microservice: Microservice,
  url: string,
  method: HTTPMethod,
  body?: V,
  contentType = 'application/json'
) => {
  const fullUrl = `${microservice}${url}`;
  return httpReqMinusProfile<T, V>(fullUrl, method, contentType, body);
};

/**
 * Sends a new request to the chosen Microservice
 * @param microservice - The microservice that the request should be forwarded to
 * @param url - The path (everything after the domain name)
 * @param method - HTTP Method -- GET | POST | PUT | DELETE
 * @param body - JS body (not JSON)
 */
const requestNoAuth = <T, V = null>(
  microservice: Microservice,
  url: string,
  method: HTTPMethod,
  body?: V,
  contentType = 'application/json'
) => {
  const fullUrl = `${microservice}${url}`;
  return httpReqNoAuth<T, V>(fullUrl, method, contentType, body);
};

/**
 * Sends a new request to the API
 * @param url - The path (everything after the domain name)
 * @param method - HTTP Method -- GET | POST | PUT | DELETE
 * @param body - JS body (not JSON)
 */
const apiRequest = <T, V = null>(url: string, method: HTTPMethod, body?: V) => {
  return request<T, V>(Microservice.MANAGER, url, method, body);
};

const mockRequest = <T, V = null>(
  url: string,
  method: HTTPMethod,
  body?: V | null,
  contentType = 'application/json'
) => {
  const fullUrl = `/api/mock/${url}`;
  return httpReq<T, string>(fullUrl, method, contentType, JSON.stringify(body));
};

export default {
  request,
  requestMedia,
  requestMediaWithHeader,
  requestMinusProfile,
  requestNoAuth,
  apiRequest,
  mockRequest,
  requestStatus,
};

import React from 'react';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useYoutubeLogin } from './useYoutubeLogin';
import { YoutubeButton } from './YoutubeLogin.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '../../../../common';

interface YoutubeLogin extends ReturnType<typeof useYoutubeLogin> {
  fullWidth?: boolean;
  monochrome?: boolean;
  LoginComponent?: typeof GoogleLogin;
  buttonText?: string;
}

export const YoutubeLogin: React.FC<YoutubeLogin> = ({
  successHandler,
  failureHandler,
  isLoading,
  youtubeErrorMessage,
  fullWidth = false,
  monochrome = false,
  buttonText = '',
}) => {
  const login = useGoogleLogin({
    onSuccess: response => successHandler(response),
    onError: error => failureHandler(error),
    onNonOAuthError: error => failureHandler(error),
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.readonly',
  });
  return (
    <>
      <YoutubeButton
        onClick={() => login()}
        disabled={isLoading}
        aria-disabled={isLoading}
        data-testid="t_youtube_login"
        fullWidth={fullWidth}
        monochrome={monochrome}
      >
        <FontAwesomeIcon icon={['fab', 'youtube']} />
        <span>{buttonText}</span>
      </YoutubeButton>
      {!!youtubeErrorMessage && <ErrorMessage message={youtubeErrorMessage} />}
    </>
  );
};

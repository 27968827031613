import ReactGA from 'react-ga4';
import { replaceAmpersandAndUnderscore } from '../../../utils/string.utils';

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on the Marketplace tab
 * Success
 */
const openMarketplaceTabSuccess = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer clicks on the Marketplace tab.',
    label: 'Success',
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer scrolls on the Marketplace product list
 * Success
 */
const scrollMarketplaceProductListSuccess = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer scrolls through the Product List.',
    label: 'Success',
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer opens the product search in marketplace
 * Attempt
 */
const marketplaceProductSearchAttempt = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer uses search feature in the product page.',
    label: 'Attempt',
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks on a product to see more information in marketplace
 * Attempt
 */
const marketplaceGetProductInformationAttempt = () => {
  ReactGA.event({
    category: 'Marketplace',
    action: 'Influencer clicks on a product in Marketplace tab.',
    label: 'Attempt',
  });
};

/**
 * Google Analytics Event Trigger
 *
 * When an influencer clicks apply button in product filters modal
 */
const marketplaceSetProductFilters = (
  advertisers: string[],
  industries: string[]
) => {
  const industriesFormatted = replaceAmpersandAndUnderscore(industries);
  ReactGA.event({
    category: 'Connect Product List Filter',
    action: `Influencer applies product filters with: retailers: ${advertisers.toString()} industries: ${industriesFormatted}`,
    label: 'Success',
  });
};

export default {
  openMarketplaceTabSuccess,
  scrollMarketplaceProductListSuccess,
  marketplaceProductSearchAttempt,
  marketplaceGetProductInformationAttempt,
  marketplaceSetProductFilters,
};

import React, { FC } from 'react';
import { FlexContainer, TextButton, SecondaryButton } from '..';
import { useTranslationsController } from '../../../utils/globalTranslationsController';
import { px2rem } from '../../styling/sizing';
import { Spacer } from '../Spacer/Spacer';

const translationObject = {
  tourStep: {
    'PROGRAMMES.TOUR.SKIP': 'Skip',
    'PROGRAMMES.TOUR.OK': 'Got it!',
    'PROGRAMMES.TOUR.NEXT': 'Next',
  },
};

export interface TourStepProps {
  text: string;
  onNext?: () => void;
  onClose: () => void;
  isLast?: boolean;
  index: number;
}

export const TourStep: FC<TourStepProps> = ({
  text,
  onNext,
  onClose,
  isLast = false,
  index,
}) => {
  const { tourStep: t } = useTranslationsController(translationObject);

  return (
    <FlexContainer
      data-testid={`t_tour_step_${index}`}
      margin={px2rem(8)}
      flexDirection="column"
    >
      {`${text}`}
      <Spacer isVertical amount={16} />
      <FlexContainer
        data-testid={`t_tour_wrapper_${index}`}
        fullWidth
        justifyContent={isLast ? 'flex-end' : 'space-between'}
      >
        {isLast ? null : (
          <TextButton
            data-testid={`t_tour_skip_btn_${index}`}
            onClick={onClose}
          >
            {t['PROGRAMMES.TOUR.SKIP']}
          </TextButton>
        )}
        {isLast || !onNext ? (
          <SecondaryButton
            data-testid={`t_tour_finish_btn_${index}`}
            onClick={onClose}
          >
            {t['PROGRAMMES.TOUR.OK']}
          </SecondaryButton>
        ) : (
          <SecondaryButton
            data-testid={`t_tour_next_btn_${index}`}
            onClick={onNext}
          >
            {t['PROGRAMMES.TOUR.NEXT']}
          </SecondaryButton>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

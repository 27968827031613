import { SocialState, SocialActions } from './store';
import { Action } from '../state';
import {
  transformGetFacebookResponse,
  transformGetYoutubeResponse,
} from './transformer';

export const SocialReducer = (
  state: SocialState,
  action: Action
): SocialState => {
  switch (action.type) {
    case SocialActions.requestPostFacebookTokenAttempt:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          facebook: true,
        },
        errors: {
          ...state.errors,
          facebook: null,
        },
      };
    case SocialActions.requestPostFacebookTokenSuccess:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          facebook: false,
        },
      };
    case SocialActions.requestPostFacebookTokenFailure:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          facebook: false,
        },
        errors: {
          ...state.errors,
          facebook: action.payload,
        },
      };
    case SocialActions.requestGetFacebookStatusAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          facebook: true,
        },
        isRequested: {
          ...state.isRequested,
          facebook: true,
        },
        errors: {
          ...state.errors,
          facebook: null,
        },
      };
    case SocialActions.requestGetFacebookStatusSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          facebook: false,
        },
        isInitialised: {
          ...state.isInitialised,
          facebook: true,
        },
        facebook: transformGetFacebookResponse(action.payload),
      };
    case SocialActions.requestGetFacebookStatusFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          facebook: false,
        },
        isInitialised: {
          ...state.isInitialised,
          facebook: true,
        },
        errors: {
          ...state.errors,
          facebook: action.payload,
        },
      };
    case SocialActions.requestPostYoutubeTokenAttempt:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          youtube: true,
        },
        errors: {
          ...state.errors,
          youtube: null,
        },
      };
    case SocialActions.requestPostYoutubeTokenSuccess:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          youtube: false,
        },
      };
    case SocialActions.requestPostYoutubeTokenFailure:
      return {
        ...state,
        isUpdating: {
          ...state.isUpdating,
          youtube: false,
        },
        errors: {
          ...state.errors,
          youtube: action.payload,
        },
      };

    case SocialActions.requestGetYoutubeStatusAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          youtube: true,
        },
        isRequested: {
          ...state.isRequested,
          youtube: true,
        },
        errors: {
          ...state.errors,
          youtube: null,
        },
      };
    case SocialActions.requestGetYoutubeStatusSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          youtube: false,
        },
        isInitialised: {
          ...state.isInitialised,
          youtube: true,
        },
        youtube: transformGetYoutubeResponse(action.payload),
      };
    case SocialActions.requestGetYoutubeStatusFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          youtube: false,
        },
        isInitialised: {
          ...state.isInitialised,
          youtube: true,
        },
        errors: {
          ...state.errors,
          youtube: action.payload,
        },
      };
    case SocialActions.clearFacebookLoginError:
      return {
        ...state,
        errors: {
          ...state.errors,
          facebook: null,
        },
      };
    default:
      return state;
  }
};

import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Tabs, Tab } from '@material-ui/core';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';
import { Breakpoint } from '../../styling/breakpoints';

const Wrapper = styled.div`
  & .MuiTabs-flexContainer.MuiTabs-centered {
    flex-wrap: wrap;
  }
  & .MuiTabs-indicator {
    background: ${props => theme(props).COLOUR.GRADIENT_BAR};
    height: ${px2rem(3)};
  }
  & .MuiTab-wrapper {
    font-size: ${px2rem(11)};
  }
  margin-bottom: ${props => theme(props).SIZE.MEDIUM};

  @media screen and (max-width: ${px2rem(Breakpoint.MOBILE, 'em')}) {
    & .MuiTabs-indicator {
      display: none;
    }

    & .Mui-selected {
      text-decoration: underline;
    }
  }
`;

interface OptionsBarProps {
  value?: number;
  onChange?: (e: any, v: any) => void;
  labels: string[];
  disabled?: boolean;
}

export const OptionsBar: FC<OptionsBarProps> = ({
  value = 0,
  onChange,
  labels,
  disabled,
}) => {
  return (
    <Wrapper data-testid="t_options_bar">
      <Tabs value={value} onChange={onChange} centered data-testid="t_options">
        {labels &&
          labels.map((label: string, index: number) => {
            return (
              <Tab
                data-tour={label && `${label.toLowerCase()}_tab`}
                disabled={labels.length < 2 || disabled}
                aria-disabled={labels.length < 2}
                key={label}
                data-testid={`t_options-tab-${index}`}
                label={label}
              />
            );
          })}
      </Tabs>
    </Wrapper>
  );
};

import React from 'react';
import { icons } from './iconsData';
import { SvgPath, CustomIconProps } from './types';

const PathData = (props: Omit<SvgPath, 'svg' | 'group'>): JSX.Element => {
  return (
    <>
      {props.paths.map((path, key) => {
        return <path {...path} key={key} />;
      })}
    </>
  );
};

const CustomIcon = ({ icon, override }: CustomIconProps): JSX.Element => {
  const { svg, paths, group }: SvgPath = icons.icon({ name: icon });
  const getSvgAndOverrideWithUserProps = () => {
    return { ...svg, ...(override?.svg || {}) };
  };

  const getGroupAndOverrideWithUserProps = () => {
    return { ...group, ...(override?.group || {}) };
  };

  if (group) {
    return (
      <svg {...getSvgAndOverrideWithUserProps()}>
        <g {...getGroupAndOverrideWithUserProps()}>
          <PathData paths={paths} />
        </g>
      </svg>
    );
  }

  return (
    <svg {...getSvgAndOverrideWithUserProps()}>
      <PathData paths={paths} />
    </svg>
  );
};

export { CustomIcon };

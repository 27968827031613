import React, { FC } from 'react';
import { CookieConsent } from '../../common/CookieConsent';
import { ConsentBannerWrapper } from './ConsentBanner.styles';
import { CookieConsentProps } from '../../common/CookieConsent/types';
import { useNavigation } from '../Navigation/useNavigation';
import { cookiesBannerTranslation } from './translations';
import { useTranslationsController } from '../../../utils/globalTranslationsController';

export const ConsentBanner: FC = () => {
  const { language } = useNavigation();
  const { cookiesBanner: t } = useTranslationsController(
    cookiesBannerTranslation
  );

  const props: CookieConsentProps = {
    linkUrl: `/about/cookies${language ? '.' + language : ''}.html`,
    ariaAcceptLabel: t['CONSENTBANNER.ARIALABEL.ACCEPT'],
    buttonText: t['CONSENTBANNER.BUTTON.ACCEPT'],
    mainTextPrefix: t['CONSENTBANNER.MAINTEXT.PREFIX'],
    linkText: t['CONSENTBANNER.LINK.TEXT'],
    mainTextSuffix: t['CONSENTBANNER.LINK.SUFFIX'],
  };

  return (
    <ConsentBannerWrapper>
      <CookieConsent {...props} />
    </ConsentBannerWrapper>
  );
};

export const socialDialogTranslation = {
  socialDialog: {
    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.SUBTITLE':
      'Looks like your connection is not valid anymore!',
    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.RECONNECTION_MESSAGE':
      'You can continue using Society without reconnecting.',
    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.EXPLANATION':
      'It might be happening because of the following reasons:',
    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.PASSWORD':
      'You have updated the password of your social account.',
    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.DISCONNECTED':
      'You have disconnected the Facebook page with your IG professional account.',
    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.SECURITY':
      'For security reasons, {0} requires you to refresh your connection.',
    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.DAYS_LEFT': '{0} Days',
    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.CONNECT':
      'To continue, please connect at least one of the following social channels',
    'LAYOUT.SOCIAL_CONTAINER.SOCIAL_DIALOG.CLOSE': 'Close',
  },
};

import React, { useState, MouseEvent } from 'react';
import {
  Hamburger,
  NavContainer,
  BarsContainer,
  BellContainer,
} from './MobileNav.styles';
import { Logo } from '../../../common/Logo';
import { NavigationPage } from './NavigationPage';
import { NotificationController } from '../../../pages/Notifications';

export const MobileNav: React.FC<{
  logoutHandler: (e: MouseEvent<Element>) => void;
  isExpanded?: boolean;
  language?: string;
  showMarketplace: boolean;
}> = ({
  logoutHandler,
  isExpanded = false,
  language = '',
  showMarketplace,
}) => {
  const [isNavExpanded, toggleExpanded] = useState(isExpanded);

  return (
    <NavContainer role="navigation" data-testid="t_mobile_nav">
      <BarsContainer>
        <Hamburger
          aria-label="toggle menu"
          aria-expanded={isNavExpanded}
          onClick={() => toggleExpanded(!isNavExpanded)}
          isChanging={isNavExpanded}
        >
          <span></span>
          <span></span>
          <span></span>
        </Hamburger>
      </BarsContainer>
      <Logo />
      <BellContainer>
        <NotificationController />
      </BellContainer>
      {isNavExpanded && (
        <NavigationPage
          logoutHandler={logoutHandler}
          toggleNavigation={() => toggleExpanded(!isNavExpanded)}
          language={language}
          showMarketplace={showMarketplace}
        />
      )}
    </NavContainer>
  );
};

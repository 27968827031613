import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import React, { useMemo } from 'react';
import { IconButtonStyle, IconButtonCircle } from './IconButton.styles';
import { IconButtonStyleProps } from './IconButton.types';

interface IconButtonProps extends IconButtonStyleProps {
  tooltip: string;
  icon: IconProp;
}

export const IconButton: React.FC<IconButtonProps> = ({
  tooltip,
  icon,
  variant,
  isMobile,
  isInlineText = false,
  ...intrinsic
}) => {
  const button = useMemo(() => {
    switch (variant) {
      case 'circle':
        return (
          <IconButtonCircle {...intrinsic} variant={variant}>
            <FontAwesomeIcon icon={icon} />
          </IconButtonCircle>
        );

      default:
        return (
          <IconButtonStyle
            {...intrinsic}
            variant={variant}
            isMobile={isMobile}
            isInlineText={isInlineText}
          >
            <FontAwesomeIcon icon={icon} />
          </IconButtonStyle>
        );
    }
  }, [intrinsic, variant, isMobile, isInlineText, icon]);

  return (
    <Tooltip title={tooltip} placement="top">
      {button}
    </Tooltip>
  );
};

import styled from 'styled-components';
import { Breakpoint } from '../../../styling/breakpoints';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';
import { ButtonProps, StandardButtonStyles } from '../../Button';

const InterestsList = styled.ul`
  width: 100%;
  display: grid;
  grid-gap: ${px2rem(16)} ${px2rem(16)};
  grid-template-columns: repeat(auto-fit, 11.25rem);
  justify-content: center;

  @media (max-width: ${px2rem(Breakpoint.TABLET, 'em')}) {
    grid-template-columns: 1fr;
  }
`;

const InterestContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 11rem;
  padding-bottom: 1rem;
`;

const IconHolder = styled.div`
  font-size: 1.5rem;
  padding: 0.5rem;
`;

const IconCheckHolder = styled.div`
  font-size: 1.5rem;
  padding: 0.5rem;
`;

const InterestLabel = styled.div`
  width: inherit;
  height: fit-content;
  white-space: initial;
  padding: 0 ${props => theme(props).SIZE.SMALL};
`;

const CheckBoxButton = styled.div<ButtonProps>`
  ${StandardButtonStyles}
  border: ${px2rem(1)} solid ${props =>
    props.mode === 'dark'
      ? theme(props).COLOUR.SECONDARY
      : theme(props).COLOUR.SECONDARY_DARKER};
  color: ${props =>
    props.mode === 'dark'
      ? theme(props).COLOUR.SECONDARY
      : theme(props).COLOUR.SECONDARY_DARKER};};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    background-color: ${props => `${theme(props).COLOUR.SECONDARY_LIGHTER}25`};
    color: ${props => theme(props).COLOUR.SECONDARY_DARK};
  }

  label:hover {
    cursor: pointer;
  }

  &[data-active='true'] {
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.SECONDARY_DARK};
    background-color: ${props => theme(props).COLOUR.SECONDARY_DARK};
    color: white;

    &:hover {
      background-color: ${props => theme(props).COLOUR.SECONDARY_DARKER};
      text-shadow: none;
    }
  }

  &[data-disabled='true'] {
    color: ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    background-color: transparent;
    border: ${px2rem(1)} solid ${props => theme(props).COLOUR.BACKGROUND_LIGHT};
    transform: none;
    opacity: 0.3;

    label {
      cursor: not-allowed;
    }
  }
`;

const CheckBoxLabel = styled.label`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
`;

const CheckBoxInput = styled.input`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
`;

const CheckBoxSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InterestItem = styled.li<{ isDesktop?: boolean }>`
  display: flex;
  justify-content: center;
  ${CheckBoxButton} {
    width: ${props => (props.isDesktop ? 'auto' : '100%')};
    height: 100%;
    min-height: 6.5rem;
  }
`;

export default {
  InterestsList,
  InterestLabel,
  CheckBoxButton,
  CheckBoxLabel,
  CheckBoxInput,
  CheckBoxSpan,
  InterestContent,
  IconHolder,
  IconCheckHolder,
  InterestItem,
};

import styled, { keyframes, css } from 'styled-components/macro';
import { px2rem } from '../../../../styling/sizing';
import { theme, Theme } from '../../../../styling/theme';
import { LinkFocusOverlayStyles } from '../../../../common/Link';
import { LinkText } from '../MenuItem/MenuItem.styles';

const SlideDown = keyframes`
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
`;

const IconDimensions = css`
  height: ${px2rem(60)};
  width: ${px2rem(60)};
  padding: ${px2rem(10)};
`;

const NavContainer = styled.div`
  width: 100vw;
  min-width: ${px2rem(300)};
  top: ${props => theme(props).SIZE.MOBILE_TOP};
  height: calc(100vh - ${props => theme(props).SIZE.MOBILE_TOP});
  background: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 ${px2rem(56)};
  position: fixed;
  z-index: 1;
  ${props => theme(props).TYPOGRAPHY.LARGE_1};
  overflow-y: auto;
  animation-name: ${SlideDown};
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
`;

const LinkContainer = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & ${LinkText} {
    ${props => theme(props).TYPOGRAPHY.MEDIUM_1};
    margin: 0;
    box-sizing: border-box;
    display: block;
    min-width: ${px2rem(175)};
    display: block;
    height: ${px2rem(20)};
  }
  & svg {
    ${IconDimensions}
    width: ${px2rem(48)} !important;
  }
  & > a {
    ${LinkFocusOverlayStyles};
    min-width: ${px2rem(250)};
    border-bottom: ${px2rem(2)} solid #e8e8e8;
    display: flex;
    width: 66vw;
    justify-content: center;
    height: ${px2rem(70)};
    color: white;
    position: relative;
  }
  padding: 0 0;
  &:last-child > a {
    border-bottom: 0 none;
  }
`;

const LinkTextContainer = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.125rem;
  padding: ${px2rem(16)};
  & > a {
    ${LinkFocusOverlayStyles};
    min-width: ${px2rem(100)};
    display: flex;
    height: 2.5rem;
    justify-content: start;
    color: white;
    position: relative;
    width: 66vw;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const LogoutContainer = styled.div`
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  & a {
    border-top: ${px2rem(2)} solid #e8e8e8;
  }
  & ${LinkText} {
    min-width: ${px2rem(90)};
  }
`;

const LanguageWrapper = styled.div`
  width: 60vw;
`;

export {
  NavContainer,
  LinkContainer,
  Container,
  LogoutContainer,
  LinkTextContainer,
  LanguageWrapper,
};

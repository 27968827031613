import React, { createContext, Dispatch, useState } from 'react';
import { Toast, ToastProps } from '../../common/Toast';

type ToastContextProps = {
  toastDetails: ToastProps;
  setToastDetails: Dispatch<React.SetStateAction<ToastProps>>;
};

const getDefaultToastProps = (): ToastProps => {
  return {
    message: '',
  };
};
const initialToast = getDefaultToastProps();

export const getDefaultToastContextProps = (): ToastContextProps => {
  return {
    toastDetails: initialToast,
    setToastDetails: () => {
      return;
    },
  };
};
const initialState = getDefaultToastContextProps();

export const ToastContext = createContext<ToastContextProps>(initialState);

export const ToastProvider: React.FC = ({ children }) => {
  const [toastDetails, setToastDetails] = useState<ToastProps>(initialToast);

  return (
    <ToastContext.Provider value={{ toastDetails, setToastDetails }}>
      {children}
      {toastDetails.message && (
        <Toast
          message={toastDetails.message}
          testId={toastDetails.testId}
          duration={toastDetails.duration}
          type={toastDetails.type}
        />
      )}
    </ToastContext.Provider>
  );
};

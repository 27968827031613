import React, { FC } from 'react';
import ReactCookieConsent from 'react-cookie-consent';
import { ExternalCookieLink } from './CookieConsent.styles';
import { CookieConsentProps } from './types';

export const CookieConsent: FC<CookieConsentProps> = ({
  linkUrl,
  ariaAcceptLabel,
  buttonText,
  mainTextPrefix,
  linkText,
  mainTextSuffix,
}) => {
  return (
    <ReactCookieConsent
      ariaAcceptLabel={ariaAcceptLabel}
      location="bottom"
      buttonText={buttonText}
      cookieName="societyInfluencerConsentGiven"
      style={{
        background: '#dbdbdb',
        color: '#333333',
        textAlign: 'left',
        fontWeight: 400,
      }}
      buttonStyle={{
        color: '#2b2b2b',
        background: '#ffffff',
        fontSize: '12px',
        fontWeight: 600,
        borderRadius: 4,
        maxWidth: 200,

        boxShadow: '0 0 1px 1px rgb(0 0 0 / 15%)',
      }}
    >
      {mainTextPrefix}{' '}
      <ExternalCookieLink href={linkUrl}>{linkText}</ExternalCookieLink>
      {mainTextSuffix}
    </ReactCookieConsent>
  );
};

import React, { FC, useRef } from 'react';
import { useExpando } from './useExpando';
import { ExpandoConfig } from './types';
import { Wrapper, Container } from './Expando.styles';

export const Expando: FC<{ config?: ExpandoConfig }> = ({
  config,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { dimensions } = useExpando(ref);
  return (
    <Wrapper dimensions={dimensions} config={config}>
      <Container ref={ref}>{children}</Container>
    </Wrapper>
  );
};

import { Pagination, PaginationApi } from '../types/api';

export const transformToPagination = (
  apiPagination: PaginationApi
): Pagination => {
  return {
    totalCount: apiPagination.total_count,
    limit: apiPagination.limit,
    page: apiPagination.page,
  };
};

import { Pagination, PaginationApi } from '../../types/api';
import { Currency } from '../../utils/localisation_currencies.utils';

export interface Statistics {
  clicks: number;
  campaigns: number;
  balance: number;
  commissions: number;
  conversions: number;
  conversionRate: number;
  sales: number;
}

export enum TransactionStatus {
  PENDING_APPROVAL = 'pending_approval',
  PENDING_PAYMENT = 'pending_payment',
  COMPLETE = 'complete',
  REJECTED = 'rejected',
  CLEARED = 'cleared',
}

export enum TransactionType {
  COMMISSION = 'commission',
  BONUS = 'bonus',
  REGULAR = 'regular',
  WITHDRAW = 'withdraw',
  AGENT_WITHDRAW = 'agent_withdraw',
  ONE_OFF = 'one_off',
  RECURRING = 'recurring',
}

export enum PaymentType {
  BONUS = 'Bonus Commission',
  REGULAR = 'Regular Payment',
  PAID = 'Paid Content',
  INFLUENCER_RECURRING = 'Recurring Payment',
  INFLUENCER_ONEOFF = 'One-Off Payment',
}

export enum TransactionCurrency {
  GBP = 'gbp',
  EUR = 'eur',
}

export interface BalancesApiModel {
  [index: string]: number;
}

export interface WithdrawalDetailsApi {
  influencer_name: string;
  programme_name: string;
  amount: number;
  transactionType: string;
}

export interface AgencyWithdrawalsApi {
  withdrawals: Record<string, WithdrawalDetailsApi[]>;
}

export type WithdrawalApiModel =
  | {
      transaction_bank_id: string;
      transaction_currency: string;
      agency_id: string;
    }
  | {
      transaction_bank_id: string;
      transaction_currency: string;
      influencer_id: string;
    };

export interface TransactionApiModel {
  transaction_id: string;
  influencer_id: string;
  programme_id?: string | null;
  transaction_type: string;
  transaction_currency: string;
  transaction_amount: number;
  transaction_commission: number;
  transaction_status: string;
  transaction_created: string;
  transaction_bank_id: string;
  payment_type?: string;
  payment_ref?: string;
  cleared_at?: string;
  vat: VatOverview | null;
  agency_id: string | null;
}

interface VatOverview {
  amount: number;
  status: 'permament' | 'estimated';
}

export interface ReadTransactionsApiModel {
  transactions: TransactionApiModel[];
  balances: BalancesApiModel;
  agencyWithdrawals?: AgencyWithdrawalsApi;
  pagination: PaginationApi | null;
}

export interface RawReadTransactionsApiModel {
  transactions: TransactionApiModel[];
  balances: BalancesApiModel;
  pagination: PaginationApi | null;
}

export interface Transaction {
  id: string;
  influencerId: string;
  programmeId: string | null;
  amount: number;
  status: TransactionStatus;
  createdOn: string;
  type: TransactionType;
  currency: string;
  paymentType?: PaymentType | null;
  paymentRef?: string | null;
  clearedAt?: string | null;
  vat: TransactionApiModel['vat'];
  agencyId: string | null;
}

export interface AgencyWithdrawals {
  withdrawalId: string;
  withdrawalTransactionDetails: Array<{
    influencerName: string;
    programmeName: string;
    amount: number;
    paymentType: string;
  }>;
}

export interface WithdrawalBreakdownWrapper {
  data?: WithdrawalBreakdown;
  isLoading: boolean;
  hasError: boolean;
}

export interface WithdrawalPayment {
  amount: number;
  transactionType: string;

  // Influencer only
  programmeId?: string;
  programmeTitle?: string;

  // Agency only
  paymentId?: string;
  influencerName?: string;
  programmeName?: string;
}

export interface WithdrawalBreakdown {
  withdrawalId: string;
  bankAccount: string | null;
  invoiceFileId?: string | null;
  payments: WithdrawalPayment[];
  isAgency: boolean;
}

export interface InfluencerWithdrawalBreakdownAPI {
  withdrawal_id: string;
  bank_account: string | null;
  invoice_file_id: string | null;
  payments: {
    programme_id: string;
    programme_title: string;
    amount: number;
    transaction_type: string;
  }[];
}

export interface AgencyWithdrawalBreakdownAPI {
  withdrawal_id: string;
  bank_account: string | null;
  payments: {
    payment_id: string;
    influencer_name: string;
    programme_name: string;
    amount: number;
    transaction_type: string;
  }[];
}

export type MinWithdrawAmounts = {
  [key in keyof typeof Currency]: number;
};

export const defaultStatistics: Statistics = {
  clicks: 0,
  campaigns: 0,
  balance: 0,
  commissions: 0,
  conversions: 0,
  conversionRate: 0,
  sales: 0,
};
export interface CurrencyBalance {
  currency: string;
  balance: number;
}

export type Balances = {
  [index: string]: number;
};

export type AutomaticWithdrawalSettings = {
  [index: string]: AutomaticWithdrawalCurrencySettings | undefined;
};

export type AutomaticWithdrawalCurrencySettings = {
  enabled: boolean;
  paymentDetailsId: string | null;
  currency: string;
  id: string;
};

export interface AutomaticWithdrawalSettingsApiModel {
  id: string;
  currency: string;
  payment_details_id: string | null;
  enabled: boolean;
}

export interface AutomaticWithdrawalUpdateApiModel {
  payment_details_id: string | null;
  enabled: boolean;
}

export interface WalletState {
  statistics: Statistics | null;
  transactions: Transaction[] | null;
  pagination?: Pagination;
  balances: Balances | null;
  withdrawals: AgencyWithdrawals[] | null;
  isFetching: boolean;
  isFetchingAccount: boolean;
  isFetchingMinWithdrawalAmounts: boolean;
  isFetchingBalances: boolean;
  error: string | null;
  requestWithdrawalError: string | null;
  requestWithdrawalValidationError: boolean;
  requestPaymentAccountError: string | null;
  requestMinAmountsError: string | null;
  currentBankStatus: CurrencyBalance | null;
  minWithdrawalAmounts: MinWithdrawAmounts | null;
  isFetchingAutomaticWithdrawalSettings: boolean;
  requestAutomaticWithdrawalSettingsError: string | null;
  automaticWithdrawalSettings: AutomaticWithdrawalSettings | null;
  isSavingAutomaticWithdrawalSettings: { [index: string]: boolean };
  saveAutomaticWithdrawalSettingsError: { [index: string]: string | null };
  agencyCurrencies: (keyof typeof Currency)[];
  firstTransactionDate: {
    date: string | null;
    isLoading: boolean;
    error: string | null;
  };
  walletDownload: {
    data:
      | {
          transaction_type: string;
          transaction_date: string;
          currency: string;
          amount: number;
          influencer_name: string;
          programme_name: string;
          payment_reference: string;
        }[]
      | null;
    isLoading: boolean;
    error: string | null;
  };
}

export const defaultWalletState: WalletState = {
  statistics: defaultStatistics,
  transactions: null,
  balances: null,
  withdrawals: null,
  isFetching: false,
  isFetchingAccount: false,
  isFetchingMinWithdrawalAmounts: false,
  isFetchingBalances: false,
  error: null,
  requestWithdrawalError: null,
  requestWithdrawalValidationError: false,
  requestPaymentAccountError: null,
  requestMinAmountsError: null,
  currentBankStatus: null,
  minWithdrawalAmounts: null,
  isFetchingAutomaticWithdrawalSettings: false,
  requestAutomaticWithdrawalSettingsError: null,
  automaticWithdrawalSettings: null,
  isSavingAutomaticWithdrawalSettings: {},
  saveAutomaticWithdrawalSettingsError: {},
  agencyCurrencies: [],
  walletDownload: {
    data: null,
    isLoading: false,
    error: null,
  },
  firstTransactionDate: {
    date: null,
    isLoading: false,
    error: null,
  },
};

export * from './reducer';
export * from './actions';

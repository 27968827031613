import { Dayjs } from 'dayjs';
export { NotificationReducer } from './reducer';

export * from './actions';

export enum NotificationPriority {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum NotificationType {
  MARKETER_PAYMENT = 'marketer_payment',
}

export interface NotificationAPIModel {
  id: string;
  keycloak_id: string;
  read: boolean;
  read_at: string | null;
  message: string;
  url: string;
  priority: string;
  type: string;
  created_at: string;
}

export interface Notification {
  id: string;
  keycloakId: string;
  read: boolean;
  readAt: Dayjs | null;
  message: string;
  url: string;
  priority: string;
  type: string;
  createdAt: Dayjs;
}

export interface NotificationGroup {
  id: string;
  name: string;
  displayName: string;
  description: string;
}
export interface NotificationGroupWithDisabled extends NotificationGroup {
  disabled: string | null;
}
export interface NotificationGroupAPI {
  id: string;
  name: string;
  display_name: string;
  description: string;
}

export interface NotificationSubscriptionType {
  id: string;
  name: string;
  displayName: string;
  default: boolean;
}
export interface NotificationSubscriptionTypeAPI {
  id: string;
  name: string;
  display_name: string;
  default: boolean;
}

export interface NotificationSubscription {
  notificationGroupId: string;
  notificationGroupSubscriptionTypeId: string;
  enabled: boolean;
}

export interface NotificationSubscriptionAPI {
  notification_group_id: string;
  notification_group_subscription_type_id: string;
  enabled: boolean;
}

export interface NotificationState {
  notifications: Notification[];
  notificationGroups: NotificationGroup[] | null;
  notificationSubscriptions: NotificationSubscription[] | null;
  notificationSubscriptionTypes: NotificationSubscriptionType[] | null;
  isShowingNotifications: boolean;

  isLoading: {
    getNotifications: boolean;
    getNotificationGroups: boolean;
    getNotificationSubscriptionTypes: boolean;
    getNotificationSubscriptions: boolean;
    putNotificationSubscriptions: boolean;
    patchNotification: boolean;
    putNotifications: boolean;
  };
  errors: {
    getNotifications: string | null;
    getNotificationGroups: string | null;
    getNotificationSubscriptionTypes: string | null;
    getNotificationSubscriptions: string | null;
    putNotificationSubscriptions: string | null;
    patchNotification: string | null;
    putNotifications: string | null;
  };
}

export const defaultNotificationState: NotificationState = {
  notifications: [],
  notificationGroups: null,
  notificationSubscriptionTypes: null,
  notificationSubscriptions: null,
  isShowingNotifications: false,

  isLoading: {
    getNotifications: false,
    getNotificationGroups: false,
    getNotificationSubscriptionTypes: false,
    getNotificationSubscriptions: false,
    putNotificationSubscriptions: false,
    patchNotification: false,
    putNotifications: false,
  },
  errors: {
    getNotifications: null,
    getNotificationGroups: null,
    getNotificationSubscriptionTypes: null,
    getNotificationSubscriptions: null,
    putNotificationSubscriptions: null,
    patchNotification: null,
    putNotifications: null,
  },
};

export enum NotificationActions {
  requestGetNotificationsAttempt = 'REQUEST_GET_NOTIFICATIONS_ATTEMPT',
  requestGetNotificationsSuccess = 'REQUEST_GET_NOTIFICATIONS_SUCCESS',
  requestGetNotificationsFailure = 'REQUEST_GET_NOTIFICATIONS_FAILURE',

  requestGetNotificationGroupsAttempt = 'REQUEST_GET_NOTIFICATION_GROUPS_ATTEMPT',
  requestGetNotificationGroupsSuccess = 'REQUEST_GET_NOTIFICATION_GROUPS_SUCCESS',
  requestGetNotificationGroupsFailure = 'REQUEST_GET_NOTIFICATION_GROUPS_FAILURE',

  requestGetNotificationSubscriptionTypesAttempt = 'REQUEST_GET_NOTIFICATION_SUBSCRIPTION_TYPES_ATTEMPT',
  requestGetNotificationSubscriptionTypesSuccess = 'REQUEST_GET_NOTIFICATION_SUBSCRIPTION_TYPES_SUCCESS',
  requestGetNotificationSubscriptionTypesFailure = 'REQUEST_GET_NOTIFICATION_SUBSCRIPTION_TYPES_FAILURE',

  requestGetNotificationSubscriptionsAttempt = 'REQUEST_GET_NOTIFICATION_SUBSCRIPTIONS_ATTEMPT',
  requestGetNotificationSubscriptionsSuccess = 'REQUEST_GET_NOTIFICATION_SUBSCRIPTIONS_SUCCESS',
  requestGetNotificationSubscriptionsFailure = 'REQUEST_GET_NOTIFICATION_SUBSCRIPTIONS_FAILURE',

  requestPutNotificationSubscriptionsAttempt = 'REQUEST_PUT_NOTIFICATION_SUBSCRIPTIONS_ATTEMPT',
  requestPutNotificationSubscriptionsSuccess = 'REQUEST_PUT_NOTIFICATION_SUBSCRIPTIONS_SUCCESS',
  requestPutNotificationSubscriptionsFailure = 'REQUEST_PUT_NOTIFICATION_SUBSCRIPTIONS_FAILURE',

  requestPatchNotificationAttempt = 'REQUEST_PATCH_NOTIFICATION_ATTEMPT',
  requestPatchNotificationSuccess = 'REQUEST_PATCH_NOTIFICATION_SUCCESS',
  requestPatchNotificationFailure = 'REQUEST_PATCH_NOTIFICATION_FAILURE',

  requestPutNotificationsAttempt = 'REQUEST_PUT_NOTIFICATIONS_ATTEMPT',
  requestPutNotificationsSuccess = 'REQUEST_PUT_NOTIFICATIONS_SUCCESS',
  requestPutNotificationsFailure = 'REQUEST_PUT_NOTIFICATIONS_FAILURE',

  setIsShowingNotifications = 'SET_IS_SHOWING_NOTIFICATIONS',
}

export enum NotificationErrors {
  requestGetNotificationsError = 'An error occurred while fetching notifications.',
  requestGetNotificationGroupsError = 'An error occurred while fetching notification groups.',
  requestGetNotificationSubscriptionTypesError = 'An error occurred while fetching notification types.',
  requestGetNotificationSubscriptionsError = 'An error occurred while fetching influencers subscriptions.',
  requestPutNotificationSubscriptionsError = 'An error occurred while updating influencers subscriptions.',
  requestPatchNotificationError = 'An error occurred while updating notification.',
  requestPutNotificationsError = 'An error occurred while updating notification.',
}

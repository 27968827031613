import { LanguagesFromApi, Languages, Translations } from './models';

export const transformMessages = (
  messages: { [index: string]: string },
  changedKeys: string[],
  state: Translations
): any => {
  const newObj: { [index: string]: string | null } = {};

  for (let i = 0; i < changedKeys.length; i++) {
    newObj[changedKeys[i]] = null;
  }

  return {
    ...newObj,
    ...state,
    ...messages,
  };
};

export const languagesPostsTransformer = (
  inLanguages: LanguagesFromApi
): Languages[] => {
  return Object.keys(inLanguages).map(langKey => {
    return { code: langKey, name: inLanguages[langKey] } as Languages;
  });
};

export const createMessagesBody = (keys: string[], locale: string): any => {
  return { 'message-keys': keys, locale };
};

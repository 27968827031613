import React from 'react';
import { PageButton, PageButtonContainer } from './PageButtons.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const renderPageButton = (
  pageIndex: number,
  handleChange: (a: number) => void,
  currentPage: number
) => {
  return (
    <PageButton
      aria-label={`Page ${pageIndex + 1}`}
      isCurrent={currentPage === pageIndex}
      key={pageIndex}
      data-testid={`t_page_button_${pageIndex}`}
      onClick={() => handleChange(pageIndex)}
    >
      {pageIndex + 1}
    </PageButton>
  );
};

const createButtons = (
  numberOfPages: number,
  handleChange: (page: number) => void,
  currentPage: number,
  nextAriaText: string,
  prevAriaText: string,
  displayLimit = 5
) => {
  const children = [];
  if (numberOfPages > displayLimit) {
    children.push(
      <PageButton
        key={'previous'}
        aria-label={prevAriaText}
        onClick={() => handleChange(currentPage - 1)}
        disabled={currentPage === 0}
        aria-disabled={currentPage === 0}
        data-testid={'t_page_button_back'}
      >
        <FontAwesomeIcon icon={'chevron-left'} />
      </PageButton>
    );

    const start = currentPage === 0 ? 0 : currentPage - 1;
    const end =
      currentPage === 0
        ? displayLimit - 2
        : Math.min(currentPage + 2, numberOfPages);

    for (let i = start; i < end; i++) {
      children.push(renderPageButton(i, handleChange, currentPage));
    }
    children.push(
      <PageButton
        key={'next'}
        aria-label={nextAriaText}
        onClick={() => handleChange(currentPage + 1)}
        disabled={currentPage === numberOfPages - 1}
        aria-disabled={currentPage === numberOfPages - 1}
        data-testid={'t_page_button_next'}
      >
        <FontAwesomeIcon icon={'chevron-right'} />
      </PageButton>
    );
  } else {
    for (let i = 0; i < numberOfPages; i++) {
      children.push(renderPageButton(i, handleChange, currentPage));
    }
  }
  return children;
};

interface PageButtonsProps {
  totalCount: number;
  pageLimit: number;
  handlePageChange: (a: number) => void;
  currentPage: number;
  displayLimit?: number;
  nextAriaText?: string;
  prevAriaText?: string;
  className?: string;
}

export const PageButtons: React.FC<PageButtonsProps> = ({
  totalCount,
  handlePageChange,
  currentPage,
  displayLimit,
  pageLimit,
  nextAriaText = 'Next page',
  prevAriaText = 'Previous page',
  className,
}) => {
  const numberOfPages = Math.ceil(totalCount / pageLimit);
  return (
    <PageButtonContainer data-testid={'t_page_buttons'} className={className}>
      {createButtons(
        numberOfPages,
        handlePageChange,
        currentPage,
        nextAriaText,
        prevAriaText,
        displayLimit
      )}
    </PageButtonContainer>
  );
};

export const OnDemandPageButtons: React.FC<{
  handlePageChange: (page: number) => void;
  currentPage: number;
  isLastPage: boolean;
  nextAriaText?: string;
  prevAriaText?: string;
}> = ({
  handlePageChange,
  currentPage,
  isLastPage,
  nextAriaText = 'Next page',
  prevAriaText = 'Previous page',
}) => {
  // currentPage is 0 indexed so the current number of pages is currentPage + 1
  // if we think there's pages after that, currentPage + 2
  const numberOfPages = isLastPage ? currentPage + 1 : currentPage + 2;
  return (
    <PageButtonContainer data-testid={'t_page_buttons'}>
      {createButtons(
        numberOfPages,
        handlePageChange,
        currentPage,
        nextAriaText,
        prevAriaText,
        2
      )}
    </PageButtonContainer>
  );
};

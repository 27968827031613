import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import React from 'react';
import { StyledComponentPropsWithRef } from 'styled-components';
import { IconButtonOutlineStyle } from './IconButtonOutline.styles';

interface IconButtonOutlineProps extends StyledComponentPropsWithRef<'button'> {
  tooltip: string;
  icon: IconProp;
}

export const IconButtonOutline: React.FC<IconButtonOutlineProps> = ({
  tooltip,
  icon,
  ...intrinsic
}) => {
  return (
    <Tooltip title={tooltip} placement="top">
      <IconButtonOutlineStyle {...intrinsic}>
        <FontAwesomeIcon icon={icon} />
      </IconButtonOutlineStyle>
    </Tooltip>
  );
};

import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

const ProgressBarWrapper = styled.div`
  & .MuiLinearProgress-root {
    height: ${px2rem(4)};
    background: ${props => theme(props).COLOUR.GRADIENT_BAR};
  }
  & .MuiLinearProgress-bar {
    background: ${props => theme(props).COLOUR.GRADIENT_BAR};
  }
  position: fixed;
  width: 100vw;
  z-index: -1;
  left: 0;
  top: ${px2rem(66)};
  max-width: initial !important;
  @media screen and (max-width: ${px2rem(768, 'em')}) {
    top: ${props => theme(props).SIZE.MOBILE_TOP};
  }
`;
export const ProgressBar = (props: any) => (
  <ProgressBarWrapper {...props}>
    <LinearProgress />
  </ProgressBarWrapper>
);

import { ContentState, Languages } from './models';

export const defaultLanguages: Languages[] = [];

export const defaultContentState: ContentState = Object.freeze({
  keyArray: [],
  localisations: null,
  translations: null,
  isLoading: false,
  availableLanguages: defaultLanguages,
  isLoadingLanguagesList: false,
  localLangCode: null,
});

export * from './models';
export * from './reducer';
export * from './actions';

import { getKeycloakInstance } from '../keycloak/keycloak-init';
import { useEffect, useState } from 'react';
import { SetRegistrationComplete } from '../store/User/store';
import { useStore } from '../store/state';

export const useAuthorisation = () => {
  const [{ userState }, dispatch] = useStore();
  const { isFetchingUser, fetchUserError, keycloakProfile, user, permissions } =
    userState;
  const [isUserInitialised, setIsUserInitialised] = useState(false);
  const [keycloakAuthenticated, setKeycloakAuthenticated] = useState(false);

  const isMarketer = (): boolean => {
    const kc = getKeycloakInstance();
    return !!kc && !!permissions && permissions.type !== 'influencer';
  };

  const isAuthenticated = keycloakAuthenticated && keycloakProfile;
  const hasAddress = isUserInitialised && user?.influencer_address;

  useEffect(() => {
    if (
      userState.permissions &&
      userState.permissions.type === 'influencer' &&
      userState.user?.influencer_id &&
      hasAddress &&
      window.profileToken !== null
    ) {
      SetRegistrationComplete(dispatch, true);
    }
  }, [
    dispatch,
    hasAddress,
    userState.permissions,
    userState.user?.influencer_id,
  ]);

  const showRegisterPage =
    fetchUserError || !hasAddress || !userState.isRegistrationComplete;

  const isRegistrationComplete = isAuthenticated && !!user && hasAddress;

  return {
    setIsUserInitialised,
    isUserInitialised,
    isMarketer,
    isFetchingUser,
    showRegisterPage,
    keycloakAuthenticated,
    isAuthenticated,
    setKeycloakAuthenticated,
    isRegistrationComplete,
    hasPermissions: !!permissions,
  };
};

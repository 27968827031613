import React, { MouseEvent } from 'react';
import {
  NavContainer,
  LinkContainer,
  Container,
  LinkTextContainer,
  LanguageWrapper,
} from './NavigationPage.styles';
import { MenuItem, MenuText, MenuTextExternalLink } from '../MenuItem/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageSelect } from '../../../../common';
import { useTranslationsController } from '../../../../../utils/globalTranslationsController';
import { navigationPageTranslation } from './translations';

export const NavigationPage: React.FC<{
  logoutHandler: (e: MouseEvent<Element>) => void;
  toggleNavigation: () => void;
  language?: string;
  showMarketplace: boolean;
}> = ({ logoutHandler, toggleNavigation, language, showMarketplace }) => {
  const { navigationPage: t } = useTranslationsController(
    navigationPageTranslation
  );
  return (
    <NavContainer data-testid="t_nav">
      <Container as="ul">
        <LinkContainer as="li">
          <MenuItem
            route="/"
            label={t['APP.NAV.HOME']}
            icon={<FontAwesomeIcon icon="home" />}
            toggleNavigation={toggleNavigation}
          />
        </LinkContainer>
        <LinkContainer as="li">
          <MenuItem
            route="/programmes"
            label={t['APP.NAV.PROGRAMMES']}
            icon={<FontAwesomeIcon icon="bullhorn" />}
            toggleNavigation={toggleNavigation}
          />
        </LinkContainer>
        {window._env.campaigns && (
          <LinkContainer as="li">
            <MenuItem
              route="/campaigns"
              label={t['APP.NAV.CAMPAIGNS']}
              icon={<FontAwesomeIcon icon="star" />}
              toggleNavigation={toggleNavigation}
            />
          </LinkContainer>
        )}
        {showMarketplace && (
          <LinkContainer as="li">
            <MenuItem
              route="/connect"
              label={t['APP.NAV.MARKETPLACE']}
              icon={<FontAwesomeIcon icon="shopping-bag" />}
              toggleNavigation={toggleNavigation}
            />
          </LinkContainer>
        )}
        <LinkContainer as="li">
          <MenuItem
            route="/profile"
            label={t['APP.NAV.PROFILE']}
            icon={<FontAwesomeIcon icon="user" />}
            toggleNavigation={toggleNavigation}
          />
        </LinkContainer>
        <LinkContainer as="li">
          <MenuItem
            route="/wallet"
            label={t['APP.NAV.WALLET']}
            icon={<FontAwesomeIcon icon="wallet" />}
            toggleNavigation={toggleNavigation}
          />
        </LinkContainer>
        <LinkContainer as="li">
          <MenuItem
            route="/wallet/bank_accounts"
            label={t['APP.NAV.PAYMENT']}
            icon={<FontAwesomeIcon icon={'university'} />}
            toggleNavigation={toggleNavigation}
          />
        </LinkContainer>
        <LinkContainer as="li">
          <MenuItem
            route="/settings"
            label={t['APP.NAV.SETTINGS']}
            icon={<FontAwesomeIcon icon={'cog'} />}
            toggleNavigation={toggleNavigation}
          />
        </LinkContainer>
        <LinkContainer
          as="li"
          data-testid="t_nav_menu_logout"
          onClick={logoutHandler}
        >
          <MenuItem
            route="/"
            label={t['APP.NAV.LOGOUT']}
            icon={<FontAwesomeIcon icon="sign-out-alt" />}
            toggleNavigation={toggleNavigation}
          />
        </LinkContainer>

        <LinkTextContainer as="li">
          <LanguageWrapper>
            <LanguageSelect />
          </LanguageWrapper>
          <MenuText
            toggleNavigation={toggleNavigation}
            route="/contact"
            label={t['APP.NAV.CONTACT']}
          />
          <MenuTextExternalLink
            toggleNavigation={toggleNavigation}
            route={`/about/terms${language ? '.' + language : ''}.html`}
            label={t['APP.NAV.TERMS']}
          />
          <MenuTextExternalLink
            toggleNavigation={toggleNavigation}
            route={`/about/privacy${language ? '.' + language : ''}.html`}
            label={t['APP.NAV.PRIVACY']}
          />
          <MenuTextExternalLink
            toggleNavigation={toggleNavigation}
            route={`/help`}
            label={t['APP.NAV.HELP']}
          />
        </LinkTextContainer>
      </Container>
    </NavContainer>
  );
};

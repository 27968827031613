import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  NotificationZeroContainer,
  NotificationZeroIcon,
  NotificationZeroInfo,
} from './NotificationItem.styles';

export const NotificationZero: React.FC<{
  isMobile: boolean;
  text: string;
}> = ({ isMobile, text = 'All caught up.' }) => {
  return (
    <NotificationZeroContainer
      data-testid={'t_notification_zero'}
      isMobile={isMobile}
    >
      <NotificationZeroIcon>
        <FontAwesomeIcon icon={'check'} />
      </NotificationZeroIcon>
      <NotificationZeroInfo>{text}</NotificationZeroInfo>
    </NotificationZeroContainer>
  );
};

export type CustomErrorMessage = string | Map<string, string>;

export interface CustomError extends Error {
  customMessage?: CustomErrorMessage;
}

const customErrorFactory = (
  name: string,
  errorObject: Record<string, any>,
  message?: CustomErrorMessage
): CustomError => {
  const customError = () => ({
    ...errorObject,
    name: name,
    customMessage: message || 'An error occurred',
    stack: new Error().stack,
    message: message?.toString() || 'An error occurred',
  });
  customError.prototype = Object.create(Error.prototype);
  customError.prototype.constructor = customError;

  return customError();
};

export const httpError = (
  statusCode: number,
  message: CustomErrorMessage
): CustomError =>
  customErrorFactory('Http Error', { status: statusCode }, message);

export const apiError = (statusCode: number, message: string): CustomError =>
  customErrorFactory('Api Error', { status: statusCode }, message);

export enum ErrorMessage {
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  INTERNAL_SERVER_ERROR = 500,
}

export interface ErrorBody {
  validationErrors: Map<string, string>;
}

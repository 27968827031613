export enum Padding {
  XSMALL = '16px',
  SMALL = '24px',
  MEDIUM = '48px',
  LARGE = '60px',
  XLARGE = '120px',
  XXLARGE = '240px',
}

export enum Margin {
  XSMALL = '16px',
  SMALL = '24px',
  MEDIUM = '48px',
  LARGE = '60px',
}

export enum Alignment {
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end',
  SPACE_BETWEEN = 'space-between',
}

export enum Direction {
  ROW = 'row',
  COLUMN = 'column',
  ROW_REVERSE = 'row-reverse',
}
export interface BoxProps {
  flex?: boolean;
  expand?: boolean;
  align?: Alignment;
  justify?: Alignment;
  marginTop?: Margin;
  marginBottom?: Margin;
  marginLeft?: Margin;
  marginRight?: Margin;
  padding?: string;
  paddingTop?: Padding;
  paddingBottom?: Padding;
  paddingLeft?: Padding;
  paddingRight?: Padding;
  direction?: Direction;
  border?: boolean;
  round?: boolean;
  width?: string;
  maxHeight?: string;
  scroll?: boolean;
  columnGap?: boolean;
  rowGap?: boolean;
}

import { DispatchFn } from '../../store/state';
import client, { Microservice } from '../../utils/http.utils';
import {
  CommissionsAPImodel,
  CommissionSummaryAPImodel,
  CommissionFilterQueries,
  PaginatedCommissionFilterQueries,
} from '../common/interfaces';
import { queryBuilder } from '../common/utils';
import { apiError } from '../../types/error.types';
import { captureException } from '@sentry/react';
import { TrackingLinksPerformancesAPI } from './store';

export enum CampaignsActions {
  requestGetCampaignAttempt = 'REQUEST_GET_CAMPAIGN_ATTEMPT',
  requestGetCampaignSuccess = 'REQUEST_GET_CAMPAIGN_SUCCESS',
  requestGetCampaignFailure = 'REQUEST_GET_CAMPAIGN_FAILURE',

  requestGetCampaignDiscountAttempt = 'REQUEST_GET_CAMPAIGN_DISCOUNT_ATTEMPT',
  requestGetCampaignDiscountSuccess = 'REQUEST_GET_CAMPAIGN_DISCOUNT_SUCCESS',
  requestGetCampaignDiscountFailure = 'REQUEST_GET_CAMPAIGN_DISCOUNT_FAILURE',

  requestGetAssociationAttempt = 'REQUEST_GET_ASSOCIATION_ATTEMPT',
  requestGetAssociationSuccess = 'REQUEST_GET_ASSOCIATION_SUCCESS',
  requestGetAssociationFailure = 'REQUEST_GET_ASSOCIATION_FAILURE',

  requestGetTrackingLinksAttempt = 'REQUEST_GET_TRACKING_LINKS_ATTEMPT',
  requestGetTrackingLinksSuccess = 'REQUEST_GET_TRACKING_LINKS_SUCCESS',
  requestGetTrackingLinksFailure = 'REQUEST_GET_TRACKING_LINKS_FAILURE',

  requestGetCampaignCommissionsSuccess = 'REQUEST_GET_CAMPAIGN_COMMISSIONS_SUCCESS',
  requestGetCampaignCommissionsAttempt = 'REQUEST_GET_CAMPAIGN_COMMISSIONS_ATTEMPT',
  requestGetCampaignCommissionsFailure = 'REQUEST_GET_CAMPAIGN_COMMISSIONS_FAILURE',

  requestGetCampaignCommissionSummaryAttempt = 'REQUEST_GET_CAMPAIGN_COMMISSION_SUMMARY_ATTEMPT',
  requestGetCampaignCommissionSummarySuccess = 'REQUEST_GET_CAMPAIGN_COMMISSION_SUMMARY_SUCCESS',
  requestGetCampaignCommissionSummaryFailure = 'REQUEST_GET_CAMPAIGN_COMMISSION_SUMMARY_FAILURE',

  requestGetCampaignCommissionOverviewAttempt = 'REQUEST_GET_CAMPAIGN_COMMISSION_OVERVIEW_ATTEMPT',
  requestGetCampaignCommissionOverviewSuccess = 'REQUEST_GET_CAMPAIGN_COMMISSION_OVERVIEW_SUCCESS',
  requestGetCampaignCommissionOverviewFailure = 'REQUEST_GET_CAMPAIGN_COMMISSION_OVERVIEW_FAILURE',

  clearCampaignCommissions = 'CLEAR_COMMISSIONS',
  requestGetCampaignTrackingLinksAttempt = 'REQUEST_GET_CAMPAIGN_TRACKING_LINKS_ATTEMPT',
  requestGetCampaignTrackingLinksSuccess = 'REQUEST_GET_CAMPAIGN_TRACKING_LINKS_SUCCESS',
  requestGetCampaignTrackingLinksFailure = 'REQUEST_GET_CAMPAIGN_TRACKING_LINKS_FAILURE',

  requestGetCampaignTrackingLinksBackgroundAttempt = 'REQUEST_GET_CAMPAIGN_TRACKING_LINKS_BACKGROUND_ATTEMPT',
  requestGetCampaignTrackingLinksBackgroundSuccess = 'REQUEST_GET_CAMPAIGN_TRACKING_LINKS_BACKGROUND_SUCCESS',
  requestGetCampaignTrackingLinksBackgroundFailure = 'REQUEST_GET_CAMPAIGN_TRACKING_LINKS_BACKGROUND_FAILURE',

  requestPostCampaignTrackingLinkAttempt = 'REQUEST_POST_CAMPAIGN_TRACKING_LINK_ATTEMPT',
  requestPostCampaignTrackingLinkSuccess = 'REQUEST_POST_CAMPAIGN_TRACKING_LINK_SUCCESS',
  requestPostCampaignTrackingLinkFailure = 'REQUEST_POST_CAMPAIGN_TRACKING_LINK_FAILURE',

  requestPutCampaignTrackingLinkAttempt = 'REQUEST_PUT_CAMPAIGN_TRACKING_LINK_ATTEMPT',
  requestPutCampaignTrackingLinkSuccess = 'REQUEST_PUT_CAMPAIGN_TRACKING_LINK_SUCCESS',
  requestPutCampaignTrackingLinkFailure = 'REQUEST_PUT_CAMPAIGN_TRACKING_LINK_FAILURE',

  requestGetTrackingLinksPerformancesAttempt = 'REQUEST_GET_TRACKING_LINKS_PERFORMANCES_ATTEMPT',
  requestGetTrackingLinksPerformancesSuccess = 'REQUEST_GET_TRACKING_LINKS_PERFORMANCES_SUCCESS',
  requestGetTrackingLinksPerformancesFailure = 'REQUEST_GET_TRACKING_LINKS_PERFORMANCES_FAILURE',

  clearTrackingFormErrors = 'CLEAR_TRACKING_FORM_ERRORS',
}

export const RequestGetCampaignAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string
): Promise<any> => {
  dispatch({ type: CampaignsActions.requestGetCampaignAttempt });

  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign/${campaignId}`,
      'GET'
    );

    dispatch({
      type: CampaignsActions.requestGetCampaignSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: CampaignsActions.requestGetCampaignFailure,
      payload: 'An error occurred trying to fetch the campaign.',
    });
    throw e;
  }
};

export const RequestGetCampaignDiscount = async (
  dispatch: DispatchFn,
  influencerId: string,
  programmeId: string,
  campaignId: string
): Promise<any> => {
  dispatch({ type: CampaignsActions.requestGetCampaignDiscountAttempt });

  try {
    const response = await client.request(
      Microservice.COMMISSION,
      `/discount/influencer/${influencerId}/programme/${programmeId}/campaign/${campaignId}`,
      'GET'
    );

    dispatch({
      type: CampaignsActions.requestGetCampaignDiscountSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: CampaignsActions.requestGetCampaignDiscountFailure,
      payload: 'An error occurred trying to fetch the campaign.',
    });
    throw e;
  }
};

export const RequestGetCampaignAssociationAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  influencerId: string
): Promise<any> => {
  dispatch({ type: CampaignsActions.requestGetAssociationAttempt });

  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign/${campaignId}/association/${influencerId}`,
      'GET'
    );

    dispatch({
      type: CampaignsActions.requestGetAssociationSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: CampaignsActions.requestGetAssociationFailure,
      payload: 'An error occurred trying to fetch the association.',
    });
    throw e;
  }
};

export const RequestGetCampaignCommissionsAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  influencerId: string,
  pageObject: PaginatedCommissionFilterQueries
): Promise<void> => {
  dispatch({ type: CampaignsActions.requestGetCampaignCommissionsAttempt });

  const queries = queryBuilder<PaginatedCommissionFilterQueries>(pageObject);
  try {
    const response: CommissionsAPImodel = await client.request(
      Microservice.COMMISSION,
      `/commission/programme/${programmeId}/campaign/${campaignId}/influencer/${influencerId}/commissions/paginated${queries}`,
      'GET'
    );

    dispatch({
      type: CampaignsActions.requestGetCampaignCommissionsSuccess,
      payload: { ...response, pageNumber: pageObject.page },
    });
  } catch (e: any) {
    captureException(e);
    dispatch({
      type: CampaignsActions.requestGetCampaignCommissionsFailure,
      payload: 'An error occurred while fetching Commissions.',
    });

    throw apiError(e.status, e.message);
  }
};

export const RequestGetCampaignCommissionSummaryAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  influencerId: string,
  queryObject: CommissionFilterQueries
): Promise<void> => {
  dispatch({
    type: CampaignsActions.requestGetCampaignCommissionSummaryAttempt,
  });

  const queries = queryBuilder<CommissionFilterQueries>(queryObject);
  try {
    const response: CommissionSummaryAPImodel = await client.request(
      Microservice.COMMISSION,
      `/commission/programme/${programmeId}/campaign/${campaignId}/influencer/${influencerId}/summary${queries}`,
      'GET'
    );

    dispatch({
      type: CampaignsActions.requestGetCampaignCommissionSummarySuccess,
      payload: { ...response },
    });
  } catch (e: any) {
    captureException(e);
    dispatch({
      type: CampaignsActions.requestGetCampaignCommissionSummaryFailure,
      payload: 'An error occurred while fetching the commission summary.',
    });
    throw apiError(e.status, e.message);
  }
};

export const RequestGetCampaignCommissionOverviewAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  influencerId: string,
  startDate: string | null
): Promise<void> => {
  dispatch({
    type: CampaignsActions.requestGetCampaignCommissionOverviewAttempt,
  });

  const queries = startDate ? `?start_date=${startDate}T00:00:00Z` : '';
  try {
    const response: CommissionSummaryAPImodel = await client.request(
      Microservice.COMMISSION,
      `/commission/programme/${programmeId}/campaign/${campaignId}/influencer/${influencerId}/overview${queries}`,
      'GET'
    );

    dispatch({
      type: CampaignsActions.requestGetCampaignCommissionOverviewSuccess,
      payload: { ...response },
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: CampaignsActions.requestGetCampaignCommissionOverviewFailure,
      payload: 'An error occurred while fetching the commission overview.',
    });
  }
};

export const RequestCampaignCommissionsAndSummary = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  influencerId: string,
  pageQuery: PaginatedCommissionFilterQueries
): Promise<void> => {
  RequestGetCampaignCommissionsAsync(
    dispatch,
    programmeId,
    campaignId,
    influencerId,
    pageQuery
  ).catch(e => captureException(e));
  RequestGetCampaignCommissionSummaryAsync(
    dispatch,
    programmeId,
    campaignId,
    influencerId,
    {
      start_date: pageQuery.start_date,
      end_date: pageQuery.end_date,
      status: pageQuery.status,
    }
  ).catch(e => captureException(e));
};

export const ClearCommissions = (dispatch: DispatchFn) => {
  dispatch({ type: CampaignsActions.clearCampaignCommissions });
};

export const RequestGetCampaignTrackingLinksAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  influencerId: string
): Promise<any> => {
  dispatch({ type: CampaignsActions.requestGetCampaignTrackingLinksAttempt });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign/${campaignId}/association/${influencerId}/tracking`,
      'GET'
    );

    dispatch({
      type: CampaignsActions.requestGetCampaignTrackingLinksSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: CampaignsActions.requestGetCampaignTrackingLinksFailure,
      payload: 'An error occurred while fetching tracking links.',
    });
  }
};

export const RequestGetCampaignTrackingLinksBackgroundAsync = async (
  dispatch: DispatchFn,
  programmeId: string,
  campaignId: string,
  influencerId: string
): Promise<any> => {
  dispatch({
    type: CampaignsActions.requestGetCampaignTrackingLinksBackgroundAttempt,
  });
  try {
    const response = await client.request(
      Microservice.PROGRAMME,
      `/programme/${programmeId}/campaign/${campaignId}/association/${influencerId}/tracking`,
      'GET'
    );

    dispatch({
      type: CampaignsActions.requestGetCampaignTrackingLinksBackgroundSuccess,
      payload: response,
    });
  } catch (e) {
    captureException(e);
    dispatch({
      type: CampaignsActions.requestGetCampaignTrackingLinksBackgroundFailure,
      payload: 'An error occurred while fetching tracking links.',
    });
  }
};

export const RequestGetTrackingLinksPerformancesByTrackingLinkIdsAndInfluencer =
  async (
    dispatch: DispatchFn,
    influencerId: string,
    trackingLinkIds: string[] | undefined
  ): Promise<any> => {
    dispatch({
      type: CampaignsActions.requestGetTrackingLinksPerformancesAttempt,
    });
    try {
      const response = await client.request<TrackingLinksPerformancesAPI>(
        Microservice.REPORTING,
        `/reporting/tracking-link-performance?tracking_link_ids=${trackingLinkIds}&influencer_id=${influencerId}`,
        'GET'
      );

      dispatch({
        type: CampaignsActions.requestGetTrackingLinksPerformancesSuccess,
        payload: response,
      });
    } catch (e) {
      captureException(e);
      dispatch({
        type: CampaignsActions.requestGetTrackingLinksPerformancesFailure,
        payload: 'An error occured while fetching performances.',
      });
    }
  };

import { ProgrammeQuery } from '../Campaigns';
import {
  CommissionFilterQueries,
  PaginatedCommissionFilterQueries,
} from './interfaces';

const getQuerySuffix = (queryName: string): string => {
  if (
    queryName === 'date_from' ||
    queryName === 'date_to' ||
    queryName === 'start_date' ||
    queryName === 'end_date'
  ) {
    return 'T00:00:00Z';
  } else {
    return '';
  }
};

type QueryValue = string | number | null | undefined;
export const queryBuilder = <T extends { [index: string]: QueryValue }>(
  queries: T
): string => {
  let query = '';
  Object.entries(queries).forEach(
    ([queryName, queryValue]: [string, QueryValue]) => {
      if (queryValue !== null && queryValue !== undefined) {
        query += `&${queryName}=${queryValue}${getQuerySuffix(queryName)}`;
      }
    }
  );
  return query.length > 0 ? `?${query.substring(1, query.length)}` : '';
};

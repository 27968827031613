import { Dispatch } from './types';
import { MarketplaceActions } from './actions';
import { MarketplaceState } from './store';
import { transformAggregationsFromApi } from './transformers';

export const MarketplaceReducer = (
  state: MarketplaceState,
  action: Dispatch
): MarketplaceState => {
  switch (action.type) {
    case MarketplaceActions.requestPostWaitlistAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          waitlist: true,
        },
        error: null,
      };
    case MarketplaceActions.requestPostWaitlistSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          waitlist: false,
        },
        waitlistStatus: action.payload,
      };
    case MarketplaceActions.requestPostWaitlistFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          waitlist: false,
        },
        error: action.payload,
      };
    case MarketplaceActions.requestGetInfluencerRetailersAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          retailers: true,
        },
        error: null,
      };
    case MarketplaceActions.requestGetInfluencerRetailersSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          retailers: false,
        },
        retailers: action.payload,
        error: null,
      };
    case MarketplaceActions.requestGetInfluencerRetailersFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          retailers: false,
        },
        error: action.payload,
      };
    case MarketplaceActions.requestGetTrackingLinkAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          productTrackingLinks: true,
        },
        getTrackingLinkError: null,
      };
    case MarketplaceActions.requestGetTrackingLinkSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          productTrackingLinks: false,
        },
        productTrackingLinks: {
          ...state.productTrackingLinks,
          [action.payload.productId]: action.payload.response,
        },
        getTrackingLinkError: null,
      };
    case MarketplaceActions.requestGetTrackingLinkFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          productTrackingLinks: false,
        },
        getTrackingLinkError: action.payload,
      };
    case MarketplaceActions.requestPostTrackingLinkAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          createTrackingLinks: true,
        },
        postTrackingLinkError: null,
      };
    case MarketplaceActions.requestPostTrackingLinkSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          createTrackingLinks: false,
        },
        productTrackingLinks: {
          ...state.productTrackingLinks,
          [action.payload.productId]: action.payload.response,
        },
        postTrackingLinkError: null,
      };
    case MarketplaceActions.requestPostTrackingLinkFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          createTrackingLinks: false,
        },
        postTrackingLinkError: action.payload,
      };
    case MarketplaceActions.requestGetInfluencerFavouriteProductsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          favouriteProducts: true,
        },
        favouriteProductsError: null,
      };
    case MarketplaceActions.requestGetInfluencerFavouriteProductsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          favouriteProducts: false,
        },
        favouriteProducts: action.payload,
        favouriteProductsError: null,
      };
    case MarketplaceActions.requestGetInfluencerFavouriteProductsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          favouriteProducts: false,
        },
        favouriteProductsError: action.payload,
      };

    case MarketplaceActions.requestPutInfluencerFavouriteProductsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          favouriteProducts: true,
        },
        favouriteProductsError: null,
      };
    case MarketplaceActions.requestPutInfluencerFavouriteProductsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          favouriteProducts: false,
        },
        favouriteProducts:
          action.payload.action === 'add'
            ? Array.from(
                new Set([
                  ...(state.favouriteProducts || []),
                  action.payload.productId,
                ])
              )
            : [
                ...(state.favouriteProducts || []).filter(
                  products => products !== action.payload.productId
                ),
              ],
        favouriteProductsError: null,
      };
    case MarketplaceActions.requestPutInfluencerFavouriteProductsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          favouriteProducts: false,
        },
        favouriteProductsError: action.payload,
      };
    case MarketplaceActions.requestGetInfluencerInterestsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          interests: true,
        },
        error: null,
      };
    case MarketplaceActions.requestGetInfluencerInterestsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          interests: false,
        },
        interests: action.payload,
      };
    case MarketplaceActions.requestGetInfluencerInterestsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          interests: false,
        },
        error: action.payload,
      };
    case MarketplaceActions.clearProductFiltersAction:
      return {
        ...state,
        productFilters: {
          ...state.productFilters,
          ...action.payload,
        },
      };
    case MarketplaceActions.setProductFiltersAction:
      return {
        ...state,
        productFilters: {
          ...state.productFilters,
          ...action.payload,
        },
      };
    case MarketplaceActions.requestPostInfluencerInterestsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          interests: true,
        },
        error: null,
      };
    case MarketplaceActions.requestPostInfluencerInterestsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          interests: false,
        },
        interests: action.payload,
      };
    case MarketplaceActions.requestPostInfluencerInterestsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          interests: false,
        },
        error: action.payload,
      };
    case MarketplaceActions.openProductTabSearchView:
      return {
        ...state,
        productSearchQuery: action.payload,
      };
    case MarketplaceActions.closeProductTabSearchView:
      return {
        ...state,
        productSearchQuery: null,
      };
    case MarketplaceActions.requestPostMarketplaceFeedbackAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postTrackingLinkFeedback: true,
        },
      };
    case MarketplaceActions.requestPostMarketplaceFeedbackSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          postTrackingLinkFeedback: false,
        },
        isTrackingLinkFeedbackCompleted: action.payload,
      };
    case MarketplaceActions.requestGetMarketplaceFeedbackFormAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          getTrackingLinkFeedbackForm: true,
        },
      };
    case MarketplaceActions.requestGetMarketplaceFeedbackFormSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          getTrackingLinkFeedbackForm: false,
        },
        hasTrackingLinkFeedbackForm: !!Object.keys(action.payload).length,
      };
    case MarketplaceActions.requestGetMarketplaceFeedbackFormFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          getTrackingLinkFeedbackForm: false,
        },
        hasTrackingLinkFeedbackForm: false,
      };
    case MarketplaceActions.requestGetProductsFromElasticSearchAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          products: true,
        },
        status: 'loading',
      };
    case MarketplaceActions.requestGetProductsFromElasticSearchFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          products: false,
        },
        status: 'error',
        error: action.payload,
      };
    case MarketplaceActions.requestGetProductsFromElasticSearchSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          products: false,
        },
        pagedProducts: {
          products: state.pagedProducts.products
            ? [...state.pagedProducts.products, action.payload.hits]
            : [action.payload.hits],
          paging: {
            totalHits: action.payload.totalHits,
            totalPages: action.payload.totalPages,
            page: action.payload.page,
            size: action.payload.size,
            isFirstPage: action.payload.isFirstPage,
            isLastPage: action.payload.isLastPage,
          },
        },
        status: 'idle',
        aggregations: {
          globalAggregations: transformAggregationsFromApi(
            action.payload.globalAggregations,
            action.payload.retailers
          ),
          queryAggregations: transformAggregationsFromApi(
            action.payload.queryAggregations,
            action.payload.retailers
          ),
        },
        error: null,
      };

    case MarketplaceActions.clearMarketplacePagedProducts:
      return {
        ...state,
        pagedProducts: {
          products: null,
          paging: {
            totalHits: null,
            totalPages: null,
            page: 0,
            size: null,
            isFirstPage: null,
            isLastPage: null,
          },
        },
        aggregations: {
          globalAggregations: {},
          queryAggregations: {},
        },
      };
    case MarketplaceActions.requestGetInfluencerMarketplaceTermsAttempt:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          marketplaceTerms: true,
        },
      };
    case MarketplaceActions.requestGetInfluencerMarketplaceTermsSuccess:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          marketplaceTerms: false,
        },
        hasAcceptedMarketplaceTerms: action.payload,
      };
    case MarketplaceActions.requestGetInfluencerMarketplaceTermsFailure:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          marketplaceTerms: false,
        },
      };
    default:
      return state;
  }
};

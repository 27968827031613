import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { RouterLink } from '..';
import { truncateText } from '../../../utils/string.utils';
import { Spacer } from '../Spacer';
import s from './Card.styles';
import { RequestGetProgrammeImageAsync } from '../../../store/Programme/store';
import { useInView } from 'react-intersection-observer';
import { Breakpoint } from '../../styling/breakpoints';
import { ActionDropdown } from '../ActionDropdown/ActionDropdown';
import { DropdownAction } from '../ActionDropdown/ActionDropdown.types';

const THUMBNAIL_IMAGE_PLACEHOLDER = '/images/Society_Thumbnail_PH_01-01.jpg';
const ICON_IMAGE_PLACEHOLDER = '/images/Society_Logo_PH_01-01.jpg';

export interface CardProps extends React.HTMLAttributes<HTMLElement> {
  title?: string;
  testid?: string;
  image?: {
    src: string | null;
    alt: string;
  };
  iconImage?: {
    src: string | null;
    alt: string;
  };
  description?: string | JSX.Element;
  actions?: ReactNode;
  actionLink?: string;
  as?: any;
  viewText?: string;
  layout?: Breakpoint;
  fullHeight?: boolean;
  buttonText?: string;
  dropdownActions?: DropdownAction[];
}

export const Card: FC<CardProps> = ({
  title,
  testid,
  image,
  iconImage,
  description,
  actions,
  actionLink,
  as,
  layout,
  fullHeight,
  buttonText = 'Click to View',
  dropdownActions,
  ...nativeProps
}) => {
  const [thumbnailSrcUrl, setThumbnailSrcUrl] = useState<string>(
    THUMBNAIL_IMAGE_PLACEHOLDER
  );
  const [hasFetchedThumbnail, setHasFetchedThumbnail] =
    useState<boolean>(false);
  const [iconSrcUrl, setIconSrcUrl] = useState<string>(ICON_IMAGE_PLACEHOLDER);
  const [hasFetchedIcon, setHasFetchedIcon] = useState<boolean>(false);
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  useEffect(() => {
    const getThumbnailUrl = async (imageId: string) => {
      const thumbnailUrl = await RequestGetProgrammeImageAsync(imageId);
      if (thumbnailUrl) setThumbnailSrcUrl(thumbnailUrl);
    };

    if (image?.src && !hasFetchedThumbnail && inView) {
      setHasFetchedThumbnail(true);
      getThumbnailUrl(image.src);
    }
  }, [inView, image, hasFetchedThumbnail]);

  useEffect(() => {
    const getIconUrl = async (imageId: string) => {
      const IconUrl = await RequestGetProgrammeImageAsync(imageId);
      if (IconUrl) setIconSrcUrl(IconUrl);
    };

    if (iconImage?.src && !hasFetchedIcon && inView) {
      setHasFetchedIcon(true);
      getIconUrl(iconImage.src);
    }
  }, [inView, iconImage, hasFetchedIcon]);

  const cardImage = useMemo(() => {
    if (!image) return <Spacer isVertical amount={8} />;

    return (
      <s.CardImagesContainer ref={ref}>
        <s.CardThumbnailImage
          data-testid={testid && `${testid}_card_image`}
          src={thumbnailSrcUrl}
          alt={image.alt}
        />
        {iconImage && !!iconImage.src && (
          <s.CardIconImage
            data-testid={testid && `${testid}_card_icon_image`}
            src={iconSrcUrl}
            alt={iconImage.alt}
            layout={layout}
          />
        )}
      </s.CardImagesContainer>
    );
  }, [image, iconImage, iconSrcUrl, ref, testid, layout, thumbnailSrcUrl]);

  const cardHeader = useMemo(() => {
    if (!title && !dropdownActions) return;

    return (
      <s.CardHeader>
        <s.CardTitle data-testid={testid && `${testid}_card_title`}>
          {title}
        </s.CardTitle>
        {dropdownActions && (
          <ActionDropdown
            actions={dropdownActions}
            testId={`${testid}_dropdown`}
            buttonId={`${testid}_dropdown`}
          />
        )}
      </s.CardHeader>
    );
  }, [title, dropdownActions, testid]);

  const cardDescription = useMemo(() => {
    if (!description) return;

    return (
      <div data-testid={testid && `${testid}_card_description`}>
        {typeof description === 'string'
          ? truncateText(description, 100)
          : description}
      </div>
    );
  }, [description, testid]);

  const cardActions = useMemo(() => {
    if (!actions && !actionLink) return;

    return (
      <s.CardActions>
        {actions && <>{actions}</>}
        {actionLink && (
          <RouterLink
            data-testid={testid && `${testid}_card_link`}
            to={actionLink}
            style={{ whiteSpace: 'nowrap' }}
          >
            {buttonText}
          </RouterLink>
        )}
      </s.CardActions>
    );
  }, [actions, actionLink, testid, buttonText]);

  return (
    <s.CardWrapper
      title={title}
      {...nativeProps}
      as={as}
      data-testid={testid}
      fullHeight={fullHeight}
    >
      {cardImage}

      <s.CardContent>
        <s.CardText>
          {cardHeader}
          {cardDescription}
        </s.CardText>
        {nativeProps.children}
      </s.CardContent>

      {cardActions}
    </s.CardWrapper>
  );
};

import styled from 'styled-components/macro';
import { px2rem } from '../../../../../styling/sizing';
import { theme } from '../../../../../styling/theme';

export const StepPara = styled.p`
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
  padding-bottom: ${px2rem(8)};
`;

export const StepList = styled.ul`
  list-style: initial;
  padding-left: ${px2rem(16)};
  padding-bottom: ${px2rem(8)};
`;

export const StepLink = styled.a`
  text-decoration: none;
`;

export const StepCheckbox = styled.div`
  & .MuiIconButton-colorSecondary {
    color: ${props => theme(props).COLOUR.TEXT_STANDARD} !important;
  }
  padding-bottom: ${px2rem(8)};
`;

import React from 'react';
import styled from 'styled-components';
import { LogoCollapsedSVG } from './LogoCollapsedSVG';
import { LogoSVG } from './LogoSVG';

export interface LogoProps {
  collapsed?: boolean;
  altText?: string;
}

export const SocietyLogo = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > * {
    transition: none;
  }
  & svg {
    margin-top: 8px;
  }
`;

export const Logo: React.FC<LogoProps> = ({
  collapsed,
  altText = 'THG Society',
}) => (
  <SocietyLogo title={altText}>
    {collapsed ? (
      <Container data-testid="t_logo_collapsed">
        <LogoCollapsedSVG />
      </Container>
    ) : (
      <Container data-testid="t_logo_expanded">
        <LogoSVG />
      </Container>
    )}
  </SocietyLogo>
);

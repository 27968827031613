import React, { FC } from 'react';
import { Interests } from '../../common/Interests/Interests';
import { formFields } from './data';
import { Details } from './Details';
import { RegisterProps } from './types';
import {
  RegisterContainer,
  LogoContainer,
  RegisterHeader,
  InterestBox,
  LogoutContainer,
  ActionContainerMobile,
} from './Register.styles';
import { Logo, TextButtonLogo } from '../../common';
import { useStore } from '../../../store/state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breakpoint } from '../../styling/breakpoints';
import { InterestOption } from '../../common/Interests/types';
import { StageBubbles } from '../../common/StageBubbles/StageBubbles';
import { Footer } from '../../common/Footer/Footer';
import { useTranslationsController } from '../../../utils/globalTranslationsController';
import { registerTranslationObject } from './translations';
import { formatString } from '../../../utils/format.utils';

export const Register: FC<RegisterProps> = props => {
  const {
    details,
    handleDetailsChange,
    handleStageChange,
    maxTopicsReached,
    stage,
    isFormValid,
    handleLogout,
    layout,
    interestsChanged,
    translate,
  } = props;
  const [{ userState }] = useStore();
  const name = userState.keycloakProfile?.firstName;
  const isMobile = layout < Breakpoint.DESKTOP;
  const { register: t } = useTranslationsController(registerTranslationObject);

  const headerText = t['PROFILE.INTERESTS.HEADER'];
  const subheaderText = t['PROFILE.INTERESTS.SUBHEADER'];
  const saveText = t['PROFILE.INTERESTS.SAVE'];
  const cancelText = t['PROFILE.INTERESTS.CANCEL'];
  const updateText = t['PROFILE.INTERESTS.UPDATE'];
  const stageInterests = t['REGISTER.STAGE.INTEREST.TITLE'];
  const stageContact = t['REGISTER.STAGE.CONTACT.TITLE'];

  return (
    <RegisterContainer role="main">
      <LogoContainer>
        <Logo collapsed={false} />
      </LogoContainer>

      <LogoutContainer>
        <TextButtonLogo
          onClick={handleLogout}
          data-testid={'t_register_logout_btn'}
        >
          <FontAwesomeIcon icon="sign-out-alt" />
          {t['REGISTER.BUTTON.LOGOUT']}
        </TextButtonLogo>
      </LogoutContainer>

      <RegisterHeader>
        {formatString(t['REGISTER.HEADER'], [name ?? ''])}
      </RegisterHeader>
      <ActionContainerMobile>
        {stage > 1 && (
          <TextButtonLogo onClick={() => handleStageChange(stage - 1)}>
            {' '}
            {t['REGISTER.BUTTON.BACK']}
          </TextButtonLogo>
        )}
        <TextButtonLogo onClick={handleLogout}>
          <FontAwesomeIcon icon="sign-out-alt" />
          {t['REGISTER.BUTTON.LOGOUT']}
        </TextButtonLogo>
      </ActionContainerMobile>

      <StageBubbles
        currentStage={stage - 1}
        stageList={[stageInterests, stageContact]}
        isDark
        mode="dark"
      />

      {stage === 1 ? (
        <InterestBox data-testid="t_interest_box">
          <Interests
            mode="dark"
            isFormValid={isFormValid}
            maxTopicsReached={maxTopicsReached}
            handleNextButton={() => handleStageChange(2)}
            interests={details.interests}
            handleInterestsChange={e =>
              handleDetailsChange(formFields.INTERESTS, e)
            }
            isRegistered={false}
            handleLogout={handleLogout}
            interestsChanged={interestsChanged}
            headerText={headerText}
            subheaderText={subheaderText}
            saveText={saveText}
            cancelText={cancelText}
            updateText={updateText}
            // the keys are coming from the BE
            getNameHandler={(option: InterestOption) =>
              translate(option.translateKey, option.name)
            }
          />
        </InterestBox>
      ) : (
        stage === 2 && <Details {...props} isMobile={isMobile} />
      )}
      <Footer isMobile={isMobile} />
    </RegisterContainer>
  );
};

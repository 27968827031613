import React, { useEffect, useCallback, useState } from 'react';
import environment from '../../../environments/environment';
import { useStore } from '../../../store/state';
import { setKeycloakProfile } from '../../../store/User/store';
import { KeycloakInfo } from '../../../store/User/types';
import {
  RequestGetAgentAsync,
  RequestGetProfileTokenAsync,
  RequestGetUser,
} from '../../../store/User/actions';
import { Register } from '../../pages/Register';
import { useAuthorisation } from '../../../hooks/useAuthorisation';
import {
  getKeycloakInstance,
  initiateAuthInstance,
} from '../../../keycloak/keycloak-init';
import { LoadingPage } from '../../pages/LoadingPage';
import { TranslationProvider } from '../TranslationProvider/index';

export const AuthProvider: React.FC = ({ children }) => {
  const [{ userState }, dispatch] = useStore();
  const [isRequested, setIsRequested] = useState(false);
  const hasProfileToken = !!window.profileToken;
  const {
    setIsUserInitialised,
    isUserInitialised,
    isMarketer,
    isFetchingUser,
    isAuthenticated,
    keycloakAuthenticated,
    setKeycloakAuthenticated,
    showRegisterPage,
    isRegistrationComplete,
    hasPermissions,
  } = useAuthorisation();

  const onAuthError = useCallback((): void => {
    setKeycloakAuthenticated(false);
  }, [setKeycloakAuthenticated]);

  const initialiseUser = useCallback(
    (registrationFlow: boolean) => {
      RequestGetUser(dispatch, registrationFlow)
        .then(() => {
          !hasPermissions &&
            !!userState.user &&
            RequestGetProfileTokenAsync(dispatch).catch(e => {
              void e;
            });
        })
        .then(() => {
          setIsUserInitialised(true);
        });
    },
    [dispatch, hasPermissions, setIsUserInitialised, userState.user]
  );

  const onAuthSuccess = useCallback((): void => {
    setKeycloakAuthenticated(true);
    const kc = getKeycloakInstance();

    if (kc) {
      kc.loadUserProfile()
        .then(profile => {
          const payload: KeycloakInfo = {
            keycloakId: kc.subject || '',
            username: profile.username || '',
            email: profile.email || '',
            firstName: profile.firstName || '',
            lastName: profile.lastName || '',
            isInfluencer: kc.hasRealmRole(environment.influencerRole),
          };
          setKeycloakProfile(dispatch, payload);

          RequestGetProfileTokenAsync(dispatch)
            .then(() => {
              if (kc && !isMarketer()) {
                initialiseUser(false);
              }
            })
            .catch(() => {
              initialiseUser(true);
            });
        })
        .catch(e => void e);
    } else {
      throw new Error('Keycloak instance is not defined');
    }
  }, [dispatch, setKeycloakAuthenticated, isMarketer, initialiseUser]);

  const onAuthRefresh = useCallback(async () => {
    await RequestGetProfileTokenAsync(dispatch).catch(e => {
      void e;
    });
  }, [dispatch]);

  useEffect(() => {
    if (!hasProfileToken && isAuthenticated) {
      setIsRequested(false);
    }
  }, [hasProfileToken, isAuthenticated]);

  useEffect(() => {
    if (environment.authConfig && !keycloakAuthenticated) {
      initiateAuthInstance(
        environment.authConfig,
        onAuthSuccess,
        onAuthError,
        onAuthRefresh
      );
    } else {
      setKeycloakAuthenticated(true);
    }
    if (isRegistrationComplete && !hasPermissions && !isRequested) {
      RequestGetProfileTokenAsync(dispatch).catch(e => {
        void e;
      });
      setIsRequested(true);
    }
  }, [
    keycloakAuthenticated,
    onAuthSuccess,
    onAuthError,
    setKeycloakAuthenticated,
    onAuthRefresh,
    isRegistrationComplete,
    hasPermissions,
    dispatch,
    isRequested,
  ]);

  useEffect(() => {
    const userType = userState.permissions?.type;
    if (userType === 'influencer_agent') {
      RequestGetAgentAsync(dispatch).catch(e => void e);
    }
  }, [dispatch, userState.permissions]);

  const isNotInitialised =
    !isUserInitialised || (!hasProfileToken && !showRegisterPage);

  const userIsMarketer = isMarketer();

  const handleDisplay = () => {
    if (isFetchingUser || isNotInitialised) {
      return <LoadingPage />;
    } else if (!userIsMarketer && showRegisterPage) {
      return (
        <TranslationProvider>
          <Register />
        </TranslationProvider>
      );
    } else {
      return children;
    }
  };

  return <>{handleDisplay()}</>;
};

export const sideNavTranslations = {
  sideNav: {
    'APP.NAV.HOME': 'Home',
    'APP.NAV.PROGRAMMES': 'Programmes',
    'APP.NAV.CAMPAIGNS': 'Campaigns',
    'APP.NAV.MARKETPLACE': 'Connect',
    'APP.NAV.PROFILE': 'Profile',
    'APP.NAV.WALLET': 'Wallet',
    'APP.NAV.SETTINGS': 'Settings',
    'APP.NAV.PAYMENT': 'Payment',
    'APP.NAV.LOGOUT.TEXT': 'Click to Log Out',
    'APP.NAV.LOGOUT': 'Log Out',
    'APP.NAV.CONTACT': 'Contact',
    'APP.NAV.TERMS': 'Terms and Conditions',
    'APP.NAV.PRIVACY': 'Privacy Policy',
    'APP.NAV.HELP': 'Help',
    'APP.NAV.TOGGLE': 'toggle menu',
  },
};

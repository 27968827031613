import styled, { css } from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { Theme, theme } from '../../styling/theme';

const getColor = (
  isDark: boolean,
  theme: Theme,
  isCompleted?: boolean,
  isActive?: boolean
) => {
  if (isActive && !isDark) {
    return theme.COLOUR.SECONDARY_DARK;
  }
  if (isCompleted) {
    return theme.COLOUR.WHITE;
  } else {
    return theme.COLOUR.TEXT_STANDARD;
  }
};

export const StagesContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  margin-left: ${props => (props.isMobile ? `4rem` : `6rem`)};
}
  & > div {
    display: grid;
    grid-template-columns: ${props =>
      props.isMobile ? `2.5rem 4rem` : `3rem 6rem`};
  }
`;

export const StagesLabelsContainer = styled.div<{
  isMobile: boolean;
  size: number;
}>`
  display: grid;
  grid-template-columns: ${props =>
    props.isMobile
      ? `repeat(${props.size}, 6.5rem)`
      : `repeat(${props.size}, 9rem)`};
`;

export const StageLabel = styled.span<{
  isActive: boolean;
  isDark: boolean;
  mode?: 'dark' | 'normal';
}>`
  text-align: center;
  color: ${props =>
    props.isDark ? 'white' : theme(props).COLOUR.TEXT_STANDARD};
  ${props =>
    props.isActive &&
    css`
      color: ${props.mode === 'dark'
        ? theme(props).COLOUR.SECONDARY
        : theme(props).COLOUR.SECONDARY_DARK};
    `};
`;

export const StageBubble = styled.div<{
  isDark: boolean;
  isCompleted?: boolean;
  isActive?: boolean;
  isMobile?: boolean;
}>`
  border: 2px solid
    ${props =>
      props.isCompleted && !props.isDark
        ? theme(props).COLOUR.SECONDARY
        : theme(props).COLOUR.BACKGROUND_MEDIUM};
  background-color: ${props => (props.isDark ? 'white' : '')};
  border-radius: 50%;
  width: ${props => (props.isMobile ? px2rem(40) : px2rem(50))};
  height: ${props => (props.isMobile ? px2rem(40) : px2rem(50))};
  color: ${props =>
    getColor(props.isDark, theme(props), props.isCompleted, props.isActive)};
  ${props =>
    props.isActive &&
    css`
      border: 3px solid ${props => theme(props).COLOUR.SECONDARY};
    `}
  ${props =>
    props.isCompleted &&
    css`
      background-color: ${props => theme(props).COLOUR.SECONDARY};
    `}
`;

export const ProgressLine = styled.div<{
  isCompleted: boolean;
  isDark: boolean;
}>`
  border-bottom: 1px solid
    ${props => (props.isDark ? 'white' : theme(props).COLOUR.BACKGROUND_MEDIUM)};
  height: 25px;
  ${props =>
    props.isCompleted &&
    css`
      border-bottom: 3px solid ${props => theme(props).COLOUR.SECONDARY};
    `}
`;

export const Label = styled.span`
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

import styled from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const LoadingPageContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${props => theme(props).COLOUR.BACKGROUND_DARKEST};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: ${px2rem(50)};
  margin: 0 auto;
  width: 50%;
`;

import styled from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const StepperWrapper = styled.div`
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
`;

export const StepperTitle = styled.h2`
  ${props => theme(props).TYPOGRAPHY.LARGE_2_alt};
`;

export const StepperSubtitle = styled.h3`
  ${props => theme(props).TYPOGRAPHY.LARGE_1_alt};
`;

export const StepperLink = styled.a`
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StepIcon = styled.span<{ visited?: boolean }>`
  border: 1px solid ${props => theme(props).COLOUR.SECONDARY};
  width: ${px2rem(40)};
  height: ${px2rem(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding-top: ${px2rem(2)};
  box-shadow: inset 0 0 0px 1px ${props => theme(props).COLOUR.SECONDARY};
  position: absolute;
  left: -${px2rem(20)};
  background: white;
  top: 0;
  transition: color 0.3s ease-out;

  background-color: ${props =>
    props.visited ? theme(props).COLOUR.SECONDARY : 'white'};
  color: ${props => (props.visited ? 'white' : theme(props).COLOUR.SECONDARY)};
`;

export const StepContent = styled.div`
  position: relative;
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT};
`;

export const StepTitle = styled.div<{ active?: boolean; visited?: boolean }>`
  ${props =>
    props.active
      ? theme(props).TYPOGRAPHY.MEDIUM_1_alt
      : theme(props).TYPOGRAPHY.MEDIUM_1};
  min-height: ${px2rem(40)};
  display: flex;
  align-items: center;
  text-decoration: ${props => (props.visited ? 'underline' : 'none')};
`;

export const Step = styled.li`
  display: flex;
  border-left: 1px solid ${props => theme(props).COLOUR.SECONDARY};
  flex-direction: column;
  margin-left: ${px2rem(20)};
  padding-left: ${px2rem(40)};
  position: relative;
  min-height: ${px2rem(72)};
  cursor: pointer;

  &:last-child {
    border-color: transparent;
  }
`;

import { useMemo } from 'react';
import { useResponsive } from '../../../../hooks/useResponsive';
import {
  RequestPutNotificationsAsync,
  SetIsShowingNotifications,
} from '../../../../store/Notification/actions';
import { useStore } from '../../../../store/state';

import { Breakpoint } from '../../../styling/breakpoints';

export const useNotificationCenter = (
  setShowNotifications = SetIsShowingNotifications,
  markAllAsRead = RequestPutNotificationsAsync
) => {
  const [{ notificationState }, dispatch] = useStore();
  const { layout } = useResponsive();

  const hasUnread = useMemo(() => {
    return !!notificationState.notifications.filter(notif => !notif.read)
      .length;
  }, [notificationState.notifications]);

  const toggleNotifications = (shouldOpen: boolean) => {
    setShowNotifications(dispatch, shouldOpen);
  };

  const handleMarkAsRead = () => {
    hasUnread && markAllAsRead(dispatch);
  };

  return {
    isMobile: layout < Breakpoint.DESKTOP,
    notifications: notificationState.notifications,
    isLoading: notificationState.isLoading.getNotifications,
    hasError: notificationState.errors.getNotifications,
    toggleHandler: toggleNotifications,
    isShowingNotifications: notificationState.isShowingNotifications,
    dispatch: dispatch,
    handleMarkAsRead,
    hasUnread,
  };
};

import styled from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const Separator = styled.hr`
  margin: 0;
  width: 100%;
  height: ${px2rem(4)};
  border: 0 none;
  ${props => theme(props).COLOUR.GRADIENT_STANDARD};
  opacity: 0.6;
`;

import { keyframes } from 'styled-components';

export const EFFECTS = {
  RADIUS_STANDARD: '8px',
  RADIUS_FULL: '100%',

  TRANSITION_QUICK: '0.3s ease',
  TRANSITION_SLOW: '0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)',

  POPOUT_DROPSHADOW: '5px 5px 19px 0px rgba(179, 177, 179, 0.78);',

  FADE_IN_ANIMATION: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
};

import React from 'react';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';
import s from '../Modal.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { marketplaceTranslations } from '../../../pages/Marketplace/translations';

export const MarketplaceWaitlist: React.FC = () => {
  const { waitlistModal: t } = useTranslationsController(
    marketplaceTranslations
  );

  return (
    <div data-testid={'t_waitlist_modal_marketplace'}>
      <s.ModalText>{t['MARKETPLACE.WAITLIST.MODAL.INTERACT.TEXT']}</s.ModalText>
      <s.ModalList>
        <s.ModalListItem>
          <span className="icon">
            <FontAwesomeIcon icon="link" />
          </span>
          {t['MARKETPLACE.WAITLIST.GENERATE.TRACKING.LINKS.TEXT']}
        </s.ModalListItem>
        <s.ModalListItem>
          <span className="icon">
            <FontAwesomeIcon icon="store" />
          </span>
          {t['MARKETPLACE.WAITLIST.ACCESS.PRODUCT.CATALOGUE.TEXT']}
        </s.ModalListItem>
        <s.ModalListItem>
          <span className="icon">
            <FontAwesomeIcon icon="percent" />
          </span>
          {t['MARKETPLACE.WAITLIST.COMMISSION.RATES.TEXT']}
        </s.ModalListItem>
        <s.ModalListItem>
          <span className="icon">
            <FontAwesomeIcon icon="chart-line" />
          </span>
          {t['MARKETPLACE.WAITLIST.MODAL.INSIGHTS.TEXT']}
        </s.ModalListItem>
      </s.ModalList>
      <s.ModalText>{t['MARKETPLACE.WAITLIST.JOIN.WAITLIST.TEXT']}</s.ModalText>
    </div>
  );
};

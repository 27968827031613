import { useCallback, useEffect, useState } from 'react';
import {
  RequestGetUserFacebookTokenStatusAsync,
  RequestGetYoutubeStatusAsync,
} from '../store/Social/store';
import { useStore } from '../store/state';
import {
  RequestGetProfileTokenAsync,
  RequestGetSocialConnection,
} from '../store/User/actions';

export const useSocialIntegrations = (
  getState = useStore,
  getFacebookStatusAction = RequestGetUserFacebookTokenStatusAsync,
  getYoutubeStatusAction = RequestGetYoutubeStatusAsync,
  getTokenAsync = RequestGetProfileTokenAsync
) => {
  const [{ socialState, userState }, dispatch] = getState();
  const [isFacebookInitialised, setIsFacebookInitialised] = useState(false);
  const [isYoutubeInitialised, setIsYoutubeInitialised] = useState(false);
  const {
    isInitialised,
    isRequested,
    isFetching,
    youtube,
    facebook,
    isUpdating,
    errors,
  } = socialState;

  const daysRemaining = userState.socialConnection?.daysRemaining;
  const daysRemainingInstagram =
    userState.socialConnection?.daysRemainingInstagram;
  const daysRemainingYoutube = userState.socialConnection?.daysRemainingYoutube;

  const allInitialised = Object.values(isInitialised).every(value => value);
  const allFetched = Object.values(isFetching).every(value => !value);
  const allUpdated = Object.values(isUpdating).every(value => !value);

  const facebookConnected = !!facebook?.handle;
  const youtubeConnected = !!youtube?.refresh_token;
  const hasOneSocialConnected = facebookConnected || youtubeConnected;
  const isLoading = !(allInitialised && allFetched && allUpdated);
  const instagramErrors = errors.facebook;
  const youtubeErrors = errors.youtube;

  const needsFacebookRefresh =
    facebookConnected && socialState.facebook?.needs_refresh;
  const needsYoutubeRefresh =
    youtubeConnected && socialState.youtube?.needs_refresh;

  const ignoreFacebookRefresh = socialState.facebook?.ignore_refresh;
  const ignoreYoutubeRefresh = socialState.youtube?.ignore_refresh;

  const fetchErrors = Object.values(errors).filter(error => error !== null);
  const fetchFacebookStatus = useCallback(() => {
    const influencerId = userState.user?.influencer_id;
    if (influencerId && !isFacebookInitialised) {
      setIsFacebookInitialised(true);
      getTokenAsync(dispatch)
        .then(() => {
          getFacebookStatusAction(dispatch, influencerId).catch(e => void e);
        })
        .catch(e => void e);
    }
  }, [
    userState.user,
    isFacebookInitialised,
    getTokenAsync,
    dispatch,
    getFacebookStatusAction,
  ]);

  const fetchYoutubeStatus = useCallback(() => {
    const influencerId = userState.user?.influencer_id;
    if (influencerId && !isYoutubeInitialised) {
      setIsYoutubeInitialised(true);
      getYoutubeStatusAction(dispatch, influencerId).catch(e => void e);
    }
  }, [dispatch, getYoutubeStatusAction, isYoutubeInitialised, userState.user]);

  useEffect(() => {
    if (!isRequested.facebook) {
      fetchFacebookStatus();
    }
    if (!isRequested.youtube) {
      fetchYoutubeStatus();
    }
  }, [socialState, fetchFacebookStatus, fetchYoutubeStatus, isRequested]);

  const influencer_id = userState.user?.influencer_id;

  useEffect(() => {
    if (userState.socialConnection === null && influencer_id) {
      RequestGetSocialConnection(dispatch, influencer_id);
    }
  }, [dispatch, influencer_id, userState.socialConnection]);

  return {
    allInitialised,
    allFetched,
    hasOneSocialConnected,
    facebookConnected,
    youtubeConnected,
    isLoading,
    errors: fetchErrors,
    instagramErrors,
    youtubeErrors,
    needsFacebookRefresh,
    needsYoutubeRefresh,
    ignoreFacebookRefresh,
    ignoreYoutubeRefresh,
    daysRemaining,
    daysRemainingInstagram,
    daysRemainingYoutube,
  };
};

import React, { FC, ChangeEvent } from 'react';
import { ValidationMessage, TextInput, FormLabel } from '..';
import { FormValue } from '../../../store/BankAccount/store';

const isValid = (val: any) => val && (val.isValid || val.value === '');

export interface FormFieldProps {
  readOnly: boolean;
  value: FormValue | null;
  label: string;
  changeHandler?: (a: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  maxLength?: number;
  validationMessage?: string;
  disabled?: boolean;
  testId?: string;
  title?: string;
}

export const FormField: FC<FormFieldProps> = ({
  value,
  label,
  changeHandler,
  placeholder,
  maxLength,
  validationMessage,
  readOnly,
  disabled = false,
  testId = 't_input_field',
  title,
}) => {
  const id = `id_${testId}_${label.toLowerCase().replace(/ /g, '_')}`;
  return (
    <>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <TextInput
        id={id}
        title={title}
        required={value?.isRequired || false}
        aria-required={value?.isRequired || false}
        readOnly={readOnly}
        aria-readonly={readOnly}
        data-testid={testId}
        placeholder={placeholder}
        onChange={changeHandler}
        value={value?.value || ''}
        maxLength={maxLength}
        disabled={disabled}
        aria-disabled={disabled}
        aria-invalid={!isValid(value)}
      />
      <ValidationMessage
        testId="t_validation"
        isValid={isValid(value)}
        message={validationMessage || `${label} is invalid`}
      />
    </>
  );
};

import styled, { css } from 'styled-components/macro';
import { theme } from '../../../../styling/theme';
import { SocialButton } from '../SocialButton';

export const FacebookButton = styled(SocialButton)<{
  fullWidth?: boolean;
  monochrome?: boolean;
}>`
  background: ${props => theme(props).COLOUR.BRAND_INSTAGRAM};

  ${props =>
    props.monochrome &&
    css`
      background: white;
      color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
    `}
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

export const ErrorMessageText = styled.div`
  color: red;
`;

export const ListElement = styled.li`
  margin-left: ${props => theme(props).SIZE.AVERAGE};
  list-style-type: circle;
`;

import React, { useState, MouseEvent } from 'react';
import {
  Hamburger,
  NavContainer,
  BarsContainer,
  BellContainer,
} from '../../Navigation/MobileNav/MobileNav.styles';
import {
  NavContainer as MobileNavContainer,
  LinkContainer,
  Container,
} from '../../Navigation/MobileNav/NavigationPage/NavigationPage.styles';
import { Logo } from '../../../common/Logo';
import { NotificationController } from '../../../pages/Notifications';
import { MenuItem } from '../../Navigation/MobileNav/MenuItem/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslationsController } from '../../../../utils/globalTranslationsController';
import { agentNavigationTranslation } from '../translations';

export const MobileNav: React.FC<{
  logoutHandler: (e: MouseEvent<Element>) => void;
  isExpanded?: boolean;
}> = ({ logoutHandler, isExpanded = false }) => {
  const [isNavExpanded, toggleExpanded] = useState(isExpanded);
  const { agencyNavigationLabels: t } = useTranslationsController(
    agentNavigationTranslation
  );

  const homeText = t['AGENT.HOME.LABEL'];
  const logoutText = t['AGENT.LOGOUT.LABEL'];
  const toggleText = t['AGENT.TOGGLE.TEXT'];
  const influencerText = t['AGENT.INFLUENCERS.LABEL'];
  const settingsText = t['APP.NAV.SETTINGS'];
  const paymentAccountsText = t['AGENT.PAYMENT_ACCOUNTS.LABEL'];
  const walletText = t['AGENT.WALLET'];
  const profileText = t['AGENT.PROFILE.LABEL'];

  return (
    <NavContainer role="navigation" data-testid="t_mobile_nav">
      <BarsContainer>
        <Hamburger
          aria-label={toggleText}
          aria-expanded={isNavExpanded}
          onClick={() => toggleExpanded(!isNavExpanded)}
          isChanging={isNavExpanded}
        >
          <span></span>
          <span></span>
          <span></span>
        </Hamburger>
      </BarsContainer>
      <Logo />
      <BellContainer>
        <NotificationController />
      </BellContainer>
      {isNavExpanded && (
        <MobileNavContainer data-testid="t_nav">
          <Container as="ul">
            {/* <LinkContainer as="li">
              <MenuItem
                route="/"
                label={homeText}
                icon={<FontAwesomeIcon icon="home" />}
                toggleNavigation={() => toggleExpanded(!isNavExpanded)}
              />
            </LinkContainer> */}
            <LinkContainer as="li">
              <MenuItem
                route="/influencers"
                label={influencerText}
                icon={<FontAwesomeIcon icon="users" />}
                toggleNavigation={() => toggleExpanded(!isNavExpanded)}
              />
            </LinkContainer>
            <LinkContainer as="li">
              <MenuItem
                route="/wallet/bank-accounts"
                label={paymentAccountsText}
                icon={<FontAwesomeIcon icon="university" />}
                toggleNavigation={() => toggleExpanded(!isNavExpanded)}
              />
            </LinkContainer>
            <LinkContainer as="li">
              <MenuItem
                route="/wallet"
                label={walletText}
                icon={<FontAwesomeIcon icon="wallet" />}
                toggleNavigation={() => toggleExpanded(!isNavExpanded)}
              />
            </LinkContainer>
            <LinkContainer as="li">
              <MenuItem
                route="/profile"
                label={profileText}
                icon={<FontAwesomeIcon icon="user" />}
                toggleNavigation={() => toggleExpanded(!isNavExpanded)}
              />
            </LinkContainer>
            <LinkContainer as="li">
              <MenuItem
                route="/settings"
                label={settingsText}
                icon={<FontAwesomeIcon icon="cog" />}
                toggleNavigation={() => toggleExpanded(!isNavExpanded)}
              />
            </LinkContainer>
            <LinkContainer
              as="li"
              data-testid="t_nav_menu_logout"
              onClick={logoutHandler}
            >
              <MenuItem
                route="/"
                label={logoutText}
                icon={<FontAwesomeIcon icon="sign-out-alt" />}
                toggleNavigation={() => toggleExpanded(!isNavExpanded)}
              />
            </LinkContainer>
          </Container>
        </MobileNavContainer>
      )}
    </NavContainer>
  );
};

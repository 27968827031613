import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { useResponsive } from '../../../../hooks/useResponsive';
import {
  RequestGetNotificationsAsync,
  SetIsShowingNotifications,
} from '../../../../store/Notification/actions';
import { useStore } from '../../../../store/state';
import { minutesToMilliseconds } from '../../../../utils/date.utils';
import { Breakpoint } from '../../../styling/breakpoints';

export const useNotificationController = (
  getNotifications = RequestGetNotificationsAsync,
  setShowNotifications = SetIsShowingNotifications
) => {
  const [{ notificationState, userState }, dispatch] = useStore();
  const [lastViewed, setLastViewed] = useLocalStorage(
    'lastViewed',
    dayjs('1970-01-01')
  );
  const { layout } = useResponsive();
  const [isInitialised, setIsInitialised] = useState<boolean>(false);

  useEffect(() => {
    if (isInitialised) {
      const interval = setInterval(() => {
        getNotifications(dispatch);
      }, minutesToMilliseconds(5));
      return () => clearInterval(interval);
    }
  });

  useEffect(() => {
    if (!isInitialised && userState.user?.key_cloak_id) {
      getNotifications(dispatch);
      setIsInitialised(true);
    }
  }, [isInitialised, getNotifications, dispatch, userState.user]);

  const toggleNotifications = (shouldOpen: boolean) => {
    setShowNotifications(dispatch, shouldOpen);
    shouldOpen && setLastViewed(dayjs());
  };

  const unreadCount = useMemo(() => {
    return notificationState.notifications.filter(
      notif => !notif.read && notif.createdAt.isAfter(lastViewed)
    ).length;
  }, [notificationState.notifications, lastViewed]);

  const hasViewedNotifications =
    !notificationState.notifications.length ||
    !unreadCount ||
    (!!notificationState.notifications.length &&
      notificationState.notifications[0].createdAt.isBefore(lastViewed));

  return {
    isInitialised,
    isMobile: layout < Breakpoint.DESKTOP,
    notifications: notificationState.notifications,
    isLoading: notificationState.isLoading.getNotifications,
    hasError: notificationState.errors.getNotifications,
    unreadCount: unreadCount,
    toggleHandler: toggleNotifications,
    isShowingNotifications: notificationState.isShowingNotifications,
    hasViewedNotifications,
  };
};

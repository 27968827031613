import styled from 'styled-components';
import { theme } from '../../styling/theme';

export const IconButtonOutlineStyle = styled.button`
  width: ${props => theme(props).SIZE.MIN_TARGET};
  height: ${props => theme(props).SIZE.MIN_TARGET};
  border: 1px solid ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
  border-radius: ${props => theme(props).EFFECTS.RADIUS_STANDARD};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${props => theme(props).COLOUR.BACKGROUND_PRIMARY};
  color: ${props => theme(props).COLOUR.BACKGROUND_DARK};

  &:hover:not([disabled]) {
    color: white;
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    background-color: ${props => theme(props).COLOUR.PRIMARY_DARK};
  }

  &:focus:not([disabled]) {
    color: white;
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
    background-color: ${props => theme(props).COLOUR.PRIMARY_DARK};
  }

  &:disabled {
    background-color: ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
    border: none;
    cursor: not-allowed;
  }
`;

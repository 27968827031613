import React, { createContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Breakpoint } from '../../styling/breakpoints';

const isWithin = (breakpoint: Breakpoint) => {
  if (!window?.matchMedia) return false;
  return window.matchMedia(`(max-width: ${breakpoint}px)`).matches;
};

const getLayout = (): Breakpoint => {
  if (isWithin(Breakpoint.MOBILE)) return Breakpoint.MOBILE;
  if (isWithin(Breakpoint.TABLET)) return Breakpoint.TABLET;
  if (isWithin(Breakpoint.DESKTOP)) return Breakpoint.DESKTOP;
  if (isWithin(Breakpoint.TEMP_1500)) return Breakpoint.TEMP_1500;

  return Breakpoint.TEMP_1900;
};

export interface ResponsiveInfo {
  layout: Breakpoint;
}

const initialState: ResponsiveInfo = {
  layout: getLayout(),
};

export const ResponsiveContext = createContext<ResponsiveInfo>(initialState);

export const ResponsiveProvider: React.FC = ({ children }) => {
  const [info, setInfo] = useState<ResponsiveInfo>(initialState);

  const updateLayout = () => {
    const newLayout = getLayout();
    setInfo({ ...info, layout: newLayout });
  };

  useEffect(() => {
    window.addEventListener('resize', updateLayout);
    return () => {
      window.removeEventListener('resize', updateLayout);
    };
  });

  return (
    <ResponsiveContext.Provider value={info}>
      {children}
    </ResponsiveContext.Provider>
  );
};

import styled, { css } from 'styled-components';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const CloseButton = styled.button`
  width: ${px2rem(25)};
  height: ${px2rem(25)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0 none;
  position: absolute;
  right: ${px2rem(16)};
  &:hover,
  &:focus {
    background-color: ${props => theme(props).COLOUR.PRIMARY};
    color: white;
    transition: all ${props => theme(props).EFFECTS.TRANSITION_QUICK};
  }
`;

export const CustomDialogTitle = styled.div`
  padding: ${px2rem(16)} ${px2rem(42)} 0 ${px2rem(16)};
  display: flex;
  justify-content: space-between;
  position: relative;
  > h2 {
    max-width: calc(100% - 32px);
    width: 100%;
  }
`;

export const CustomDialogContent = styled.div<{
  fullScreen: boolean;
}>`
  padding: ${px2rem(16)} ${px2rem(16)} 0;
  ${props =>
    props.fullScreen &&
    css`
      flex: 1;
    `}
`;

export const CustomDialogActions = styled.div`
  padding: ${px2rem(16)} ${px2rem(16)} ${px2rem(16)} ${px2rem(16)};
  display: flex;
  justify-content: flex-end;
`;

export const ProgressBarContainer = styled.div<{ showLoading: boolean }>`
  visibility: ${props => (props.showLoading ? 'visible' : 'hidden')};
`;

export const ModalHeader = styled.div`
  ${props => theme(props).TYPOGRAPHY.LARGE_2_alt};
`;

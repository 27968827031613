import styled, { css } from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { GridContainerProps } from './GridContainer.types';

export const GRID_POINTS = 8;

export const GridContainerStyles = css<GridContainerProps>`
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-gap: ${px2rem(16)};
  grid-template-columns: ${props =>
    props.columnWidth
      ? `repeat(auto-fit, minmax(${px2rem(props.columnWidth)}, 1fr))`
      : `repeat(auto-fit, minmax(${px2rem(270)}, 1fr))`};
  opacity: ${props => (props.isLoading ? 0.3 : 1)};
  transition: opacity 0.3s linear;

  @media screen and (max-width: ${props =>
      px2rem(props.columnWidth || 270, 'em')}) {
    grid-template-columns: 1fr;
  }
`;

export const StandardLayout = styled.div`
  ${GridContainerStyles};
`;

export const MasonryLayout = styled.div`
  ${GridContainerStyles};
  grid-gap: 0 ${px2rem(GRID_POINTS * 2)};
  grid-auto-rows: ${px2rem(GRID_POINTS)};
  grid-auto-flow: dense;
`;

export const StandardBrick = styled.div`
  & > * {
    display: flex;
    height: 100%;
  }
`;

export const MasonryBrick = styled.div<{ spans?: number }>`
  grid-row-end: span ${props => props.spans || 0};
  overflow: hidden;
  visibility: ${props =>
    props?.spans && props.spans > 0 ? 'visible' : 'hidden'};

  > * {
    padding-bottom: ${px2rem(GRID_POINTS * 2)};
    height: fit-content;
  }
`;

export const MasonryWrapper = styled.div`
  & .grid {
    display: flex;
    margin-left: -${px2rem(16)};
    width: auto;
  }
  & .column {
    padding-left: ${px2rem(16)};
    background-clip: padding-box;

    & > * {
      padding-bottom: ${px2rem(16)};
    }
  }
`;

import React, { createContext, useContext, useReducer } from 'react';
import { Dispatch, MarketplaceDispatchFn } from './types';
import { MarketplaceReducer } from './reducer';
import { MarketplaceState, defaultMarketplaceState } from './store';

interface State {
  marketplaceState: MarketplaceState;
}

export const defaultState: State = Object.freeze({
  marketplaceState: defaultMarketplaceState,
});

type Store = [State, MarketplaceDispatchFn];

const MarketplaceStoreContext = createContext<Store>([
  defaultState,
  () => {
    return;
  },
]);

const rootReducer = (state: State = defaultState, action: Dispatch): State => {
  return {
    marketplaceState: MarketplaceReducer(state.marketplaceState, action),
  };
};

export const MarketplaceStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, defaultState);

  return (
    <MarketplaceStoreContext.Provider value={[state, dispatch]}>
      {children}
    </MarketplaceStoreContext.Provider>
  );
};

export const useMarketplaceStore = (): [State, MarketplaceDispatchFn] =>
  useContext(MarketplaceStoreContext);

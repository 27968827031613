import styled from 'styled-components';
import { px2rem } from '../../../styling/sizing';
import { theme } from '../../../styling/theme';

export const PageButtonContainer = styled.div`
  width: 100%;
  justify-content: center;
  outline: none;
  padding-top: ${props => theme(props).SIZE.SMALL};
  display: flex;
`;

export const PageButton = styled.button<{ isCurrent?: boolean }>`
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  user-select: none;
  border-radius: ${px2rem(8)};
  cursor: pointer;
  min-width: ${px2rem(115)};
  border: none;
  border-color: transparent;
  min-height: ${px2rem(24)};
  padding: 0 ${px2rem(24)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  outline: none;
  background-color: white;
  min-width: auto;
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  ${props =>
    props.isCurrent &&
    `background-color: ${theme(props).COLOUR.BACKGROUND_LIGHTEST}`};
  cursor: pointer;
  :hover {
    background-color: ${props => theme(props).COLOUR.BACKGROUND_LIGHTEST};
  }
  :active {
    background-color: ${props => theme(props).COLOUR.BACKGROUND_DARK};
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  & svg {
    font-size: ${props => theme(props).SIZE.SMALL};
    color: ${props => theme(props).COLOUR.BACKGROUND_MEDIUM};
    margin: ${props => theme(props).SIZE.SMALL} 0;
  }
`;

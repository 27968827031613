import { StringMap } from '../../types/map.types';
import { Programme } from '../Programme/store';

export interface MediaObjectAPI {
  link: string;
  file_upload_id: string;
  thumbnail_file_upload_id: string;
}

export enum ReportingPeriod {
  day = 'day',
  days30 = 'days30',
  months6 = 'months6',
  year = 'year',
}

export interface CommissionValues {
  sales_value: number;
  sales_commission: number;
}

export type TotalCommissions = StringMap<StringMap<CommissionValues>>;

export interface ReportAPI {
  total_commissions: TotalCommissions | null;
  top_posts: {
    instagram: MediaObjectAPI[];
    youtube: MediaObjectAPI[];
  };
  social_insights: {
    instagram: {
      impressions: number;
      reach: number;
      engagement: number;
      likes: number;
    };
    youtube: {
      views: number;
      comments: number;
      likes: number;
      dislikes: number;
    };
  };
  clicks: number;
  conversion_rate: number | null;
}

export interface MediaObject {
  link: string;
  fileUploadId: string;
  thumbnailFileUploadId: string;
}

export interface ReportTopPosts {
  [index: string]: MediaObject[];
}

interface ReportSocialInsightsInstagram {
  impressions: number;
  reach: number;
  engagement: number;
  likes: number;
}

interface ReportSocialInsightsYoutube {
  views: number;
  comments: number;
  likes: number;
  dislikes: number;
}

export interface Report {
  totalCommissions: TotalCommissions | null;
  topPosts: ReportTopPosts;
  socialInsights: {
    instagram: ReportSocialInsightsInstagram;
    youtube: ReportSocialInsightsYoutube;
  };
  clicks: number;
  conversionRate: number | null;
}

export interface TrackingLinkPerformanceAPI {
  total_clicks: number;
  click_conversion_rate: number;
  average_order_value: number;
  total_partner_commission: number;
  earliest_tracking_date: string;
}

export interface TrackingLinkPerformance {
  totalClicks: number;
  clickConversionRate: number;
  averageOrderValue: number;
  totalPartnerCommission: number;
  earliestTrackingDate: string;
}

export type TrackingLinksPerformancesAPI = Record<
  string,
  TrackingLinkPerformanceAPI
>;
export type TrackingLinksPerformances = Record<string, TrackingLinkPerformance>;

export interface ReportingState {
  report: Report | null;
  selectedReportingProgramme: Programme | null;
  selectedReportingDateRange: ReportingPeriod;
  trackingLinksPerformances: TrackingLinksPerformances | null;
  isLoading: {
    getReport: boolean;
    trackingLinksPerformances: boolean;
  };
  errors: {
    getReport: string | null;
    trackingLinksPerformances: string | null;
  };
}

export const defaultReportingState: ReportingState = {
  report: null,
  selectedReportingProgramme: null,
  selectedReportingDateRange: ReportingPeriod.day,
  trackingLinksPerformances: null,
  isLoading: {
    getReport: false,
    trackingLinksPerformances: false,
  },
  errors: {
    getReport: null,
    trackingLinksPerformances: null,
  },
};

export interface ReportingQueries {
  programme: string;
  period: string;
}

import styled from 'styled-components/macro';
import { px2rem } from '../../styling/sizing';
import { theme } from '../../styling/theme';

export const Header1 = styled.h1<{ align?: string }>`
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  ${props => theme(props).TYPOGRAPHY.LARGE_2_alt};
  padding: ${px2rem(25)} 0 ${px2rem(26)} 0;
  user-select: none;
  width: 100%;
  text-align: ${props => (props.align ? props.align : 'initial')};
`;

export const Header2 = styled.h2<{ align?: string }>`
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1_alt};
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  text-align: ${props => (props.align ? props.align : 'initial')};
`;

export const Header3 = styled.h3<{ align?: string }>`
  ${props => theme(props).TYPOGRAPHY.MEDIUM_1};
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  text-align: ${props => (props.align ? props.align : 'initial')};
`;

export const Header4 = styled.h4<{ align?: string }>`
  ${props => theme(props).TYPOGRAPHY.BODY_TEXT_alt};
  color: ${props => theme(props).COLOUR.TEXT_STANDARD};
  text-align: ${props => (props.align ? props.align : 'initial')};
`;

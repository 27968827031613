import styled, { css } from 'styled-components/macro';
import { Margin, Padding } from '../Box/types';

export const CenteredFlexStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface FlexContainerProps {
  fullWidth?: boolean;
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: 'row' | 'column' | 'row-reverse';
  margin?: string;
  marginLeft?: Margin;
  marginRight?: Margin;
  marginBottom?: Margin;
  paddingRight?: Padding;
  marginTop?: Margin;
  flexWrap?: string;
  textAlign?: string;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  ${CenteredFlexStyles};
  ${props => props.fullWidth && 'width: 100%;'}
  ${props =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => props.alignItems && `align-items: ${props.alignItems};`};
  ${props => props.flexDirection && `flex-direction: ${props.flexDirection};`};
  ${props => props.flexWrap && `flex-wrap: ${props.flexWrap};`}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.margin && `margin-top: ${props.marginTop};`}
  ${props => props.margin && `margin-bottom: ${props.marginBottom};`}
  ${props => props.margin && `margin-right: ${props.marginRight};`}
  ${props => props.margin && `margin-left: ${props.marginLeft};`}
  ${props => props.paddingRight && `padding-right: ${props.paddingRight};`}
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
`;
